<section class="edit-container-popup" style="margin: auto;width: 98%;">     
    <form [formGroup]="formAdd">
         
            <div class="row" fxLayout="column" fxLayoutGap=".5em">
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <small id="name" class="form-text text-muted">Regular Distribute Time Units Factor<span style="color:red">*</span>:</small>
                    <div>
                        <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="distributeTimeUnitsFactor" class="form-control" placeholder="0.00" (change)="castToFloat($event,'distributeTimeUnitsFactor','float')">
                        <mat-error *ngIf="!formAdd.controls.distributeTimeUnitsFactor.valid && formAdd?.touched">
                            Time Units Factor is required
                        </mat-error>
                    </div>
                </div>
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <small id="name" class="form-text text-muted">Regular Distribute Time Units Factor<span style="color:red">*</span>:</small>
                    <div>
                        <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="OverTimedistributeTimeUnitsFactor" class="form-control" placeholder="0.00" (change)="castToFloat($event,'OverTimedistributeTimeUnitsFactor','float')">
                        <mat-error *ngIf="!formAdd.controls.OverTimedistributeTimeUnitsFactor.valid && formAdd?.touched">
                            Over Time Units Factor is required
                        </mat-error>
                    </div>
                </div>
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <small id="name" class="form-text text-muted">Over Time Start<span style="color:red">*</span>:</small>
                    <div>
                        <input type="time" class="form-control p-8" id="OverTimeStart" formControlName="OverTimeStart" />
                        <mat-error *ngIf="!formAdd.controls.OverTimeStart.valid && formAdd?.touched">
                            Over Time Start is required
                        </mat-error>
                    </div>
                </div>
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <small id="name" class="form-text text-muted">Over Time End<span style="color:red">*</span>:</small>
                    <div>
                        <input type="time" class="form-control p-8" id="OvertimeEnd" formControlName="OvertimeEnd" />
                        <mat-error *ngIf="!formAdd.controls.OvertimeEnd.valid && formAdd?.touched">
                            Over Time End is required
                        </mat-error>
                    </div>
                </div>
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Provider Type:</small>
                        <app-multi-select [dropdownList]="data?.lookupData?.providerType" [selectedItems]="data?.node?.data?.filters?.providerTypeId" [singleSelection]="'true'" [placeholder]="'Provider Type'" #providerType></app-multi-select>
                    </div>
                </div>
                <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Cpt Codes:</small>
                        <app-multi-select [dropdownList]="data?.lookupData?.excludeCpts" [selectedItems]="data?.node?.data?.filters?.cptCodes" [singleSelection]="'true'" [placeholder]="'Cpt Codes'" #cptcodes></app-multi-select>
                    </div>
                </div>
            </div>
        
    </form>

    <mat-divider></mat-divider>
    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" (click)="onSaveConnector()">OK</button>
        <!-- <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button> -->
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
    </div>
</section>
