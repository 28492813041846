import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { getAge, getAgeDisplay } from '../../../../../Utility/common-functions';

@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.css']
})
export class TaskSummaryComponent {
  public _task: any;
  public selectedTabIndex: number = 0;
  public arData: any[] = [];
  @Input() set task(task: any) {
    /*this.sortedData.data = users;*/
    this._task = task;
    this.calculateAr()
  }
  get task() {
    return this._task;
  }
  calculateAr() {
    if (this._task) { 
      var age = getAge(this._task.FirstDOS);
      if (this.selectedTabIndex == 1)
        age = getAge(this._task.FirstDOE);
      if (this.selectedTabIndex == 2)
        age = getAge(this._task.DischargeDate);
      let data = [];
      var bu = parseInt(((age / 30) + 1).toString());
      data.push({
        Name: 'Ins',
        "Bu1": bu == 1 ? this._task.Total_Insurance_balance : '',
        "Bu2": bu == 2 ? this._task.Total_Insurance_balance : '',
        "Bu3": bu == 3 ? this._task.Total_Insurance_balance : '',
        "Bu4": bu == 4 ? this._task.Total_Insurance_balance : '',
        "Bu5": bu >= 5 ? this._task.Total_Insurance_balance : '',
      });
      data.push({
        Name: 'Pat',
        "Bu1": bu == 1 ? this._task.Total_Patient_Balance : '',
        "Bu2": bu == 2 ? this._task.Total_Patient_Balance : '',
        "Bu3": bu == 3 ? this._task.Total_Patient_Balance : '',
        "Bu4": bu == 4 ? this._task.Total_Patient_Balance : '',
        "Bu5": bu >= 5 ? this._task.Total_Patient_Balance : '',
      });
      data.push({
        Name: 'Total',
        "Bu1": bu == 1 ? this._task.Total_Balance : '',
        "Bu2": bu == 2 ? this._task.Total_Balance : '',
        "Bu3": bu == 3 ? this._task.Total_Balance : '',
        "Bu4": bu == 4 ? this._task.Total_Balance : '',
        "Bu5": bu >= 5 ? this._task.Total_Balance : '',
      });
      this.arData = data;
    }
  }
  onTabChanged(data: any) {
    let clickedIndex = data.index;
    //if (clickedIndex != this.selectedTabIndex) {
      //this.selectedTabIndex = clickedIndex - 1;
      this.calculateAr();
     
  }
}
