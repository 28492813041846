<div class="body-wrapper">
    <mat-card class="main-card" style="min-height:75vh">
        <mat-card-content>
            <mat-card-title>
                <div class="row" style="margin: auto;">
                    <div class="form-group col-lg-2">
                        <h3>
                            Payroll
                        </h3>
                        <h5>Home &gt; Payroll</h5>
                    </div>
                    <div class="form-group col-lg-10">
                        <div class="form-group float-end" fxLayout="row" fxLayoutGap=".5em">
                            <button mat-flat-button extended class="btn" (click)="onClear()">Clear</button>
                            <div *ngIf="showLock" class="btn-lock" [ngClass]="locked ? 'locked' : ''">
                                <i (click)="changeMode()" class="fas fa-lock-open" [ngStyle]="{ display: locked ? 'none' : 'block' }"></i>
                                <i (click)="changeMode()" class="fas fa-lock" [ngStyle]="{ display: locked ? 'block' : 'none' }"></i>
                            </div>
                            <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="runFlow()">Save</button>
                        </div>
                    </div>
                </div>
            </mat-card-title>
            <mat-divider></mat-divider>
            <div class="row align-items-start">
                <mat-drawer-container class="example-container" autosize style="padding:0px !important; min-height: 65vh;">
                    <mat-drawer #drawer class="example-sidenav queue-label-sidenav" mode="side" [opened]="true" [ngStyle]="{'width': '300px'}">
                        <div style="padding: 10px 0px 0px 10px;">
                            <ng-container *ngFor="let node of nodes">
                                <div class="drag-drawflow" draggable="true" (dragstart)="onDrawflowEvent($event)" [id]="node.id">

                                    <!--  <img [src]="node.infos.icon" *ngIf="node.infos.icon" /> -->
                                    {{ node.infos.name }}
                                </div>
                            </ng-container>
                        </div>
                    </mat-drawer>

                    <div class="example-sidenav-content">
                        <div id="drawflow"
                             (dragenter)="onDrawflowEvent($event)"
                             (dragleave)="onDrawflowEvent($event)"
                             (dragover)="onDrawflowEvent($event)"
                             (dragend)="onDrawflowEvent($event)"
                             (drop)="onDrawflowEvent($event)"></div>

                        <div *ngIf="showLock" class="btn-lock" [ngClass]="locked ? 'locked' : ''">
                            <i (click)="changeMode()" class="fas fa-lock-open" [ngStyle]="{ display: locked ? 'none' : 'block' }"></i>
                            <i (click)="changeMode()" class="fas fa-lock" [ngStyle]="{ display: locked ? 'block' : 'none' }"></i>
                        </div>
                        <div class="bar-zoom" *ngIf="showLock">
                            <i (click)="onZoomOut()" class="fas fa-search-minus"></i>
                            <i (click)="onZoomReset()" class="fas fa-search"></i>
                            <i (click)="onZoomIn()" class="fas fa-search-plus"></i>
                        </div>
                    </div>

                    <mat-drawer #drawer class="example-sidenav queue-label-sidenav" mode="side" [opened]="opensettingpane" [ngStyle]="{'width': '300px'}" position="end">
                        <div style="padding: 10px 0px 0px 10px;">
                            <app-payroll-input *ngIf="opensettingpane" (onCancel)="doCancel($event)" [data]="nodeData"></app-payroll-input>
                        </div>
                    </mat-drawer>
                </mat-drawer-container>
            </div>

        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
