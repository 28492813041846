<!--
<div class="box-header with-border">
    <label class="drawer-title">Ticket # {{(task)?task["Ticket#"]:''}}</label>


    <mat-icon style="float:right;" class="btn-closs" (click)="doCancelEdit()">close</mat-icon>



</div>
<mat-divider></mat-divider>
-->
<cdk-accordion class="example-accordion">
    <cdk-accordion-item #accordionItemDetails="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        [disabled]="tasks?.length>1 || tasks?.length==0"
                        [attr.id]="'accordion-header-details'"
                        [attr.aria-expanded]="accordionItemDetails.expanded"
                        [attr.aria-controls]="'accordion-body-details'" #expPanels>
        <div class="example-accordion-item-header" matTooltip="Deatils" [ngClass]="{'disabled':tasks?.length>1 || tasks?.length==0}" (click)="accordionItemDetails.toggle();doExpand()" fxLayout="row" fxLayoutGap=".5em">
            <!-- <mat-icon>av_timer</mat-icon> -->
            <mat-icon>info</mat-icon>
            <span *ngIf="expanded">Details</span>


        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemDetails.expanded ? '' : 'none'"
             [attr.id]="'accordion-body-details'"
             [attr.aria-labelledby]="'accordion-header-details'">
            <div fxLayout="column" fxLayoutGap="1em">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Ticket #:</div>
                            <div class="col-md-6">{{(task)?task.encounternumber:''}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">DOS:</div>
                            <div class="col-md-6">{{task?.firstDOS | datePipeSafe: 'MM/DD/YYYY':true}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Patient:</div>
                            <div class="col-md-6"> {{task?.patientName}}</div>
                        </div>

                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">DOS Age:</div>
                            <div class="col-md-6">{{task?.firstDOS | displayAge}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Account #:</div>
                            <div class="col-md-6"> {{task?.patient_accountnumber}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Followup Age:</div>
                            <div class="col-md-6">{{task?.lastactiondate | displayAge}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Priority :</div>
                            <div class="col-md-6"> {{task?.TaskListPriority}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Status :</div>
                            <div class="col-md-6"> {{task?.claimStatus}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Clinic :</div>
                            <div class="col-md-6"> {{task?.Clinics}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">CPT :</div>
                            <div class="col-md-6"> {{task?.CPT}}</div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="false">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Modifier :</div>
                            <div class="col-md-6"> {{task?.modifiers}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 claim-details-title">Diagnosis :</div>
                            <div class="col-md-6"> {{task?.diagnosiscodes}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item #accordionItemAction="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        id="accordion-header-action"
                        [attr.aria-expanded]="accordionItemAction.expanded"
                        attr.aria-controls="accordion-body-action" #expPanels>
        <div class="example-accordion-item-header" (click)="accordionItemAction.toggle();doExpand()" matTooltip="Add Action" fxLayout="row" fxLayoutGap=".5em">
            <mat-icon>list</mat-icon>
            <span *ngIf="expanded">Action</span>
        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemAction.expanded ? '' : 'none'"
             id="accordion-body-action"
             aria-labelledby="accordion-header-action">
            <form [formGroup]="formAdd">
                <div fxLayout="column" fxLayoutGap="1em">
                    <div class="row">
                        <div class="form-group">
                            <small id="name" class="form-text text-muted">Last Action</small>
                            <select class="form-select" disabled [value]="task?.CurrentAction??''">
                                <option *ngFor="let action of actions" [value]="action.description">{{action.description}}</option>                                 
                            </select>


                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <small id="name" class="form-text text-muted">Action<span style="color:red">*</span>:</small>
                            <select class="form-select" formControlName="action">
                                <option *ngFor="let action of actions" [value]="action.description">{{action.description}}</option>

                            </select>
                            <mat-error *ngIf="!formAdd.controls.action.valid && formAdd.controls.action.touched">
                                Action is required
                            </mat-error>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group">
                            <small id="name" class="form-text text-muted">Last Action Date:</small>
                            <div>
                                <input type="text" class="form-control" readonly [value]="task?.Lastactiondate??''" placeholder="MM-dd-YYYY">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <small id="name" class="form-text text-muted" style="width:100%">Next Action Date <span style="color:red">*</span>:</small>
                            <div>
                                <input type="text" [matDatepicker]="picker" formControlName="nextActionDate" style="width:88%;float: left;" class="form-control" readonly placeholder="MM-dd-YYYY">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker class="form-group-text" style="float:right;" #picker></mat-datepicker>
                            </div>
                            <mat-error *ngIf="!formAdd.controls.nextActionDate.valid && formAdd.controls.nextActionDate.touched">
                                Next Action Date is required
                            </mat-error>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-md-12">
                            <div class="form-group">
                                <small id="name" class="form-text text-muted">Tags:</small>
                                <div>
                                    <ng-multiselect-dropdown [placeholder]="'Tags'"
                                                             [settings]="dropdownSettings"
                                                             [data]="dropdownList"
                                                             [(ngModel)]="selectedItems"
                                                             (onSelect)="onItemSelect($event)"
                                                             (onSelectAll)="onSelectAll($event)"
                                                             (onFilterChange)="onFilterChange($event)" [ngModelOptions]="{standalone: true}">
                                    </ng-multiselect-dropdown>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group">
                            <small id="name" class="form-text text-muted">Assignee:</small>
                            <div>
                                <input type="text" matInput [formControl]="assigneeName" class="form-control" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">                                     
                                    <mat-option *ngFor="let user of filteredOptions" [value]="user">{{user.description}}</mat-option>                                     
                                </mat-autocomplete>                                 
                            </div>                        

                        </div>
                    </div>

                    <div>
                        <button mat-flat-button color="primary"   style="margin-right:10px;" class="btn" [disabled]="this._tasks?.length==0" (click)="onSaveAction()">Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item #accordionItemNotes="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        id="accordion-header-notes"
                        [attr.aria-expanded]="accordionItemNotes.expanded"
                        attr.aria-controls="accordion-body-notes" #expPanels>
        <div class="example-accordion-item-header" (click)="accordionItemNotes.toggle();doExpand()" matTooltip="Add Notes" fxLayout="row" fxLayoutGap=".5em">
            <mat-icon>note_add</mat-icon>
            <span *ngIf="expanded">Notes</span>
        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemNotes.expanded ? '' : 'none'"
             id="accordion-body-notes"
             aria-labelledby="accordion-header-notes">
            <div fxLayout="column" fxLayoutGap="1em">
                <div class="comment-section_ar all-targeted-sections" data-id="TicketCommentsSection" style="">
                    <div class="row">
                        <form [formGroup]="formAddNotes">
                            <div class="col-md-12 comment-box">

                                <div class="form-group">
                                    <textarea id="txtarea_notes" rows="3" style="width: 100%; height: 102px;" placeholder="Ask a question or post another description..." formControlName="notes"></textarea>
                                    <mat-error *ngIf="!formAddNotes.controls.notes.valid && formAddNotes.controls.notes.touched">
                                        Notes is required
                                    </mat-error>
                                </div>
                            </div>
                            <div>
                                <button mat-flat-button color="primary" style="margin-right:10px;" class="btn" (click)="onSaveNotes()" [disabled]="this._tasks?.length==0">Save Changes</button>
                            </div>
                        </form>
                    </div>
                    <div class="row collaborator-row" style="margin-top:10px;border: .5px solid #bfbaba;padding: 10px;" *ngIf="false">
                        <div class="col-md-2">
                            Collaborators
                        </div>
                        <div class="col-md-12">
                            <div *ngFor="let collabrator of task?.CollaboratorsNames" style="width: auto;float: left;padding: 0px 5px;">
                                <app-avatar [username]="collabrator"></app-avatar>
                            </div>
                        </div>
                        <!--  <ng-multiselect-dropdown [placeholder]="'custom placeholder'"
                                                 [settings]="dropdownSettings"
                                                 [data]="dropdownList"
                                                 [(ngModel)]="selectedItems"
                                                 (onSelect)="onItemSelect($event)"
                                                 (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown> -->
                    </div>
                </div>
            </div>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item #accordionItemLogs="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        id="accordion-header-logs"
                        [disabled]="tasks?.length>1 || tasks?.length==0"
                        [attr.aria-expanded]="accordionItemLogs.expanded"
                        attr.aria-controls="accordion-body-logs" #expPanels>
        <div class="example-accordion-item-header" [ngClass]="{'disabled':tasks?.length>1 || tasks?.length==0}" matTooltip="Logs" (click)="accordionItemLogs.toggle();doExpand()" fxLayout="row" fxLayoutGap=".5em">
            <mat-icon>history</mat-icon>

            <span *ngIf="expanded">Logs</span>
        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemLogs.expanded ? '' : 'none'"
             id="accordion-body-logs"
             aria-labelledby="accordion-header-logs">
            <div fxLayout="column" fxLayoutGap="1em">
                <div class="d-flex no-blcok" *ngFor='let activity of activities'>
                    <div class="m-r-20" style="margin-left: 10px;">
                        <app-avatar [username]="activity.CreatedBy"></app-avatar>
                    </div>
                    <div class="p-b-10 b-b m-b-10">
                        <h4 class="m-0">
                            {{activity.LogType}}
                            <small class="text-muted">({{ (activity.Createdon)? formatDate(activity.Createdon):''}})</small>
                        </h4>
                        <p class="text-muted" style="margin: 10px 0;">{{activity.Action}}</p>

                    </div>
                </div>
            </div>
        </div>
    </cdk-accordion-item>

</cdk-accordion>

