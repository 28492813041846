import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../user/user.service';
import { Group } from '../group';
import { GroupService } from '../group.service';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.css']
})
export class GroupDetailsComponent {
  public _group!: Group;
  public _isLoading: boolean = false;
  public hasGroup_Edit: boolean = false;
  formAdd: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.maxLength(250)]),
    isActive: new FormControl(true),
  });
  @Input() set group(group: Group) {    
    this._group = group;
    if (group?.groupid)
      this.getGroupDetails();
  }

  get group() {
    return this._group;
  }
  ngOnInit(): void {
    this.setPrivileges();
  }
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit(false);
  }
  constructor(private dialog: MatDialog, private groupService: GroupService, private userService: UserService, private auth: AuthService) { }
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {
    
    if (this.formAdd.valid) {
      let groupNew: any[] = [{
        groupid: this.group?.groupid ?? -1,
        name: this.formAdd.controls.name.value,
        description: this.formAdd.controls.description.value ??'',
        isActive: (this.group?.groupid ?? -1) == -1 ? 1 : this.formAdd.controls.isActive.value ? 1 : 0,
        createdBy: 1
      }];
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.title = 'Information!';
      data.showYes = true;
      data.noText = 'NO';
      data.yesText = 'YES';
      let update = true;
      if (groupNew[0].isActive == 0 && this.group.isactive != groupNew[0].isActive) {
        data.message = 'Are you sure you want to in active this group?';
        update = await Confirmation(this.dialog, data);
      }

      if (update) {
       
        this.onShowLoading.emit(true);
        let lookups: any[] = [];
        if (groupNew[0].isActive == 0) {
          lookups = await Promise.all([
            this.groupService.setGroup(groupNew),
            this.userService.setUserGroups([{
              userid: -2,
              groupid: this.group.groupid,
              typeid: 2,
              loginuserid: -1
            }])
          ]);
        }
        else {
          lookups = await Promise.all([
            this.groupService.setGroup(groupNew)
          ]);
        }
        this._isLoading = false;
        this.onShowLoading.emit(false);
        
        if (lookups[0] && lookups[0].length > 0) {          

          data.message = lookups[0][0]?.errormessage;
          data.showYes = false;
          data.noText = 'OK';
          if (type != 'next' || this.formAdd.controls.isActive.value != 1) {            
            if (lookups[0][0]?.result == 1) {
              await Confirmation(this.dialog, data);
              this._group.groupname = groupNew[0].name
              this._group.isactive = groupNew[0].isActive
              this._group.groupdescription = groupNew[0].description
              this._group.groupid = lookups[0][0]?.p_groupid
              this.onUpdateEdit.emit(this._group);
            }
            else {
              await Confirmation(this.dialog, data)
            }
          }
          else {
            if (type == 'next' && lookups[0][0]?.result == 0) {
              await Confirmation(this.dialog, data)
            }
            else {
              this.onUpdateEdit.emit(this._group);
              this.doNextEdit.emit(1)
            }
          }

        }        

      }
    }
    else {
      this.formAdd.markAllAsTouched();
      return;
    }

  }
  getGroupDetails() {
    this.formAdd.controls.name.setValue(this.group.groupname);
    this.formAdd.controls.description.setValue(this.group.groupdescription);
    this.formAdd.controls.isActive.setValue(this.group.isactive);

  }
  @Output() onShowLoading = new EventEmitter();
  setPrivileges() {
    this.hasGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.GroupManage_Edit);
  }
}

