import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { Group } from '../group';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.css']
})
export class GroupEditComponent {
  public _group!: Group;
  public selectedTab!: number;
  public hasGroup_View: boolean = false;
  public hasGroupClient_View: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }  

  @Input() set group(group: Group) {
    this._group = group;

  }
  constructor(private auth: AuthService) { }

  get group() {
    return this._group;
  }
  @Output() onCancelEdit = new EventEmitter();   
  //doCancelEdit(group: Group ) {
  //  this.onCancelEdit.emit(group);
  //}
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(group: Group) {
    //this._group = group;
    this.onUpdateEdit.emit(group);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(group: Group) {
    //this._group = group;
    this.onShowLoading.emit(group);
  }

  @Output() onDelete = new EventEmitter<Group>();
  doDelete(group: Group) {    
    this.onDelete.emit(group);
  }
  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }
  setPrivileges() {
    this.hasGroup_View = this.auth.hasPrivilege(PrivilegeValues.GroupManage_View);
    this.hasGroupClient_View = this.auth.hasPrivilege(PrivilegeValues.GroupClient_View);    
  }

}
