<mat-drawer-container class="example-container" autosize style="padding:0px !important">

    <div class="example-sidenav-content">
        <div class="task-title">

            <div class="row" style="margin: auto;">
                <div class="form-group col-lg-8">
                    <h3>
                        Tasks
                    </h3>
                    <h5>Home &gt; Tasks &gt; {{task?.encounternumber}}</h5>
                </div>
                <div class="form-group col-lg-4">
                    <div class="button-group">
                        <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" style="width: 100px;margin-left: -100px;z-index: 20000;">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            Back
                        </button>
                    </div>
                </div>

            </div>

            <mat-tab-group style="max-height: 72vh;overflow: auto;">
                <mat-tab label="Detail">
                    <app-task-detail [task]="task" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="    ($event)"></app-task-detail>
                </mat-tab>
                <mat-tab label="Financial Detail">
                    <app-task-financial-detail [task]="task" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)"></app-task-financial-detail>
                </mat-tab>
                <mat-tab label="Remit">
                    <app-task-remit [task]="task" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)"></app-task-remit>
                </mat-tab>
            </mat-tab-group>


        </div>
        <mat-divider></mat-divider>
    </div>
    <mat-drawer #drawer1 class="example-sidenav" mode="side" [opened]="'true'" position="end" disableClose [ngStyle]="{'width': sideDrawerWidth}" style="">
        <app-task-action-add [task]="task" (onExpand)="onExpand($event)" [lookupData]=lookupData (onRefreshInforamtion)="doRefreshInforamtion($event)"></app-task-action-add>
    </mat-drawer>

</mat-drawer-container>



