import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-task-information',
  templateUrl: './task-information.component.html',
  styleUrls: ['./task-information.component.css']
})
export class TaskInformationComponent {
  public _task: any;
  public _lookupData: any;
  public tasks: any[] = [];
  public sideDrawerWidth: any = '60px';
  @Input() set task(task: any) {
    /*this.sortedData.data = users;*/
    this._task = task;
    //if (task?._id)
    //  this.getTaskDetails();
  }
  @Input() set lookupData(lookupData: any) {
    this._lookupData = lookupData;
     
  }
  get lookupData() {
    return this._lookupData;
  }
  get task() {
    return this._task;
  }
  @Output() onCancelEdit = new EventEmitter();
  @Output() onRefreshInforamtion = new EventEmitter();
  doCancelEdit(item: any) {
    
    this.onCancelEdit.emit(item);
  }
  doRefreshInforamtion(item: any) {
    this.onRefreshInforamtion.emit(this.task);
  }
  constructor(private taskService: TaskService) {
  }
  doUpdateEdit(item: any) {
    //this.drawer1.toggle();
  }
  doShowLoading(item: any) {
    //this.drawer1.toggle();
  }
  onExpand(item: string) {
    this.sideDrawerWidth = item;
  }
  getTaskDetails() {
    this.taskService.getTaskDetails(this.task._id).then(res => {
      if (res && res.length > 0) {
        this.task = res[0];
        this.tasks = [this.task];
      }
    });

  }

}
