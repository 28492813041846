import { Component, Inject, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef, Renderer2, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import * as custom from '../../../Utility/CustomValidators.utilities'
import { getMonthName, getWeekName, getConverted12HourTime, getWeekShortName } from '../../../Utility/common-functions';
import * as moment from 'moment';
import { CredentialsData, ConfigurationData, ReportsData } from '../bot-dashboard';
//import { Schedule } from '../bot-dashboard';
import { BotDashboardService } from '../bot-dashboard.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { ClientService } from '../../../services/client.service';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { ReportFilterComponent } from '../../report/report-filter/report-filter.component';
import { PayrollDetailsComponent } from '../../payroll/payroll-details/payroll-details.component';

//import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-scheduling-action',
  templateUrl: './scheduling-action.component.html',
  styleUrls: ['./scheduling-action.component.css']
})
export class SchedulingActionComponent implements OnInit, AfterViewInit {

  // to collect credential details
  //@ViewChildren('credentialsRow') credentialsRows!: QueryList<ElementRef>;
  @ViewChildren('credentialsRow', { read: ElementRef }) credentialsRows!: QueryList<ElementRef>;
  @ViewChildren('urlInput') urlInput!: QueryList<ElementRef>;
  @ViewChildren('usernameInput') usernameInput!: QueryList<ElementRef>;
  @ViewChildren('passwordInput') passwordInput!: QueryList<ElementRef>;

  // to collect configuration details
  //@ViewChildren('configurationRow') configurationRows!: QueryList<ElementRef>;
  @ViewChildren('configurationRow', { read: ElementRef }) configurationRows!: QueryList<ElementRef>;
  @ViewChildren('keyInput') keyInput!: QueryList<ElementRef>;
  @ViewChildren('valueInput') valueInput!: QueryList<ElementRef>;

  // Collect Report Information
  @ViewChildren('reportsRow', { read: ElementRef }) reportsRow!: QueryList<ElementRef>;

  // collect selected multiselect dropdown values
  @ViewChild('clients') _client!: MultiSelectComponent;
  @ViewChild('payrolldetails') _payrolldetails!: PayrollDetailsComponent;

  @ViewChildren(ReportFilterComponent) mycomponents!: any;

  // emmitter to sent data to automation dashboard main component
  @Output() getAutomationSummary = new EventEmitter<any>();
  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private botDashboardService: BotDashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SchedulingActionComponent>,
    public elemRef: ElementRef,
    private renderer: Renderer2) {
    this.loadDetails();
  }


  // variables
  public dropdownSettings: any = {
    singleSelection: false,
    idField: 'item_text',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 300,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  // Create a formatter for the desired time zone
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: this.timeZone, // Use the local time zone
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });

  P_ScheduleAddonText = "_ARCalytics";
  P_SelectedPracticesTemp: any[] = [];
  actionHeaderTitle: string = "Add Schedule";
  actionButtonTitle: string = "Save";
  instanceId: number = -1;
  waiting: boolean = false;
  scheduleType: number = 1;
  dataToRebind: any;
  isLoading: boolean = false;
  currentUTCDate: Date = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds());
  minutesToAdd = 10; // create a future date
  currentDate = new Date();
  futureDate = new Date(this.currentDate.getTime() + this.minutesToAdd * 60000);
  weekDayName: string = getWeekName(this.futureDate.getDay());
  startDate: number = this.futureDate.getDate();
  monthNameDate: string = getMonthName(this.futureDate.getMonth()) + " " + this.startDate;
  minStartDate: Date = this.futureDate;
  minEndDate: Date = this.futureDate;
  minDateTypeEndDate: Date = this.futureDate;
  //allSchedule: LookupEntityBase[] = [];
  //scheduleInstanceId = new FormControl('-1');
  //createdById = new FormControl('-1');
  //eventId = new FormControl('-1');
  //scheduleStartDate !: any;
  //scheduleStartTime !: any;
  //scheduleEndDate !: any;
  //scheduleEndTime !: any;
  //attachReportsInSingleEmail: boolean = false;
  //sendAsPassProtected: boolean = false;
  //saveScreenShots: boolean = true;
  //screenShotsOptions!: number;
  //serversOptions !: number;
  //automationOptions !: number;
  //eventtimezone: string = "";
  currentTimeHours: number = this.futureDate.getHours();
  currentTimeMinutes: number = this.futureDate.getMinutes();
  twelveHourTime: string = getConverted12HourTime(this.currentTimeHours.toString(), this.currentTimeMinutes.toString(), true);
  selectedGoogleCalendarOption: any = 1;
  googleCalendarOptions: any = this.getOptions();
  screenShotOptions: any = [
    {
      "id": 1,
      "label": "Never"
    },
    {
      "id": 2,
      "label": "Every Second"
    },
    {
      "id": 3,
      "label": "Every 5 Seconds"
    },
    {
      "id": 4,
      "label": "Every 10 Seconds"
    },
    {
      "id": 5,
      "label": "Every 15 Seconds"
    },
    {
      "id": 6,
      "label": "Every 20 Seconds"
    },
    {
      "id": 7,
      "label": "Every 25 Seconds"
    },
    {
      "id": 8,
      "label": "Every 30 Seconds"
    },
    {
      "id": 9,
      "label": "Every 35 Seconds"
    },
    {
      "id": 10,
      "label": "Every 40 Seconds"
    },
    {
      "id": 11,
      "label": "Every 45 Seconds"
    },
    {
      "id": 12,
      "label": "Every 50 Seconds"
    },
    {
      "id": 13,
      "label": "Every 55 Seconds"
    },
    {
      "id": 14,
      "label": "Every Minute"
    }
  ];
  listOfServers: any = [];
  listOfAutomation: any = [];
  credentialsList: CredentialsData[] = [];
  credentialsData: CredentialsData[] = [
    { URL: "www.google.com", UserName: 'user1', PassWord: "password@123" },
    { URL: "www.google.com", UserName: 'user2', PassWord: "password@123" },
    { URL: "www.google.com", UserName: 'user3', PassWord: "password@123" },
    { URL: "www.google.com", UserName: 'user4', PassWord: "password@123" },
    { URL: "www.google.com", UserName: 'user5', PassWord: "password@123" }
  ];
  configurationList: ConfigurationData[] = [];
  configurationData: ConfigurationData[] = [
    { key: "key1", value: 'value1' },
    { key: "key2", value: 'value2' },
    { key: "Save Screenshots", value: 'value3' },
    { key: "key4", value: 'value4' },
    { key: "key5", value: 'value5' }
  ];
  displayedCredentialsDataColumns: string[] = ['url', 'username', 'password'];
  displayedConfigurationDataColumns: string[] = ['key', 'value'];
  CredentialInfoGlobal: any = [];
  ConfigurationInfoGlobal: any = [];
  AutomationIdGlobal: number = 0;

  // reports
  selectedEmailProfile: any = 1;
  Reports: any = []; //[
  //  {
  //    "id": 1,
  //    "name": "Report 1",
  //    "reportworkspaceid": 1
  //  },
  //  {
  //    "id": 2,
  //    "name": "Report 2",
  //    "reportworkspaceid": 3
  //  },
  //  {
  //    "id": 3,
  //    "name": "Report 3",
  //    "reportworkspaceid": 5
  //  },
  //  {
  //    "id": 4,
  //    "name": "Report 4",
  //    "reportworkspaceid": 7
  //  }
  //];
  ReportTypes: any = [
    {
      "id": 1,
      "name": "PDF"
    }
    //,
    //{
    //  "id": 2,
    //  "name": "XLSX"
    //},
    //{
    //  "id": 3,
    //  "name": "Link"
    //}
  ];
  EmailProfiles: any = []
  reportsMultipleRows: any = this.getReportJSON(1);
  reportList: ReportsData[] = [];
  reportFilter: any;

  // Real Time Refresh
  selectedPracticeManagement: number = 1;
  selectedPractice: any = 1;
  //selectedWareHouse: number = 1;
  selectedDateType: number = 1;
  public pmLookup!: LookupEntityBase[];
  public clientLookup!: any[];
  public allClientLookup!: any[];
  //DataWareHouses: any = [
  //  {
  //    "id": 1,
  //    "name": "Warehouse 1",
  //    "dagid": 11
  //  },
  //  {
  //    "id": 2,
  //    "name": "Warehouse 2",
  //    "dagid": 12
  //  },
  //  {
  //    "id": 3,
  //    "name": "Warehouse 3",
  //    "dagid": 13
  //  },
  //  {
  //    "id": 4,
  //    "name": "Warehouse 4",
  //    "dagid": 14
  //  },
  //  {
  //    "id": 5,
  //    "name": "Warehouse 5",
  //    "dagid": 15
  //  }
  //];
  DateTypes: any = [
    {
      "id": 1,
      "name": "Accounting"
    },
    {
      "id": 2,
      "name": "DOS"
    },
    {
      "id": 3,
      "name": "Transaction"
    },
    {
      "id": 4,
      "name": "DOE"
    }
  ];

  public lookupData: any = {};
  public payroll: any = {};
  public filterData: any[] = [];
  public filters: any = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
  public reportId: string = '';
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  showFilter: boolean = false;

  ngOnInit() { }
  ngAfterViewInit() { }
  ngBeforeViewInit() { }
  async loadDetails() {
    this.scheduleType = this.data.scheduleType;
    this.dataToRebind = this.data.dataToRebind;
    if (this.scheduleType == 1) {
      // collect server information
      for (let i = 0; i < this.data.lookups[0].length; i++) {
        this.listOfServers.push({
          "id": this.data.lookups[0][i].automationserverdetailid,
          "name": this.data.lookups[0][i].servername,
          "serverip": this.data.lookups[0][i].ipaddress
        });
      }

      // collect automation information
      for (let i = 0; i < this.data.lookups[1].length; i++) {
        this.listOfAutomation.push({
          "id": this.data.lookups[1][i].automationid,
          "name": this.data.lookups[1][i].automationname,
          "deployment_location": "D:/TestDeploy",
          "git_location": this.data.lookups[1][i].giturl,
          "github_token": "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", //"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", (Old Sourabh's Token)
          "inputconfigurations": this.data.lookups[1][i].inputconfigurations
        });
      }
    }
    else if (this.scheduleType == 2) {
      // report filters
      this.lookupData = this.data.lookupData;
      this.filters = this.data.filters;
      this.filterData = this.data.filterData;
      this.reportId = this.data.reportId;

      this.EmailProfiles = this.data.lookups[0];
      let P_allReports = this.data.lookups[1];

      this.Reports = [];
      let P_parentReports = P_allReports.filter((obj: any) => {
        return (obj.menuparentid == 9) // add menuid
      });

      for (let k = 0; k < P_parentReports.length; k++) {
        let P_localData = P_allReports.filter((obj: any) => {
          return (obj.menuparentid == P_parentReports[k].menuid) // add menuid
        });
        for (let m = 0; m < P_localData.length; m++) {
          this.Reports.push(P_localData[m]);
        }
      }


      // exclude reports not having clients dropdown

      //Customize Query, Vie AI, Water Fall Model, What IF Analysis, Allowed Amount by CPT Code & Payers,
      //Billed Amount vs Allowed Amount, Checks Received by Payer, Denial Details, Denials Summary, Denials Trend
      //Remittance Overview Report

      let P_excludeReports = ["47", "49", "36", "37", "38", "39", "40", "41", "42", "43", "44"];
      this.Reports = this.Reports.filter((obj: any) => {
        return (P_excludeReports.indexOf(obj.menuid.toString()) == -1)
      })

      this.reportFilter = this.data.lookups[2];

    }
    else if (this.scheduleType == 3) {
      this.pmLookup = this.data.lookups[0]?.map((s: any) => { return { code: s.id, description: s.name, active: true } });
      this.clientLookup = this.data.lookups[1]?.map((s: any) => { return { code: s.clientid, description: s.clientname, dagid: s.dagid, warehouseuniqueidentifier: s.warehouseuniqueidentifier, practicemangementsystemnameid: s.practicemangementsystemnameid, active: true } });
      this.allClientLookup = this.clientLookup;
    }
    else if (this.scheduleType == 4) {
      this.lookupData = this.data.lookupData;
    }
    this.onRunAlways();


    if (this.dataToRebind != null &&
      this.dataToRebind != undefined &&
      this.dataToRebind != "") {
      this.rebindForm(this.dataToRebind);
    } else {
      if (this.scheduleType == 1) {
        this.onAutomationChange();
      }
    }
    this.changeModalTitleBtn();

    if (this.scheduleType == 2) {
      setTimeout(() => {
        this.resetReportsArray();
      }, 500);
    }
  }

  public scheduleStatus = new FormControl(true);
  formAdd: FormGroup = new FormGroup({
    eventId: new FormControl('-1'),
    scheduleInstanceId: new FormControl('-1'),
    createdById: new FormControl('-1'),
    scheduleName: new FormControl(''),
    scheduleNameHidden: new FormControl(''),
    scheduleDetails: new FormControl(''),
    scheduleStartDate: new FormControl(this.futureDate, [custom.validateDate]),
    scheduleStartTime: new FormControl(this.twelveHourTime),
    scheduleEndDate: new FormControl(this.futureDate, [custom.validateDate]),
    scheduleEndTime: new FormControl(this.twelveHourTime),
    attachReportsInSingleEmail: new FormControl(false),
    sendAsPassProtected: new FormControl(false),
    saveScreenShots: new FormControl(true),
    otherOptions: new FormControl(1),
    screenShotsOptions: new FormControl(1),
    serversOptions: new FormControl(1),
    automationOptions: new FormControl(1),
    credentialsValues: new FormControl(""),
    configurationValues: new FormControl(""),
    scheduleStatus: this.scheduleStatus,
    deploymentLocation: new FormControl(''),
    runAlways: new FormControl(false),

    // report
    emailProfile: new FormControl(1),
    mailTo: new FormControl(""),
    mailCC: new FormControl(""),
    mailBCC: new FormControl(""),
    emailSubject: new FormControl(""),
    emailBody: new FormControl(""),

    // real time refresh
    practiceManagement: new FormControl(1),
    practice: new FormControl(1),
    //wareHouse: new FormControl(1),
    dateType: new FormControl(1),
    dateTypeStartDate: new FormControl(this.futureDate, [custom.validateDate]),
    dateTypeEndDate: new FormControl(this.futureDate, [custom.validateDate])
    //eventtimezone: this.getTimeZoneShort()
  });

  doCancelEdit(item: boolean) {
    this.doReset();
    this.dialogRef.close(item);
  }
  doReset() {
    this.formAdd.controls.eventId.setValue("-1");
    this.formAdd.controls.scheduleInstanceId.setValue("-1");
    this.formAdd.controls.createdById.setValue("-1");
    this.formAdd.controls.scheduleName.setValue("");
    this.formAdd.controls.scheduleNameHidden.setValue("");
    this.formAdd.controls.deploymentLocation.setValue("");
    //this.formAdd.controls.scheduleDescription.setValue("");
    this.formAdd.controls.scheduleDetails.setValue("");
    this.formAdd.controls.scheduleStartDate.setValue(this.futureDate);
    this.formAdd.controls.scheduleStartTime.setValue(this.twelveHourTime);
    this.formAdd.controls.scheduleEndDate.setValue(this.futureDate);
    this.formAdd.controls.scheduleEndTime.setValue(this.twelveHourTime);
    this.formAdd.controls.attachReportsInSingleEmail.setValue(false);
    this.formAdd.controls.sendAsPassProtected.setValue(false);
    this.formAdd.controls.saveScreenShots.setValue(false);
    this.formAdd.controls.otherOptions.setValue(1);
    this.formAdd.controls.screenShotsOptions.setValue(1);
    this.formAdd.controls.serversOptions.setValue(1);
    this.formAdd.controls.automationOptions.setValue(1);
    this.formAdd.controls.credentialsValues.setValue("");
    this.formAdd.controls.configurationValues.setValue("");
    this.formAdd.controls.emailProfile.setValue(1);
    this.formAdd.controls.mailTo.setValue("");
    this.formAdd.controls.mailCC.setValue("");
    this.formAdd.controls.mailBCC.setValue("");
    this.formAdd.controls.emailSubject.setValue("");
    this.formAdd.controls.emailBody.setValue("");
    this.formAdd.controls.practiceManagement.setValue(1);
    this.formAdd.controls.practice.setValue(1);
    this.formAdd.controls.runAlways.setValue(false);
    //this.formAdd.controls.wareHouse.setValue(1);
    this.formAdd.controls.dateType.setValue(1);
    this.formAdd.controls.scheduleStatus.setValue(true);
    this.formAdd.controls.dateTypeStartDate.setValue(this.futureDate);
    this.formAdd.controls.dateTypeEndDate.setValue(this.futureDate);
    this.reportsMultipleRows = this.getReportJSON(1);
    this.CredentialInfoGlobal = [];
    this.ConfigurationInfoGlobal = [];
    this.AutomationIdGlobal = 0;


    if (this.scheduleType == 3) {
      this.changePracticeValues();
    }
    else {
      this.onRunAlways();
      if (this.scheduleType == 1) {
        this.onAutomationChange();
      }
      if (this.scheduleType == 2) {
        this.resetReportsArray();
      }
    }
  }

  onBlurDate(dp: any) {
    var date = moment(this.formAdd.controls.scheduleStartDate.value);
    const status = date.isValid();
    if (!status) {
      this.formAdd.controls.scheduleStartDate.setValue("");
    }
    //this.currentValue = +dp.input.currentValue.split('/')[0];
  }

  onDateChange(event: any) {
    let P_newStartDate = this.formAdd.controls.scheduleStartDate.value;
    let P_fullDate = new Date(P_newStartDate).toLocaleDateString();
    let P_dateType = new Date(P_fullDate);
    this.weekDayName = P_dateType.toLocaleDateString('en-US', { weekday: 'long' });
    this.startDate = P_dateType.getDate();
    this.monthNameDate = P_dateType.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    this.googleCalendarOptions = this.getOptions();
    this.futureDate = P_dateType;
    if (moment(P_newStartDate).format("MMDDYYYY") == moment().format("MMDDYYYY")) {
      this.futureDate = new Date(P_dateType.getTime() + this.minutesToAdd * 60000);
    }
    this.formAdd.controls.scheduleEndDate.setValue(P_newStartDate);
    this.minEndDate = new Date(P_newStartDate);
  }

  onSaveScreenShots(event: any) {
    //this.saveScreenShots = event.checked;
    this.formAdd.controls.saveScreenShots.setValue(event.checked);
  }

  async onSaveSchedule() {
    if (this.canSave() && (this._payrolldetails?.isvalid??true)) {
      //if (this.formAdd.valid) {
        this.waiting = true;
        let P_scheduleInstanceId = this.formAdd.controls.scheduleInstanceId.value;
        let P_scheduleStartDate = this.formAdd.controls.scheduleStartDate.value;
        let P_scheduleEndDate = this.formAdd.controls.scheduleEndDate.value;

        if (P_scheduleInstanceId == "-1") { // check whether real time refresh event is not picking up by exe twice.
          // Define the two date objects
          let date1: any = new Date();
          let date2: any = new Date(P_scheduleStartDate);

          // Calculate the difference in milliseconds
          const diffInMs = date2 - date1;

          // Convert milliseconds to minutes and seconds
          const diffInMinutes = Math.floor(diffInMs / 60000);

          if (diffInMinutes <= 7) {
            this.futureDate = new Date(new Date().getTime() + this.minutesToAdd * 60000);

            this.formAdd.controls.scheduleStartDate.setValue(this.futureDate);
            this.formAdd.controls.scheduleEndDate.setValue(this.futureDate);
            //this.formAdd.controls.dateTypeStartDate.setValue(this.futureDate);
            //this.formAdd.controls.dateTypeEndDate.setValue(this.futureDate);
            this.onDateTypeChange();

            P_scheduleStartDate = this.formAdd.controls.scheduleStartDate.value;
            P_scheduleEndDate = this.formAdd.controls.scheduleEndDate.value;
          }
        }

        //let startDate = new Date(P_scheduleStartDate).toLocaleDateString('en-US', {
        //  timeZone: 'UTC',
        //});
        //let endDate = new Date(P_scheduleEndDate).toLocaleDateString('en-US', {
        //  timeZone: 'UTC',
        //});

        let localDate = new Date(P_scheduleEndDate);
        let endDate = this.formatter.format(localDate);

        localDate = new Date(P_scheduleStartDate);
        let startDate = this.formatter.format(localDate);

        let istStartDateString: any = startDate + " " + this.formAdd.controls.scheduleStartTime.value;
        let istEndDateString: any = endDate + " " + this.formAdd.controls.scheduleEndTime.value;

        istStartDateString = this.datePipe.transform(istStartDateString, "yyyy-MM-ddTHH:mm:ss.SSSZ", "UTC");
        istEndDateString = this.datePipe.transform(istEndDateString, "yyyy-MM-ddTHH:mm:ss.SSSZ", "UTC");

        let P_StartTimeUTC = this.getUtcTime(this.formAdd.controls.scheduleStartTime.value);
        let P_EndTimeUTC = this.getUtcTime(this.formAdd.controls.scheduleEndTime.value);

        let startTime = getConverted12HourTime(P_StartTimeUTC.split(":")[0],
          P_StartTimeUTC.split(":")[1],
          false);
        let endTime = getConverted12HourTime(P_EndTimeUTC.split(":")[0],
          P_EndTimeUTC.split(":")[1],
          false);

        startTime = startTime.replace(" ", ":");
        endTime = endTime.replace(" ", ":");


        let P_serverId = this.formAdd.controls.serversOptions.value;
        let P_automationId = this.formAdd.controls.automationOptions.value;
        let P_serverOtherDetails = this.listOfServers.filter((obj: any) => {
          return (obj.id == P_serverId)
        });
        let P_automationOtherDetails = this.listOfAutomation.filter((obj: any) => {
          return (obj.id == P_automationId)
        });

        let P_ScheduleName = this.formAdd.controls.scheduleName.value;
        let P_ScheduleNameHidden = this.formAdd.controls.scheduleNameHidden.value;
        var P_json: any = "";
        let P_RunAlways: any = false;

        if (this.scheduleType == 1) { // json for automation
          if (P_scheduleInstanceId == "-1") {
            P_ScheduleName = P_ScheduleName + this.P_ScheduleAddonText + this.generateRandomString(7);
          } else {
            P_ScheduleName = P_ScheduleName + P_ScheduleNameHidden;
          }

          P_RunAlways = this.formAdd.controls.runAlways.value;

          P_json = [
            {
              ScheduleName: P_ScheduleName,
              ScheduleDetails: this.formAdd.controls.scheduleDetails.value,
              StartTime: istStartDateString,
              EndTime: istEndDateString,
              OccurenceRule: this.CreateCalendarRules(),
              EventSummary: this.formAdd.controls.otherOptions.value,
              EventTimeZone: this.getTimeZoneShort(),
              ServerId: P_serverOtherDetails[0].id,
              ServerName: P_serverOtherDetails[0].name,
              ServerIP: P_serverOtherDetails[0].serverip,
              AutomationId: P_automationOtherDetails[0].id,
              AutomationName: P_automationOtherDetails[0].name,
              CredentialInfo: JSON.parse(this.formAdd.controls.credentialsValues.value), //this.getCredentialInfo(),
              ConfigurationInfo: JSON.parse(this.formAdd.controls.configurationValues.value), //this.getConfigurationInfo(),
              ActionType: (P_scheduleInstanceId == "-1") ? 0 : 1,
              ProcessStatus: (P_scheduleInstanceId == "-1") ? 0 : 1,
              AutomationLocation: this.formAdd.controls.deploymentLocation.value, //P_automationOtherDetails[0].deployment_location,
              GitLocation: P_automationOtherDetails[0].git_location,
              GithubToken: P_automationOtherDetails[0].github_token
            }
          ];
        }
        else if (this.scheduleType == 2) { // json for report
          P_RunAlways = this.formAdd.controls.runAlways.value;
          let P_emailBody: any = document.getElementById('inputEmailBody')?.innerHTML;
          P_emailBody = P_emailBody.replaceAll("\"", "DoubleQuotation;");
          P_json = [
            {
              "StartTime": istStartDateString,
              "EndTime": istEndDateString,
              "OccurenceRule": this.CreateCalendarRules(),
              "EventSummary": this.formAdd.controls.otherOptions.value,
              "EventTimeZone": this.getTimeZoneShort(),
              "attachinsingleemail": (this.formAdd.controls.attachReportsInSingleEmail.value) ? 1 : 0,
              "emailprofileid": this.formAdd.controls.emailProfile.value,
              "emailto": this.formAdd.controls.mailTo.value,
              "emailcc": this.formAdd.controls.mailCC.value,
              "emailbcc": this.formAdd.controls.mailBCC.value,
              "emailsubject": this.formAdd.controls.emailSubject.value,
              "emailbody": P_emailBody,
              "reports": this.getReportInfo()
            }
          ];
        }
        else if (this.scheduleType == 3) { // json for real time refresh

          let P_practiceIds = "";
          let P_practices: any = this._client.selectedItems;
          for (let i = 0; i < P_practices.length; i++) {
            if (i == (P_practices.length - 1)) {
              P_practiceIds += P_practices[i].code;
            } else {
              P_practiceIds += P_practices[i].code + ",";
            }
          }

          let P_OtherIdsPractices = this.allClientLookup.filter((obj: any) => {
            return (P_practices[0].code.toString() == obj.code.toString());
          });
          let P_DagId = P_OtherIdsPractices[0].dagid;
          let P_warehouseuniqueidentifier = P_OtherIdsPractices[0].warehouseuniqueidentifier;

          // Usage example
          let date1 = this.formAdd.controls.dateTypeStartDate.value.toString();
          date1 = this.formatDate(date1);

          let date2 = this.formAdd.controls.dateTypeEndDate.value.toString();
          date2 = this.formatDate(date2);

          //let selectedDataWareHouse = this.DataWareHouses.filter((obj: any) => {
          //  return (obj.id == this.formAdd.controls.wareHouse.value)
          //});
          P_json = [
            {
              dagid: P_DagId, //selectedDataWareHouse[0].dagid,
              warehouseuniqueid: P_warehouseuniqueidentifier, //selectedDataWareHouse[0].id,
              practicemanagementsystem: this.formAdd.controls.practiceManagement.value,
              uniquepracticeid: P_practiceIds, //this._client.selectedItems, //this.formAdd.controls.practice.value,
              datetype: this.formAdd.controls.dateType.value,
              //schedulefromdate: istStartDateString, //this.formAdd.controls.scheduleStartDate.value,
              //scheduletodate: istEndDateString, //this.formAdd.controls.scheduleEndDate.value,
              fromdate: date1, //this.formAdd.controls.dateTypeStartDate.value, //.toString(),
              todate: date2 //this.formAdd.controls.dateTypeEndDate.value //.toString()
            }
          ];
        }
        else if (this.scheduleType == 4) {
          P_json = [{
            EventSummary: this.formAdd.controls.otherOptions.value,
            payrolldata:this._payrolldetails.requestdata
          }]
        }
        let request = [{
          EventId: this.formAdd.controls.eventId.value,
          ScheduleInstanceId: P_scheduleInstanceId,
          CreatedById: this.formAdd.controls.createdById.value??-1,
          ScheduleTypeId: this.scheduleType, // 1 for automation scheduling, 2 for report scheduling, 3 for refresh report
          EmailProfileId: this.formAdd.controls.emailProfile.value,
          ScheduleName: P_ScheduleName,
          ScheduleDetails: this.formAdd.controls.scheduleDetails.value,
          IsActive: (this.formAdd.controls.scheduleStatus.value) ? 1 : 0,
          StartTime: istStartDateString,
          EndTime: istEndDateString,
          OccurenceRule: this.CreateCalendarRules(),
          RunAlways: P_RunAlways,
          ScheduleDescription: JSON.stringify(P_json),
        }];

        this.formAdd.markAsUntouched();
        this.isLoading = true;
        console.log("request: ", request);
        let res = await this.botDashboardService.addUpdateInstance(request);
        this.isLoading = false;
        this.waiting = false;

        //this.saveScheduleLog(request); // this API will save data in schedule log table with status 0.

        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.message = res[0].message;
        data.title = 'Information!';
        data.showNo = true;
        data.showYes = false;
        data.noText = 'Ok';
        data.yesText = 'YES';
        await Confirmation(this.dialog, data);
        this.doCancelEdit(true);
      //}
      //else {
        //this.formAdd.markAsTouched();
      //}
    }
  }

  canSave() {
    let result = true;
    let P_scheduleName = this.formAdd.controls.scheduleName.value;
    let P_scheduleStartDate = this.formAdd.controls.scheduleStartDate.value;
    let P_scheduleStartTime = this.formAdd.controls.scheduleStartTime.value;
    let P_scheduleEndDate = this.formAdd.controls.scheduleEndDate.value;
    let P_scheduleEndTime = this.formAdd.controls.scheduleEndTime.value;
    let P_otherOptions = this.formAdd.controls.otherOptions.value;
    let P_serversOptions = this.formAdd.controls.serversOptions.value;
    let P_automationOptions = this.formAdd.controls.automationOptions.value;

    let P_selectedpractice: any = [];
    if (this.scheduleType == 3) {
      if (this._client != undefined && this._client.selectedItems != undefined) {
        P_selectedpractice = this._client.selectedItems;
      }
    }

    if (P_scheduleName == null || P_scheduleName == undefined || P_scheduleName == "") { result = false; }
    if (P_scheduleStartDate == null || P_scheduleStartDate == undefined || P_scheduleStartDate == "") { result = false; }
    if (P_scheduleStartTime == null || P_scheduleStartTime == undefined || P_scheduleStartTime == "") { result = false; }
    if (P_scheduleEndDate == null || P_scheduleEndDate == undefined || P_scheduleEndDate == "") { result = false; }
    if (P_scheduleEndTime == null || P_scheduleEndTime == undefined || P_scheduleEndTime == "") { result = false; }
    if (this.scheduleType != 4 && (P_otherOptions == null || P_otherOptions == undefined || P_otherOptions == 0)) { result = false; }
    if (this.scheduleType != 4 && (P_serversOptions == null || P_serversOptions == undefined || P_serversOptions == "")) { result = false; }
    if (this.scheduleType != 4 && (P_automationOptions == null || P_automationOptions == undefined || P_automationOptions == "")) { result = false; }

    if (this.scheduleType == 3) {
      if (P_selectedpractice == 1 || P_selectedpractice.length == 0) { result = false; }
    }

    return result;
  }

  getTimeZoneShort() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  convertDateFormat(dateString: string) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Format the date and time parts
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const seconds = '00'; // Set seconds manually

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  // function get recursion/event trigger rules based on setting for google api 
  CreateCalendarRules() {
    var recursionRule = "";
    var P_EndDate: any = new Date(this.formAdd.controls.scheduleEndDate.value).toLocaleDateString();
    //var P_NewDate = new Date(P_EndDate);
    //P_NewDate.setDate(P_NewDate.getDate() + 1);

    var untildate = this.returnValidUntilDate(new Date(P_EndDate)); //this.returnValidUntilDate(P_NewDate);
    var P_RunAlways = this.formAdd.controls.runAlways.value;
    if (this.formAdd.controls.otherOptions.value == 1) { // Does Not Repeat
      recursionRule = "RRULE:FREQ=DAILY";
      recursionRule = recursionRule + ";COUNT=1";
    }
    else if (this.formAdd.controls.otherOptions.value == 2) { // Daily
      recursionRule = "RRULE:FREQ=DAILY";
      recursionRule = recursionRule + ";INTERVAL=1";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
    }
    else if (this.formAdd.controls.otherOptions.value == 3) { // Every weekday (Monday to Friday)
      recursionRule = "RRULE:FREQ=WEEKLY";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      recursionRule = recursionRule + ";BYDAY=MO,TU,WE,TH,FR";
    }
    else if (this.formAdd.controls.otherOptions.value == 4) { // Every Monday, Wednesday and Friday
      recursionRule = "RRULE:FREQ=WEEKLY";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      recursionRule = recursionRule + ";BYDAY=MO,WE,FR";
    }
    else if (this.formAdd.controls.otherOptions.value == 5) { // Every Tuesday and Thursday
      recursionRule = "RRULE:FREQ=WEEKLY";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      recursionRule = recursionRule + ";BYDAY=TU,TH";
    }
    else if (this.formAdd.controls.otherOptions.value == 6) { // Weekly
      recursionRule = "RRULE:FREQ=WEEKLY";
      recursionRule = recursionRule + ";INTERVAL=1";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      recursionRule = recursionRule + ";BYDAY=" + getWeekShortName(this.futureDate.getDay());
    }
    else if (this.formAdd.controls.otherOptions.value == 7) { // Monthly
      recursionRule = "RRULE:FREQ=MONTHLY";
      recursionRule = recursionRule + ";INTERVAL=1";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      recursionRule = recursionRule + ";BYMONTHDAY=" + this.futureDate.getDate();
    }
    else if (this.formAdd.controls.otherOptions.value == 8) { // Annually
      recursionRule = "RRULE:FREQ=YEARLY";
      recursionRule = recursionRule + ";INTERVAL=1";
      if (!P_RunAlways) {
        recursionRule = recursionRule + ";UNTIL=" + untildate;
      }
      // Month of the Reoprt
      recursionRule = recursionRule + ";BYMONTH=" + (this.futureDate.getMonth() + 1);
      // Date of the Year
      recursionRule = recursionRule + ";BYMONTHDAY=" + (this.futureDate.getDate());
    }

    return recursionRule;
  }

  returnValidUntilDate(date: any) {
    let today = date;
    today = new Date(date);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    today = today = yyyy.toString() + mm.toString() + dd.toString() + "T240000Z";
    return today;
  }

  getCredentialInfo() {
    this.credentialsRows.forEach((currentRow) => {
      const tds = currentRow.nativeElement.getElementsByTagName('td');
      if (tds.length > 0) {
        const rowData = {
          URL: tds[0]?.getElementsByTagName('input')[0]?.value,
          UserName: tds[1]?.getElementsByTagName('input')[0]?.value,
          PassWord: tds[2]?.getElementsByTagName('input')[0]?.value
        };
        this.credentialsList.push(rowData);
      }
    });

    return this.credentialsList;
  }

  getConfigurationInfo() {
    this.configurationRows.forEach((currentRow) => {
      const tds = currentRow.nativeElement.getElementsByTagName('td');
      let P_value = "";

      // as of now value can be single input or value
      if (tds[1]?.getElementsByTagName('input').length > 0) {
        P_value = tds[1]?.getElementsByTagName('input')[0]?.value || "";
      } else if (tds[1]?.getElementsByTagName('select').length > 0) {
        P_value = this.formAdd.controls.screenShotsOptions.value;
      }

      let rowData = {
        key: tds[0]?.getElementsByTagName('input')[0]?.value,
        value: P_value
      };

      this.configurationList.push(rowData);
    });

    return this.configurationList;
  }

  getOptions() {
    return [
      {
        "id": 1,
        "label": "Does not repeat"
      },
      {
        "id": 2,
        "label": "Daily"
      },
      {
        "id": 3,
        "label": "Every weekday (Monday to Friday)"
      },
      {
        "id": 4,
        "label": "Every Monday, Wednesday and Friday"
      },
      {
        "id": 5,
        "label": "Every Tuesday and Thursday"
      },
      {
        "id": 6,
        "label": `Weekly on ${this.weekDayName}`
      },
      {
        "id": 7,
        "label": `Monthly on date ${this.startDate}`
      },
      {
        "id": 8,
        "label": `Annually on ${this.monthNameDate}`
      }
    ];
  }

  addNewRow() {
    this.reportsMultipleRows.push(this.getReportJSON(2));
    setTimeout(() => {
      this.resetReportsArray();
    }, 500);
  }

  getReportInfo() {
    this.reportList = [];
    let index = 0;
    //this.mycomponents.forEach((element)=>{
    //  element.disable();
    //})
    this.reportsRow.forEach((currentRow) => {
      const P_elements = currentRow.nativeElement.getElementsByClassName('report-div');
      let P_reportid: any = "";
      let P_reportname = "";
      let P_reporttype = "";
      let P_passwordprotected: any = "";
      let P_reportfilter: any = "";
      let P_doreportfilter: any = "";
      let P_filepassword = "";

      if (P_elements[0]?.getElementsByClassName('report-name').length > 0) {
        P_reportid = P_elements[0]?.getElementsByClassName('report-name')[0]?.value || "";
      }
      if (P_elements[1]?.getElementsByClassName('report-type').length > 0) {
        P_reporttype = P_elements[1]?.getElementsByClassName('report-type')[0]?.value || "";
      }
      if (P_elements[2]?.getElementsByClassName('password-protection').length > 0) {
        P_passwordprotected = P_elements[2]?.getElementsByClassName('password-protection')[0].getAttribute("class").indexOf('mat-checkbox-checked') > -1;
      }
      if (P_elements[3]?.getElementsByClassName('report-filters').length > 0) {
        P_reportfilter = (P_elements[3]?.getElementsByClassName('report-filters')[0].value != "") ? JSON.parse(P_elements[3]?.getElementsByClassName('report-filters')[0].value) : JSON.parse("[]");
      }

      let P_reportOtherDetails = this.Reports.filter((obj: any) => {
        return (obj.menuid == P_reportid)
      });

      if (P_passwordprotected) {
        P_filepassword = this.generateRandomString(10);
      }

      let P_filters: any = "";
      P_reportfilter = this.mycomponents._results.find((w: any) => { return w.reportid == P_reportOtherDetails[0].externalsystem_guid });
      P_doreportfilter = this.doFilter(P_reportfilter.filtervalue);

      P_doreportfilter.forEach((w: any) => {
        let target: any = w.target
        switch (w.filterType) {
          case models.FilterType.RelativeDate:
            {
              P_filters = P_filters + this.getDateFilterRange(w as models.IRelativeDateFilter);
              break;
            }
          case models.FilterType.Basic:
            {
              P_filters = P_filters + `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} ${w.operator.toLocaleLowerCase()} ('${w.values.join("','")}') and `;
              break;
            }
          case models.FilterType.Advanced:
            {
              P_filters = P_filters + `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')}${w.conditions.map((a: any) => { return ' ' + ((a.operator == "LessThanOrEqual") ? 'le' : 'ge') + ' datetime' + `'${moment(a.value).format("YYYY-MM-DD")}T00:00:00'` }).join(' ' + w.logicalOperator.toLocaleLowerCase() + " " + target.table.replaceAll(' ', '_x0020_') + '/' + target.column.replaceAll(' ', '_x0020_'))} and `;
              break;
            }
        }
      });

      P_filters = P_filters.split(' and ').filter((w: any) => { return w != '' }).join(' and ').toString();
      P_filters = P_filters
        .replaceAll("/", "ForwardSlash;")
        .replaceAll("(", "OpenCurlyBrace;")
        .replaceAll(")", "CloseCurlyBrace;")
        .replaceAll("'", "SingleQuotation;")
        .replaceAll(",", "Comma;");

      let rowData = {
        reportname: P_reportOtherDetails[0].menuname,
        reportsendas: P_reporttype,
        sendaspasswordprotected: P_passwordprotected ? 1 : 0,
        filtersjson: [P_reportfilter.filtervalue], //JSON.stringify([P_reportfilter.filtervalue]),
        filters: P_filters,
        reportidfrombi: P_reportOtherDetails[0].menuid,
        externalsystem_guid: P_reportOtherDetails[0].externalsystem_guid,
        filepassword: P_filepassword
        //, reportworkspaceid: "-1"
      };

      this.reportList.push(rowData);

      index++;
    });

    return this.reportList;
  }

  rebindForm(dataToBind: any) {
    let P_description = JSON.parse(dataToBind[0].description);
    if (this.scheduleType == 4)
      this.payroll = dataToBind[0]; 
    //let P_start = new Date(dataToBind[0].start);
    //let P_end = new Date(dataToBind[0].end); 
    //console.log("this.payroll: ", this.payroll);
    let P_start = new Date(dataToBind[0].start_datetime_string);
    let P_end = new Date(dataToBind[0].end_datetime_string); 

    let P_starttime = getConverted12HourTime(P_start.getHours().toString(),
      P_start.getMinutes().toString(),
      true);
    let P_endtime = getConverted12HourTime(P_end.getHours().toString(),
      P_end.getMinutes().toString(),
      true);

    // common for automation, reports and real time refresh
    let P_ScheduleName = dataToBind[0].title;
    let P_ScheduleNameHidden = "";
    if (dataToBind[0].title.indexOf(this.P_ScheduleAddonText) > -1) {
      P_ScheduleName = dataToBind[0].title.split(this.P_ScheduleAddonText)[0];
      P_ScheduleNameHidden = this.P_ScheduleAddonText + dataToBind[0].title.split(this.P_ScheduleAddonText)[1];
    }

    this.scheduleType = dataToBind[0].schedule_type_id;
    this.formAdd.controls.eventId.setValue(dataToBind[0].event_id);
    this.formAdd.controls.scheduleInstanceId.setValue(dataToBind[0].id);
    this.instanceId = dataToBind[0].id;
    this.formAdd.controls.createdById.setValue(dataToBind[0].created_by_id);
    this.formAdd.controls.scheduleName.setValue(P_ScheduleName);
    this.formAdd.controls.scheduleNameHidden.setValue(P_ScheduleNameHidden);
    this.formAdd.controls.scheduleDetails.setValue(dataToBind[0].schedule_details);
    this.formAdd.controls.scheduleStartDate.setValue(P_start);
    // common for automation and reports
    if (this.scheduleType != 3) {
      this.formAdd.controls.scheduleStartTime.setValue(P_starttime);
      this.formAdd.controls.scheduleEndTime.setValue(P_endtime);
      this.onDateChange("");
      this.formAdd.controls.otherOptions.setValue(P_description[0].EventSummary);
    }

    this.formAdd.controls.scheduleEndDate.setValue(P_end);

    if (this.scheduleType == 1) { // rebind automations data
      let P_CredentialInfo = JSON.stringify(P_description[0].CredentialInfo);
      let P_ConfigurationInfo = JSON.stringify(P_description[0].ConfigurationInfo);

      this.formAdd.controls.serversOptions.setValue(P_description[0].ServerId);
      this.formAdd.controls.automationOptions.setValue(P_description[0].AutomationId);
      this.formAdd.controls.deploymentLocation.setValue(P_description[0].AutomationLocation);

      // set run always option - true/false
      if (P_description[0].OccurenceRule.toLowerCase().indexOf("until=") > -1) {
        this.formAdd.controls.runAlways.setValue(false);
      } else {
        this.formAdd.controls.runAlways.setValue(true);
        this.onRunAlways();
      }

      this.formAdd.controls.credentialsValues.setValue(P_CredentialInfo);
      this.formAdd.controls.configurationValues.setValue(P_ConfigurationInfo);
      this.CredentialInfoGlobal = P_CredentialInfo;
      this.ConfigurationInfoGlobal = P_ConfigurationInfo;
      this.AutomationIdGlobal = P_description[0].AutomationId;
    } else if (this.scheduleType == 2) { // rebind reports data
      let P_EmailBody = P_description[0].emailbody;
      P_EmailBody = P_EmailBody.replaceAll("DoubleQuotation;", "\"")

      this.formAdd.controls.attachReportsInSingleEmail.setValue((P_description[0].attachinsingleemail == 1) ? true : false);
      this.selectedEmailProfile = P_description[0].emailprofileid;
      this.formAdd.controls.emailProfile.setValue(P_description[0].emailprofileid);
      this.formAdd.controls.mailTo.setValue(P_description[0].emailto);
      this.formAdd.controls.mailCC.setValue(P_description[0].emailcc);
      this.formAdd.controls.mailBCC.setValue(P_description[0].emailbcc);
      this.formAdd.controls.emailSubject.setValue(P_description[0].emailsubject);
      this.formAdd.controls.emailBody.setValue(P_EmailBody);

      // set run always option - true/false
      if (P_description[0].OccurenceRule.toLowerCase().indexOf("until=") > -1) {
        this.formAdd.controls.runAlways.setValue(false);
      } else {
        this.formAdd.controls.runAlways.setValue(true);
        this.onRunAlways();
      }

      let P_reportFilters = P_description[0].reports;
      this.reportsMultipleRows = [];
      this.rebindReportFilters(P_reportFilters);

    } else if (this.scheduleType == 3) { // rebind real time refresh data

      let P_SelectedPracticeIds = P_description[0].uniquepracticeid.split(",");
      let P_SelectedPractices = this.allClientLookup.filter((obj: any) => {
        return (P_SelectedPracticeIds.indexOf(obj.code) > -1);
      });
      this.P_SelectedPracticesTemp = [];
      for (let i = 0; i < P_SelectedPractices.length; i++) {
        this.P_SelectedPracticesTemp.push({
          "code": P_SelectedPractices[i].code,
          "description": P_SelectedPractices[i].description
        });
      }

      this.selectedPracticeManagement = P_description[0].practicemanagementsystem;
      //this.selectedWareHouse = P_description[0].warehouseuniqueid;
      this.selectedDateType = P_description[0].datetype;
      this.formAdd.controls.practiceManagement.setValue(P_description[0].practicemanagementsystem);
      this.changePracticeValues();

      //TODO: this.formAdd.controls.practice.setValue(P_description[0].uniquepracticeid);
      this.selectedPractice = this.P_SelectedPracticesTemp; // P_description[0].uniquepracticeid; // P_description[0].uniquepracticeid;
      this.formAdd.controls.practice.setValue(this.P_SelectedPracticesTemp);
      //this.formAdd.controls.wareHouse.setValue(P_description[0].warehouseuniqueid);
      this.formAdd.controls.dateType.setValue(P_description[0].datetype);
      //this.formAdd.controls.scheduleStartDate.setValue(new Date(P_description[0].schedulefromdate));
      //this.formAdd.controls.scheduleEndDate.setValue(new Date(P_description[0].scheduletodate));
      //this.formAdd.controls.scheduleStartDate.setValue(new Date(P_start));
      //this.formAdd.controls.scheduleEndDate.setValue(new Date(P_end));

      let P_fromdate = P_description[0].fromdate;
      let P_todate = P_description[0].todate;

      P_fromdate = this.parseDate(P_fromdate);
      P_todate = this.parseDate(P_todate);

      this.formAdd.controls.dateTypeStartDate.setValue(P_fromdate);
      this.formAdd.controls.dateTypeEndDate.setValue(P_todate);

      this.onSelectionChange(null);
    }
  }

  changeModalTitleBtn() {

    if (this.scheduleType == 1 && this.instanceId == -1) {
      this.actionHeaderTitle = "Add Instance";
    } else if (this.scheduleType == 2 && this.instanceId == -1) {
      this.actionHeaderTitle = "Add Report";
    } else if (this.scheduleType == 3 && this.instanceId == -1) {
      this.actionHeaderTitle = "Add Ondemand Request";
    } else if (this.scheduleType == 1 && this.instanceId != -1) {
      this.actionHeaderTitle = "Update Instance";
    } else if (this.scheduleType == 2 && this.instanceId != -1) {
      this.actionHeaderTitle = "Update Report";
    } else if (this.scheduleType == 3 && this.instanceId != -1) {
      this.actionHeaderTitle = "Update Ondemand Request";
    }

    if (this.instanceId != -1) {
      this.actionButtonTitle = "Update";
    }
  }

  onAutomationChange() {
    try {
      let P_automationId = this.formAdd.controls.automationOptions.value;
      let P_automationOtherDetails: any;
      let P_data: any;

      if (this.AutomationIdGlobal == 0 ||
        P_automationId != this.AutomationIdGlobal) {
        P_automationOtherDetails = this.listOfAutomation.filter((obj: any) => {
          return (obj.id.toString() == P_automationId.toString())
        });

        P_data = JSON.parse(P_automationOtherDetails[0].inputconfigurations);

        this.formAdd.controls.credentialsValues.setValue(JSON.stringify(P_data[0].CredentialInfo));
        this.formAdd.controls.configurationValues.setValue(JSON.stringify(P_data[0].ConfigurationInfo));
      } else {
        this.formAdd.controls.credentialsValues.setValue(this.CredentialInfoGlobal);
        this.formAdd.controls.configurationValues.setValue(this.ConfigurationInfoGlobal);
      }
    } catch (exe) {
    }
  }

  onDateTypeSTRDTChange() {
    let P_newStartDate = this.formAdd.controls.dateTypeStartDate.value;
    this.formAdd.controls.dateTypeEndDate.setValue(P_newStartDate);
    this.minDateTypeEndDate = new Date(P_newStartDate);
  }

  onDateTypeChange() {
    this.formAdd.controls.dateTypeStartDate.setValue(this.futureDate);
    this.formAdd.controls.dateTypeEndDate.setValue(this.futureDate);
  }

  setFilterValue(item: any) {
    item.filters = `[{
          "practice": "",
          "uniqueservicelocationid": "",
          "selector": "",
          "calendar": "",
          "check_date": "",
          "transaction_date": ""
       }]`;

    this.resetReportsArray();
  }

  rebindReportFilters(P_reportFilters: any) {
    for (let i = 0; i < P_reportFilters.length; i++) {
      this.reportsMultipleRows.push({
        btnVisible: (i == 0 ? true : false),
        selectedReport: P_reportFilters[i].reportidfrombi,
        reportId: P_reportFilters[i].reportId,
        selectedReportType: P_reportFilters[i].reportsendas,
        sendAsPasswordProtected: (P_reportFilters[i].sendaspasswordprotected == 1) ? true : false,
        filters: P_reportFilters[i].filtersjson[0] // JSON.stringify(P_reportFilters[i].filtersjson)
      })
    }
  }

  getReportJSON(P_type: number) {
    let P_input: any = "";

    if (P_type == 1) {
      P_input = [
        {
          btnVisible: true,
          selectedReport: 23,
          selectedReportType: 1,
          sendAsPasswordProtected: true,
          reportId: 23,
          filters: `[{
          "practice": "",
          "uniqueservicelocationid": "",
          "selector": "",
          "calendar": "",
          "check_date": "",
          "transaction_date": ""
        }]`
        }
      ];
    } else if (P_type == 2) {
      P_input = {
        btnVisible: false,
        selectedReport: 23,
        selectedReportType: 1,
        reportId: 23,
        sendAsPasswordProtected: true,
        filters: `[{
          "practice": "",
          "uniqueservicelocationid": "",
          "selector": "",
          "calendar": "",
          "check_date": "",
          "transaction_date": ""
        }]`
      };
    }
    return P_input;
  }

  saveScheduleLog(data: any) {
    let request = [{
      "ScheduleLogID": -1,
      "ScheduleID": data[0].ScheduleInstanceId,
      "EventID_google": data[0].EventId,
      "Status": 0,
      "ScheduleLogDetails": data[0].ScheduleDescription,
      "ScheduleTypeID": data[0].ScheduleTypeId
    }];

    this.botDashboardService.setScheduleLog(request).then((res: any) => {
      //data.message = res[0]?.message;
      //data.title = 'Information!';
      //data.showYes = false;
      //data.noText = 'OK';
      //Confirmation(this.dialog, data);
    })
  }

  generateRandomString(length: number) {
    // Define the characters that will be used in the string
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';

    // Generate a random string of the specified length
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  onSelectionChange(item: any) {
    //let selectedItems = (this._client && item) ? this._client?.selectedItems : this._filters.practiceIds

    if (item == null) {
      let P_SelectedPractices: any = this.allClientLookup.filter((obj: any) => {
        return (obj.code == this.P_SelectedPracticesTemp[0].code)
      });
      this.clientLookup = this.allClientLookup.filter((obj: any) => {
        return (obj.dagid == P_SelectedPractices[0].dagid &&
          obj.warehouseuniqueidentifier == P_SelectedPractices[0].warehouseuniqueidentifier)
      });

      //this.clientLookup = this.allClientLookup;
    } else {
      if (this._client.selectedItems.length == 1) {
        let P_s: any = this._client.selectedItems;
        let P_SelectedPractices: any = this.allClientLookup.filter((obj: any) => {
          return (obj.code == P_s[0].code)
        });

        this.clientLookup = this.allClientLookup.filter((obj: any) => {
          return (obj.dagid == P_SelectedPractices[0].dagid &&
            obj.warehouseuniqueidentifier == P_SelectedPractices[0].warehouseuniqueidentifier)
        });
      }
      if (this._client.selectedItems.length == 0) {
        this.clientLookup = this.allClientLookup;
      }
    }
  }

  getUtcTime(P_time: any) {
    let P_timeOffset = new Date().getTimezoneOffset();
    let Hour = Number((P_timeOffset / 60).toString().split(".")[0])
    let Minutes = P_timeOffset % 60;
    let updatedHour = Number(P_time.split(":")[0]) + Hour;
    let updatedMinutes = Number(P_time.split(":")[1]) + Minutes;

    return Math.abs(updatedHour) + ":" + Math.abs(updatedMinutes);
  }

  onRunAlways() {
    let P_val = this.formAdd.controls.runAlways.value;
    if (P_val) {
      this.formAdd.controls.scheduleEndDate.disable();
      this.formAdd.controls.scheduleEndTime.disable();
    } else {
      this.formAdd.controls.scheduleEndDate.enable();
      this.formAdd.controls.scheduleEndTime.enable();
    }
  }

  changePracticeValues() {
    this.selectedPractice = [];
    this.formAdd.controls.practice.setValue([]);


    let P_PMId = this.formAdd.controls.practiceManagement.value;
    this.clientLookup = this.allClientLookup.filter((obj: any) => {
      return (obj.practicemangementsystemnameid == P_PMId)
    });
  }

  formatDate(dateInput: any) {
    let date = new Date(dateInput);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  parseDate(dateString: any) {
    let [month, day, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }


  doFilter(items: any) {
    if (items) {
      this.filters = items;
    }

    //const report = this.reportObj.getReport();
    //if (report) {
    let reportfilter = [];



    if (this.filters.practiceIds != undefined && this.filters.practiceIds.length > 0) {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "uniquepracticeid",
          table: "Financial Details",
        },
        operator: "In",
        values: items.practiceIds.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    } else {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "uniquepracticeid",
          table: "Financial Details",
        },
        operator: "In",
        values: this.lookupData.clients.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    }

    if ((this.filters.serviceLocations?.length ?? 0) > 0) {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "unique_servicelocationid",
          table: "Financial Details",
        },
        operator: "In",
        values: items.serviceLocations.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    }
    if ((this.filters.serviceProviderIds?.length ?? 0) > 0) {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "uniqueservicingproviderid",
          table: "Financial Details",
        },
        operator: "In",
        values: items.serviceProviderIds.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    }

    if ((this.filters.selectors?.length ?? 0) > 0) {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "Selector",
          table: "Selector",
        },
        operator: "In",
        values: this.filters.selectors.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    }
    if ((this.filters.payorsIds?.length ?? 0) > 0) {
      reportfilter.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          column: "uniqueprimarypayorid",
          table: "Financial Details",

          //

        },
        operator: "In",
        values: this.filters.payorsIds.map((w: any) => w.code),
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
      });
    }
    if (this.filters.calenderStartDate && this.filters.calenderStartDate != '' && this.filters.calenderEndDate != '') {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
          table: "Calendar",
          column: "Date"
        },
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: this.filters.calenderStartDate + 'T00:00:00'
          },
          {
            operator: "LessThanOrEqual",
            value: this.filters.calenderEndDate + 'T00:00:00'
          }
        ],
        filterType: models.FilterType.Advanced
      });
    }
    if (this.filters.checkstartDate && this.filters.checkstartDate != '' && this.filters.checkendDate != '') {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
          table: "Check Date",
          column: "Date"
        },
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: this.filters.checkstartDate + 'T00:00:00'
          },
          {
            operator: "LessThanOrEqual",
            value: this.filters.checkendDate + 'T00:00:00'
          }
        ],
        filterType: models.FilterType.Advanced
      });
    }

    if (this.filters.transactionstartDate && this.filters.transactionstartDate != '' && this.filters.transactionendDate != '') {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
          table: "Transaction Date",
          column: "Transaction date"
        },
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: this.filters.transactionstartDate + 'T00:00:00'
          },
          {
            operator: "LessThanOrEqual",
            value: this.filters.transactionendDate + 'T00:00:00'
          }
        ],
        filterType: models.FilterType.Advanced
      });
    }
    if (this.filters.transactionDate && this.filters.transactionDate != '') {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
          table: "Transaction Date",
          column: "Transaction date"
        },
        logicalOperator: "Or",
        conditions: [
          {
            operator: "LessThanOrEqual",
            value: this.filters.transactionDate + 'T00:00:00'
          }
        ],
        filterType: models.FilterType.Advanced
      });
    }
    if (this.filters.transactionDates && this.filters.transactionDates?.operator.length > 0) {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#relativeDate",
        target: {
          table: "Transaction Date",
          column: "Transaction date"
        },
        operator: parseInt(this.filters.transactionDates.operator[0].code),
        timeUnitsCount: this.filters.transactionDates.value,
        timeUnitType: parseInt(this.filters.transactionDates.timeUnit[0].code),
        includeToday: true,
        filterType: models.FilterType.RelativeDate
      });
    }
    if (this.filters.checkDate && this.filters.checkDate?.operator.length > 0) {
      reportfilter.push({
        $schema: "https://powerbi.com/product/schema#relativeDate",
        target: {
          table: "Check Date",
          column: "Date"
        },
        operator: parseInt(this.filters.checkDate.operator[0].code),
        timeUnitsCount: this.filters.checkDate.value,
        timeUnitType: parseInt(this.filters.checkDate.timeUnit[0].code),
        includeToday: true,
        filterType: models.FilterType.RelativeDate
      });
    }


    this.showFilter = false;
    //report.setFilters(reportfilter);
    return reportfilter;
    //}
  }

  resetReportsArray() {
    try {
      let index = 0;
      this.reportList = [];
      this.reportsRow.forEach((currentRow) => {
        const P_elements = currentRow.nativeElement.getElementsByClassName('report-div');
        let P_reportid: any = "";
        if (P_elements[0]?.getElementsByClassName('report-name').length > 0) {
          P_reportid = P_elements[0]?.getElementsByClassName('report-name')[0]?.value || "";
        }
        //this.reportsMultipleRows[index].reportId = P_reportid;

        let P_reportOtherDetails = this.Reports.filter((obj: any) => {
          return (obj.menuid == P_reportid)
        });

        this.reportsMultipleRows[index].reportId = P_reportOtherDetails[0].externalsystem_guid;
        index++;
      });
    } catch (exe) {
    }
  }

  getDateFilterRange(w: models.IRelativeDateFilter) {
    let target: any = w.target
    if (w.operator == 1) w.timeUnitsCount = 1;
    let endDate: string = '';
    let startDate: string = '';

    switch (w.timeUnitType) {
      case models.RelativeDateFilterTimeUnit.Days:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'd').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Weeks:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'w').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Months:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'M').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Years:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'y').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarWeeks:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'w').startOf('week').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'w').endOf('week').format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarMonths:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'M').startOf('month').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'M').endOf('month').format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarYears:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'y').startOf('year').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'y').endOf('year').format("YYYY-MM-DD");
          break;
        }
    }
    return `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} ge datetime'${startDate}T00:00:00' and ${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} le datetime'${endDate}T00:00:00' and `

  }

}
