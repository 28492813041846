<h2 mat-dialog-title>{{data.title}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
    <span [innerHTML]="data.message"></span>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button class="btn btn-btn-primary" *ngIf="data.showYes" (click)="doYes()" mat-dialog-close>{{data.yesText}}</button>
    <button mat-button class="btn" *ngIf="data.showNo" (click)="doNo()" cdkFocusInitial>{{data.noText}}</button>
</mat-dialog-actions>
