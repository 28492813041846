<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="seq">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.seq}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="payorname">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.payorname}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="payorgroupname">
        <th mat-header-cell *matHeaderCellDef> Group </th>
        <td mat-cell *matCellDef="let element"> {{element.payorgroupname}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="isCurrent">
        <th mat-header-cell *matHeaderCellDef>Current </th>
        <td mat-cell *matCellDef="let element"> {{element.isCurrent?'Yes':'No'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No record(s) found</td>
    </tr>
</table>
