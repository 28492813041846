
import * as moment from 'moment-timezone';
import { CurrencyPipe, DatePipe } from '@angular/common'; 

 
export function parseFloatNumbersOnly(value: string): number|null {
  if (value) {
    return parseFloat(value.replace(/[^-0-9.]/g, ''));
  }
  return null;
}

export function dateOnSameDay(val1: Date, val2: Date): boolean {
  if (val1 && val2) {
    return val1.getFullYear() === val2.getFullYear() &&
      val1.getMonth() === val2.getMonth() &&
      val1.getDate() === val2.getDate();
  } else {
    return !val1 && !val2;
  }
}

export function NegativeFormatCurrency(num: number, nullvalue: string = ''): string {
  if (num == null) {
    return nullvalue;
  } else {
    if (num < 0) {
      return `($${Math.abs(num).toFixed(2)})`;
    } else {
      return '$' + num.toFixed(2);
    }
  }
}

// "True" rounding - half-cent increases value.
// Note that math operations may still cause precision issues - i.e., 1.005 * 100 => 100.49999999
export function currencyRound(value: number): number|null {
  if (value == null) {
    return null;
  }
  return Math.round(value * 100) / 100;
}

export function getAge(date: any): number {
  if (date) {
    const bdate = moment(date);
    if (bdate.isValid()) {
      const age = moment().diff(bdate, 'days');
      return age;
    }
  }
  return 0;
}

export function getAgeDisplay(startDate: any, includeParens: boolean = false): string { 
  if (startDate) {
    const bdate = moment(startDate);
    
    if (bdate.isValid()) {
      const age = moment().diff(bdate, 'days');
      if (age >= 0 && age < 300) {
        if (includeParens) {
          return `(${age})`;
        } else {
          return `${age}`;
        }
      }
    }
  }
  return '';
}

export function getSafeDateReturnMoment(dateval: any, local: boolean = false, safeMaxAge: number = 500) {
  if (dateval) {
    const sd = local ? moment(dateval) : moment.utc(dateval); 
    if (sd.isValid() && !sd.isSame(moment("01-01-1900"))) {
      const age = moment().diff(sd, 'years');
      if (safeMaxAge === -1 || age < safeMaxAge) {
        return sd;
      }
    }
  }
  return null;
}

export function getSafeDate(dateval: any, local: boolean = false, safeMaxAge: number = 500) {
  const ret = getSafeDateReturnMoment(dateval, local, safeMaxAge);
  if (ret) {
    return dateval;
  }
  return null;
}


export function convertDateToUTC(date: Date | null, includeTime: boolean = false): Date | null {
  if (date == null) {
    return null;
  }
  const tmp = new Date(date);
  if (includeTime) {
    return new Date(tmp.getUTCFullYear(), tmp.getUTCMonth(), tmp.getUTCDate(), tmp.getUTCHours(), tmp.getUTCMinutes(), tmp.getUTCSeconds(), tmp.getUTCMilliseconds());
  } else {
    return new Date(tmp.getUTCFullYear(), tmp.getUTCMonth(), tmp.getUTCDate(), 0, 0, 0);
  }
}

export function getDateFromDB(date: Date | null): Date | null {
  const safeDate = getSafeDate(date);
  if (safeDate == null) {
    return null;
  }
  const tmp = new Date(safeDate);
  return new Date(tmp.getUTCFullYear(), tmp.getUTCMonth(), tmp.getUTCDate(), 0, 0, 0);
}

export function getLocalStartOfDay(date: Date | null): Date | null {
  if (date == null) {
    return null;
  }
  return moment(date).startOf('day').toDate();
}

export function getLocalToday(): Date {
  return moment().startOf('day').toDate();
}

export function convertTimestampToDate(date: Date | null): Date | null {
  if (date == null) {
    return null;
  }
  const tmp = new Date(date);
  return new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate()));
}

export function convertIntervalToTime(val: string): string {
  // API returns time as a period, in ISO-8601 format ('PT05H59M', e.g.)
  if (val && val !== '') {
    const m = moment(val, 'HH:mm:ss');
    return m.format('h:mm A');
  } else {
    return '';
  }
}

 
export function getPercentDisplay(val: number): string|null {
  if (!isNaN(val)) { // evaluating val directly fails when val is 0
    let ret = `${(val * 100).toPrecision(10)}`;
    if (ret.indexOf('.') > -1) {
      while (ret.endsWith('0')) {
        ret = ret.substr(0, ret.length - 1);
      }
      if (ret.endsWith('.')) {
        ret = ret.substr(0, ret.length - 1);
      }
      if (ret === '') {
        ret = '0';
      }
    }
    ret += '%';
    return ret;
  } else {
    return null;
  }
}
export function transformDate(value: any, format ?: string, local ?: boolean, safeMaxAge ?: number): string | null {
  var sd: moment.Moment | null;
  if (!safeMaxAge) {
    sd = getSafeDateReturnMoment(value, local);
  }
  else {
    sd = getSafeDateReturnMoment(value, local, safeMaxAge);
  }
  if (sd) {
    return sd.format(format);
  }
  return null;
}
export function getPercentString(numerator: number, denominator: number): string|null {
  let pct = 0;
  let result = '';

  if (numerator === 0 && denominator === 0) {
    pct = 0; // no change
  } else {
    pct = numerator / denominator;
  }
  if (pct !== 0 && pct < 0.01) {
    result = '< 1%';
  } else {
    pct = Math.round(pct * 100) / 100;
    return getPercentDisplay(pct);
  }
  return result;
}


export function getODataFilterStringFromArray(filters: string[], operator: string = 'and'): string {
  let filterStr = '';
  for (let index = 0; index < filters.length; index++) {
    if (index > 0) {
      filterStr += ` ${operator} (${filters[index]})`;
    } else {
      filterStr = `(${filters[index]})`;
    }
  }
  return filterStr;
}

 
export function isToday(date: any) {
  // Comparing the Date values doesn't work - this uses the local timezone for both sides
  // Need to format the date for the selected time zone (local for selected date; Pacific for "now" time)
  // and compare the values.  Ugh, but can't think of a better way right off...
  const pacNow = moment().tz('America/Los_Angeles');
  return moment(date).format('YYYY-MM-DD') === pacNow.format('YYYY-MM-DD');
}
 
export function cutoffTime() {
  return moment('11:45:00', 'HH:mm:ss').tz('America/Los_Angeles', true);
}

export function toISODateString(date: Date) {
  return moment(date).format('YYYY-MM-DD');
}

export function isGuid(value: string): boolean {
  if (!value) {
    return false;
  }
  // This could be restricted a little more, but one of ours failed so I allow for all digits everywhere
  const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  if (guidPattern.test(value) === true) {
    return true;
  } else {
    return false;
  }
}

export function getMonthName(index: number) {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return monthNames[index];
}

export function getWeekName(index: number) {
  const monthNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday","Saturday"];
  return monthNames[index];
}

export function getWeekShortName(index: number) {
  const monthNames = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  return monthNames[index];
}
 
export function getConverted12HourTime(hours: string, minutes: string, forInput: boolean = false) {
  let timeInAMPM = "";
  let P_Hours = 0;
  let P_timeZone = "";
  if (Number(hours) > 12) {
    P_Hours = Number(hours) - 12;
    P_timeZone = "PM";
  } else if (Number(hours) == 12) {
    P_Hours = 12;
    P_timeZone = "PM";
  } else {
    P_Hours = Number(hours);
    P_timeZone = "AM";
  }

  timeInAMPM = P_Hours + " " + (Number(minutes) < 10 ? ("0" + minutes) : minutes) + " " + P_timeZone;

  if(forInput) { // to set value in inputs type time in angular
    timeInAMPM = (Number(hours) < 10 ? ("0" + hours) : hours) + ":" + (Number(minutes) < 10 ? ("0" + minutes) : minutes);
  }

  //console.log("timeInAMPM: ", timeInAMPM);

  return timeInAMPM;
}

export function GetFormattedDate(date: Date) {
  return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
}
