import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MultiSelectComponent } from '../../../../shared/multi-select/multi-select.component';


@Component({
  selector: 'app-update-custom-time-units',
  templateUrl: './update-custom-time-units.component.html',
  styleUrls: ['./update-custom-time-units.component.css']
})
export class UpdateCustomTimeUnitsComponent {
  public _lookupData: any;
  @Input() set data(data: any) {
    this._lookupData = data;
    this.calculateValues();
  }
  get data() {
    return this._lookupData;
  }

  @ViewChild('providerType') _providerTypeId!: MultiSelectComponent;
  @ViewChild('asaCodes') _asaCodes!: MultiSelectComponent;
  @ViewChild('cptcodes') _cptCodes!: MultiSelectComponent;
  @ViewChild('excludemodifiers') _excludemodifiers!: MultiSelectComponent;
  @ViewChild('includemodifiers') _includemodifiers!: MultiSelectComponent;
  formAdd: FormGroup = new FormGroup({
    defaultunits: new FormControl(''),
    anesthesia: new FormControl(true, [Validators.required]),
    providerordersequence: new FormControl(''),
  }, [(control: AbstractControl) => {
    const status = this.isValidInput('defaultunits')
    return status ? null : { 'defaultunits': true };
  }]);
  @Output() onCancel = new EventEmitter();
  onSaveConnector() {
    this.formAdd.markAllAsTouched();
    if (this.formAdd.valid) {
      let item: any = {
        tablename: "",
        uniquepracticeid: "",
        cptCodes: this._cptCodes?.selectedItems ?? [], /* multiple input cptcode|units,cptcode|units*/
        asaCodes: this._asaCodes?.selectedItems ?? [], /* multiple input cptcode|units,cptcode|units*/
        defaultunits: this.formAdd.controls.defaultunits.value, /* should be used when we need to update hardcode value */
        providerOrderSequence: this.formAdd.controls.providerordersequence.value, 
        providerTypeId: this._providerTypeId?.selectedItems ?? [], /*servicing or directing*/ /* multiple input*/
        isAnesthesia: this.formAdd.controls.anesthesia.value ? 1 : 0, /* 1 for anesthesia  and 0 for non anesthesia rows */
        excludeModifiers: this._excludemodifiers?.selectedItems ?? [], /*multiple input*/
        includeModifiers: this._includemodifiers?.selectedItems ?? [], /*multiple input*/ 
      };
      this.onCancel.emit(item);
    }
  }
  doCancelEdit() {
    this.doReset();
    this.doCancel(false);
  }
  doReset() {
   
  }
  doCancel(item: any) {
    this.onCancel.emit(item);
  }
  castToFloat(item: any, control: string, type: string) {
    let val = parseFloat(this.formAdd.controls[control].value);
    if (type == 'int') {
      val = parseInt(this.formAdd.controls[control].value);
    }

    if (isNaN(val)) {
      this.formAdd.controls[control].setValue('0');
    }
    else if (val > 100) {
      this.formAdd.controls[control].setValue('0');
    }
    else {
      this.formAdd.controls[control].setValue(val);
    }
  }
  calculateValues() {
    if (this.data && this.data?.node?.data?.filters) {
      let filters = this.data?.node?.data?.filters;
      let lookups = this.data?.lookupData;
      this.formAdd.controls.distributeTimeUnitsFactor.setValue(filters.RegulardistributeTimeUnitsFactor)
      this.formAdd.controls.OverTimedistributeTimeUnitsFactor.setValue(filters.OverTimedistributeTimeUnitsFactor)
      this.formAdd.controls.OverTimeStart.setValue(filters.OverTimeStart)
      this.formAdd.controls.OvertimeEnd.setValue(filters.OvertimeEnd);
      if (filters.cptCodes?.length > 0) {
        filters.cptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
      if (filters.excludecptCodes?.length > 0) {
        filters.excludecptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
    }
  }
  isValidInput(input: string) {
    switch (input) {
      case 'defaultunits': {
        return (this.formAdd?.controls[input]?.value??'') != '' ||
          (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0;
      }
    }
  }
  //validateInputs(control: AbstractControl) => {    
  //  const status = this.isValidInput('defaultunits')
  //  return status ? null : { 'defaultunits': true };
  //}
}
