import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as custom from '../Utility/CustomValidators.utilities';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})

export class ClientService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Client';
  constructor(private http: HttpClient) {

  }
  async getlookup(request: any): Promise<any> {
    if (!request) {
      request = []
    }
    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/lookup`,
      //custom.httpOptions()).toPromise();
    //console.log("lookup: ", JSON.stringify(result));

    let result = [{"clientid":"0210_100252180","clientname":"Northside_nexgen_1","dagid":"777777","warehouseuniqueidentifier":100252180},{"clientid":"95-62559_2222102","clientname":"Whiteside_kareo_1","dagid":"2222","warehouseuniqueidentifier":2222102},{"clientid":"1000060123_2124312","clientname":"Port_Elise_centricity_1","dagid":"3333","warehouseuniqueidentifier":2124312},{"clientid":"206005_206005","clientname":"Murrayhaven_allscript_1","dagid":"9999","warehouseuniqueidentifier":206005},{"clientid":"243036_243036","clientname":"Novellaport_drchrono_1","dagid":"7777","warehouseuniqueidentifier":243036},{"clientid":"10023557_57206076","clientname":"East_Arlo_collab_1","dagid":"5555","warehouseuniqueidentifier":57206076},{"clientid":"202304041220_202304041220","clientname":"Port_Devyn_ecw_1","dagid":"1010","warehouseuniqueidentifier":202304041220},{"clientid":"154021_20230901","clientname":"Baylor_Dr_amd_1","dagid":"8888","warehouseuniqueidentifier":20230901}];

    return result;
  }
  public async getClient(request: any[]): Promise<any> {
   
/*
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getclientsdetails`,
      custom.httpOptions()).toPromise();
    return result;
    */

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getclientsdetails`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getClientDetails(id: number): Promise<any> {
 
 
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }
  async setClient(request: any): Promise<any> {

    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setclientsdetails`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getreportfilter(request: any[]): Promise<any> {
    
    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/reportfilter`,
     // custom.httpOptions()).toPromise();

    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${reportid}`,
      //httpOptionsJSON).toPromise();

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getreportfilter`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getselectors(request: any): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/selectors`,
      custom.httpOptions()).toPromise();
    return result;
  }
  async getServiceLocations(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }    
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/servicelocations`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getPracticeManagementLookup(request: any): Promise<any> {
    if (!request) {
      request = [{
        "id": -1
      }]
    }
    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/pmlookup`,
      //custom.httpOptions()).toPromise();
    //console.log("pmlookup: ", JSON.stringify(result));

    let result = [{ "id": 1, "name": "kareo" }, { "id": 2, "name": "vertex" }, { "id": 3, "name": "allscript" }, { "id": 4, "name": "amd" }, { "id": 5, "name": "centricity" }, { "id": 6, "name": "collab" }, { "id": 7, "name": "pdm" }, { "id": 8, "name": "ecw" }, { "id": 9, "name": "drchrono" }, { "id": 10, "name": "phygenesis" }];
    return result;
  }
  async getServicingProviders(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/servicingproviders`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async GetPrimaryPayorGroups(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/primarypayorgroups`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async GetPrimaryPayors(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/primarypayors`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
}
