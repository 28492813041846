import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-loader',
  templateUrl: './data-loader.component.html',
  styleUrls: ['./data-loader.component.css']
})
export class DataLoaderComponent {
  public _isLoading: boolean= false;
  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }
  get isLoading() {
    return this._isLoading;
  }
}
