import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Group } from './group';
const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})
export class GroupService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'group';
  constructor(private http: HttpClient) { }
   
  public async getGroup(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getGroupDetails(id:number): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }
  async setGroup(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async deleteGroup(request: any[]): Promise<any> {    
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/deletegroup`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getGroupClient(request: any[]): Promise<any> {    
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getclientsdetails`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async setGroupClient(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setclientsdetails`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
}
