import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { UserGroup, UserRole } from '../../user/user-group';
import { UserService } from '../../user/user.service';
import { Role, RoleMenu } from '../role';

@Component({
  selector: 'app-role-users',
  templateUrl: './role-users.component.html',
  styleUrls: ['./role-users.component.css']
})
export class RoleUsersComponent {
  public _role!: Role;
  public _isLoading: boolean = false;
  @Output() onUpdateEdit = new EventEmitter();
  @Input() set role(_role: Role) {
 
    this._role = _role;
    if (_role && _role.roleid != -1) {
      this.getMasterDetails(_role.roleid)
    }
   
  }
  get role() {
    return this._role;
  }

  roleUsers!: UserRole[];
  dataSource = new MatTableDataSource<UserRole>([]);
  assignAll: boolean = true;
  displayedColumns: string[] = ['checkbox','name', 'description'];
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit();
  }
  constructor(private userService: UserService, private dialog: MatDialog) {

  
  }
  
  async doSave() {
    this.onShowLoading.emit(true);
 
    let request = this.roleUsers.filter(w => { return w.assigned }).map(w => {
      return {
        userid: w.userId,
        roleid: this._role.roleid,
        typeid: 2,
        loginuserid: -1
      }
    });
    console.log(request)
    if (request.length == 0) {
      request = [{
        userid: -1,
        roleid: this._role.roleid,
        typeid: 2,
        loginuserid: -1
      }]
    }
    this.userService.setUserRoles(request).then(res => {
      this.onShowLoading.emit(false);
      this.onUpdateEdit.emit(this._role);
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      Confirmation(this.dialog, data);
    }); 
   
  }
  onCheckboxChange(event: any, element: UserRole | null) {
  
    if (element == null) {
      this.roleUsers.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.roleUsers;
    }
    else {
      element.assigned = event.checked;
    }
    this.assignAll = this.roleUsers.filter(w => { return w.assigned }).length == this.roleUsers.length;
    console.log(element)
    console.log('Update successful:', this.dataSource);
  }
  async getMasterDetails(id: number) {
    let request = [{
      userid: -1,
      roleid: id
    }]
    const lookups = await Promise.all([
      this.userService.getUserRoles(request)
    ]);
    console.log(lookups)
    this.roleUsers = lookups[0].map((w: any, idx: number) => {
      
      let s: UserRole = {} as UserRole;
      s.assigned = w.isassigned ==1;
      s.userId = w.userid;
      s.email = w.email;
      s.name = w.name;
      return s;
    });
    this.assignAll = this.roleUsers.filter(w => { return w.assigned }).length == this.roleUsers.length;

    this.dataSource.data = this.roleUsers;

  }
  @Output() onShowLoading = new EventEmitter();
  
}
