<div class="row" style="margin: 10px;">
    <div class="col-lg-7">
     
        <div fxLayout="column" fxLayoutGap=".5em">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Practice:</div>
                        <div class="col-md-6"> {{task?.practice}}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Ticket #:</div>
                        <div class="col-md-6">{{(task)?task.encounterid:''}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Patient:</div>
                        <div class="col-md-6"> {{task?.PatientName}}</div>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Patient #:</div>
                        <div class="col-md-6"> {{task?.patient_accountnumber}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">DOS:</div>
                        <div class="col-md-6">{{task?.FirstDOS | datePipeSafe: 'MM/DD/YYYY':true}}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-7 claim-details-title">DOD:</div>
                        <div class="col-md-5">{{task?.DischargeDate | datePipeSafe: 'MM/DD/YYYY':true}}</div>
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Claim Status :</div>
                        <div class="col-md-6"> {{task?.claimStatus}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Facility:</div>
                        <div class="col-md-6"> {{task?.PM_System_facilityname}}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 claim-details-title">Service Location:</div>
                        <div class="col-md-6"> {{task?.PM_System_Servicelocationname}}</div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row task-payor-Details">
                <mat-tab-group>
                    <mat-tab label="Payers">
                        <app-task-payor-list [task]="task"></app-task-payor-list>
                    </mat-tab>
                </mat-tab-group>

            </div>
        </div>
        </div>
    <div class="col-lg-5 border-left">
        <app-task-summary [task]="task"></app-task-summary>
    </div>

</div>
