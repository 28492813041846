import { Pipe, PipeTransform } from '@angular/core';
import { getAge } from '../Utility/common-functions';

@Pipe({
  name: 'displayAgingBucket'
})
export class DisplayAgingBucketPipe implements PipeTransform {

  transform(value: any): any {
    const age = getAge(value);

    if (age < 31) {
      return '1-30'
    }
    else if (age < 61) {
      return '31-60'
    }
    else if (age > 90) {
      return 'Over 90'
    }

    return getAge(value);
  }

}
