import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../components/user/user.service';
import {Contact,contacts} from './contact-data';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {

  contactsData: Contact[] = [];
  _isLoading: boolean = false;
  constructor(private userService: UserService) {
    this._isLoading = true;
    userService.getTeams([]).then(res => {
      this._isLoading  = false;
      this.contactsData = res.map((w:any) => {

        return {
          image: "",
          class: "",
          name: w.name,
          email: w.username
        }
      });
    });
  }

  ngOnInit(): void {
  }

}
