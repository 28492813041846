<section class="table-container">
  <!--<mat-form-field>-->
    <!--<mat-label>Filter</mat-label>-->
    
  <!--</mat-form-field>-->

  <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="actions" sticky>
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>
      </td>
    </ng-container>
    <ng-container matColumnDef="username" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <span mat-sort-header class="mat-header-text">User Name</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.username }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <span mat-sort-header class="mat-header-text">Email </span>
        <!--<button type="button" class="mat-header-btn" mat-button (click)="onContextMenu($event, 'email')">
          <mat-icon [style.color]="filterData['email']?.length>0 ? '#1e88e5' : null">filter_list</mat-icon>
      </button>-->
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="employeeID">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Employee #</th>
      <td mat-cell *matCellDef="let element">
        {{element.employeeid }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name </th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.name?.toLocaleLowerCase() }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isEmailVerified">
      <th mat-header-cell *matHeaderCellDef>
        <span mat-sort-header class="mat-header-text">Verified </span>
        <!--<button type="button" class="mat-header-btn" mat-button (click)="onContextMenu($event, 'isemailverified')">
          <mat-icon [style.color]="filterData['isemailverified']?.length>0 ? '#1e88e5' : null">filter_list</mat-icon>
      </button>-->
      </th>
      <!--<td mat-cell *matCellDef="let element" class="mat-column-boolean">
      <mat-checkbox class="example-margin" [checked]="element.isemailverified==1" disabled></mat-checkbox>
    </td>-->

      <td mat-cell *matCellDef="let element" [app-email-verified]="element.isemailverified" [app-email-verified-cell]="true"></td>
    </ng-container>
    <ng-container matColumnDef="IsActive">
      <th mat-header-cell *matHeaderCellDef>
        <span mat-sort-header class="mat-header-text">Active </span>
        <!--<button type="button" class="mat-header-btn" mat-button (click)="onContextMenu($event, 'isactive')">
        <mat-icon [style.color]="filterData['isactive']?.length>0 ? '#1e88e5' : null">filter_list</mat-icon>
      </button>-->
      </th>

      <td mat-cell *matCellDef="let element" [app-entity-status]="element.isactive" [app-entity-status-cell]="true"></td>
    </ng-container>
    <ng-container matColumnDef="groupCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Groups </th>
      <td mat-cell *matCellDef="let element">
        {{element.groupcount }}
      </td>
    </ng-container>
    <ng-container matColumnDef="roleCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Roles </th>
      <td mat-cell *matCellDef="let element">
        {{element.rolecount }}
      </td>
    </ng-container>
    <ng-container matColumnDef="portalCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Portals </th>
      <td mat-cell *matCellDef="let element">
        {{element.portalcount }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">Created By </th>
      <td mat-cell *matCellDef="let element" class="cell-created-by ellipses-on-line-3" [title]="element.createdby">
        {{element.createdby }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Createdon">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-on"> Created On </th>
      <td mat-cell *matCellDef="let element" class="cell-created-on text-right"> {{ DateFormate(element.createdon, "MM/dd/yyyy hh:mm:ss tt")}} </td>
    </ng-container>
    <ng-container matColumnDef="modifiedBy">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-by">Modified By </th>
      <td mat-cell *matCellDef="let element" class="cell-modified-by ellipses-on-line-3" [title]="element.modifiedby">
        {{element.modifiedby }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="modifiedOn">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-on"> Modified On </th>
      <td mat-cell *matCellDef="let element" class="cell-modified-on text-right"> {{ DateFormate(element.modifiedon, "MM/dd/yyyy hh:mm:ss tt")}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>

    <!--<tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching the filter "{{element.filtervalue}}"</td> 
  </tr>-->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>    
  </tr>

  </table>
</section>
<mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngIf="hasUserAccount_Edit" (click)="doEditUser(item)">Edit</button>
        <button mat-menu-item *ngIf="hasUserAccount_Delete" (click)="doDeleteUser(item)"> Delete</button>
    </ng-template>
</mat-menu>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menuFilter" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #menuFilter="matMenu">
    <ng-template matMenuContent let-item="item">
        <div mat-dialog-title (click)="$event.stopPropagation();">
            <app-custom-filter [masterData]="item" (onFilterApply)="onFilterApply($event)"></app-custom-filter>            
        </div>         
    </ng-template>
</mat-menu>

