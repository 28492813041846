import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Condition, Group, RuleAction } from '../../../entities/condition-entity';
import { MatMenuTrigger } from '@angular/material/menu';
import { TableList } from '../../../entities/table-list';
import { Table } from '../../../entities/table-entity';
import { TableService } from '../../../services/table.service';
import { RuleService } from '../../../services/rule.service';

@Component({
  selector: 'app-rule-action',
  templateUrl: './rule-action.component.html',
  styleUrls: ['./rule-action.component.css']
})
export class RuleActionComponent {
  public sortedData = new MatTableDataSource<(Condition | Group)>([]);
  displayedColumns: string[] = ['block', 'condition', 'fromColumn', 'oprator', 'columnValue'];

  public actionDataSource = new MatTableDataSource<RuleAction>([]);
  actionDisplayColumns: string[] = ['block', 'table', 'column', 'columnValue'];

  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  public _createTable!: Table
  @Input() set Table(createTable: Table) {
    this._createTable = createTable as Table;
  }
  get table() {
    return this._createTable;
  }
  tableColumns(elemnt: any) {
    return this.tables.find(w => w.name == elemnt.tableName)?.columns;
  }

  public conditions: (Condition | Group)[];
  public tables!: TableList[];

  constructor(tableService: TableService, ruleService: RuleService) {
    this.tables = tableService.getTableList();
    this.conditions = ruleService.getRuleActionConditions();
    let actions = ruleService.getRuleAction();
    this.actionDataSource.data = actions;
    this.setConditions();
  }
  isGroup(index: any, item: any): boolean {
    return item.isGroup;
  }

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextAddCondition(item: any) {
    let condition = {
      id: this.conditions.length + 1,
      condition: "AND",
      fromColumn: "",
      toColumn: "",
      oprator: "",
      value: "",
      isStart: false,
      isGroup: false,
      groupId: item?.id
    } as Condition;
    if (!item) {
      item = {
        "id": this.conditions.length+2,
        "groupId": -1,
        "group": "Block-",
        "isStart": true,
        "isGroup": true,
        "expanded": true,
         condition: "AND"
      }
      condition.groupId = item?.id;
      this.conditions.push(item);
    }
    this.conditions.push(condition);
    this.setConditions();
  }

  onContextMenuAction2(item: any) {
    //alert(`Click on Action 2 for ${item.name}`);
  }
  getConditionValue(element: Condition) {
    //
    return element.condition;
  }
  setConditionValue(element: Condition) {

    return element.condition;
  }
  setConditions() {
    let conditions: any[] = [];
    let groups = this.conditions.filter(w => {
      if (w.groupId == -1) return true;
    });
    groups.map((w, idx) => {
      w.isStart = (idx == 0);
      conditions.push(w);
      this.conditions.filter(s => { return s.groupId == w.id }).map((s, indx) => {
        s.isStart = (indx == 0);
        conditions.push(s);
        this.conditions.filter(s1 => { return s1.groupId == s.id }).map((s1, indx1) => {
          s1.isStart = (indx1 == 0);
          conditions.push(s1);

        })
      });
    });
    this.sortedData.data = conditions;
  }
  groupHeaderClick(row: any) {
    row.expanded = !row.expanded;
    //this.sortedData.filter = performance.now().toString();  // bug here need to fix
  }
  visible(row: any): boolean {
    let parent = this.sortedData.data.find(w => { return w.id == row.groupId })

    if (parent && parent?.groupId != -1) {
      let subParent = this.sortedData.data.find(w => { return w.id == parent?.groupId })
      if (subParent)
        return parent.expanded && subParent.expanded;
      else return true;
    }
    if (parent)
      return parent.expanded;
    else return true;
  }
  onInputChange(event: any, item: any, column: string) {
    //

    switch (column) {
      case 'name': {
        item.name = event.currentTarget.value;
        break;
      }
      case 'size': {
        item.size = event.currentTarget.value;
        break;
      }
      case 'scal': {
        item.scal = event.currentTarget.value;
        break;
      }
      case 'value': {
        item.value = event.currentTarget.value;
        break;
      }
    }
  }

}
