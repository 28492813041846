<section class="edit-container-popup">
    <mat-card-title>
        Connector
    </mat-card-title>
    <mat-divider></mat-divider>

    <form [formGroup]="formAdd" class="popup-form">
        <div fxLayout="column" fxLayoutGap="1em">
            <div class="row">
                <div class="Connector-info col-lg-6 col-md-6 col-sm-6">
                    <small id="name" class="form-text text-muted">Connector type:</small>
                    <div>
                        <select name="Connectortype" disabled
                                id="Connectortype"
                                class="form-control"
                                formControlName="ConnectorType">
                            <option value="1" disabled>Google Drive</option>
                            <option value="2" selected>Upload File</option>
                            <option value="3" disabled>Database</option>
                            <option value="4" disabled>FTP</option>
                        </select>
                    </div>
                </div>
                <div class="Connector-info col-lg-6 col-md-6 col-sm-6">
                    <small id="name" class="form-text text-muted">Connector Name:</small>
                    <div>
                        <input type="text" matInput formControlName="ConnectorName" class="form-control" placeholder="Connector Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="Connector-info col-lg-6 col-md-6 col-sm-6">
                    <small id="name" class="form-text text-muted">Upload File:</small>
                    <div>
                        <input #f_input type="file" id="upload" class="account-file-input" hidden="" (change)="onChange($event)">
                        <button mat-flat-button style="margin-right: 10px; margin-bottom: 10px;" (click)="f_input.click()" color="primary">Upload</button>
                        <small id="name" class="form-text text-muted">Uploaded File: {{file}}</small>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <mat-divider></mat-divider>

    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" (click)="onSaveConnector()">Save</button>
        <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button>
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
    </div>
</section>
