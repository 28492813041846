import { Component, Input } from '@angular/core';
import { Table, TableColumn } from '../../../entities/table-entity';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-create-table',
  templateUrl: './create-table.component.html',
  styleUrls: ['./create-table.component.css']
})
export class CreateTableComponent {
  @Input() _createTable!: Table

  @Input() set Table(createTable: Table) {
    this._createTable = createTable;
    this._createTable as Table;
    this.sortedData.data = createTable.columns;
  }
  get Table() {

    this._createTable.columns = this.sortedData.data;
    
    return this._createTable;
  }

  public sortedData = new MatTableDataSource<TableColumn>([]);
  displayedColumns: string[] = ['actions', 'name', 'dataType', 'size', 'scal', 'isNotNull', 'default'];
  contextMenuPosition = { x: '0px', y: '0px' };

  public dataType: string[];
  constructor() {

    this.dataType = [
      "INT",
      "SMALLINT",
      "BIGINT",
      "DECIMAL",
      "NUMERIC",
      "FLOAT",
      "DOUBLE",
      "REAL",
      "CHAR",
      "VARCHAR",
      "TEXT",
      "DATE",
      "TIME",
      "TIMESTAMP",
      "DATETIME",
      "BOOLEAN",
      "BINARY",
      "VARBINARY",
      "BLOB",
      "JSON",
      "UUID",
      "ENUM",
      "SET"
    ]
  }


  addColumn() {
    this._createTable.columns.push({
      id: (new Date()).getTime(),
      name: "",
      dataType: "",
      size: 0,
      scal: 0,
      isNotNull: false,
      default: "",
      sequence: 0,
    } as TableColumn);
    this.sortedData.data = this.Table.columns;
  }
  onContextAddCondition() {

  }
  onCheckboxChange(event: any, item: TableColumn) {
    item.isNotNull = event.checked;
    console.log('Update successful:', this.sortedData);
  }
  onNameChange(event: any, item: TableColumn) {
    //
    item.name = event.currentTarget.value;
    console.log('Update successful:', this.sortedData);
  }
  onInputChange(event: any, item: TableColumn,column:string) {
    //

    switch (column) {
      case 'name': {
        item.name = event.currentTarget.value;
        break;
      }
      case 'size': {
        item.size = event.currentTarget.value;
        break;
      }
      case 'scal': {
        item.scal = event.currentTarget.value;
        break;
      }      
      case 'default': {
        item.default = event.currentTarget.value;
        break;
      }
    } 
  }
}
