<div class="tree">
    <ul>
        <li *ngFor="let item of flowChart">
            <a href="#" droppable [dragOverClass] [dropScope]="['CreateTable', 'Join','MapColumn','AddFilter','Desision','Action']"
               [dragHintClass]="'drag-hint'" (onDrop)="onAnyDrop($event,item)">{{item.name}}</a>
            <app-nested-flow *ngIf="item.Child.length>0" [flowChart]="item.Child"></app-nested-flow>

        </li>
    </ul>
</div>

