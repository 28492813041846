import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import { ColumnList, ColumnSelection, TableList } from '../../../entities/table-list';
import { TableService } from '../../../services/table.service';
import { RulesConditionComponent } from '../rules-condition/rules-condition.component';


@Component({
  selector: 'app-rules-joins',
  templateUrl: './rules-joins.component.html',
  styleUrls: ['./rules-joins.component.css']
})
export class RulesJoinsComponent {
  public dataSource = new MatTableDataSource<ColumnSelection>([]);
  public _selectedColumn: ColumnSelection[] = []
  @Input() set selectedColumn(selectedColumn: ColumnSelection[]) {
    this._selectedColumn = selectedColumn;
  }
  get selectedColumn() {
    return this._selectedColumn;
  }
  tables: TableList[] = [];
  toTable!: TableList;
  joinLookup!: LookupEntityBase;
  fromTable!: TableList;
  selectedDevice = 'two';
  _tempTable = '';
  @Input() set tempTable(tempTable: string) {
    this._tempTable = tempTable;
  }
  get tempTable() {
    return this._tempTable;
  }
  displayedColumns: string[] = ['action', 'tableName', 'name', 'displayName'];
  @ViewChild(RulesConditionComponent) rulesCondition!: RulesConditionComponent;
  joins: any[] = [
    { "code": '1', description: 'Inner' },
    { "code": '2', description: 'Left' },
    { "code": '3', description: 'Right' },
    { "code": '4', description: 'Full' }
  ] as LookupEntityBase[];
  public form: FormGroup = new FormGroup({
    joinTable: new FormControl('', [Validators.required]),
    toTable: new FormControl('', [Validators.required]),
    fromTable: new FormControl('', [Validators.required]),
    tableName: new FormControl('', [Validators.required])
  });
  public columnList: ColumnList[] = [];
  //public selectedColumn: ColumnSelection[] = [];
  constructor(tableService: TableService) {
    this.columnList = [];
    let tableData = tableService.getTableList();
    tableData.map(w => {
      this.tables.push(w);
      w.columns?.map(s => {
        this.columnList.push(s)
        return s
      })
      return w
    })

  }
  addColumn() {
    const bewClm: ColumnSelection = {
      name: "",
      tableName: "",
      displayName: "",
      id: this.dataSource.data?.length + 1,
      columnId: -1,
    }
    this._selectedColumn.push(bewClm);
    this.dataSource.data = this._selectedColumn;
  }
  onDeleteColumn(item: ColumnSelection) {
    const s = this.dataSource.data.filter(s => { return s.id != item.id });
    this.dataSource.data = s;
  }
  getJoinColumns() {
    var columns: ColumnSelection[] = [];
    if (this.fromTable) {
      this.fromTable?.columns?.map(w => {
        const bewClm: ColumnSelection = {
          name: w.name,
          tableName: this.fromTable?.name,
          displayName: w.name,
          id: columns?.length + 1,
          columnId: w.id,
        }
        columns.push(bewClm);
      })
    }
    if (this.toTable) {
      this.toTable?.columns?.map(w => {
        const bewClm: ColumnSelection = {
          name: w.name,
          tableName: this.toTable?.name,
          displayName: w.name,
          id: columns?.length + 1,
          columnId: w.id,
        }
        columns.push(bewClm);
      })
    }
    this._selectedColumn = columns;
    this.dataSource.data = columns;
  }
  onInputChange(event: any, item: ColumnSelection, column: string) {
    //

    switch (column) {
      case 'name': {
        item.name = event.currentTarget.value;
        break;
      }
      case 'tableName': {
        item.tableName = event.currentTarget.value;
        break;
      }
      case 'displayName': {
        item.displayName = event.currentTarget.value;
        break;
      }
      case 'columnId': {

        item.name = this.fromTable?.columns?.find(w => { return w.id == event })?.name ?? '';

        if (item.name == '') {
          item.name = this.toTable?.columns?.find(w => { return w.id == event })?.name ?? '';
        }
        item.displayName = item.name;
        item.columnId = event;
        break;
      }
      case 'fromTable': {
        this.getJoinColumns();
        break;
      }
      case 'toTable': {
        this.getJoinColumns();
        break;
      }
    }
  }

  getElementValue(item: ColumnSelection, column: string) {
    return item.columnId;
  }

}
