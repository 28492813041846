<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <div class="row align-items-start">
                 <div class="example-sidenav-content">
                        <div>

                            <div class="row">
                                <div class="form-group col-lg-2">
                                    <h3>
                                        Workqueues
                                    </h3>
                                    <h5>Home &gt; Workqueues</h5>
                                </div>
                                <div class="form-group col-lg-10">
                                    <div class="form-group float-end" fxLayout="row" fxLayoutGap=".5em">
                                        <button mat-mini-fab color="primary" style="float:right" (click)="addworkqueue()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <button mat-mini-fab color="primary" style="float:right" class="print-report " (click)="exportData()">
                                            <mat-icon>cloud_download</mat-icon>
                                        </button>
                                    </div>
                                    <div class="form-group col-lg-2 float-end">
                                        <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <app-workqueue-list [filtervalue]="filtervalue" [workqueueList]="workqueueList" (onEditWorkqueue)="onEditWorkqueue($event)" (onDelete)="onDelete($event)"></app-workqueue-list>
                    </div>
            </div>


            
        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
