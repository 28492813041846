<mat-card>
    <div class="p-20 bg-info position-relative">
        <h4 class="card-title text-white m-0">My Contact</h4>
        <h6 class="card-subtitle text-white m-0 op-5">Checkout my contacts here</h6>
     <!--    <button mat-mini-fab color="accent" class="add-contact">+</button> -->
    </div>
    <div class="message-box contact-box p-20" style="height: 300px;overflow: auto;">
        <div class="message-widget contact-widget">
            <mat-progress-bar *ngIf="_isLoading" mode="indeterminate"></mat-progress-bar>
            <!-- Message -->
            <a href="#" *ngFor='let contact of contactsData'>
                <div class="user-img">
                    <!-- <img [src]='contact.image' alt="user" class="img-circle"> -->
                    <app-avatar [username]="contact.name" [userInitialclass]="'large'"></app-avatar>
                    <span class="profile-status pull-right {{contact.class}}"></span>
                </div>
                <div class="mail-contnet">
                    <h5>{{contact.name}} </h5>
                    <span class="mail-desc">{{contact.email}}</span>
                </div>
            </a>
        </div>
    </div>
</mat-card>
