
<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content>
      <mat-card-title>
        Groups
        <button *ngIf="!showEdit && hasGroup_Add" mat-mini-fab color="primary" style="float:right" (click)="onAddGroup()">
          <mat-icon>add</mat-icon>
        </button>
        <div class="form-group col-lg-2" style="float:right" *ngIf="!showEdit">
          <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value] ="filtervalue">
        </div>
      </mat-card-title>
      <mat-card-subtitle> Home &gt; Groups</mat-card-subtitle>
      <mat-divider></mat-divider>
      <app-group-list *ngIf="!showEdit && hasGroup_View" [groupList]="groupList" [filtervalue]="filtervalue" (onEditGroup)="onEditGroup($event)" (onDelete)="doDelete($event)" (onShowDetails)="onShowDetails($event)"></app-group-list>
      <section class="edit-container" *ngIf="showEdit && (hasGroup_Add || hasGroup_Edit)">
        <app-group-edit [group]="group" [isLoading]="isLoading" (onCancelEdit)="onCancelEdit($event)" (onShowLoading)="onShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)" (doDelete)="doDelete($event)"></app-group-edit>
      </section>
    </mat-card-content>
  </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>
