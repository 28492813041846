<section class="edit-container-popup">
    <mat-card-title>
        <span>{{ actionHeaderTitle }}</span>
    </mat-card-title>
    <mat-divider></mat-divider>

    <form [formGroup]="formAdd" class="popup-form" [ngClass]="scheduleType == 4?'popup-form-payroll':''">

        <div fxLayout="column" fxLayoutGap="1em">
            <input type="text" matInput formControlName="eventId" class="form-control" hidden />
            <input type="text" matInput formControlName="scheduleInstanceId" class="form-control" hidden />
            <input type="text" matInput formControlName="createdById" class="form-control" hidden />
            <input type="text" matInput formControlName="scheduleNameHidden" class="form-control" hidden />

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <small class="form-text text-muted">Schedule Name: <span class="required-fields">*</span></small>
                    <div>
                        <input type="text" matInput formControlName="scheduleName" class="form-control" placeholder="first schedule">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <small class="form-text text-muted">Schedule Details:</small>
                    <div>
                        <input type="text" matInput formControlName="scheduleDetails" class="form-control" placeholder="ABC 123">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="scheduleType != 3">


                <div [ngClass]="scheduleType == 4?'col-lg-8 col-md-8 col-sm-12':'col-lg-12 col-md-12 col-sm-12'">
                    <small class="form-text text-muted">Schedule: <span class="required-fields">*</span></small>
                    <div class="row">
                        <div [class]="scheduleType != 3 ? 'col-lg-3 col-md-3 col-sm-3 mx-auto position-relative' : 'col-lg-5 col-md-5 col-sm-5 mx-auto position-relative'">
                            <input name="startdate"
                                   [type]="'text'"
                                   class="form-control"
                                   placeholder="mm/dd/yyyy"
                                   (click)="schedulestartdatepicker.open()"
                                   formControlName="scheduleStartDate"
                                   [matDatepicker]="schedulestartdatepicker"
                                   [min]="minStartDate"
                                   (blur)="onBlurDate($event)"
                                   (dateChange)="onDateChange($event)"
                                   [disabled]="scheduleType == 3">
                            <div class="position-absolute" style="right: 18px;top: 8px;">
                                <mat-icon matSuffix class="form-input-date-icon" (click)="schedulestartdatepicker.open()">calendar_today</mat-icon>
                            </div>
                            <mat-datepicker class="form-group-text" style="float:right;" #schedulestartdatepicker></mat-datepicker>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
                            <input type="time" class="form-control p-8" id="scheduleStartTime" formControlName="scheduleStartTime" />
                        </div>

                        <div class="col-1">
                            <p class="schedule-datepicker-text">TO</p>
                        </div>

                        <div [class]="scheduleType != 3 ? 'col-lg-3 col-md-3 col-sm-3 mx-auto position-relative' : 'col-lg-5 col-md-5 col-sm-5 mx-auto position-relative'">
                            <input name="enddate"
                                   [type]="'text'"
                                   class="form-control"
                                   placeholder="mm/dd/yyyy"
                                   (click)="scheduleenddatepicker.open()"
                                   formControlName="scheduleEndDate"
                                   [matDatepicker]="scheduleenddatepicker"
                                   [min]="minEndDate"
                                   (blur)="onBlurDate($event)"
                                   [disabled]="scheduleType == 3 || P_IsRunAlways">
                            <div class="position-absolute" style="right: 18px;top: 8px;">
                                <mat-icon matSuffix class="form-input-date-icon" (click)="scheduleenddatepicker.open()">calendar_today</mat-icon>
                            </div>
                            <mat-datepicker class="form-group-text" style="float:right;" #scheduleenddatepicker></mat-datepicker>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-3 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
                            <input type="time" class="form-control p-8" id="scheduleEndTime" formControlName="scheduleEndTime" />
                        </div>

                        <!--<div class="col-1">
                      <p class="schedule-datepicker-text">EST</p>
                    </div>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="scheduleType == 4" style="margin-bottom: 1em;">
                    <small class="form-text text-muted" style="display: flow-root; width: 100%;">
                        Options:
                        <span class="required-fields">*</span>
                        <mat-checkbox (change)="onRunAlways()" formControlName="runAlways" [checked]="this.formAdd.controls.runAlways.value" style="float: right;">
                            Always
                        </mat-checkbox>
                    </small>
                    <select name="otherOptions"
                            id="otherOptions"
                            class="form-control"
                            formControlName="otherOptions">
                        <option *ngFor="let option of googleCalendarOptions" [value]="option.id" [selected]="selectedGoogleCalendarOption == option.id">{{ option.label }}</option>
                    </select>
                </div>

            </div>
            <div *ngIf="scheduleType == 1">
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 1em;">
                        <small class="form-text text-muted" style="display: flow-root; width: 100%;">
                            Options:
                            <span class="required-fields">*</span>
                            <mat-checkbox (change)="onRunAlways()" formControlName="runAlways" [checked]="this.formAdd.controls.runAlways.value" style="float: right;">
                                Always
                            </mat-checkbox>
                        </small>
                        <select name="otherOptions"
                                id="otherOptions"
                                class="form-control"
                                formControlName="otherOptions">
                            <option *ngFor="let option of googleCalendarOptions" [value]="option.id" [selected]="selectedGoogleCalendarOption == option.id">{{ option.label }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 1em;">
                        <small class="form-text text-muted d-block">Servers: <span class="required-fields">*</span></small>
                        <select name="serversOptions"
                                id="serversOptions"
                                class="form-control"
                                formControlName="serversOptions">
                            <option *ngFor="let option of listOfServers" [value]="option.id" [selected]="serversOptions == option.id">{{ option.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <small class="form-text text-muted d-block">Automation: <span class="required-fields">*</span></small>
                        <select name="automationOptions"
                                id="automationOptions"
                                class="form-control"
                                formControlName="automationOptions"
                                (change)="onAutomationChange()">
                            <option *ngFor="let option of listOfAutomation" [value]="option.id">{{ option.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <small class="form-text text-muted">Deployment Location: <span class="required-fields">*</span> (folder should be empty)</small>
                        <div>
                            <input type="text" matInput formControlName="deploymentLocation" class="form-control" placeholder="Enter folder name you want to create.">
                        </div>
                    </div>

                </div>

                <!--<div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <small id="scheduleDescription" class="form-text text-muted">Schedule Description:</small>
                <div>
                  <input type="text" matInput formControlName="scheduleDescription" class="form-control" placeholder="ABC 123">
                </div>
              </div>
              <mat-datepicker class="form-group-text" style="float:right;" #schedulestartdatepicker></mat-datepicker>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-2 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
              <input type="time" class="form-control p-8" id="scheduleStartTime" formControlName="scheduleStartTime" />
            </div>

            <div class="col-1">
              <p class="schedule-datepicker-text">TO</p>
            </div>

            <div [class]="scheduleType != 3 ? 'col-lg-3 col-md-3 col-sm-3 mx-auto position-relative' : 'col-lg-5 col-md-5 col-sm-5 mx-auto position-relative'">
              <input name="enddate"
                     [type]="'text'"
                     class="form-control"
                     placeholder="mm/dd/yyyy"
                     (click)="scheduleenddatepicker.open()"
                     formControlName="scheduleEndDate"
                     [matDatepicker]="scheduleenddatepicker"
                     [min]="minEndDate"
                     (blur)="onBlurDate($event)"
                     [disabled]="scheduleType == 3">
              <div class="position-absolute" style="right: 18px;top: 8px;">
                <mat-icon matSuffix class="form-input-date-icon" (click)="scheduleenddatepicker.open()">calendar_today</mat-icon>
              </div>
              <mat-datepicker class="form-group-text" style="float:right;" #scheduleenddatepicker></mat-datepicker>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
              <input type="time" class="form-control p-8" id="scheduleEndTime" formControlName="scheduleEndTime" />
            </div>

            <!--<div class="col-1">
              <p class="schedule-datepicker-text">EST</p>
            </div>-->

                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <small class="form-text text-muted d-block">Credentials:</small>
                        <textarea cols="3" rows="5" matInput formControlName="credentialsValues" class="form-control report-filters" placeholder="place your credentials here"></textarea>

                        <!--<table class="width-100" mat-table [dataSource]="credentialsData">-->
                        <!-- Position Column -->
                        <!--<ng-container matColumnDef="url">
                        <th mat-header-cell *matHeaderCellDef> URL </th>
                        <td mat-cell *matCellDef="let element" class="">
                          <input type="text" matInput [value]="element.URL" class="form-control w-100" disabled #urlInput />
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef> User Name </th>
                        <td mat-cell *matCellDef="let element" class="">
                          <input type="text" matInput [value]="element.UserName" class="form-control w-100" #usernameInput />
                        </td>

                      </ng-container>
                      <ng-container matColumnDef="password">
                        <th mat-header-cell *matHeaderCellDef> Password </th>
                        <td mat-cell *matCellDef="let element" class="">
                          <input type="password" matInput [value]="element.PassWord" class="form-control w-100" #passwordInput />
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedCredentialsDataColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedCredentialsDataColumns;" #credentialsRow></tr>

                    </table>-->
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <small class="form-text text-muted d-block">Configuration:</small>
                        <textarea cols="3" rows="5" matInput formControlName="configurationValues" class="form-control report-filters" placeholder="place your configuration here"></textarea>
                        <!--<table class="width-100" mat-table [dataSource]="configurationData">-->
                        <!-- Position Column -->
                        <!--<ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef> Key </th>
                        <td mat-cell *matCellDef="let element" class="">
                          <input type="text" matInput [value]="element.key" class="form-control w-100" #keyInput />
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef> Value </th>
                        <td mat-cell *matCellDef="let element" class="">
                          <div *ngIf="element.key=='Save Screenshots'">
                            <select name="screenShotsOptions"
                                    id="screenShotsOptions"
                                    class="form-control mr-20"
                                    formControlName="screenShotsOptions"
                                    [class.cursor-not-allowed]="!formAdd.controls.saveScreenShots">
                              <option *ngFor="let option of screenShotOptions" [value]="option.id" [selected]="screenShotsOptions == option.id" [disabled]="!formAdd.controls.saveScreenShots">{{ option.label }}</option>
                            </select>
                          </div>

                          <div *ngIf="element.key != 'Save Screenshots'">
                            <input type="text" matInput [value]="element.value" class="form-control w-100" #valueInput />
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedConfigurationDataColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedConfigurationDataColumns;" #configurationRow></tr>

                    </table>-->
                    </div>

                </div>

            </div>
            <div *ngIf="scheduleType == 2">
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                        <mat-checkbox class="attach-reports" formControlName="attachReportsInSingleEmail" [checked]="this.formAdd.controls.attachReportsInSingleEmail.value">
                            Attach in a single email.
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted" style="display:flow-root; width:100%;">
                            Options:
                            <span class="required-fields">*</span>
                            <mat-checkbox (change)="onRunAlways()" formControlName="runAlways" [checked]="this.formAdd.controls.runAlways.value" style="float: right;">
                                Always
                            </mat-checkbox>
                        </small>
                        <select name="otherOptions"
                                id="otherOptions"
                                class="form-control"
                                formControlName="otherOptions">
                            <option *ngFor="let option of googleCalendarOptions" [value]="option.id" [selected]="selectedGoogleCalendarOption == option.id">{{ option.label }}</option>
                        </select>
                    </div>
                </div>

                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-6 col-md-6 col-sm-6" style="margin-bottom: 1em;">
                        <small class="form-text text-muted d-block">Email Profile: <span class="required-fields">*</span></small>

                        <select name="emailProfile"
                                id="emailProfile"
                                class="form-control"
                                formControlName="emailProfile">
                            <option *ngFor="let eProfile of EmailProfiles" [value]="eProfile.emailtypeid" [selected]="selectedEmailProfile == eProfile.emailtypeid">{{ eProfile.smtpusername }}</option>
                        </select>

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6" style="margin-bottom: 1em;">
                        <small class="form-text text-muted">Mail To: <span class="required-fields">*</span></small>
                        <div>
                            <input type="text" matInput formControlName="mailTo" class="form-control" placeholder="john@gmail.com,jane@outlook.com">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted">Mail CC:</small>
                        <div>
                            <input type="text" matInput formControlName="mailCC" class="form-control" placeholder="john@gmail.com,jane@outlook.com">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted">Mail BCC:</small>
                        <div>
                            <input type="text" matInput formControlName="mailBCC" class="form-control" placeholder="john@gmail.com,jane@outlook.com">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 1em; margin-top: 1em;">
                        <small class="form-text text-muted">Email Subject: <span class="required-fields">*</span></small>
                        <div>
                            <input type="text" matInput formControlName="emailSubject" class="form-control" placeholder="Regarding something">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <small class="form-text text-muted">Email Body: <span class="required-fields">*</span></small>
                        <div>
                            <!--<textarea cols="3" rows="3" matInput formControlName="emailBody" class="form-control" placeholder="Please enter something...">
                        </textarea>-->

                            <app-text-editor [inputString]="this.formAdd.controls.emailBody.value"></app-text-editor>

                            <!--<quill-editor formControlName="emailBody"></quill-editor>-->
                        </div>
                    </div>
                </div>

                <div class="row report-row" style="margin-bottom: 1em;" *ngFor="let element of reportsMultipleRows" #reportsRow>
                    <div class="col-lg-1 col-md-1 col-sm-1 d-flex align-items-center justify-content-center" *ngIf="element.btnVisible">
                        <button mat-flat-button extended color="primary" class="btn" (click)="addNewRow()" style=" position: relative; top: 13px;">+</button>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 report-div">
                        <small class="form-text text-muted d-block">Reports: <span class="required-fields">*</span></small>
                        <select class="form-control report-name" (change)="setFilterValue(element)">
                            <option *ngFor="let report of Reports" [value]="report.menuid" [selected]="element.selectedReport == report.menuid">{{ report.menuname }}</option>
                        </select>
                    </div>
                    <div [class]="element.btnVisible ? 'col-lg-3 col-md-3 col-sm-3 report-div' : 'col-lg-4 col-md-4 col-sm-4 report-div'">
                        <small class="form-text text-muted d-block">Report Type: <span class="required-fields">*</span></small>
                        <select class="form-control report-type">
                            <option *ngFor="let reportType of ReportTypes" [value]="reportType.id" [selected]="element.selectedReportType == reportType.id">{{ reportType.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center report-div">
                        <mat-checkbox class="password-protection" [checked]="element.sendAsPasswordProtected">
                            Send as password protected.
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 report-div">
                        <small class="form-text text-muted d-block">Filters: <span class="required-fields">*</span></small>
                        <textarea *ngIf="false" cols="3" rows="5" [value]="element.filters" matInput class="form-control report-filters" placeholder="place your filters"></textarea>

                        <div class="filter-pane" style="width: 96%;">
                            <app-report-filter [lookupData]="lookupData" (onFilter)="doFilter($event)" [filters]="element.filters" [filterData]="filterData" [reportid]="element.reportId" [isFromInfo]="true"></app-report-filter>
                        </div>

                    </div>
                </div>

            </div>

            <div *ngIf="scheduleType == 3">
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted d-block">Practice Management Systems: <span class="required-fields">*</span></small>

                        <select name="practiceManagement"
                                id="practiceManagement"
                                class="form-control"
                                formControlName="practiceManagement"
                                (change)="changePracticeValues()">
                            <option *ngFor="let element of pmLookup" [value]="element.code" [selected]="selectedPracticeManagement == element.code">{{ element.description }}</option>
                        </select>

                        <!--<app-multi-select [dropdownList]="pmLookup" [selectedItems]="selectedPracticeManagement" [placeholder]="'PM System'" #clients></app-multi-select>-->

                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted d-block">Practices: <span class="required-fields">*</span></small>

                        <!--<select name="practice"
                      id="practice"
                      class="form-control"
                      formControlName="practice">
                      <option *ngFor="let practice of Practices" [value]="practice.id" [selected]="selectedPractice == practice.id">{{ practice.name }}</option>
                    </select>-->

                        <app-multi-select (onSelectionChange)="onSelectionChange($event)"
                                          [dropdownList]="clientLookup"
                                          [selectedItems]="selectedPractice"
                                          [placeholder]="'Client'"
                                          #clients>
                        </app-multi-select>

                    </div>

                    <!--<div class="col-lg-6 col-md-6 col-sm-6">
                  <small class="form-text text-muted d-block">Warehouse: <span class="required-fields">*</span></small>

                  <select name="wareHouse"
                          id="wareHouse"
                          class="form-control"
                          formControlName="wareHouse">
                    <option *ngFor="let warehouse of DataWareHouses" [value]="warehouse.id" [selected]="selectedWareHouse == warehouse.id">{{ warehouse.name }}</option>
                  </select>
                </div>-->
                </div>
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <small class="form-text text-muted d-block">Date Types: <span class="required-fields">*</span></small>

                        <select name="dateType"
                                id="dateType"
                                class="form-control"
                                formControlName="dateType"
                                (change)="onDateTypeChange()">
                            <option *ngFor="let datetype of DateTypes" [value]="datetype.id" [selected]="selectedDateType == datetype.id">{{ datetype.name }}</option>
                        </select>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <small class="form-text text-muted">Start:</small>
                        <input name="dateTypeStartDate"
                               [type]="'text'"
                               class="form-control"
                               placeholder="mm/dd/yyyy"
                               (click)="datetypestartdatepicker.open()"
                               formControlName="dateTypeStartDate"
                               [matDatepicker]="datetypestartdatepicker"
                               [max]="minStartDate"
                               (dateChange)="onDateTypeSTRDTChange()">
                        <div class="position-absolute" style="right: 18px;top: 8px;">
                            <mat-icon matSuffix class="form-input-date-icon" (click)="datetypestartdatepicker.open()">calendar_today</mat-icon>
                        </div>
                        <mat-datepicker class="form-group-text" style="float:right;" #datetypestartdatepicker></mat-datepicker>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <small class="form-text text-muted">End:</small>
                        <input name="dateTypeEndDate"
                               [type]="'text'"
                               class="form-control"
                               placeholder="mm/dd/yyyy"
                               (click)="datetypeenddatepicker.open()"
                               formControlName="dateTypeEndDate"
                               [matDatepicker]="datetypeenddatepicker"
                               [min]="minDateTypeEndDate"
                               [max]="minStartDate">
                        <div class="position-absolute" style="right: 18px;top: 8px;">
                            <mat-icon matSuffix class="form-input-date-icon" (click)="datetypeenddatepicker.open()">calendar_today</mat-icon>
                        </div>
                        <mat-datepicker class="form-group-text" style="float:right;" #datetypeenddatepicker></mat-datepicker>
                    </div>

                </div>
            </div>
            <div *ngIf="scheduleType == 4" style="margin-bottom: 1em;height: 55vh;overflow: hidden;">

                <app-payroll-details #payrolldetails [lookupData]="lookupData" [payroll]="payroll"></app-payroll-details>
            </div>

        </div>


    </form>

    <mat-divider></mat-divider>
    <div class="row" style="margin: 5px;">
        <div class="col-lg-6 col-md-6 col-sm-6">
            <mat-checkbox [formControl]="scheduleStatus" [checked]="scheduleStatus.value">
                Status
            </mat-checkbox>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6" style="text-align:right;">
            <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting || !canSave()" (click)="onSaveSchedule()"><span>{{ actionButtonTitle }}</span></button>
            <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button>
            <button mat-flat-button extended class="btn" (click)="doCancelEdit(false)">Cancel</button>
        </div>
    </div>
</section>

<app-data-loader [isLoading]="isLoading"></app-data-loader>
