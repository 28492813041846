import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
//import { CalendarOptions } from '@fullcalendar/angular'; // Import CalendarOptions from @fullcalendar/angular
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, DatesSetArg } from '@fullcalendar/core'; // Import the types from @fullcalendar/core
import { BotDashboardService } from '../bot-dashboard.service';
import { AutomationSummary } from '../bot-dashboard';
import { MatMenuTrigger } from '@angular/material/menu';
import { EventInput } from '@fullcalendar/core';
import { MatDialog } from '@angular/material/dialog';
import { SchedulingActionComponent } from '../scheduling-action/scheduling-action.component';
import { UserService } from '../../../components/user/user.service';
import { getConverted12HourTime, GetFormattedDate } from '../../../Utility/common-functions';
import { ClientService } from '../../../services/client.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { AdhocrunActionComponent } from '../adhocrun-action/adhocrun-action.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.css']
})
export class SummaryListComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private botDashboardService: BotDashboardService,
    private clientService: ClientService,
    private userService: UserService) {
    //this.loadDetails();
  }

  public data: any[] = [];
  public distictServers: any;
  public distictInstances: any;
  public distictSteps: any;
  public distinctMap: any;
  public distinctFilterInstances: any;
  public distinctFilterSteps: any;

  // get summary data in child
  public _scheduleType: number = 0;
  public _summaryList: AutomationSummary[] = [];
  calenderHeight!: number;
  lastviewName!: string;
  calendarOptions!: CalendarOptions;
  contextMenuVisible: boolean = false;
  contextMenuPosition: { [key: string]: string } = {};
  selectedEvent: any;
  summaryInputJSON: object = {};
  serverIps: string = "";
  input1: boolean = false;
  input2: boolean = false;
  input3: boolean = false;
  input4: boolean = false;
  P_ScheduleAddonText = "_ARCalytics";

  @Input() set scheduleType(scheduleType: number) {
    this._scheduleType = scheduleType;
    this.input1 = true;

    this.summaryInputJSON = {
      schedule_instance_id: "-1",
      eventid_google: "-1",
      scheduletypeid: this._scheduleType,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: "01/01/1900",
      todate: "01/01/1900"
    }
    this.setCalendarOption();
  }
  @Input() set summaryList(summaryList: AutomationSummary[]) {
    this._summaryList = summaryList;
    this.input2 = true;
    this.setCalendarOption();
  }
  @Input() set automationStepsList(automationStepsList: AutomationSummary[]) {
    this.automationsteps = automationStepsList;
    this.input3 = true;
    this.setCalendarOption();
  }

  @Output() getAutomationDetails = new EventEmitter<any>(); // sent json for details
  @Output() getAutomationSummary = new EventEmitter<any>();

  counter: number = 1;
  background_color: string = "#2e8bc0";
  colorList: string[] = [
    "#9F2B68",
    "#BF40BF",
    "#800020",
    "#702963",
    "#AA336A",
    "#301934",
    "#483248",
    "#5D3FD3",
    "#915F6D",
    "#770737",
    "#DA70D6",
    "#A95C68",
    "#800080",
    "#51414F",
    "#953553",
    "#630330",
    "#7F00FF",
    "#722F37"
  ];
  timeList: string[] = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
    "12 AM"
  ];
  widthPercentage: number = 100;
  marginPercentage: number = 0;
  time12Hour: string = "";
  timeLiesIndex: number = 0;
  dialogueWidth: string = '920px';
  isLoading: boolean = true;
  lookups!: any;
  automationsteps!: any;

  ngOnInit() {
    this.bindCalendarData();
  }

  async bindCalendarData() {
    let menuRequestJson = [{ // get reports menu
      menuids: -1,
      loginuserid: -1,
      portalid: 1
    }];
    const reportFilterRequestJson = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }];
    
    this.lookups = await Promise.all([
      this.clientService.getPracticeManagementLookup([]),
      this.clientService.getlookup([]),
      this.botDashboardService.getServers([{id: "-1"}]),
      this.botDashboardService.getAutomation([{ id: "-1" }]),
      this.botDashboardService.getEmailProfile([{ id: "-1" }]),
      this.userService.getMenus(menuRequestJson),
      this.clientService.getreportfilter(reportFilterRequestJson)
    ]);

    this.isLoading = false;
    //this.bindCurrentMonthData("", "");

    //this.summaryList = data;
    //this._summaryList = data;
    //this.distictServers = this.getDistinct(this._summaryList, "servername").distinctElements;
    //this.distictInstances = this.getDistinct(this._summaryList, "processid").distinctElements;

    //for (let i = 0; i < this.distictServers.length; i++) {
    //  this.data.push({
    //    "id": this.distictServers[i]["uniqueserverid"],
    //    "title": this.distictServers[i]["servername"],
    //    "start": this.distictServers[i]["starttime"],
    //    "end": this.distictServers[i]["endtime"],
    //    "description": this.distictServers[i]["serverusername"],
    //    "isactive": 1,
    //    //"created_by": 87,
    //    "display": "block",
    //    "assignee_name": "",
    //    "createdby_name": this.distictServers[i]["createdby"],
    //    //"eventsummary": "Weekly on Monday, Friday,6 times",
    //    //"is_createdby": 1,
    //    "background_color": "#2e8bc0",
    //    "border_color": "#2e8bc0",
    //    "event_priority_id": 1,
    //    "is_server": 1
    //    //"slotDuration": "00:30:00"
    //    //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //  });

    //  this.distinctFilterInstances = this.distictInstances.filter((obj: any) => {
    //    return (obj.uniqueserverid == this.distictServers[i]["uniqueserverid"])
    //  });

    //  for (let i = 0; i < this.distinctFilterInstances.length; i++) {
    //    this.data.push({

    //      "id": this.distinctFilterInstances[i]["processid"],
    //      "title": this.distinctFilterInstances[i]["automationname"],
    //      "start": this.distinctFilterInstances[i]["starttime"],
    //      "end": this.distinctFilterInstances[i]["endtime"],
    //      "description": this.distinctFilterInstances[i]["serverusername"],
    //      "isactive": 1,
    //      //"created_by": 87,
    //      "display": "block",
    //      "assignee_name": "",
    //      "createdby_name": this.distinctFilterInstances[i]["createdby"],
    //      //"eventsummary": "Weekly on Monday, Friday,6 times",
    //      //"is_createdby": 1,
    //      "background_color": "#2e8bc0",
    //      "border_color": "#2e8bc0",
    //      "event_priority_id": 2,
    //      "is_server": 0
    //      //"slotDuration": "00:30:00"
    //      //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //    });

    //    this.distinctFilterSteps = this._summaryList.filter((obj: any) => {
    //      return (obj.processid == this.distinctFilterInstances[i]["processid"])
    //    });

    //    for (let i = 0; i < this.distinctFilterSteps.length; i++) {
    //      this.data.push({
    //        "id": this.distinctFilterSteps[i]["automationstepid"],
    //        "title": this.distinctFilterSteps[i]["automationstep"],
    //        "start": this.distinctFilterSteps[i]["automationstepstarttime"],
    //        "end": this.distinctFilterSteps[i]["automationstependtime"],
    //        "description": this.distinctFilterSteps[i]["serverusername"],
    //        "isactive": 1,
    //        //"created_by": 87,
    //        "display": "block",
    //        "assignee_name": "",
    //        "createdby_name": this.distinctFilterSteps[i]["createdby"],
    //        //"eventsummary": "Weekly on Monday, Friday,6 times",
    //        //"is_createdby": 1,
    //        "background_color": "#2e8bc0",
    //        "border_color": "#2e8bc0",
    //        "event_priority_id": 3,
    //        "is_server": 0
    //        //"slotDuration": "00:30:00"
    //        //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //      })
    //    }

    //  }
    //}
  }

  randomNumber() {
    let num: number = Math.random() * (this.colorList.length - 1) + 1;
    return parseInt(num.toString());
  }

  getDistinct(arr: any = [], field: any) {
    this.distinctMap = {};

    arr.forEach((item: any) => {
      if (!this.distinctMap[item[field]]) {
        this.distinctMap[item[field]] = item;
      }
    });
    const distinctFields = Object.keys(this.distinctMap);
    const distinctElements = Object.values(this.distinctMap);

    return { distinctFields, distinctElements }
  }

  handleDateClick(arg: DateSelectArg) {
    //alert('Date clicked: ' + arg.dateStr);
  }

  async onAddInstance() {
    let dlgRef = this.dialog.open(SchedulingActionComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          scheduleType: this._scheduleType,
          lookups: this.lookups
        },
        panelClass: 'confirm-dialog-container',
        width: this.dialogueWidth
      });

    var Action_result = await dlgRef.afterClosed().toPromise();
    if (Action_result) {
      this.getAutomationSummary.emit(this.summaryInputJSON);
    }
  }

  async onEditInstance(arg: EventClickArg) {
    var P_data = this._summaryList.filter((obj) => {
      return (arg.event.id == obj.id.toString())
    });

    if (P_data.length != 0 &&
      arg.event.extendedProps.is_server != 1) {
      const dlgRef = this.dialog.open(SchedulingActionComponent,
        {
          disableClose: true,
          autoFocus: false,
          data: {
            dataToRebind: P_data,
            lookups: this.lookups
          },
          panelClass: 'confirm-dialog-container',
          width: this.dialogueWidth,
          /*height: '500px',*/
        });

      var Action_result = await dlgRef.afterClosed().toPromise();
      if (Action_result) {
        this.getAutomationSummary.emit(this.summaryInputJSON);
      }
    }
  }

  onCancelEdit(item: any) {
    //this.dialog.close(item);
  }

  eventDidMount(info: { el: HTMLElement; event: EventApi }) {
    const eventEl = info.el;
    const event = info.event;
    // Attach context menu listener
    info.el.addEventListener('contextmenu', (e: MouseEvent) => {
      e.preventDefault(); // Prevent the default context menu

      // Position your custom context menu
      this.showContextMenu(e, info.event.extendedProps);
    });
  }

  renderEventContent(info: { el: HTMLElement; event: EventApi }) {
    try {
      let headerDiv = "";
      headerDiv += `<div class="header-calendar-div position-relative" style="display: none;">`;
      headerDiv += `<div class="first-cell position-absolute left-0">${this.timeList[0]}</div>`;
      for (let i = 1; i < this.timeList.length; i++) {
        headerDiv += `<div class="other-cell float-left"><span>${this.timeList[i]}</span></div>`;
      }
      headerDiv += `</div>`;

      let P_separator = ".";
      if (info.event.extendedProps.start_string.indexOf("+") != -1) {
        P_separator = "+";
      }
      let startTime = info.event.extendedProps.start_string.split('T')[1].split(P_separator)[0];

      P_separator = ".";
      if (info.event.extendedProps.end_string.indexOf("+") != -1) {
        P_separator = "+";
      }
      let endTime = info.event.extendedProps.end_string.split('T')[1].split(P_separator)[0];
      this.time12Hour = getConverted12HourTime(startTime.split(":")[0], startTime.split(":")[1]);
      this.timeLiesIndex = this.getTimeLiesIndex(this.time12Hour);
      this.marginPercentage = this.getMarginPercentage(this.timeLiesIndex, this.time12Hour.split(" ")[1]);

      startTime = startTime.split(":")[0] + ":" + startTime.split(":")[1];
      endTime = endTime.split(":")[0] + ":" + endTime.split(":")[1];

      this.widthPercentage = this.getTimeDifference(startTime, endTime);
      this.widthPercentage = this.getWidthPercentage(this.widthPercentage);

      if (info.event.extendedProps.is_server != null &&
        info.event.extendedProps.is_server != undefined &&
        info.event.extendedProps.is_server == 1) {
        this.background_color = this.colorList[this.randomNumber()];
        this.widthPercentage = 100;
        this.marginPercentage = 0;
      }

      let P_schedule_details = "";
      if (info.event.extendedProps.schedule_details != "") {
        P_schedule_details = `(${info.event.extendedProps.schedule_details})`;
      }

      let priorityLabel = "";
      let P_customWidthClass = "";
      if (this.widthPercentage < 20) {
        P_customWidthClass = "low-width";
      }

      // Commenting Priority
      //if (info.event.extendedProps.event_priority_id == 1) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#34A853;" title="Low"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      //} else if (info.event.extendedProps.event_priority_id == 2) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style ="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#E1AD01;" title="Medium"></span>`/*'<i class="fa fa-circle" style="color:#E1AD01;"></i>'*/;
      //} else if (info.event.extendedProps.event_priority_id == 3) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#B3261E;" title="High"></span>`/*'<i class="fa fa-circle" style="color:#800000;"></i>'*/;
      //}

      // Showing Status - as of now showing only for automation
      if (info.event.extendedProps.schedule_type_id == 1) {
        if (info.event.extendedProps.event_status_id == 0) {
          priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:grey;" title="Not Started"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
        } else if (info.event.extendedProps.event_status_id == 1) {
          priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:blue;" title="In Progress"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
        } else if (info.event.extendedProps.event_status_id == 2) {
          priorityLabel = `<div class="main-div ${P_customWidthClass}" style ="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:green;" title="Succeed"></span>`/*'<i class="fa fa-circle" style="color:#E1AD01;"></i>'*/;
        } else if (info.event.extendedProps.event_status_id == 3) {
          priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:red;" title="Failed"></span>`/*'<i class="fa fa-circle" style="color:#800000;"></i>'*/;
        }
      } else {
        priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:blue;" title="In Progress"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      }
      

      //var customHtml = headerDiv + priorityLabel + '&nbsp;' + startTime + "-" + endTime + '&nbsp; <span class="strip-description" title="' + info.event.title + '">' + info.event.title + '</span></div>';
      var customHtml = `${headerDiv} ${priorityLabel} <span class="strip-description" title="${info.event.title} ${P_schedule_details}">${info.event.title} <small>${P_schedule_details}</small></span></div>`;
      return { html: customHtml };
    } catch (ex) {
    }
  }

  getTimeDifference(startTime: string, endTime: string) {
    let startHour: any = startTime.split(":")[0];
    let endHour: any = endTime.split(":")[0];
    let startMinute: any = startTime.split(":")[1];
    let endMinute: any = endTime.split(":")[1];

    startHour = Number(startHour);
    endHour = Number(endHour);
    startMinute = Number(startMinute);
    endMinute = Number(endMinute);

    if (startHour > 12 && endHour > 12) {
      startHour = startHour - 12;
      endHour = endHour - 12;
    }

    let totalStartMinutes = (startHour * 60) + startMinute;
    let totalEndMinutes = (endHour * 60) + endMinute;
    let totalMinuteDifference = Math.abs(totalStartMinutes - totalEndMinutes);

    let totalDifference = totalMinuteDifference/60;
    return totalDifference;
  }

  getWidthPercentage(widthPercentage: number) {
    let width = (widthPercentage * 100) / 24;
    return width;
  }

  getTimeLiesIndex(time12Hour: string) {
    let timeSplit = time12Hour.split(" ");
    for (let i = 0; i < this.timeList.length; i++) {
      if (this.timeList[i] == (timeSplit[0] + " " + timeSplit[2])) {
        return i;
      }
    }
    return 0;
  }

  getMarginPercentage(timeLiesIndex: number, minutes: string) {
    let P_margin = 0;

    if (timeLiesIndex == 0) {
      P_margin = 0;
    } else {
      P_margin = timeLiesIndex * 4.166 + ((Number(minutes) * 4.166) / 60);
    }
    return P_margin;
  }

  setCalendarOption() {
    if (!this.input1 || !this.input2 || !this.input3) {
      return;
    }

    this.data = [];
    //this.calendarOptions = {};
    this.serverIps = "";
    if (this._scheduleType == 1) {
      for (let i = 0; i < this._summaryList.length; i++) {

        let P_serverIp = JSON.parse(this._summaryList[i].description)[0].ServerIP;
        let P_StartDate = this._summaryList[i]["start"].split('T')[0];
        if (this.serverIps.indexOf(P_serverIp + P_StartDate) == -1) {

          this.serverIps += P_serverIp + P_StartDate + ",";

          let P_instances = this.getInstanceDetails(this._summaryList, this._summaryList[i], P_serverIp);
          this.data.push({
            "id": this._summaryList[i]["id"],
            "title": P_instances[0].servername + "(" + P_instances[0].serverip + ")",
            "start": this._summaryList[i]["start"],
            "end": this._summaryList[i]["new_end"],
            "new_end": this._summaryList[i]["end"],
            "description": "",
            "isactive": 1,
            "display": "block",
            "assignee_name": "",
            "createdby_name": this._summaryList[i]["createdby_name"],
            "background_color": "#2e8bc0",
            "border_color": "#2e8bc0",
            "event_priority_id": 2,
            "event_status_id": 2,
            "is_server": 1,
            "start_string": this._summaryList[i]["start"],
            "end_string": this._summaryList[i]["end"],
            "event_id": this._summaryList[i]["event_id"],
            "schedule_instance_id": this._summaryList[i]["id"],
            "schedule_details": "",
            "created_by_id": this._summaryList[i]["created_by_id"],
            "schedule_type_id": this._summaryList[i]["schedule_type_id"],
            "email_profile_id": this._summaryList[i]["email_profile_id"],
          });

          for (let i = 0; i < P_instances[0].records.length; i++) {
            let P_starttime = "";
            let P_endtime = "";
            let P_AutomationDuration = 0;
            let P_schedulelogdetails: any = "";
            let P_AutomationStatus = 0;
            if (P_instances[0].automationsteps.length > 0) {
              P_schedulelogdetails = JSON.parse(P_instances[0].automationsteps[0].schedulelogdetails);
              P_AutomationStatus = Number(P_schedulelogdetails[0].AutomationStatus);
              P_AutomationDuration = Number(P_schedulelogdetails[0].AutomationDuration);
              if (P_AutomationDuration == 0) { // in case of abnormal termination of bot there will be chances that duration comes 0, in that case we will merge all steps duration
                for (let k = 0; k < P_schedulelogdetails[0].InstanceDetails.length; k++) {
                  P_AutomationDuration += Number(P_schedulelogdetails[0].InstanceDetails[k].StepDuration);
                }
              }
            }
            
            P_starttime = P_instances[0].records[i]["start"];
            P_endtime = this.calculateStepEndTime(P_starttime, P_AutomationDuration);

            let P_title = P_instances[0].records[i]["title"];
            if (P_title.indexOf(this.P_ScheduleAddonText) > -1) {
              P_title = P_title.split(this.P_ScheduleAddonText)[0];
            }
            this.data.push({
              "id": P_instances[0].records[i]["id"],
              "title": P_title,
              "start": P_starttime,
              "end": P_endtime,
              "new_end": P_instances[0].records[i]["end"],
              "description": P_instances[0].records[i]["description"],
              "isactive": 1,
              "display": "block",
              "assignee_name": "",
              "createdby_name": P_instances[0].records[i]["createdby_name"],
              "background_color": "#2e8bc0",
              "border_color": "#2e8bc0",
              "event_priority_id": 2,
              "event_status_id": P_AutomationStatus,
              "is_server": 0,
              "start_string": P_instances[0].records[i]["start"],
              "end_string": P_instances[0].records[i]["end"],
              "event_id": P_instances[0].records[i]["event_id"],
              "schedule_instance_id": P_instances[0].records[i]["id"],
              "schedule_details": P_instances[0].records[i]["schedule_details"],
              "created_by_id": P_instances[0].records[i]["created_by_id"],
              "schedule_type_id": P_instances[0].records[i]["schedule_type_id"],
              "email_profile_id": P_instances[0].records[i]["email_profile_id"],
            });

            // TODO:
            if (P_instances[0].automationsteps.length > 0 &&
              P_schedulelogdetails[0].InstanceDetails.length > 0) {
              for (let j = 0; j < P_schedulelogdetails[0].InstanceDetails.length; j++) {
                let P_StepDuration = Number(P_schedulelogdetails[0].InstanceDetails[j].StepDuration);
                if (P_starttime == "") {
                  P_starttime = P_instances[0].records[i]["start"];
                  P_endtime = this.calculateStepEndTime(P_starttime, P_StepDuration);
                } else {
                  P_starttime = P_endtime;
                  P_endtime = this.calculateStepEndTime(P_endtime, P_StepDuration);
                }

                this.data.push({
                  "id": P_schedulelogdetails[0].InstanceDetails[j].StepSerial,
                  "title": P_schedulelogdetails[0].InstanceDetails[j].StepName,
                  "start": P_starttime,
                  "end": P_endtime,
                  "new_end": "",
                  "description": P_schedulelogdetails[0].InstanceDetails[j].StepDescription,
                  "isactive": 1,
                  "display": "block",
                  "assignee_name": "",
                  "createdby_name": "",
                  "background_color": "#2e8bc0",
                  "border_color": "#2e8bc0",
                  "event_priority_id": 2,
                  "event_status_id": P_schedulelogdetails[0].InstanceDetails[j].StepStatus,
                  "is_server": 2,
                  "start_string": P_starttime,
                  "end_string": P_endtime,
                  "event_id": this._summaryList[i]["event_id"],
                  "schedule_instance_id": this._summaryList[i]["id"],
                  "schedule_details": P_schedulelogdetails[0].InstanceDetails[j].StepDescription,
                  "created_by_id": this._summaryList[i]["created_by_id"],
                  "schedule_type_id": this._summaryList[i]["schedule_type_id"],
                  "email_profile_id": this._summaryList[i]["email_profile_id"],
                });
              }
            }
          }
        }

      }
    } else {
      for (let i = 0; i < this._summaryList.length; i++) {

        let P_StartTime: any = this._summaryList[i]["start"];
        let P_NewEnd: any = this._summaryList[i]["new_end"];
        let P_EndTime: any = this._summaryList[i]["end"];
        let P_title = this._summaryList[i]["title"];
        if (P_title.indexOf(this.P_ScheduleAddonText) > -1) {
          P_title = P_title.split(this.P_ScheduleAddonText)[0];
        }

        this.data.push({
          "id": this._summaryList[i]["id"],
          "title": P_title,
          "start": P_StartTime,
          "end": P_NewEnd,
          "new_end": P_EndTime,
          "description": this._summaryList[i]["description"],
          "isactive": 1,
          "display": "block",
          "assignee_name": "",
          "createdby_name": this._summaryList[i]["createdby_name"],
          "background_color": "#2e8bc0",
          "border_color": "#2e8bc0",
          "event_priority_id": 2,
          "event_status_id": 1,
          "is_server": 0,
          "start_string": this._summaryList[i]["start"],
          "end_string": this._summaryList[i]["end"],
          "event_id": this._summaryList[i]["event_id"],
          "schedule_instance_id": this._summaryList[i]["id"],
          "schedule_details": this._summaryList[i]["schedule_details"],
          "created_by_id": this._summaryList[i]["created_by_id"],
          "schedule_type_id": this._summaryList[i]["schedule_type_id"],
          "email_profile_id": this._summaryList[i]["email_profile_id"],
        });
      }
    }

    this.calenderHeight = window.innerHeight - 180;
    this.lastviewName = 'dayGridMonth';
    if (this.calendarOptions == undefined) {
      this.calendarOptions = {
        timeZone: 'local',
        initialView: this.lastviewName,
        height: this.calenderHeight,
        themeSystem: 'standard',
        plugins: [dayGridPlugin, interactionPlugin],
        dayMaxEvents: 3,
        navLinks: true,
        expandRows: true,
        dayMaxEventRows: true,
        fixedWeekCount: false,
        showNonCurrentDates: false,
        nowIndicator: true,
        //allDay: false,
        defaultTimedEventDuration: '00:01',
        nextDayThreshold: '0:00:00',
        //allDaySlot: false,
        scrollTime: '10:00:00',
        headerToolbar: {
          start: 'prev,next today addInstanceCustomButton',
          center: 'title',
          end: 'dayGridDay,dayGridWeek,dayGridMonth'
        },
        views: {
          dayGridMonth: {
            titleFormat: { year: 'numeric', month: 'long' }
          },
          timeGridWeek: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
          },
          timeGridDay: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
          }
        },
        buttonText: {
          today: 'Today',
          dayGridMonth: 'Month',
          timeGridWeek: 'Week',
          timeGridDay: 'Day'
        },
        datesSet: this.onChangeDayWeekMonth.bind(this),
        customButtons: {
          addInstanceCustomButton: {
            text: 'Add Instance',
            click: this.onAddInstance.bind(this)
          }
        },
        eventClick: this.onEditInstance.bind(this),
        events: this.data,
        eventContent: this.renderEventContent.bind(this),
        eventOrder: "default",
        eventDidMount: this.eventDidMount.bind(this),
        displayEventTime: true,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        }
      };
    } else {
      this.calendarOptions = {
        ...this.calendarOptions,
        // Reset or update your options here
        events: this.data
      };
    }
  }

  showContextMenu(event: MouseEvent, calendarEventInfo: any) {
    this.contextMenuVisible = true;
    this.contextMenuPosition = {
      left: `${event.pageX}px`,
      top: `${event.pageY - 75}px`
    };
    this.selectedEvent = calendarEventInfo;
  }

  async onAdhocRun() {

    //var P_data = this._summaryList.filter((obj) => {
    //  return (this.selectedEvent.schedule_instance_id == obj.id.toString())
    //});
    //let P_dataDescription = JSON.parse(P_data[0].description);

    this.contextMenuVisible = false;

    // for start date and start time
    //let dlgRef = this.dialog.open(AdhocrunActionComponent,
    //  {
    //    disableClose: true,
    //    autoFocus: false,
    //    data: {
    //      "schedule_instance_id": this.selectedEvent.schedule_instance_id,
    //      "event_id": this.selectedEvent.event_id,
    //      "schedule_type_id": this.selectedEvent.schedule_type_id,
    //      "server_ip": P_dataDescription[0].ServerIP,
    //      "server_name": P_dataDescription[0].ServerName,
    //      "automation_id": P_dataDescription[0].AutomationId,
    //      "automation_name": P_dataDescription[0].AutomationName,
    //      "automation_location": P_dataDescription[0].AutomationLocation
    //    },
    //    panelClass: 'confirm-dialog-container',
    //    width: '470px'
    //  }
    //);
    //var Action_result = await dlgRef.afterClosed().toPromise();
    //if (Action_result) {
    //  this.getAutomationSummary.emit(this.summaryInputJSON);
    //}

    this.contextMenuVisible = false;
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = this.getActionInputJSON(1, null);
    data.message = 'Are you sure you want to adhoc run?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.isLoading = true;

      this.botDashboardService.setScheduleLog(request).then((res: any) => {
        this.isLoading = false;
        data.message = res[0]?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.getAutomationSummary.emit(this.summaryInputJSON);
      })
    }
  }

  async onForceQuite(event: any) {
    this.contextMenuVisible = false;
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = this.getActionInputJSON(2, null);
    data.message = 'Are you sure you want to force quit?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.isLoading = true;

      this.botDashboardService.setScheduleLog(request).then((res: any) => {
        this.isLoading = false;
        data.message = res[0]?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.getAutomationSummary.emit(this.summaryInputJSON);
      })
    }
  }

  getActionInputJSON(type: number, Action_result: any) {

    var P_data = this._summaryList.filter((obj) => {
      return (this.selectedEvent.schedule_instance_id == obj.id.toString())
    });
    let P_dataDescription = JSON.parse(P_data[0].description);

    let request: any = [];

    let P_AdhocRun = false;
    let P_ForceQuit = false;
    if (type == 1) {
      P_AdhocRun = true;
      P_ForceQuit = false;
    } else if (type == 2) {
      P_AdhocRun = false;
      P_ForceQuit = true;
    }

    request = [{
      "ScheduleLogID": -1,
      "ScheduleID": this.selectedEvent.schedule_instance_id,
      "EventID_google": this.selectedEvent.event_id,
      "Status": 0,
      "ScheduleLogDetails": JSON.stringify([
        {
          "AdhocRun": P_AdhocRun,
          "ForceQuit": P_ForceQuit,
          "ServerIP": P_dataDescription[0].ServerIP,
          "ServerName": P_dataDescription[0].ServerName,
          "AutomationId": P_dataDescription[0].AutomationId,
          "AutomationName": P_dataDescription[0].AutomationName,
          "AutomationLocation": P_dataDescription[0].AutomationLocation
        }
      ]),
      "ScheduleTypeID": this.selectedEvent.schedule_type_id
    }];

    return request;
  }

  getInstanceDetails(fulldata: any, rowdata: any, P_serverIp: string) {
    let P_servername = JSON.parse(rowdata.description)[0].ServerName;
    let P_StartDate = rowdata.start.split('T')[0];
    let P_automationid = JSON.parse(rowdata.description)[0].AutomationId;
    let P_SubEventId = rowdata.sub_event_id;
    let P_records = fulldata.filter((obj: any) => {
      return (obj.description.indexOf(P_serverIp) > -1 &&
        P_StartDate == obj.start.split('T')[0]);
    });

    //let P_automationsteps = this.automationsteps.filter((obj: any) => {
    //  return (obj.automationid == P_automationid);
    //});
    let P_automationsteps = this.automationsteps.filter((obj: any) => {
      return (obj.scheduleid == rowdata.id &&
        obj.eventid_google == rowdata.event_id &&
        obj.scheduletypeid == rowdata.schedule_type_id);
    });
    P_automationsteps = P_automationsteps.filter((obj: any) => {
      var P_json = JSON.parse(obj.schedulelogdetails);
      return (P_json[0].processid != null &&
        P_json[0].processid != undefined &&
        P_json[0].processid != "" &&
        P_json[0].SubEventId == P_SubEventId);
    });

    return [
      {
        "servername": P_servername,
        "serverip": P_serverIp,
        "records": P_records,
        "automationsteps": P_automationsteps
      }
    ]

  }

  calculateStepEndTime(P_starttime: any, P_automationsteps: any) {
    // Original ISO 8601 date-time string
    const isoString = P_starttime;

    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Add 60 seconds to the time
    date.setSeconds(date.getSeconds() + P_automationsteps);

    //date.setMinutes(date.getMinutes() + P_automationsteps);

    // Convert the Date object back to an ISO string
    const newIsoString = date.toISOString();

    return newIsoString;
  }

  GetSystemTimeZone(P_DateTime: any) {
    const utcDate = new Date(P_DateTime);
    const systemTimezoneOffset = utcDate.getTimezoneOffset() * 60000; // Offset in milliseconds
    const systemTimestamp = utcDate.getTime() + systemTimezoneOffset;
    const systemDate = new Date(systemTimestamp);
    //const formattedSystemDate = this.datePipe.transform(systemTimestamp, "yyyy-MM-ddTHH:mm:ss.SSS", "IST");
    const formattedSystemDate = systemDate.toISOString();
    return formattedSystemDate;
  }

  async onChangeDayWeekMonth(arg: any) {
    if (!this.input4) {
      this.input4 = true;
      return;
    }
    const startDate = arg.start.toISOString().slice(0, 10);
    const endDate = arg.end.toISOString().slice(0, 10);
    this.bindCurrentMonthData(startDate, endDate);
  }

  async bindCurrentMonthData(startDate: any, endDate: any) {

    if (this.scheduleType != 1) {
      return;
    }

    if (startDate == "") {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      startDate = GetFormattedDate(firstDay);
      endDate = GetFormattedDate(lastDay);
    }

    const scheduleLogsRequestJson = [{
      ScheduleID: -1,
      EventID_google: -1,
      Status: -1,
      ScheduleTypeID: 1,
      pagesze: 1000,
      pagestartrecord: 0,
      fromdate: startDate + ' 01:01:01',
      todate: endDate + ' 01:01:01'
    }];
    this.automationsteps = await this.botDashboardService.getScheduleLog(scheduleLogsRequestJson)
    this.setCalendarOption();
  }

  resetCalendarOptions() {
    this.calendarOptions = {
      ...this.calendarOptions,
      // Reset or update your options here
    };
  }
}
