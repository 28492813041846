import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[usd-money]',
  providers: [CurrencyPipe]
})
export class MoneyDirective {
  private _value: any;
  private _money: number = 0;
  private _color: any = 'red';
  private _isCell: boolean = false;
  private _showSign: boolean = false;
  private _suffix: string = '';
  private _preffix: string = '$';
  private _display: boolean = false;

  currencyChars = new RegExp('[\.,]', 'g'); // we're going to remove commas and dots

  constructor(public elemRef: ElementRef, public renderer: Renderer2, private currency: CurrencyPipe) { }

  ngOnInit() {
    //this.format(this.elemRef.nativeElement.value); // format any initial values
  }

  @Input('usd-money')
  set value(value: any) {
    var moneyStr: string;

    this._value = value;
    this._money = (value != null) ? parseFloat(value) : 0.00;
    this.updateDisplay();
    this.updateAttributes();
  }
  @Input('usd-money-cell')
  set isCell(isCell: boolean) {
    this._isCell = isCell;
    this.updateDisplay();
    this.updateAttributes();
  }
  private updateDisplay() {
    let moneyStr = '\u00A0';
    if (this._money != null) {
      if (this._isCell && !this._display) {
        moneyStr = this.currency.transform(this._money * ((this._money<0)?-1:1)) ?? '0.00';
      }
      else {
        moneyStr = this.currency.transform(this._money * ((this._money < 0) ? -1 : 1)) ?? '0.00';
      }

      if (this._money < 0) {
        moneyStr = `(${moneyStr})`
      }

      //if (!this._showSign) {
      //  moneyStr = this.minusToParen.transform(moneyStr) ?? '0';
      //}
    }

    this.renderer.setProperty(this.elemRef.nativeElement, 'innerText',  moneyStr );
  }

  private updateAttributes() {
    this.renderer.setStyle(this.elemRef.nativeElement, 'color', this._money < 0 ? this._color : 'inherit');
    this.renderer.setStyle(this.elemRef.nativeElement, 'padding-left', this._isCell ? '1em' : 'inherit');
    if (this._isCell) {
      this.renderer.addClass(this.elemRef.nativeElement, 'mat-cell');
    }
    else {
      this.renderer.removeClass(this.elemRef.nativeElement, 'mat-cell');
    }
  }

}
