import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupEntityBase, LookupEntitySelect } from '../../../entities/lookup-entity';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import * as Custom from '../../../Utility/CustomValidators.utilities'
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  public sortedData = new MatTableDataSource<User>([]);
  displayedColumns: string[] = ['actions', 'username', 'email', 'employeeID', 'name', 'isEmailVerified', 'IsActive', 'groupCount', 'roleCount', 'portalCount', 'createdBy', 'Createdon', 'modifiedBy', 'modifiedOn'];
  
  public pageSize = 10;
  public sort!: Sort;
  public _userList: User[] = [];
  public hasUserAccount_Edit: boolean = false;
  public hasUserAccount_Delete: boolean = false;

  ngOnInit() {    
    this.setPrivileges();
  }
  //public filterValue!: string
  @Output() onEditUser = new EventEmitter<User>();
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  @Input() set userList(users: User[]) {
    this.sortedData.data = users;
    this._userList = users;
  }

  @Input() set filtervalue(filtervalue: string) {        
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';    
  }
  
  get userList() {
    return this._userList;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }

  constructor(
    private dialog: MatDialog, private userService: UserService, private auth: AuthService) {
    this.sortedData.data = this.userList;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortAndFilterData() {
    let filteredData = this.userList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;

    });

    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }


    this.sortedData.data = filteredData.sort((a: User, b: User) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'username':
          return Custom.compare(a.username, b.username, isAsc);
        case 'email':
          return Custom.compare(a.email, b.email, isAsc);
        case 'name':
          return Custom.compare(a.name, b.name, isAsc);
        case 'employeeID':
          return Custom.compare(a.employeeid, b.employeeid, isAsc);
        case 'isEmailVerified':
          return Custom.compare(a.isemailverified, b.isemailverified, isAsc);
        case 'IsActive':
          return Custom.compare(a.isactive, b.isactive, isAsc);
        case 'groupCount':
          return Custom.compare(a.groupCount, b.groupCount, isAsc);
        case 'roleCount':
          return Custom.compare(a.roleCount, b.roleCount, isAsc);
        case 'portalCount':
          return Custom.compare(a.portalCount, b.portalCount, isAsc);
        case 'createdBy':
          return Custom.compare(a.createdby, b.createdby, isAsc);
        case 'Createdon':
          return Custom.compare(a.createdon, b.createdon, isAsc);
        case 'modifiedBy':
          return Custom.compare(a.modifiedby, b.modifiedby, isAsc);
        case 'modifiedOn':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        default:
          return 0;
      }
    });
  }
  DateFormate(DateString: string, formateString: string) {
    return Custom.FormatDate(DateString, formateString);
  }
  doEditUser(user: User) {
    this.onEditUser.emit(user);
  }
  @Output() onUpdate = new EventEmitter();
  async doDeleteUser(user: User) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: user.userid,
    }]

    data.data = user;
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.userService.deleteUser(request).then(res => {
        data.message = res[0]?.result;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        this.onUpdate.emit();
        Confirmation(this.dialog, data);

      });
    }
  }

  getFiterData(columnName: string) {
    
    let data = []
    if (columnName == "isemailverified" || columnName == 'isactive') {
      data = Custom.booleanLookups(this.filterData[columnName]);
    } else {

      

      data = this.userList
        .map((item: any) => {
          return {
            "description": item[columnName],
            "code": item[columnName]?.toString(),
            active: true,
            selected: this.filterData[columnName] && (this.filterData[columnName] as any[]).findIndex(w => w.code == item[columnName]?.toString()) >= 0
          }
        }
        )
        .filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val.code == value.code) == index
          }
        );
    }
    return data as LookupEntitySelect[];
  }
  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();

  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    let items = this.getFiterData(item);
    this.menuFilter.menuData = { 'item': items };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.menuFilter.openMenu();
  }

  applyFilter(event: Event) {
  //  
    const filterValue = (event.target as HTMLInputElement).value;
    this.sortedData.filter = filterValue.trim().toLowerCase();
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    if (this.hasUserAccount_Edit) {
      this.onShowDetails.emit(item);
    }
  }
  setPrivileges() {
    this.hasUserAccount_Edit = this.auth.hasPrivilege(PrivilegeValues.UserManage_Edit)
    this.hasUserAccount_Delete = this.auth.hasPrivilege(PrivilegeValues.UserManage_Delete);
    if (!this.hasUserAccount_Edit && !this.hasUserAccount_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }
}
