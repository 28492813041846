import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  public _user!: User;
  @Input() set user(user: User) {
    this._user = user;
  }
  get user() {
    return this._user;
  } 

  @Output() onCancelEdit = new EventEmitter<User>();
  doCancelEdit(user: User) {
    //this.onCancelEdit.emit(user);
  }


  constructor(userService: UserService, private dialog: MatDialog) {    
    const request = [{ loginuserid: -1, isactive: -1 }]
    userService.GetCurrentUser(request).then(res => {      
      this._user = res[0] as User;
    })
  }
}
