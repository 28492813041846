import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payroll-connector',
  templateUrl: './payroll-connector.component.html',
  styleUrls: ['./payroll-connector.component.css']
})
export class PayrollConnectorComponent {
  public file: string = "";
  public filebytes: string = "";
  formAdd: FormGroup = new FormGroup({
    ConnectorName: new FormControl(''),
    ConnectorType: new FormControl(2),
  });
  @ViewChild('f_input') f_input: any;
  @Output() onCancel = new EventEmitter();
  onSaveConnector() {
    let item = {
      connectorName: this.formAdd.controls.ConnectorName.value,
      connectorType: this.formAdd.controls.ConnectorType.value,
      filename: this.file,
      filebytes: this.filebytes,
    }
    this.onCancel.emit(item);
  }
  doReset() {
    this.formAdd.controls.ConnectorName.setValue('');
    this.file=''
    this.filebytes = ''
  }
  doCancelEdit() {
    this.doReset();
    this.onCancel.emit(false);
  }
  onChange(event: any) {
    //this.file = this.f_input.nativeElement.files[0];
    const reader = new FileReader(); 
    this.file = this.f_input.nativeElement.files[0].name;
    reader.readAsDataURL(this.f_input.nativeElement.files[0]);
    reader.onload = (e) => {
      this.filebytes = reader?.result as string;
    };

  }
}
