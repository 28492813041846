import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payroll-dialog',
  templateUrl: './payroll-dialog.component.html',
  styleUrls: ['./payroll-dialog.component.css']
})
export class PayrollDialogComponent {
  public html:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PayrollDialogComponent>) {
    
  }
  public doYes(item: any) {
    this.dialogRef.close(item);
  }

  public doCancel(item:any) {
    this.dialogRef.close(item);
  }
}
