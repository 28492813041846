import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-remit-line-list',
  templateUrl: './remit-line-list.component.html',
  styleUrls: ['./remit-line-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RemitLineListComponent {
  dataSource = new MatTableDataSource<any>([]);
  lineInfoColumns: string[] = ['action', 'cpt', 'Dos', 'modifiers', 'units', 'Billed', 'Paid', 'Allowed'];
  @Input() set LineData(linedata: any) {
    this.dataSource.data = linedata;
  }
  get LineData() {
    return this.dataSource.data;
  }
  onExpandRow(item: any) {
    if (!item.expanded) {
      let data = this.dataSource.data.map(w => { w.expanded = false; return w });
    }
    item.expanded = !item.expanded;
  }
}
