import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Drawflow from 'drawflow';
import { ClientService } from '../../services/client.service';
import { BotDashboardService } from '../bot-dashboard/bot-dashboard.service';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog';
import { Confirmation } from '../confirm-dialog/confirm-dialog.component';
import { PayrollDialogComponent } from './payroll-dialog/payroll-dialog.component';
import { PayrollService } from './payroll.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit, AfterViewInit, OnChanges {
  _isLoading: boolean = false;
  @ViewChild('f_input') f_input: any;
  @Input()
  nodes: any[] = [];
  @Input()
  drawingData: string = '';
  @Input()
  locked: boolean = false;
  @Input()
  showLock: boolean = false;
  @Input()
  showNodes: boolean = true;
  @Input()
  otherDetails: any = '';

  editor!: any;
  editDivHtml!: HTMLElement;
  editButtonShown: boolean = true;

  drawnNodes: any[] = [];
  selectedNodeId!: string;
  selectedNode: any = {};
  currentNode: any = {};

  lastMousePositionEv: any;

  nodeModal!: ElementRef;
  @ViewChild('content') set setNodeModal(el: ElementRef) {
    this.nodeModal = el;
  }

  public lookupData: any = {};
  public nodeData: any = {};
  constructor(
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private clientService: ClientService,
    private botDashboardService: BotDashboardService,
  ) {
    this.getLookupData();
    payrollService.getNodes([]).then(nodes => {
      this.nodes = nodes;
    });

  }

  // Private functions
  private initDrawFlow(): void {
    const drawFlowHtmlElement = <HTMLElement>document.getElementById('drawflow');

    //const testEditor = new Drawflow(drawFlowHtmlElement);
    // testEditor.addConnection();

    this.editor = new Drawflow(drawFlowHtmlElement);

    this.editor.reroute = true;
    this.editor.curvature = 0.5;
    this.editor.reroute_fix_curvature = true;
    this.editor.reroute_curvature = 0.5;
    this.editor.force_first_input = false;
    this.editor.line_path = 1;
    this.editor.editor_mode = 'edit';

    this.editor.start();

    // this.editor.import(dataToImport);

    /*
    if (this.drawingData && Object.keys(JSON.parse(this.drawingData).drawflow.Home.data).length > 0) {
      console.log('this.drawingData :>> ', this.drawingData);
      this.editor.import(JSON.parse(this.drawingData));
    }
    */
  }

  private resetAllInputsOutputs() {
    this.nodes.forEach((node) => {
      node.inputs = 1;
      node.outputs = 1;
    });
  }

  private addEditorEvents() {
    // Events!
    this.editor.on('nodeCreated', (id: any) => {

    });

    this.editor.on('nodeRemoved', (id: any) => {
      console.log('Editor Event :>> Node removed ' + id);
    });

    this.editor.on('nodeSelected', (id: any) => {
      //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
      //this.open(this.selectedNode.html, this.selectedNodeId);
    });
    this.editor.on('dblclick', (e: any) => {
      //console.log('Editor Event :>> Click :>> ', e);
      //console.log(this.selectedNode.html);
      //this.open(this.selectedNode.html, this.selectedNodeId);
    });
    this.editor.on('click', (e: any) => {
      //console.log('Editor Event :>> Click :>> ', e);
      //console.log(this.selectedNode.html);
      //this.open(this.selectedNode.html, this.selectedNodeId);
      //if (e.target.closest('.drawflow_content_node') != null || e.target.classList[0] === 'drawflow-node') {
      //  //if (e.target.closest('.drawflow_content_node') != null) {
      //  //  this.selectedNodeId = e.target.closest('.drawflow_content_node').parentElement.id;
      //  //} else {
      //  //  this.selectedNodeId = e.target.id;
      //  //}
      //  //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${this.selectedNodeId.slice(5)}`];
      //}

      //if (e.target.closest('#editNode') != null || e.target.classList[0] === 'edit-node-button') {
      //  // Open modal with Selected Node
      //  console.log(this.selectedNode.html);
      //  this.open(this.selectedNode.html, this.selectedNodeId);
      //}

      //if (e.target.closest('#editNode') === null) {
      //  this.hideEditButton();
      //}
    });

    this.editor.on('moduleCreated', (name: any) => {
      console.log('Editor Event :>> Module Created ' + name);
    });

    this.editor.on('moduleChanged', (name: any) => {
      console.log('Editor Event :>> Module Changed ' + name);
    });

    this.editor.on('connectionCreated', (connection: any) => {
      console.log('Editor Event :>> Connection created ', connection);
    });

    this.editor.on('connectionRemoved', (connection: any) => {
      console.log('Editor Event :>> Connection removed ', connection);
    });

    this.editor.on('contextmenu', (e: any) => {
      console.log('Editor Event :>> Context Menu :>> ', e);

      if (e.target.closest('.drawflow_content_node') != null || e.target.classList[0] === 'drawflow-node') {
        if (e.target.closest('.drawflow_content_node') != null) {
          this.selectedNodeId = e.target.closest('.drawflow_content_node').parentElement.id;
        } else {
          this.selectedNodeId = e.target.id;
        }
        this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${this.selectedNodeId.slice(5)}`];

        this.showEditButton();
      }
    });

    this.editor.on('zoom', (zoom: any) => {
      console.log('Editor Event :>> Zoom level ' + zoom);
    });

    this.editor.on('addReroute', (id: any) => {
      console.log('Editor Event :>> Reroute added ' + id);
    });

    this.editor.on('removeReroute', (id: any) => {
      console.log('Editor Event :>> Reroute removed ' + id);
    });

    // this.editor.on('mouseMove', (position: any) => {
    //   console.log('Editor Event :>> Position mouse x:' + position.x + ' y:' + position.y);
    // });

    // this.editor.on('nodeMoved', (id: any) => {
    //   console.log('Editor Event :>> Node moved ' + id);
    // });

    // this.editor.on('translate', (position: any) => {
    //   console.log(
    //     'Editor Event :>> Translate x:' + position.x + ' y:' + position.y
    //   );
    // });
  }

  private initDrawingBoard() {
    this.initDrawFlow();
    if (!this.locked) {
      this.addEditorEvents();
    }
  }

  private showEditButton() {
    this.editButtonShown = true;
    this.editDivHtml = document.createElement('div');
    this.editDivHtml.id = 'editNode';
    this.editDivHtml.innerHTML = '<mat-icon>edit</mat-icon>';
    this.editDivHtml.style.display = 'block';
    this.editDivHtml.style.position = 'absolute';
    this.editDivHtml.className = 'edit-node-button';

    const selectedNodeHtml = document.getElementById(this.selectedNodeId);
    selectedNodeHtml?.append(this.editDivHtml);
  }

  private hideEditButton() {
    this.editButtonShown = false;
    this.editDivHtml = document.getElementById('editNode') as HTMLElement;
    if (this.editDivHtml) {
      this.editDivHtml.remove();
    }
  }

  async open(content: any, nodeId: string) {
    this.hideEditButton();

    // const oldNodeIdNumber = parseInt(nodeId.slice(5));
    //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${oldNodeIdNumber}`];
    //const oldNodeStringified = JSON.stringify(this.selectedNode);
    // const { inputsCount, outputsCount } = this.countInOutputsOfNode(JSON.parse(oldNodeStringified));
    /*
    const dlgRef = this.dialog.open(PayrollDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          node: this.selectedNode,
          lookupData: this.lookupData
        },
        width: '40%',
      });
    let item = await dlgRef.afterClosed().toPromise()
    {
      
      if (item  ) {
        this.selectedNode.data.filters = item;
      }

    }
    */

    this.opensettingpane = true;
    this.nodeData = {
      node: this.selectedNode,
      lookupData: this.lookupData
    }

  }

  onKeyEvent(e: any) {
    // this.nameAlreadyUsed = this.checkIfNameIsUsed(e.target.value, this.selectedNode.id);
  }

  private countInOutputsOfNode(node: any) {
    let inputsCount = 0;
    let outputsCount = 0;

    Object.keys(node.inputs).forEach((inputKey) => {
      if (node.inputs[`${inputKey}`].connections.length > 0) {
        inputsCount++;
      }
    });

    Object.keys(node.outputs).forEach((outputKey) => {
      if (node.outputs[`${outputKey}`].connections.length > 0) {
        outputsCount++;
      }
    });

    return { inputsCount, outputsCount };
  }

  private checkIfNameIsUsed(name: string, id: number) {
    for (let i = 0; i < Object.keys(this.editor.drawflow.drawflow.Home.data).length; i++) {
      const nodeId = Object.keys(this.editor.drawflow.drawflow.Home.data)[i];
      if (nodeId != `${id}`) {
        if (this.editor.drawflow.drawflow.Home.data[`${nodeId}`].name == name) {
          return true;
        }
      }
    }
    return false;
  }

  private reestablishOldConnections(oldNode: any, newNodeId: number) {
    Object.keys(oldNode.inputs).forEach((inputKey) => {
      oldNode.inputs[`${inputKey}`].connections.forEach((connection: any) => {
        this.editor.addConnection(connection.node, newNodeId, connection.input, inputKey);
      });
    });

    Object.keys(oldNode.outputs).forEach((outputKey) => {
      oldNode.outputs[`${outputKey}`].connections.forEach((connection: any) => {
        this.editor.addConnection(newNodeId, connection.node, outputKey, connection.output);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes :>> ', changes);

    if (
      changes['drawingData'] &&
      changes['drawingData'].currentValue &&
      changes['drawingData'].currentValue.length > 0 &&
      Object.keys(JSON.parse(changes['drawingData'].currentValue).drawflow.Home.data).length > 0
    ) {
      this.editor.import(JSON.parse(changes['drawingData'].currentValue));
    }
  }

  ngOnInit(): void {
    // this.initDrawingBoard();
    // this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
    this.getPayrolldetails(66);
  }

  ngAfterViewInit(): void {
    this.initDrawingBoard();
    this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
  }

  onDrawflowEvent(e: any) {
    switch (e.type) {
      case 'dragstart':
        // console.log('Drawflow Event: DragStart :>> e :>> ', e);
        this.currentNode = [...this.nodes].find((node) => node.id == parseInt(e.target.id));
        //this.selectedNode.data = JSON.parse(
        //  JSON.stringify([...this.nodes].find((node) => node.infos.name === e.target.outerText))
        //);
        break;
      case 'dragenter':
        // console.log('Drawflow Event: DragEnter :>> e :>> ', e);
        break;
      case 'dragover':
        // console.log('Drawflow Event: DragOver :>> e :>> ', e);
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'dragleave':
        // console.log('Drawflow Event: DragLeave :>> e :>> ', e);
        break;
      case 'drop':
        // console.log('Drawflow Event: Drop :>> e :>> ', e);
        e.preventDefault();
        this.addNodeToDrawBoard(e.clientX, e.clientY);
        // this.resetAllInputsOutputs();
        break;

      default:
        // console.log('Other Drawflow Event :>> e :>> ', e);
        break;
    }
  }

  // Drawflow Editor Operations
  addNodeToDrawBoard(pos_x: number, pos_y: number) {
    if (this.editor.editor_mode === 'edit') {
      pos_x =
        pos_x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom));

      pos_y =
        pos_y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom));

      //console.log('addNodeToDrawBoard :>> this.selectedNode :>> ', this.selectedNode);

      let htmlTemplate = `<div id='${this.currentNode.id}'>${this.currentNode.infos.name}</div>`;


      var node = this.editor.addNode(
        this.currentNode.name,
        this.currentNode.inputs,
        this.currentNode.outputs,
        pos_x,
        pos_y,
        this.currentNode.infos.name,
        this.currentNode,
        htmlTemplate,
        false
      );
      document.getElementById('node-' + node)?.addEventListener('dblclick', (s: any) => {
        let id = s.currentTarget.id?.replaceAll("node-", '');
        if (id) {
          this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
          this.open(this.selectedNode.html, this.selectedNodeId);
        }
      });
    }
  }

  onClear() {
    this.editor.clear();
  }

  changeMode() {
    this.locked = !this.locked;
    this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
  }

  onZoomOut() {
    this.editor.zoom_out();
  }

  onZoomIn() {
    this.editor.zoom_in();
  }

  onZoomReset() {
    this.editor.zoom_reset();
  }

  exportDrawingData() {
    return this.editor.export();
  }

  onSubmit() {
    this.drawingData = this.exportDrawingData();
  }

  runFlow() {
    var isvalid = true;
    let data = this.editor.export().drawflow.Home.data
    let requestdata: any[] = []
    let flowId = (new Date()).getTime().toString();
    Object.keys(data).map((w, idx) => {
      let node = document.getElementById('node-' + data[w].id);
      if (!data[w].data.filters) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'Select valid inputs'
        }
      }
      else {

        let newData: any = {};
        newData.name = data[w].data.name
        newData.inputdata = data[w].data.inputdata;
        newData.pos_x = data[w].pos_x;
        newData.pos_y = data[w].pos_y;
        newData.id = data[w].id;
        newData.nodeId = data[w].data.id;
        newData.input = data[w].inputs ?? {};
        newData.output = data[w].outputs ?? {};
        if (((data[w].data.inputs == 1 && newData.input != -1) || data[w].data.inputs == 0) && ((data[w].data.outputs == 1 && newData.output != -1) || data[w].data.outputs == 0)) {
          if (node) {
            node.style.color = 'var(--dfNodeTextColor)'
            node.title = '';
          }
          Object.keys(newData.inputdata).forEach(s => {
            if (newData.inputdata[s] == 2) {
              newData.inputdata[s] = requestdata[idx - 1].inputdata[s];
            }
            else if (data[w].data.filters[s]?.constructor === Array) {
              newData.inputdata[s] = data[w].data.filters[s].map((ss: any) => { return ss.code }).join(',')
            }
            else if (s == "flowId") {
              newData.inputdata[s] = flowId
            }
            else if (s == "Isfirststep") {
              newData.inputdata[s] = newData.input == 1;
            }
            else if (data[w].data.filters[s]) {
              newData.inputdata[s] = data[w].data.filters[s];
            }
            else {
              newData.inputdata[s] = "";
            }
          });
        }
        else {
          isvalid = false;
          if (node) {
            node.style.color = 'red';
            node.title = 'Select valid input/output connections'
          }
        }

        requestdata.push(newData);
      }
    });
    if (isvalid) {
      let request = [
        {
          "EventId": "-1",
          "ScheduleInstanceId": 66,
          "CreatedById": "-1",
          "ScheduleTypeId": 4,
          "EmailProfileId": -1,
          "ScheduleName": "payroll 1",
          "ScheduleDetails": "payroll 1",
          "IsActive": 1,
          "StartTime": "",
          "EndTime": "",
          "OccurenceRule": "RRULE:FREQ=DAILY;INTERVAL=1;UNTIL=20240823T240000Z",
          "ScheduleDescription": JSON.stringify(requestdata)
        }
      ]
      this.botDashboardService.addUpdateInstance(request).then(res => {
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.title = 'Information!';
        data.showYes = true;
        data.noText = 'NO';
        data.yesText = 'YES';
        data.message = res[0]?.message;
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data)
      });
    }
  }
  async download(res: any) {
    let blob = await res.blob();
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = "output.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  onChange(event: any) {
    //this.file = this.f_input.nativeElement.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.f_input.nativeElement.files[0]);
    reader.onload = (e) => {
      
      //this.file = reader?.result as string;
    };

  }
  public opensettingpane: boolean = false;
  doCancel(item: any) {
    this.opensettingpane = false;
    if (item) {
      this.selectedNode.data.filters = item;
    }
  }
  async getLookupData() {

    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    const locationRequest = [{
      clientid: '',
      uniqueservicingproviderid: -1,
      pagesize: 100,
      pagestartrecord: 0,
    }]
    this._isLoading = true;
    const lookups = await Promise.all([
      this.clientService.getlookup([]),
      this.clientService.getServiceLocations(locationRequest),
    ]);
    this.lookupData = {
      practices: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
      dateType: [{ code: '1', description: 'Accounting' }, { code: '2', description: 'DOS' }, { code: '3', description: 'Transaction Date' }, { code: '4', description: 'DOE' }],
      runOn: [{ code: 'last30', description: 'Last 30 Days' }, { code: 'lastmonth', description: 'Last Month' }, { code: 'lastweek', description: 'Last week' }, { code: 'last7', description: 'Last 7 days' }],
      excludeCpts: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      asacodes: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      modifiers: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      workingDays: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      providerType: [{ code: '0', description: 'Servicing' }, { code: '1', description: 'Directing' }],
      occurances: [{ code: '1', description: '1' }, { code: '2', description: '2' }, { code: '3', description: '3' }, { code: '4', description: '4' }, { code: '5', description: '5' }],
      serviceLocation: lookups[1]?.map((s: any) => { return { code: s.uniqueservicingproviderid, description: s.servicingprovidername, active: true } }),
    }
    //this.nodeData = {
    //  node: this.selectedNode,
    //  lookupData: this.lookupData
    //}
    this._isLoading = false;
  }


  getPayrolls() {
    let request: any = {
      schedule_instance_id: '-1',
      eventid_google: "-1",
      scheduletypeid: 4,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: "01/01/1900",
      todate: "01/01/1900"
    };
    this.botDashboardService.getAutomationSummary([request]).then(data => {
      //console.log("this.summaryList: ", this.summaryList);
      //this.summaryList = data;
      //this.isLoading = false;
      //this.showSummary = true;
    });
  }
  getPayrolldetails(id: number) {
    let request: any = {
      schedule_instance_id: id,
      eventid_google: "-1",
      scheduletypeid: 4,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: "01/01/1900",
      todate: "01/01/1900"
    };
    this._isLoading = true;
    this.botDashboardService.getAutomationSummary([request]).then(data => {
      this._isLoading = false;
      let dataToImport = {
        "drawflow": {
          "Home": {
            "data": {
            }
          }
        }
      }
      let newdata: any = {}
      JSON.parse(data[0].description).forEach((node: any, indx: number) => {
        //if (indx <= 1)
        {
          var currentNode = this.nodes.find(s => { return s.id == node.nodeId })
          let htmlTemplate = `<div id='${currentNode.id}'>${currentNode.infos.name}</div>`;
          currentNode.filters = {
            tablename: node.inputdata.tablename,
            uniquepracticeid: this.lookupData.practices.filter((w:any) => { return node.inputdata.uniquepracticeid?.split(',').includes(w.code) }),
            datetype: this.lookupData.dateType.filter((w: any) => { return node.inputdata.datetype?.split(',').includes(w.code) }),
            runOn: this.lookupData.runOn.filter((w: any) => { return node.inputdata.runOn?.split(',').includes(w.code) }),
            reducepercentage: node.inputdata.reducepercentage,
            cptCodes: this.lookupData.excludeCpts.filter((w: any) => { return node.inputdata.cptCodes?.split(',').includes(w.code) }),
            asaCodes: this.lookupData.asacodes.filter((w: any) => { return node.inputdata.asaCodes?.split(',').includes(w.code) }),
            providerOrderSequence: node.inputdata.providerOrderSequence,
            providerTypeId: this.lookupData.providerType.filter((w: any) => { return node.inputdata.providerTypeId?.split(',').includes(w.code) }),
            isanesthesia: node.inputdata.isanesthesia, /* 1 for anesthesia  and 0 for non anesthesia rows */
            defaultunits: node.inputdata.defaultunits, /* should be used when we need to update hardcode value */
            excludemodifiers: this.lookupData.modifiers.filter((w: any) => { return node.inputdata.excludeModifiers?.split(',').includes(w.code) }),
            includemodifiers: this.lookupData.modifiers.filter((w: any) => { return node.inputdata.includeModifiers?.split(',').includes(w.code) }),
            excludeoccurances: this.lookupData.occurances.filter((w: any) => { return node.inputdata.excludeoccurances?.split(',').includes(w.code) }),
            includeoccurances: this.lookupData.occurances.filter((w: any) => { return node.inputdata.includeoccurances?.split(',').includes(w.code) }),
            excludecptCodes: this.lookupData.excludeCpts.filter((w: any) => { return node.inputdata.excludecptCodes?.split(',').includes(w.code) }),
            serviceLocation: node.inputdata.uniqueServicelocationid==""?[]: this.lookupData.serviceLocation.filter((w: any) => { return node.inputdata.uniqueServicelocationid?.split(',').includes(w.code) }),
            workingDays: this.lookupData.workingDays.filter((w: any) => { return node.inputdata.workingDays?.split(',').includes(w.code) })
          }

          newdata[node?.id?.toString()] = {
            "id": node?.id,
            "name": currentNode.name,
            "data": currentNode,
            "class": currentNode.infos.name,
            "html": htmlTemplate,
            "typenode": false,
            "inputs": node.input,
            "outputs": node.output,
            "pos_x": node.pos_x,
            "pos_y": node.pos_y,            
          }
          /*
          var newnode = this.editor.addNode(
            currentNode.name,
            currentNode.inputs,
            currentNode.outputs,
            node.pos_x,
            node.pos_y,
            currentNode.infos.name,
            currentNode,
            htmlTemplate,
            false
          );
          //this.editor.addConnection(node.id, node.input, 'input_' + indx, 'output' + indx);
          var oldNode = this.editor.drawflow.drawflow.Home.data[newnode];
          if (oldNode)
            Object.keys(oldNode.inputs).forEach((inputKey) => {
              this.editor.addConnection(newnode,node.output, 'input_' + indx, inputKey);
              //[
              //  {
              //    "node": "2",
              //    "output": "input_1"
              //  }
              //]
              //oldNode.inputs[`${inputKey}`].connections.forEach((connection: any) => {
              //  this.editor.addConnection(connection.node, newnode, connection.input, inputKey);
              //});
            });
          document.getElementById('node-' + newnode)?.addEventListener('dblclick', (s: any) => {
            let id = s.currentTarget.id?.replaceAll("node-", '');
            if (id) {
              this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
              this.open(this.selectedNode.html, this.selectedNodeId);
            }
          });*/
        }
      })
      dataToImport.drawflow.Home.data = newdata;
      this.editor.import(dataToImport);
      JSON.parse(data[0].description).forEach((node: any, indx: number) => {

        document.getElementById('node-' + node.id)?.addEventListener('dblclick', (s: any) => {
          let id = s.currentTarget.id?.replaceAll("node-", '');
          if (id) {
            this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
            this.open(this.selectedNode.html, this.selectedNodeId);
          }
        });
      });

    })
  }
}
