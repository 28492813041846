import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent {
  public _task: any;
  @Input() set task(task: any) {
    this._task = task;
  }
  get task() {
    
    return this._task;
  }
 
}
