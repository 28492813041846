<form [formGroup]="form" class="condition-form">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='fromColumn' (selectionChange)="modo($event)">
                <mat-option *ngFor="let table of tables" [value]="table">{{table.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='fromColumn'>
                <mat-option *ngFor="let column of toTable?.columns" [value]="column.id">{{column.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">           
            <input matInput value="" formControlName='oprator'>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">
            <input matInput value="" formControlName='toColumn'>            
        </mat-form-field>

    </div>
</form>
