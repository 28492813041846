import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { setUser, User, UserDetails } from '../user';
import * as custom from '../../../Utility/CustomValidators.utilities'
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent {
  public file: string =  "assets/images/profile_blank.png";
  public oldfile: string = "assets/images/profile_blank.png";  
  public userDetails!: UserDetails;
  public _isLoading: boolean = false;
  public selectedTab!: number;
  public hasUserAccount_Edit: boolean = false;



  formAdd: FormGroup = new FormGroup({
    address1: new FormControl(''),
    address2: new FormControl(''),
    address3: new FormControl(''),
    city: new FormControl(''),
    country: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    middleName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    username: new FormControl('', [Validators.required]),
    phone: new FormControl('', [custom.validatePhone, Validators.pattern("^[0-9]*$"),
      Validators.minLength(8)]),
    employeeId: new FormControl('', [Validators.required]),
    userTypeId: new FormControl('', [Validators.required]),
    useronBoardDate: new FormControl('', [Validators.required]),
    state: new FormControl(''),
    zipCode: new FormControl('', [this.zipCodeValidator()]),
    isActive: new FormControl(''),
  }, { validators: this.addressValidator() });
  
  @Input() set user(user: UserDetails) {    
    this.userDetails = user;
    if (user)
      this.getUserDetail();
    
  }

  @ViewChild('f_input') f_input: any;
  @Output() onShowLoading = new EventEmitter();
  onChange(event: any) {
    //this.file = this.f_input.nativeElement.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.f_input.nativeElement.files[0]);
    reader.onload = (e) => {
      this.file = reader?.result as string;
    };

  }
  ngOnInit(): void {
    this.setPrivileges();
  }
  onReset() {
    this.file = this.oldfile;
  }
  @Output() onCancelEdit = new EventEmitter();
  @Output() onUpdateUser = new EventEmitter();
  //@Output() onUpdateEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {      
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
    
  }
  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this.file = 'assets/images/profile_blank.png';
    this._profileEdit = profileEdit;
  }
  get profileEdit() {
    return this._profileEdit;
  }

  
 @Output() doNextEdit = new EventEmitter();
  constructor(private dialog: MatDialog, private fb: FormBuilder, private userService: UserService, private router: Router, private auth: AuthService) { }
  async doSave(type: string) {    
    if (this.formAdd.valid) {

      let fileDetail;
      if (this.file == undefined) {
        fileDetail = 'assets/images/profile_blank.png';
      }
      else {
        fileDetail = this.file;
      }

      let userNew: setUser[] = [{
        userid: this.userDetails?.userid ?? -1,        
        firstname: this.formAdd.controls.firstName.value ?? '',
        lastname: this.formAdd.controls.lastName.value ?? '',
        middlename: this.formAdd.controls.middleName.value??'',
        email: this.formAdd.controls.email.value ?? '',
        //username: this.userDetails?.username ?? -1,
        username: this.formAdd.controls.username.value ?? '',
        phone: this.formAdd.controls.phone.value ?? '',
        employeeid: this.formAdd.controls.employeeId.value ?? '',
        usertypeid: this.formAdd.controls.userTypeId.value ?? '',
        joiningdate: custom.FormatDate(this.formAdd.controls.useronBoardDate.value,'MM/dd/yyyy'),
        address: this.formAdd.controls.address1.value ?? '',
        country: this.formAdd.controls.country.value ?? '',
        state: this.formAdd.controls.state.value ?? '',
        city: this.formAdd.controls.city.value ?? '',
        zipCode: this.formAdd.controls.zipCode.value ?? '',
        isActive: this.formAdd.controls.isActive.value?1:0,
        portalid: 1, 
        userimagepath: fileDetail.replace("data:image/jpeg;base64,", "") ?? '',
        createdBy: -1,

      }]      
      this.onShowLoading.emit(true);
      this.userService.setUser(userNew).then(res => {
        this.onShowLoading.emit(false);        
        this._isLoading = false;        
        if (res && res.length > 0) {
 
          let data: ConfirmDialogData = {} as ConfirmDialogData;
          data.showNo = true;
          data.message = res[0]?.userserrormessage;
          data.title = 'Information!';
          data.showYes = false;
          data.noText = 'OK';
          if (type != 'next' || !this.formAdd.controls.isActive.value) {            
            userNew[0].userid = res[0].userid;
            if (res[0].result != 0) {
              this.onUpdateUser.emit(userNew[0]);
            }
            Confirmation(this.dialog, data);
          }
          else {
            if (type == 'next' && res[0].result == 0) {
              Confirmation(this.dialog, data);  
            }
            else {
              userNew[0].userid = res[0].userid;
              this.onUpdateUser.emit(userNew[0]);
              this.doNextEdit.emit(1)
            }

          }
        }        

      })

    }
    else {
      this.formAdd.markAllAsTouched();
      return;
    }

  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phone = control.value;
      if (!phone) {
        return null;
      }

      const isValidPhone = /^[0-9]{10,10}$/.test(phone);
      return isValidPhone ? null : { invalidPhone: true };
    };
  }
  zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const zipCode = control.value;
      if (!control.value)
        return null;
      const isValidZipCode = /^[0-9]{5}$/.test(zipCode);
      return isValidZipCode ? null : { invalidZipCode: true };
    };
  }
  addressValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const address1 = control.get('address1')?.value;
      const address2 = control.get('address2')?.value;
      const address3 = control.get('address3')?.value;
      const city = control.get('city');
      const state = control.get('state');
      const country = control.get('country');

      if (address1 || address2 || address3) {
        const errors: ValidationErrors = {};
        if (!city?.value) {
          errors.cityRequired = true;
        }
        if (!state?.value) {
          errors.stateRequired = true;
        }
        if (!country?.value) {
          errors.countryRequired = true;
        }
        if (Object.keys(errors).length > 0) {
          return errors;
        }
      }

      return null;
    };
  }

  getUserDetail() {
   
    this.formAdd.controls.address1.setValue(this.userDetails.address);
    this.formAdd.controls.firstName.setValue(this.userDetails.firstname);
    this.formAdd.controls.lastName.setValue(this.userDetails.lastname);
    this.formAdd.controls.middleName.setValue(this.userDetails.middlename);
    this.formAdd.controls.email.setValue(this.userDetails.email);
    this.formAdd.controls.username.setValue(this.userDetails.username);
    this.formAdd.controls.city.setValue(this.userDetails.city);
    this.formAdd.controls.country.setValue(this.userDetails.country);
    this.formAdd.controls.phone.setValue(this.userDetails.phoneno)
    this.formAdd.controls.userTypeId.setValue(this.userDetails.usertypeid);
    this.formAdd.controls.useronBoardDate.setValue(this.userDetails.useronboarddate);
    this.formAdd.controls.state.setValue(this.userDetails.state);
    this.formAdd.controls.zipCode.setValue(this.userDetails.zip);
    
    this.file = this.userDetails.userimagepath;
    if (this.file == '') {
      this.file = "assets/images/profile_blank.png";
    }
    if (this.profileEdit)
      this.formAdd.controls.employeeId = new FormControl('');
    this.formAdd.controls.employeeId.setValue(this.userDetails.employeeid);

    if (this.userDetails.userid != -1) {
      this.formAdd.controls.username.disable()
      this.formAdd.controls.isActive.setValue(this.userDetails.isactive);
    }
    else {
      this.formAdd.controls.isActive.setValue(1);
    }           
  }

  setPrivileges() {
    this.hasUserAccount_Edit = this.auth.hasPrivilege(PrivilegeValues.UserManage_Edit);    
  }

}
