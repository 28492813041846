import { Component, OnInit } from '@angular/core';
import { PrivilegeValues } from '../../entities/Privilege';
import { AuthService } from '../../services/auth.service';
import { Role } from './role';
import { RoleService } from './role.service';

@Component({
  selector: 'app-role-managment',
  templateUrl: './role-managment.component.html',
  styleUrls: ['./role-managment.component.css']
})
export class RoleManagmentComponent implements OnInit {
  public roleList: Role[] = [];
  public role!: Role;
  public filtervalue: string = "";
  public showEdit: boolean = false
  public isLoading: boolean = true;
  public hasRole_View: boolean = false;
  public hasRole_Edit: boolean = false;
  public hasRole_Add: boolean = false;
  constructor(
    private roleService: RoleService, private auth: AuthService
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getRoles();
  }
  getRoles() {
    const request = {
      roleid: -1,
      isactive: -1,
      rolename: '',
      pagesize: 1000,
      pagestartrecord: 0
    }
    this.roleService.getRole([request]).then(data => {
      this.roleList = data;
      this.isLoading = false;
    });
  }
 
  onEditRole(role: Role) {    
    this.getRoleDetails(role.roleid);
  }
  onCancelEdit(item: any) {    
    this.role = {} as Role;
    this.showEdit = false;

    if (item)
      this.getRoles();
  }
  onAddRole() {
    this.role = {} as Role;
    this.role.roleid = -1;
    this.role.isactive = true;
    this.showEdit = true;
  }
  getRoleDetails(id: number, showLoading = true) { 
    if (showLoading)
      this.isLoading = true;
    this.roleService.getRoleDetails(id).then(res => {
     
      if (res) {
        this.isLoading = false;
        this.role = res[0] as Role;
        if (showLoading)
        this.showEdit = true;
      }
    });
  }
  doUpdateEdit(role: Role) {
    this.getRoles();
    this.getRoleDetails(role.roleid, false);
  }
  doDelete(role: Role) {
    this.getRoles();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }
  async onShowDetails(item: any) {
    item = this.getRoleDetails(item?.roleid, true);
    this.role = item;
    this.showEdit = true;
  }
  setPrivileges() {
    this.hasRole_View = this.auth.hasPrivilege(PrivilegeValues.RoleManage_View);
    this.hasRole_Edit = this.auth.hasPrivilege(PrivilegeValues.RoleManage_Edit);
    this.hasRole_Add = this.auth.hasPrivilege(PrivilegeValues.RoleManage_Add);
  }  
}
