
export class PrivilegeKey { [key: string]: boolean  }

export class PrivilegeValues {
  public static readonly Dashboard_View = '16V';

  public static readonly Privilege_All  = '999V';

  public static readonly Task_View = '11V';
  public static readonly Task_Edit = '11E';
  public static readonly Task_Action_View = '12V';
  public static readonly Task_Action_Add = '12A';
  public static readonly Task_Logs_View = '14V';
  public static readonly Task_Notes_View = '13V';
  public static readonly Task_Notes_Add = '13A';

  public static readonly UserManage_View = "1V";
  public static readonly UserManage_Add = "1A";
  public static readonly UserManage_Edit = "1E";
  public static readonly UserManage_Delete = "1D";  
  public static readonly UserSecurity_View = "5V";
  public static readonly UserSecurity_Edit = "5E";
  public static readonly UserNotification_View = "6V";
  public static readonly UserNotification_Edit = "6E";
  public static readonly UserRoles_View = "7V";
  public static readonly UserRoles_Edit = "7E";
  public static readonly UserGroup_View = "8V";
  public static readonly UserGroup_Edit = "8E";

  public static readonly RoleManage_View = "3V";
  public static readonly RoleManage_Add = "3A";
  public static readonly RoleManage_Edit = "3E";
  public static readonly RoleManage_Delete = "3D";
  public static readonly RoleMenu_View = "9V";
  public static readonly RoleMenu_Edit = "9E";

  public static readonly GroupManage_View = "2V";
  public static readonly GroupManage_Add = "2A";
  public static readonly GroupManage_Edit = "2E";
  public static readonly GroupManage_Delete = "2D";
  public static readonly GroupClient_View = "10V";
  public static readonly GroupClient_Edit = "10E";

  public static readonly ClientManage_View = "17V";
  public static readonly ClientManage_Add = "17A";
  public static readonly ClientManage_Edit = "17E";
  public static readonly ClientManage_Delete = "17D";

  public static readonly WorkqueueManage_View = "17V";
  public static readonly WorkqueueManage_Add = "17A";
  public static readonly WorkqueueManage_Edit = "17E";
  public static readonly WorkqueueManage_Delete = "17D";

}
