import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condition } from '../../../../entities/condition-entity';
import { TableList } from '../../../../entities/table-list';

@Component({
  selector: 'app-rule-add',
  templateUrl: './rule-add.component.html',
  styleUrls: ['./rule-add.component.css']
})
export class RuleAddComponent {
  @Input() toTable!: TableList;
  @Input() fromTable!: TableList;
  public condition!: Condition;
  @Input('condition')
  set getCondition(condition: Condition) {
    this.condition = condition;
    //this.form.controls.id.setValue(this.condition.id);
    this.form.controls.condition.setValue(this.condition.condition);
    this.form.controls.fromColumn.setValue(this.condition.fromColumn);
    this.form.controls.oprator.setValue(this.condition.oprator);
    this.form.controls.toColumn.setValue(this.condition.toColumn); 
  }
  public form: FormGroup = new FormGroup({
    condition: new FormControl('', [Validators.required]),
    fromColumn: new FormControl('', [Validators.required]),
    oprator: new FormControl('', [Validators.required]),
    toColumn: new FormControl('', [Validators.required])
  });
  public getConditionValue() {
    this.condition.condition = this.form.controls.condition.value;
    this.condition.fromColumn=this.form.controls.fromColumn.value;
    this.condition.oprator = this.form.controls.oprator.value;
    this.condition.toColumn = this.form.controls.toColumn.value; 
    return this.condition;
  }
  constructor() {

  }
}
