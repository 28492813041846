import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TableList } from '../entities/table-list';
import { environment } from '../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})

export class TableService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Table';
  public isLoggedIn: boolean = false;
  public tableList: TableList[] = [
    {
      id: 1, name: 'Transactions',
      columns: [
        { id: 19, "tableName": "Transactions", "name": "TransactionId" },
        { id: 1, "tableName": "Transactions", "name": "VisitId" },
        { id: 2, "tableName": "Transactions", "name": "Charges" },
        { id: 3, "tableName": "Transactions", "name": "Payments" },
        { id: 4, "tableName": "Transactions", "name": "Adjustments " },
        { id: 5, "tableName": "Transactions", "name": "Insurancebal" },
        { id: 6, "tableName": "Transactions", "name": "PatientBal" }],
    },
    {
      id: 2, name: 'Visits',
      columns: [
        { id: 7, "tableName": "Visit", "name": "Ticketnumber" },
        { id: 8, "tableName": "Visit", "name": "DateOfService" },
        { id: 9, "tableName": "Visit", "name": "DateOfVisit" },
        { id: 10, "tableName": "Visit", "name": "VisitType " },
        { id: 11, "tableName": "Visit", "name": "VisitStatus" },
        { id: 12, "tableName": "Visit", "name": "UserId" },
        { id: 20, "tableName": "Visit", "name": "PatientId" },
        { id: 13, "tableName": "Visit", "name": "Dischargedate" },
        { id: 14, "tableName": "Visit", "name": "Visitid" }
      ],
    },
    {
      id: 3, name: 'Users',
      columns: [
        { id: 20, "tableName": "Users", "name": "UserId" },
        { id: 15, "tableName": "Users", "name": "FirstName" },
        { id: 16, "tableName": "Users", "name": "LastName" }
      ]
    },
    {
      id: 4, name: 'Patients',
      columns: [
        { id: 21, "tableName": "Patients", "name": "PatientId" },
        { id: 17, "tableName": "Patients", "name": "FirstName" },
        { id: 18, "tableName": "Patients", "name": "LastName" }
      ],
    }

  ];

  constructor(private http: HttpClient) { }
  public getTableList(): TableList[] {
    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}`).toPromise();
    //as Practice[];
    return this.tableList;
  }

}
