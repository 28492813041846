import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import { UserService } from '../../user/user.service';
import { TaskService } from '../task.service';
import * as custom from '../../../Utility/CustomValidators.utilities';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActionService } from '../../../services/action.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit {
  public dropdownList: any[] = [];
  public selectedItems: any[] = [];
  public dropdownSettings: any = {};
  public users: LookupEntityBase[] = [];
  public actions: LookupEntityBase[] = [];
  public tage_list: string[] = [];
  public tages: string[] = [];
  public activities: any[] = [];
  @ViewChild("accordionItemDetails") accordionItemDetails!: CdkAccordionItem;
  @ViewChild("accordionItemLogs") accordionItemLogs!: CdkAccordionItem;
  @ViewChild("accordionItemNotes") accordionItemNotes!: CdkAccordionItem;
  @ViewChild("accordionItemAction") accordionItemAction!: CdkAccordionItem;
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit();
  }
  @Output() onExpand = new EventEmitter();
  doExpand() {
    if (this.expanded)
      this.onExpand.emit('30%');
    else
      this.onExpand.emit('60px');
  }
  public _tasks: any[] = [];
  public task: any = null;
  public showTitles: boolean = false;
  assigneeName = new FormControl('');
  formAdd: FormGroup = new FormGroup({
    action: new FormControl('', [Validators.required]),
    nextActionDate: new FormControl('', [Validators.required]),
    tages: new FormControl(''),
    assigneeName: this.assigneeName,
  });
  formAddNotes: FormGroup = new FormGroup({
    notes: new FormControl('', [Validators.required])
  });
  filteredOptions: LookupEntityBase[] = [];
  constructor(private taskService: TaskService, userService: UserService, actionService: ActionService) {
    this.assigneeName.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe();

    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    userService.getLookup(request).then(res => {
      this.users = res.map((s: any) => {
        let w = {} as LookupEntityBase;
        w.code = s.userid;
        w.description = s.name ?? '';
        w.active = s.isactive == 1;
        return w;
      });
    })
    actionService.getlookup(request).then(res => {
      this.actions = res.map((s: any) => {
        let w = {} as LookupEntityBase;
        w.code = s.actionid;
        w.description = s.actionname;
        return w;
      });
    })
  }
  @Input() set tasks(_tasks: any[]) {
    
    this._tasks = _tasks;
    if (this.tasks?.length == 1) {
      this.task = this.tasks[0];
      this.getTaskLog();
    }
    else {
      this.task = null;
      if (this.accordionItemLogs?.expanded) {
        this.accordionItemLogs.toggle();
      }
      else if (this.accordionItemDetails?.expanded) {
        this.accordionItemLogs.toggle();
      }
      this.activities = [];
      this.doExpand();
    }
  }
  get tasks() {
    return this._tasks;
  }

  ngOnInit() {
    this.dropdownList = [
      { item_text: 'Mumbai' },
      { item_text: 'Bangaluru' },
      { item_text: 'Pune' },
      { item_text: 'Navsari' },
      { item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_text: 'Pune' },
      { item_text: 'Navsari' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_text',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 300,
      allowSearchFilter: true,
      closeDropDownOnSelection:true
    };
  }
  onItemSelect(item: any) {
    this.dropdownList.map(w => {
      if (w.item_text == item.item_text && w.isCustom)
        w.isCustom = false;
      return w
    })
  }
  onFilterChange(item: any) {
    //if (this.dropdownList.findIndex(s => s.item_text == item) < 0) {

    this.dropdownList = this.dropdownList.filter(w => !w.isCustom);
    if (item != '')
      this.dropdownList.push({ item_text: item, isCustom: true })
    //}
  }
  onSelectAll(items: any) {

  }
  get expanded() {
    return this.accordionItemDetails?.expanded || this.accordionItemLogs?.expanded || this.accordionItemNotes?.expanded || this.accordionItemAction?.expanded;
  }
  getTaskLog() {
    
    this.taskService.getTaskDetails(this.task._id).then(res => {
      if (this.tasks.length == 1) {
        if (res && res.length > 0) {
          
          this.activities = res[0].Logs;
          this.task = res[0];
          this.tage_list = [];
          this.task?.Tags.map((w: any) => {
            return w.UserTags?.map((s: string) => this.tage_list.push(s))
          });
          this.tage_list = this.tage_list.filter(
            (value, index, current_value) => {
              return current_value.findIndex(val => val == value) == index
            }
          )
        }
      }
    });

  }
  getTaskTages() {
    return this.task?.Tags?.map((w: any) => { return w.UserTags });
  }
  onSaveAction() {
    if (this.formAdd.valid) {

      var request = this._tasks.map(task => {
        let req = {
          id: task._id,
          action: this.formAdd.controls.action.value,
          nextActionDate: this.formAdd.controls.nextActionDate.value,
          tages: this.selectedItems.map(w => { return w.item_text }).join(','),
          assigneeName: this.formAdd.controls.assigneeName.value?.description ?? '',
          currentAssigneeName: task.CurrentAssigneeName ?? ''
        }
        return req
      });
      this.formAdd.markAsUntouched();
      //this.formAdd.controls.notes.value("");
      this.formAdd.controls.nextActionDate.setValue("");
      this.formAdd.controls.tages.setValue("");
      this.formAdd.controls.assigneeName.setValue("");
      this.taskService.setActions(request).then(res => {
        this.getTaskLog();
      })
    }
  }
  onSaveNotes() {
    if (this.formAddNotes.valid) {

      var request = this._tasks.map(s => {
        let req = {
          id: s._id,
          notes: this.formAddNotes.controls.notes.value,
        }
        return req
      });
      this.formAddNotes.markAsUntouched();
      this.formAddNotes.controls.notes.setValue("");
      this.taskService.setNotes(request).then(res => {
        this.getTaskLog();
      })
    }
  }
  formatDate(Createdon: any) {
    return custom.FormatDate(Createdon, "MM/dd/yyyy hh:mm tt");
  }
  displayFn(user: LookupEntityBase) {
    return user && user.description ? user.description : '';
  }
  private _filter(value: string): LookupEntityBase[] {
    const filterValue = value ?? ''.toLowerCase();

    this.filteredOptions = this.users?.filter(user => { if (!user.description) return false; else return user.description.toLowerCase().includes(filterValue) });
    return this.filteredOptions;
  }
}
