import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-passwordgenerator',
  templateUrl: './passwordgenerator.component.html',
  styleUrls: ['./passwordgenerator.component.css'],
  
})
export class PasswordgeneratorComponent {
 
  public formAdd: FormGroup = new FormGroup({
 
    passwordgeneratorcheckbox: new FormControl(false),
    passwordgenerator: new FormControl('')
  });
  checkboxcheck = this.formAdd.controls.passwordgeneratorcheckbox.value
  clipboardText = ''
  checkBoxCheckUncheck() {
    if (this.checkboxcheck) {
      this.checkboxcheck = false
      
    }
    else {
      this.checkboxcheck = true;
    }
    console.log(this.clipboardText)
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PasswordgeneratorComponent>) {

  }
  GeneratePassword() {
    var keylist = "abcdefghijklmnopqrstuvwxyz"
    var upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var number = "0123456789"
    var special="@#$%!&"
    var temp = ''

    let i;
    for (i = 0; i < 7; i++)
      temp += keylist.charAt(Math.floor(Math.random() * keylist.length))

    for (let i = 0; i < 3; i++) {
      let x = (Math.floor((Math.random() * 10))) % temp.length;
      var a=''
      if (i == 0) {
      a = special.charAt(Math.floor(Math.random() * special.length))
      }
      else if (i == 1) {
        a = number.charAt(Math.floor(Math.random() * number.length))
      }
      else {
        a = upperCase.charAt(Math.floor(Math.random() * upperCase.length))
      }
      temp = temp.substring(0, x) + a + temp.substring(x, temp.length)
    }
     this.formAdd.controls.passwordgenerator.setValue(temp)
     
    console.log(this.formAdd.controls.passwordgenerator.value)
     this.clipboardText = temp
  }
 
  usePassword() {
    this.dialogRef.close(this.formAdd.controls.passwordgenerator.value);
  }
  cancelPassword() {
    this.dialogRef.close("")
  }
}
      


