import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  imageclass: string;
}
export interface MenuItem {
  menuname: string;
  linkurl: string;
  menuparentid: number;
  menuid: number;
  sequence: number;
  imagepath: string;
  imageclass: string;
  externalsystem_guid: string;
}

const MENUITEMS = [
  { imageclass: '', state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { imageclass: '', state: 'Roles', name: 'Roles', type: 'link', icon: 'crop_7_5' },
  { imageclass: '', state: 'Users', name: 'Users', type: 'link', icon: 'view_list' },
  { imageclass: '', state: 'Groups', name: 'Groups', type: 'link', icon: 'view_headline' },
  { imageclass: '', state: 'report', type: 'link', name: 'Reports', icon: 'tab' },
  { imageclass: '', state: 'Task', type: 'link', name: 'Tasks', icon: 'view_comfy' },
  //{ state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
  //{ state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
  //{ state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
  //{ state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
  //{
  //  state: 'expansion',
  //  type: 'link',
  //  name: 'Expansion Panel',
  //  icon: 'vertical_align_center'
  //},
  //{ state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
  //{ state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
  //{
  //  state: 'progress-snipper',
  //  type: 'link',
  //  name: 'Progress snipper',
  //  icon: 'border_horizontal'
  //},
  //{
  //  state: 'progress',
  //  type: 'link',
  //  name: 'Progress Bar',
  //  icon: 'blur_circular'
  //},
  //{
  //  state: 'dialog',
  //  type: 'link',
  //  name: 'Dialog',
  //  icon: 'assignment_turned_in'
  //},
  //{ state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
  //{ state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
  //{ state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
  //{
  //  state: 'slide-toggle',
  //  type: 'link',
  //  name: 'Slide Toggle',
  //  icon: 'all_inclusive'
  //}
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
