import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupEntitySelect } from '../../../entities/lookup-entity';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import * as Custom from '../../../Utility/CustomValidators.utilities'
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { Role } from '../role';
import { RoleService } from '../role.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  public sortedData = new MatTableDataSource<Role>([]);
  displayedColumns: string[] = ['actions', 'name', 'description', 'isActive', 'userCount', 'createdBy', 'Createdon', 'modifiedBy', 'modifiedOn'];
  public pageSize = 10;
  public sort!: Sort;
  public _roleList: Role[] = [];
  public hasRole_Edit: boolean = false;
  public hasRole_Delete: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }

  @Output() onEditRole = new EventEmitter<Role>();
  @Output() onDelete = new EventEmitter<Role>();
  @Input() set roleList(roles: Role[]) {
    console.log("roles: ", roles);
    this.sortedData.data = roles;
    this._roleList = roles;    
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  get roleList() {
   
    return this._roleList;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
    
  }

  constructor(
    private dialog: MatDialog, private roleService: RoleService, private auth: AuthService) {
    this.sortedData.data = this.roleList;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  sortAndFilterData() {
    let filteredData = this.roleList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;
    });




    if (!this.sort) {
      this.sortedData.data = filteredData;
      return;
    }
    else if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: Role, b: Role) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name':
          return Custom.compare(a.rolename.toLowerCase(), b.rolename.toLowerCase(), isAsc);
        case 'description':
          return Custom.compare(a.roledescription.toLowerCase(), b.roledescription.toLowerCase(), isAsc);
        case 'userCount':
          return Custom.compare(a.usercount, b.usercount, isAsc);
        case 'createdBy':
          return Custom.compare(a.createdby.toLowerCase(), b.createdby.toLowerCase(), isAsc);
        case 'createdon':
          return Custom.compare(a.createdon, b.createdon, isAsc);
        case 'modifiedBy':
          return Custom.compare(a.modifiedby.toLowerCase(), b.modifiedby.toLowerCase(), isAsc);
        case 'modifiedOn':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        case 'isActive':
          return Custom.compare(a.isactive, b.isactive, isAsc);
        default:
          return 0;
      }
    });
  }
  DateFormate(DateString: string, formateString: string) {
    return Custom.FormatDate(DateString, formateString);
  }
  doEditUser(role: Role) {
    this.onEditRole.emit(role);
  }
  async doDeleteUser(role: Role) {   
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: role.roleid,
    }]
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.roleService.deleteRole(request).then(res => {
        data.message = res[0]?.result;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();

  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    let items = this.getFiterData(item);
    this.menuFilter.menuData = { 'item': items };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.menuFilter.openMenu();
  }
  getFiterData(columnName: string) {
    let data = []
    if (columnName == "isemailverified" || columnName == 'isactive') {
      data = Custom.booleanLookups(this.filterData[columnName]);
    } else {

      data = this.roleList
        .map((item: any) => {
          return {
            "description": item[columnName],
            "code": item[columnName]?.toString(),
            active: true,
            selected: this.filterData[columnName] && (this.filterData[columnName] as any[]).findIndex(w => w.code == item[columnName]?.toString()) >= 0
          }
        }
        )
        .filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val.code == value.code) == index
          }
        );
    }
    return data as LookupEntitySelect[];
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    if (this.hasRole_Edit) {
      this.onShowDetails.emit(item);
    }
  }
  setPrivileges() {
    this.hasRole_Edit = this.auth.hasPrivilege(PrivilegeValues.RoleManage_Edit);
    this.hasRole_Delete = this.auth.hasPrivilege(PrivilegeValues.RoleManage_Delete);

    if (!this.hasRole_Edit && !this.hasRole_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }
}
