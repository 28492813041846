import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { Client } from '../clientinfo';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent {
  public _client!: Client;
  public selectedTab!: number;
  public hasClient_View: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }
  
  @Input() set client(client: Client) {
    this._client = client;
  }
  constructor(private auth: AuthService) { }
  get client() {
    return this._client;
  }

  @Output() onCancelEdit = new EventEmitter();
  //doCancelEdit(client: Client) {
  //  this.onCancelEdit.emit(client);
  //}
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(client: Client) {
    this.onUpdateEdit.emit(client);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(client: Client) {
    this.onShowLoading.emit(client);
  }
  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }
  setPrivileges() {
    this.hasClient_View = this.auth.hasPrivilege(PrivilegeValues.ClientManage_View);    
  }

}
