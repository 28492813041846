import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FullComponent } from './layouts/full/full.component';
import { RulesComponent } from './components/rules/rules.component';
import { FlowChartComponent } from './components/flow-chart/flow-chart.component';
import { ReportComponent } from './components/report/report.component';
import { GridComponent } from './components/grid/grid.component';
import { FormElimentComponent } from './components/form-eliment/form-eliment.component';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { UserAccountComponent } from './components/user/user-account/user-account.component';
import { UserComponent } from './components/user/user.component';
import { RoleManagmentComponent } from './components/role-managment/role-managment.component';
import { GroupManagmentComponent } from './components/group-managment/group-managment.component';
import { TaskListComponent } from './components/tasks/task-list/task-list.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { LoginComponent } from './components/login/login.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BotDashboardComponent } from './components/bot-dashboard/bot-dashboard.component';
import { ClientManagementComponent } from './components/client-management/client-management.component';

import { PasswordgeneratorComponent } from './components/passwordgenerator/passwordgenerator.component';
import { DrawFlowDemoComponent } from './components/draw-flow-demo/draw-flow-demo.component';

import { ChildRouteComponent } from './components/child-route/child-route.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { AuthGuardService } from './services/auth-guard.service';
import { PrivilegeValues } from './entities/Privilege';
import { WorkqueuesComponent } from './components/workqueues/workqueues.component';
import { PayrollComponent } from './components/payroll/payroll.component';

import { ActionMasterComponent } from './components/action-master/action-master.component';

import { RdlReportComponent } from './components/report/rdl-report/rdl-report.component';
import { NotificationComponent } from './components/notification/notification.component';

const AppRoutes: Routes = [

  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
        //canActivate: [AuthGuardService], 
        //data: {
        //  breadcrumb: 'dashboard',
        //  privileges: [PrivilegeValues.Task_View]
        //}
      },
      //{
      //  path: ':#code',
      //  component: LoginComponent
      //},
      //{
      //  path: '/:code',
      //  redirectTo: '/dashboard',
      //  pathMatch: 'full'
      //},
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        //canActivate: [AuthGuardService],
        data: {
          //breadcrumb: 'dashboard',
          privileges: [PrivilegeValues.Dashboard_View]
        }
      },
      {
        data: {
          privileges: [PrivilegeValues.Privilege_All]
        },
        path: 'Rules',
        component: RulesComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.Privilege_All]
        },
        path: 'FlowChart',
        component: FlowChartComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.Privilege_All]
        },
        path: 'Grid',
        component: GridComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.Privilege_All]
        },
        path: 'Form',
        component: FormElimentComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.UserManage_View]
        },
        path: 'Profile',
        component: UserProfileComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.UserManage_View]
        },
        path: 'Users',
        component: UserComponent
      }
      ,

      {
        data: {
          privileges: [PrivilegeValues.RoleManage_View]
        },
        path: 'Roles',
        component: RoleManagmentComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.GroupManage_View]
        },
        path: 'Groups',
        component: GroupManagmentComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.GroupManage_View]
        },
        path: 'groups',
        component: GroupManagmentComponent
      }
      ,
      {
        path: 'ResetPassword',
        component: ResetpasswordComponent
      }
      ,
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent
      },
      {
        path: 'Workqueues',
        data: {
          privileges: [PrivilegeValues.WorkqueueManage_View]
        },
        component: WorkqueuesComponent
      }
      , {
        path: 'AutomationDashboard',
        data: {
          scheduleType: 1
        },
        component: BotDashboardComponent
      }
      ,
      {
        path: 'schedule',
        data: {
          scheduleType: 2
        },
        component: BotDashboardComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.ActionMaster_View]
        },
        path: 'Actions',
        component: ActionMasterComponent
      },
      {
        path: 'realtimerefresh',
        data: {
          scheduleType: 3
        },
        component: BotDashboardComponent
      },
      {
        path: 'payroll',
        data: {
          scheduleType:4
        },
        component: BotDashboardComponent
      },
      {
        data: {
          privileges: [PrivilegeValues.ClientManage_View]
        },
        path: 'Client',
        component: ClientManagementComponent
      },
      {
        path: 'payroll',
        component: PayrollComponent
      },
      {
        path: 'accessdenied',
        component: AccessDeniedComponent
      },
      {
        path: 'notifications',
        component: NotificationComponent
      }
    ]
  },
  {
    data: {
      privileges: [PrivilegeValues.Task_View]
    },
    path: 'Task',
    component: FullComponent,
    children: [
      { path: '', component: TasksComponent },
      {
        path: ':id', component: TasksComponent,
      }
    ]
  },
  {
    path: 'report',
    component: FullComponent,
    children: [
      { path: '', component: ReportComponent },
      {
        path: 'groups/:id', component: ChildRouteComponent,
        children: [
          { path: '', component: ReportComponent },
          { path: 'reports/:id', component: ReportComponent }

        ]
      },
      {
        path: 'groups/:id/:reportid', component: ReportComponent,
      }
    ]
  },
  {
    path: 'rdlreport',
    component: FullComponent,
    children: [
      { path: '', component: RdlReportComponent },       
      {
        path: ':groupid/:reportid', component: RdlReportComponent,
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  }
  ,
  {
    path: 'code',
    component: LoginComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

