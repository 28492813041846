import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as custom from '../Utility/CustomValidators.utilities';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'action';
  constructor(private http: HttpClient) {

  }
  async getlookup(request: any): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/lookup`,
      custom.httpOptions()).toPromise();
    return result;
  } 
}
