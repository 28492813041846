<div class="form-group col-lg-6">
    <h3>
        Line Level Information
    </h3>
</div>
<mat-divider></mat-divider>
<div class="col-lg-12" fxLayout="column" fxLayoutGap=".5em">
    <div class="row">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table-remit-cas-code" style="width: 95%;margin: auto;margin-bottom: 10px;margin-top: 10px;" multiTemplateDataRows>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>                 
                <td mat-cell *matCellDef="let lineInfo">
                    <mat-icon> {{lineInfo.expanded?'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="cpt">
                <th mat-header-cell *matHeaderCellDef>Cpt </th>
                <td mat-cell *matCellDef="let lineInfo"> {{lineInfo.cpt}} </td>
            </ng-container>
            <ng-container matColumnDef="Dos">
                <th mat-header-cell *matHeaderCellDef> DOS </th>
                <td mat-cell *matCellDef="let lineInfo">
                    {{ lineInfo.dos }}
                </td>
            </ng-container>
            <ng-container matColumnDef="modifiers">
                <th mat-header-cell *matHeaderCellDef> Modifiers </th>
                <td mat-cell *matCellDef="let lineInfo">
                    {{ lineInfo.modifiers }}
                </td>
            </ng-container>
            <ng-container matColumnDef="units">
                <th mat-header-cell *matHeaderCellDef> Units </th>
                <td mat-cell *matCellDef="let lineInfo">
                    {{ lineInfo.units }}
                </td>
            </ng-container>
            <ng-container matColumnDef="Billed">
                <th mat-header-cell *matHeaderCellDef> Billed </th>
                <td mat-cell *matCellDef="let lineInfo" class="mat-column-number" [usd-money]="(!lineInfo.billed)?0:lineInfo.billed" [usd-money-cell]="true">
                </td>
            </ng-container>

            <ng-container matColumnDef="Paid">
                <th mat-header-cell *matHeaderCellDef> Paid </th>
                <td mat-cell *matCellDef="let lineInfo" class="mat-column-number" [usd-money]="(!lineInfo.paid)?0:lineInfo.paid" [usd-money-cell]="true">
                </td>
            </ng-container>

            <ng-container matColumnDef="Allowed">
                <th mat-header-cell *matHeaderCellDef> Allowed </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.allowed)?0:element.allowed" [usd-money-cell]="true">
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="lineInfoColumns.length">
                    <div [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                        <div class="row remit-payor-detail-row">
                            <div fxLayout="column" fxLayoutGap=".5em">
                                <div class="row">
                                    <app-remit-line-cas-code [LineData]="element.CAS_CODE_DETAILS"></app-remit-line-cas-code>
                                </div>

                            </div>
                        </div>
                    </div>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="lineInfoColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: lineInfoColumns;"
                class="example-element-row"
                [class.example-expanded-row]="row.expanded"
                (click)="onExpandRow(row)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" [ngClass]="{'display-row':  !row.expanded}"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="lineInfoColumns.length">No record(s) found.</td>
            </tr>
        </table>
    </div>
</div>
