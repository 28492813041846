import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit, AfterViewInit {
  public _username: string = '';
  public _userInitialclass: string = '';
  @Input() set username(username: string) { 
    if (username) {
      this._username = username;
      const name = username.toUpperCase().split(' ');
      this.Initialcolor = this.colorList[name[0].charCodeAt(0) - 65];
    }
  }
  get username() {
    return this._username;
  }
  get userInitials() {
    if (this._username) {
      return `${this._username[0].charAt(0)}`.toUpperCase();
    }
  }
  @Input() set userInitialclass(Initialclass: string) {
    this._userInitialclass = Initialclass;
  }
  @Input() set initialColor(colorCode: string) {
    this.Initialcolor = colorCode;
  }
  get userInitialclass() {

    return `rounded-circle-avatar ${this._userInitialclass}`;

  }
  @Input() public imgurl!: string;
  //@Input() public Initialcolor!: string;
  public Initialcolor: string;
  public colors: string[] = [

  ];
  colorList = [
    "#9F2B68",
    "#BF40BF",
    "#800020",
    "#702963",
    "#AA336A",
    "#301934",
    "#483248",
    "#5D3FD3",
    "#E6E6FA",
    "#CBC3E3",
    "#CF9FFF",
    "#AA98A9",
    "#E0B0FF",
    "#915F6D",
    "#770737",
    "#DA70D6",
    "#C3B1E1",
    "#CCCCFF",
    "#673147",
    "#A95C68",
    "#800080",
    "#51414F",
    "#953553",
    "#D8BFD8",
    "#630330",
    "#7F00FF",
    "#722F37",
    "#BDB5D5"
  ];

  constructor(

  ) {
    this.colorList = this.colorList.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    this.Initialcolor = ''
  }
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }
  //userInitials() {
  //  if (!this.username) return
  //  const name = this.username.toUpperCase().split(' ')
  //  //return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`.toUpperCase()

  //  return `${name[0].charAt(0)}`.toUpperCase()
  //}
  // Function to generate random number
  randomNumber() {
    let num: number = Math.random() * (this.colorList.length - 1) + 1;

    return parseInt(num.toString());
  }
}
