import { Component } from '@angular/core';
import { TaskService } from '../tasks/task.service';
import { WorkqueueService } from '../workqueue.service';

@Component({
  selector: 'app-workqueues',
  templateUrl: './workqueues.component.html',
  styleUrls: ['./workqueues.component.css']
})
export class WorkqueuesComponent {
  _isLoading: boolean = false;
  public workqueueList: any[] = [];
  public queues: any[] = [];
  public filtervalue: string = "";
  constructor(private workqueueService: WorkqueueService, private taskService: TaskService) {

    this.getQueues();

  }
  async getQueues() {
    this._isLoading = true;
    var request: any[] = [{ UniquePracticeIds: "" }];
    await this.taskService.getQueues(request).then(res => {

      this.queues = res.table1;
      this.getWorkqueues();
    });
  }
  getWorkqueues() {
    this._isLoading = true;
    this.workqueueService.getWorkqueues([]).then(res => {
      this.workqueueList = res.map((QueueLabel: any, indx: number) => {
        const s = this.queues.find((w: any) => { return w._id == QueueLabel.QueueName });

        QueueLabel.Order = parseFloat(QueueLabel.Order)
        QueueLabel.count = s?.count ?? 0
        return QueueLabel
      });
      console.log(this.workqueueList)
      this._isLoading = false;
    })
  }
  getWorkqueue() {
    this.workqueueService.getWorkqueue("66c754a12c2cd3b4a891f0c3").then(res => {

    })
  }
  setWorkqueues() {
    let request = {
      "Filter": {
        "$and": [
          {
            "encounternumber": "1111"
          },
          {
            "CPT.CPTCode": {
              "$in": [
                "1233"
              ]
            }
          }
        ]
      },
      "QueueName": "test",
      "Description": "dddd",
      "Active": "on",
      "Order": "1111",
      "Color": "#c43636",
      "Owner": "",
      "Responsible": "",
      "FilterJson": "filterJsonData",
      "id": ""
    }
    this.workqueueService.setWorkqueues(request).then(res => {
      console.log(res)
    })
  }
  addworkqueue() {
    var url = "https://templatestaging.pnestec.com/";
    if (window.location.host.includes('localhost')) {
      url = "https://localhost:3000/";
    }
    window.location.href = url;
  }
  onEditWorkqueue(item: any) {
    var url = "https://templatestaging.pnestec.com/";
    if (window.location.host.includes('localhost')) {
      url = "https://localhost:3000/";
    }
    window.location.href = url + item._id
  }

  applyFilter(item: any) {
    const filterValue = (item.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }
  onDelete(item: any) {
    this.getWorkqueues();
  }

  async exportData() {
    this._isLoading = true;
    this.workqueueService.getWorkqueuesExport(
      [{ columns: JSON.stringify([{ "title": "Color", "type": "color", "data": "Color" }, { "title": "Order", "type": "string", "data": "Order" }, { "title": "Queue Name", "type": "string", "data": "QueueName" }, { "title": "Description", "type": "string", "data": "Description" }, { "title": "isActive", "type": "bool", "data": "isActive" }, { "title": "count", "type": "int", "data": "count" }]) }]
    ).then(res => {
      this.taskService.downloadWorkqueuesfile(res.filename, this.aftredownloadReport);

      this._isLoading = false;
    });
  }
  aftredownloadReport() {
  }
}
