import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
//import { Role, RoleMenu } from './role';
const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class BotDashboardService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'automationdashboard';
  constructor(private http: HttpClient) { }

  public async getAutomationSummary(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }

  public async getAutomationEvents(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/events`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }

  public async getAutomationLogs(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/logs`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getAutomationDetails(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/details`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async setAutomationActions(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setaction`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async addUpdateInstance(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/addupdateinstance`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getServers(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/servers`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getAutomation(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/automations`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getAutomationSteps(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/automationsteps`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getEmailProfile(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/emailprofile`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async setScheduleLog(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setschedulelog`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getScheduleLog(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getschedulelog`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

}
