<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-header>
            <mat-card-title>My Tasks</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <!-- style="height:73vh;overflow:auto" -->
            <section class="table-container" tabindex="0">
                <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">


                    <!-- Position title -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Title </th>
                        <td mat-cell *matCellDef="let element">
                            <h5 class="task-title">{{formateString(element.title) }} {{element.title.length > charLimit ? '...' : ''}}</h5>
                            <div class="task-desc">{{formateString(element.description) }} {{element.description.length > charLimit ? '...' : ''}}</div>
                        </td>
                    </ng-container>

                    <!-- Name Priority -->
                    <ng-container matColumnDef="Priority">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Priority </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="badge priority" [ngClass]="{'text-danger': element.priorityId==1,'text-warning': element.priorityId==2,'text-primary': element.priorityId==3}">{{element.priority.name}}</span>
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="dueDate">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Due Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.dueDate}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="reportedOn">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Reported On </th>
                        <td mat-cell *matCellDef="let element"> {{ DateFormate(element.reportedOn, "MM/dd/yyyy hh:mm:ss tt")}} </td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="reportedByNavigation">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Reported By </th>
                        <td mat-cell *matCellDef="let element">  {{ getUserName(element.reportedByNavigation) }}</td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="taskCollabrators">
                        <th mat-header-cell *matHeaderCellDef> Collabrators </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngFor="let collabrator of element.taskCollabrators">
                                <app-avatar [username]="getUserName(collabrator.collabrator)"></app-avatar>
                            </div>
                        </td>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </section>
        </mat-card-content>
        <mat-card-actions>
            <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
            </mat-paginator>
        </mat-card-actions>
    </mat-card>


</div>
