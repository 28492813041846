<section class="edit-container-popup">
<mat-card-title>
  <span>Request: Adhoc Run</span>
</mat-card-title>
<mat-divider></mat-divider>

<form [formGroup]="formAdd" class="popup-form">
  <div fxLayout="column" fxLayoutGap="1em">
    <div class="row m-0">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <small class="form-text text-muted pb-2" style="padding-bottom: 10px;">When should you adhoc-run?: <span class="required-fields">*</span></small>
        <div class="row m-0">
          <div class="col-lg-8 col-md-8 col-sm-8 mx-auto position-relative pl-0">
            <input name="startdate"
                   [type]="'text'"
                   class="form-control"
                   placeholder="mm/dd/yyyy"
                   (click)="startdatepicker.open()"
                   formControlName="startDate"
                   [matDatepicker]="startdatepicker"
                   [min]="minStartDate"
                   (blur)="onBlurDate($event)">
            <div class="position-absolute" style="right: 18px;top: 8px;">
              <mat-icon matSuffix class="form-input-date-icon" (click)="startdatepicker.open()">calendar_today</mat-icon>
            </div>
            <mat-datepicker class="form-group-text" style="float:right;" #startdatepicker></mat-datepicker>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 mx-auto position-relative pr-0">
            <input type="time" class="form-control p-8" id="startTime" formControlName="startTime" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<mat-divider></mat-divider>
<div class="button-group">
    <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting || !canSave()" (click)="onAction()"><span>Do It</span></button>
    <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button>
    <button mat-flat-button extended class="btn" (click)="doCancelEdit(false)">Cancel</button>
</div>
</section>

<app-data-loader [isLoading]="isLoading"></app-data-loader>
