<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar class="topbar telative" *ngIf="isLoggedIn">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <span fxShow="false" fxShow.gt-xs>
          <img [src]="themeService.isDarkMode()? 'assets/images/Logo-dark.png':'assets/images/logo-white.png'" width="180" id="topLogoImage" />
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed" *ngIf="false">
      <mat-icon>menu</mat-icon>
    </button>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
  </mat-toolbar>
  <mat-toolbar class="left-sidebar with-horizontal" *ngIf="isLoggedIn">
      <mat-nav-list>
          <!--https://material.angular.io/components/list/overview-->
          <mat-list-item class="mat-varticle-list mat-manu-route" *ngFor="let menuitem of getMenu()" [ngClass]="{'route-active':router.url== menuitem.linkurl || hasActiveRoute(menuitem.menuid)}">
              <a [routerLink]="[menuitem.linkurl]" *ngIf="menuitem.linkurl!=''">
                  <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
                  <span>{{ menuitem.menuname }}</span>

              </a>
              <a *ngIf="menuitem.linkurl==''" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: menuitem}">
                  <span>{{ menuitem.menuname }}</span>
                  <mat-icon style="padding-top: 7px;">expand_more</mat-icon>
              </a>
          </mat-list-item>
          <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-item="item">
                  <mat-list-item class="mat-manu-route mat-sub-list" *ngFor="let menuitem of getMenuitem(item.menuid)" [ngClass]="{'route-active':router.url== menuitem.linkurl}">
                      <a [routerLink]="[menuitem.linkurl]" *ngIf="menuitem.linkurl!=''">
                          <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
                          <span>{{ menuitem.menuname }}</span>

                      </a>
                      <a *ngIf="menuitem.linkurl==''" mat-menu-item [matMenuTriggerFor]="subMenu" [matMenuTriggerData]="{item: menuitem}">
                          <span>{{ menuitem.menuname }}</span>                           
                      </a>
                  </mat-list-item>
              </ng-template>
          </mat-menu>
          <mat-menu #subMenu="matMenu">
              <ng-template matMenuContent let-item="item">
                  <mat-list-item class="mat-manu-route mat-sub-list" *ngFor="let menuitem of getMenuitem(item.menuid)" [ngClass]="{'route-active':router.url== menuitem.linkurl}" [routerLink]="[menuitem.linkurl]">
                      <span>
                          <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
                          <span>{{ menuitem.menuname }}</span>

                      </span>
                  </mat-list-item>
              </ng-template>
          </mat-menu>

      </mat-nav-list>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" *ngIf="false" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">

      <app-sidebar></app-sidebar>


    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" *ngIf="isLoggedIn">

      <div class="page-content">

        <router-outlet></router-outlet>

      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
<style>

  .page-content {
    padding: 0px 0px 0px 0px !important;
    margin: 0 auto;
    padding-left: 0px;
    min-height: calc(90vh - 50px) !important;
    max-height: calc(90vh - 50px) !important;
    max-width: 100% !important;
    background-color: white;
  }
</style>
