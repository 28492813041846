import { Component, ElementRef, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { ReportService } from '../../services/report.service';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { UserService } from '../user/user.service';
import { ThemeService } from '../../services/theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { report } from 'process';
import { filter } from 'rxjs';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import * as moment from 'moment';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
  public MINUTES_BEFORE_EXPIRATION = 10;

  // Set the refresh interval time to 30 seconds
  public INTERVAL_TIME = 30000;

  // Get the token expiration from the access token
  public tokenExpiration: any;





  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  // Track Report embedding status
  isEmbedded = true;
  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';

  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false
    },
    theme: {
      themeJson: {}
    }
  };
  _isLoading: boolean = true;
  showFilter: boolean = false;
  lookupData: any = {};
  filterData: any[] = [];
  selectorData: any = {};
  serviceLocationData: any = {};

  filters: any = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
  reportSetting: any = {};
  public reportSettingTimeer: any;
  public isDarkMode: boolean = false;
  public isApiStatus: boolean = false;
  public groupId: string = '';
  public reportId: string = '';
  public fileStatus: string = '';
  public reportUniqueId: string = '';
  constructor(public httpService: ReportService,
    private userService: UserService,
    private reportService: ReportService,
    private themeService: ThemeService,
    private clientService: ClientService,
    route: ActivatedRoute,
    router: Router,
  ) {
    this.getLookupData();
    router.events.subscribe((val: any) => {
      //this.groupId = route.snapshot.params["id"];
      if (val.snapshot && val.snapshot.params["id"]) {
        this.isEmbedded = false;
        this._isLoading = true;
        this.reportId = val.snapshot.params["reportid"];
        this.groupId = val.snapshot.params["id"];
        this.filters = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
        this.embedReport();
      }
    })
    this.groupId = route.snapshot.params["id"];
    this.reportId = route.snapshot.params["reportid"];
    this.isDarkMode != this.themeService.isDarkMode();
    this.reportSettingTimeer = setInterval(() => {
      if (this.isDarkMode != this.themeService.isDarkMode()) {
        this.isDarkMode = this.themeService.isDarkMode();
        this.UpdateReportTheme();
      }

    }, 100);
    this.setUserToken();
    setInterval(() => this.checkTokenAndUpdate(), this.INTERVAL_TIME);

  }
  setUserToken() {
    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting)
      this.embedReport();
    });
  }
  UpdateReportTheme() {
    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting);
      const report = this.reportObj.getReport();
      if (report) {
        report.resetTheme();
        report.setAccessToken(this.httpService.accessToken);
        report.applyTheme({ themeJson: this.reportSetting });
        //report.reload();

      }
    });
  }

  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(): Promise<void> {

    let reportResponse = await this.httpService.getEmbedURL(this.groupId, this.reportId);
    this.reportConfig = {
      ...this.reportConfig,
      id: reportResponse.id,
      embedUrl: reportResponse.embedUrl,
      accessToken: this.httpService.accessToken,
      theme: { themeJson: this.reportSetting },
      permissions: (this.groupId == 'd147ea95-fa4d-489e-99f2-9a8b0cea6c46') ? models.Permissions.Copy : models.Permissions.Read,
      viewMode: (this.groupId == 'd147ea95-fa4d-489e-99f2-9a8b0cea6c46') ? models.ViewMode.Edit : models.ViewMode.View
      //, filters: filter
    };
    // Update the reportConfig to embed the PowerBI report

    this.isEmbedded = true;
    this._isLoading = false;
    if (this.reportObj) {
      const report = this.reportObj.getReport();
      report.on('loaded', event => {
        this._isLoading = false;
        if (this.reportId == "bbc66a7a-4762-4755-a262-2ce140a38114" || this.reportId == "6fb3a239-a861-4786-9984-ce42a4d785b3")
          this.doFilter(null)
      })
    }
    else {
      this._isLoading = false;
    }


  }

  checkTokenAndUpdate() {
    // Get the current time
    const currentTime = Date.now();
    const expiration = Date.parse(this.tokenExpiration);

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    // Update the token if it is about to expired
    if (timeUntilExpiration <= timeToUpdate) {
      console.log("Updating report access token");
      this.UpdateReportTheme();
    }
  }

  async printReport() {
    this._isLoading = true;


    let filters = ''
    const report = this.reportObj.getReport();
    const report_filter = await report.getFilters();
    console.log(report_filter);

    report_filter.forEach((w: any) => {
      let target: any = w.target
      switch (w.filterType) {
        case models.FilterType.RelativeDate:
          {
            filters = filters + this.getDateFilterRange(w as models.IRelativeDateFilter);
            break;
          }
        case models.FilterType.Basic:
          {
            filters = filters + `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} ${w.operator.toLocaleLowerCase()} ('${w.values.join("','")}') and `;
            break;
          }
        case models.FilterType.Advanced:
          {
            filters = filters + `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')}${w.conditions.map((a: any) => { return ' ' + ((a.operator == "LessThanOrEqual") ? 'le' : 'ge') + ' datetime' + `'${moment(a.value).format("YYYY-MM-DD")}T00:00:00'` }).join(' ' + w.logicalOperator.toLocaleLowerCase() + " " + target.table.replaceAll(' ', '_x0020_') + '/' + target.column.replaceAll(' ', '_x0020_'))} and `;
            break;
          }
      }
    });


    /*
    Object.keys(this.filters).map((w, idx) => {
      switch (w) {
        case 'practiceIds':
          {
            if ((this.filters?.practiceIds?.length ?? 0) > 0)
              filters = filters + `Practice_x0020_Details/uniquepracticeid in ('${this.filters.practiceIds.map((w: any) => w.code).join("','")}') and `
            break;
          }
        case 'transactionstartDate':
          {
            if (this.filters.transactionstartDate != '')
              filters = filters + `Transaction_x0020_Date/Transaction_x0020_date ge datetime'${this.filters.transactionstartDate}T00:00:00' and Transaction_x0020_Date/Transaction_x0020_date le datetime'${this.filters.transactionendDate}T00:00:00' and `//
            break;
          }
        case 'calenderStartDate':
          {
            if (this.filters.calenderStartDate != '')
              filters = filters + `Calendar/Date ge datetime'${this.filters.calenderStartDate}T00:00:00' and Calendar/Date le datetime'${this.filters.calenderEndDate}T00:00:00' and `
            break;
          }
        case 'checkstartDate':
          {
            if (this.filters.checkstartDate != '')
              filters = filters + `Check_x0020_Date/Date ge datetime'${this.filters.checkstartDate}T00:00:00' and Check_x0020_Date/Date le datetime'${this.filters.checkendDate}T00:00:00' and `
            break;
          }
        case 'serviceLocations':
          {
            if ((this.filters?.serviceLocations?.length ?? 0) > 0)
              filters = filters + `Financial_x0020_Details/Warehouse_x0020_Service_x0020_Location_x0020_ID in ('${this.filters.serviceLocations.map((w: any) => w.code)}') and `
            break;
          }
        case 'selectors':
          {
            if ((this.filters?.selectors?.length ?? 0) > 0)
              filters = filters + `Selector/Selector eq '${this.filters.selectors.map((w: any) => w.code)}' and `
            break;
          }
      }



    });
    */
    let request = {
      format: 'PDF',
      powerBIReportConfiguration: filters != '' ? {
        reportLevelFilters: [{
          filter: filters.split(' and ').filter(w => { return w != '' }).join(' and ')
        }]
      } : undefined,
      settings: {
        includeHiddenPages: true
      },
      paginatedReportConfiguration: {
        formatSettings: {
          AccessiblePDF: true,
          PageHeight: "11in",
          PageWidth: "8.5in",
          MarginBottom: "2in"
          //"StartPage": "1",
          //"EndPage": "4"
        }
      },
    }
    this.reportService.printReport(request, this.reportId).then(res => {
      console.log(res[0]);
      let exportId = res.id;
      console.log(exportId);
      this.checkReportStatus(request, exportId);
    }).catch(error => { console.log(error) });

  }
  checkReportStatus(request: any, exportId: string) {
    this.reportService.checkReportStatus(request, this.reportId, exportId).then(async resStaus => {
      this.fileStatus = await resStaus.status;
      if (this.fileStatus != "Succeeded") {
        setTimeout((s: any) => {
          this.checkReportStatus(request, exportId);
        }, 5000)

      }
      else {
        this.downloadReport(request, exportId, resStaus.reportName + resStaus.resourceFileExtension);
      }

    });
  }
  downloadReport(request: any, exportId: string, filename: string) {
    this._isLoading = false;
    this.reportService.downloadReport(request, this.reportId, exportId, filename, this.aftredownloadReport);
  }
  aftredownloadReport() {

  }
  async getLookupData() {
    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    this._isLoading = true;
    const locationRequest = [{
      clientid: '',
      uniqueservicelocationid: -1,
      pagesize: 100,
      pagestartrecord: 0,
    }]
    const providerRequest = [{
      clientid: '',
      uniqueservicingproviderid: -1,
      pagesize: 100,
      pagestartrecord: 0,
    }]
    const lookups = await Promise.all([
      this.clientService.getlookup([]),
      this.clientService.getreportfilter(request),
      this.clientService.getselectors([]),
      this.clientService.getServiceLocations(locationRequest),
      this.clientService.getServicingProviders(providerRequest),
      this.clientService.GetPrimaryPayorGroups([{
        clientid: '-1', uniqueprimarypayorgroupid: -1,
        pagesize: 100, pagestartrecord: 0,
      }]),
      this.clientService.GetPrimaryPayors([{
        clientid: '-1', uniqueprimarypayorid: -1,
        pagesize: 100, pagestartrecord: 0,
      }]),
    ]);

    this.filterData = lookups[1];
    this.lookupData = {
      clients: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
      selector: lookups[2],
      serviceLocation: lookups[3]?.filter((s: any) => { return s.servicelocationname })?.map((s: any) => { return { code: s.uniqueservicelocationid, description: s.servicelocationname, active: true, clientid: s.clientid } }),
      serviceProvider: lookups[4]?.filter((s: any) => { return s.servicingprovidername })?.map((s: any) => { return { code: s.uniqueservicingproviderid, description: s.servicingprovidername, active: true, clientid: s.clientid } }),
      payorGroups: lookups[5]?.filter((s: any) => { return s.primarypayorgroupname })?.map((s: any) => { return { code: s.uniqueprimarypayorgroupid, description: s.primarypayorgroupname, active: true, clientid: s.clientid } }),
      payors: lookups[6]?.filter((s: any) => { return s.primarypayorname })?.map((s: any) => { return { code: s.uniqueprimarypayorid, description: s.primarypayorname, active: true, clientid: s.clientid } }),
      relativeDateOperators: [{ code: '0', description: 'Last', active: 1 }, { code: '1', description: 'This', active: 1 }],
      dateFilterTimeUnits: this.getDateFilterTimeUnit()
    }

  }



  doFilter(items: any) {
    if (items) {
      this.filters = items;
    }

    const report = this.reportObj.getReport();
    if (report) {
      let reportfilter = [];



      if (this.filters.practiceIds.length > 0) {
        reportfilter.push({
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            column: "uniquepracticeid",
            table: "Practice Details",
          },
          operator: "In",
          values: items.practiceIds.map((w: any) => w.code),
          filterType: models.FilterType.Basic,
          requireSingleSelection: true
        });
      }
      if ((this.filters.serviceLocations?.length ?? 0) > 0) {
        reportfilter.push({
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            column: "unique_servicelocationid",
            table: "Financial Details",
          },
          operator: "In",
          values: items.serviceLocations.map((w: any) => w.code),
          filterType: models.FilterType.Basic,
          requireSingleSelection: true
        });
      }
      if ((this.filters.serviceProviderIds?.length ?? 0) > 0) {
        reportfilter.push({
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            column: "uniqueservicingproviderid",
            table: "Financial Details",
          },
          operator: "In",
          values: items.serviceProviderIds.map((w: any) => w.code),
          filterType: models.FilterType.Basic,
          requireSingleSelection: true
        });
      }

      if ((this.filters.selectors?.length ?? 0) > 0) {
        reportfilter.push({
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            column: "Selector",
            table: "Selector",
          },
          operator: "In",
          values: this.filters.selectors.map((w: any) => w.code),
          filterType: models.FilterType.Basic,
          requireSingleSelection: true
        });
      }
      if ((this.filters.payorsIds?.length ?? 0) > 0) {
        reportfilter.push({
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            column: "uniqueprimarypayorid",
            table: "Primary Payor Group",

          },
          operator: "In",
          values: this.filters.payorsIds.map((w: any) => w.code),
          filterType: models.FilterType.Basic,
          requireSingleSelection: true
        });
      }
      if (this.filters.calenderStartDate && this.filters.calenderStartDate != '' && this.filters.calenderEndDate != '') {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#advanced",
          target: {
            table: "Calendar",
            column: "Date"
          },
          logicalOperator: "And",
          conditions: [
            {
              operator: "GreaterThanOrEqual",
              value: this.filters.calenderStartDate + 'T00:00:00'
            },
            {
              operator: "LessThanOrEqual",
              value: this.filters.calenderEndDate + 'T00:00:00'
            }
          ],
          filterType: models.FilterType.Advanced
        });
      }
      if (this.filters.checkstartDate && this.filters.checkstartDate != '' && this.filters.checkendDate != '') {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#advanced",
          target: {
            table: "Check Date",
            column: "Date"
          },
          logicalOperator: "And",
          conditions: [
            {
              operator: "GreaterThanOrEqual",
              value: this.filters.checkstartDate + 'T00:00:00'
            },
            {
              operator: "LessThanOrEqual",
              value: this.filters.checkendDate + 'T00:00:00'
            }
          ],
          filterType: models.FilterType.Advanced
        });
      }

      if (this.filters.transactionstartDate && this.filters.transactionstartDate != '' && this.filters.transactionendDate != '') {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#advanced",
          target: {
            table: "Transaction Date",
            column: "Transaction date"
          },
          logicalOperator: "And",
          conditions: [
            {
              operator: "GreaterThanOrEqual",
              value: this.filters.transactionstartDate + 'T00:00:00'
            },
            {
              operator: "LessThanOrEqual",
              value: this.filters.transactionendDate + 'T00:00:00'
            }
          ],
          filterType: models.FilterType.Advanced
        });
      }
      if (this.filters.transactionDate && this.filters.transactionDate != '') {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#advanced",
          target: {
            table: "Transaction Date",
            column: "Transaction date"
          },
          logicalOperator: "Or",
          conditions: [
            {
              operator: "LessThanOrEqual",
              value: this.filters.transactionDate + 'T00:00:00'
            }
          ],
          filterType: models.FilterType.Advanced
        });
      }
      if (this.filters.transactionDates && this.filters.transactionDates?.operator.length > 0) {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#relativeDate",
          target: {
            table: "Transaction Date",
            column: "Transaction date"
          },
          operator: parseInt(this.filters.transactionDates.operator[0].code),
          timeUnitsCount: this.filters.transactionDates.value,
          timeUnitType: parseInt(this.filters.transactionDates.timeUnit[0].code),
          includeToday: true,
          filterType: models.FilterType.RelativeDate
        });
      }
      if (this.filters.checkDate && this.filters.checkDate?.operator.length > 0) {
        reportfilter.push({
          $schema: "https://powerbi.com/product/schema#relativeDate",
          target: {
            table: "Check Date",
            column: "Date"
          },
          operator: parseInt(this.filters.checkDate.operator[0].code),
          timeUnitsCount: this.filters.checkDate.value,
          timeUnitType: parseInt(this.filters.checkDate.timeUnit[0].code),
          includeToday: true,
          filterType: models.FilterType.RelativeDate
        });
      }


      this.showFilter = false;
      console.log(reportfilter);
      report.setFilters(reportfilter);
    }
  }
  selectorFilterData() {
    let data: any[] = [
      { code: 'DOA', description: 'DOA', active: 1 },
      { code: 'DOS', description: 'DOS', active: 1 },
      { code: 'DOE', description: 'DOE', active: 1 },
      { code: 'Discharge Date', description: 'Discharge Date', active: 1 },
      { code: 'Transaction Date', description: 'Transaction Date', active: 1 }
    ];
    return data;
  }
  getDateFilterTimeUnit() {
    let data: any[] = [
      { code: '0', description: 'Days', active: 1 },
      { code: '1', description: 'Weeks', active: 1 },
      { code: '2', description: 'Calendar Weeks', active: 1 },
      { code: '3', description: 'Months', active: 1 },
      { code: '4', description: 'Calendar Months', active: 1 },
      { code: '5', description: 'Years', active: 1 },
      { code: '6', description: 'Calendar Years', active: 1 },
      //{ code: '7', description: 'Minutes', active: 1 },
      //{ code: '8', description: 'Hours ', active: 1 },
    ];
    return data;
  }
  getDateFilterRange(w: models.IRelativeDateFilter) {
    let target: any = w.target
    if (w.operator == 1) w.timeUnitsCount = 1;
    let endDate: string = '';
    let startDate: string = '';

    switch (w.timeUnitType) {
      case models.RelativeDateFilterTimeUnit.Days:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'd').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Weeks:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'w').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Months:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'M').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.Years:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'y').format("YYYY-MM-DD");
          endDate = moment().format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarWeeks:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'w').startOf('week').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'w').endOf('week').format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarMonths:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'M').startOf('month').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'M').endOf('month').format("YYYY-MM-DD");
          break;
        }
      case models.RelativeDateFilterTimeUnit.CalendarYears:
        {
          startDate = moment().subtract(w.timeUnitsCount, 'y').startOf('year').format("YYYY-MM-DD");
          endDate = moment().subtract(w.timeUnitsCount, 'y').endOf('year').format("YYYY-MM-DD");
          break;
        }
    }
    return `${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} ge datetime'${startDate}T00:00:00' and ${target.table.replaceAll(' ', '_x0020_')}/${target.column.replaceAll(' ', '_x0020_')} le datetime'${endDate}T00:00:00' and `

  }

  displayFilters() {
    if (this.filterData)
      return this.filterData?.findIndex((w: any) => { return w.reportId == this.reportId }) > -1;

    return true;

  }
}
