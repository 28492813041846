import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LookupEntityBase } from '../../entities/lookup-entity';
import { PrivilegeValues } from '../../entities/Privilege';
import { ActionService } from '../../services/action.service';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { UserService } from '../user/user.service';
import { QueueLabel } from './task';
import { TaskActionDialogComponent } from './task-action-dialog/task-action-dialog.component';
import { TaskService } from './task.service';
import * as custom from '../../Utility/CustomValidators.utilities';
import { TaskListComponent } from './task-list/task-list.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent {
  public taskList: any[] = [];
  public tasks: any[] = [];
  public task!: any;
  public filters: any = {
    practiceIds: [], owners: [], actions: [],
    otherfilters: [],
    freezed: "0"
  };
  public Bedcrum: string[] = ["Home", "Task", "Queues", " Unassigned"]
  public showFilter = false;
  public showDetails = false;
  public users: LookupEntityBase[] = [];
  public lookupData: any = {};
  public actions: LookupEntityBase[] = [];
  public sideDrawerWidth: any = '60px';
  public queueDrawerWidth: any = '50px';
  public queueDrawerClass!: string;
  public queueFilter: QueueLabel | undefined = undefined;
  public clientLookup!: LookupEntityBase[];
  @ViewChild('drawer1') drawer1!: any;
  @ViewChild('taskListView') taskListView!: TaskListComponent;
  public queueLabel: QueueLabel[] = [];
  public _isLoading: boolean = true;
  public showInfo: boolean = false;
  public expanded: boolean = false;
  public totalCount: number = 0;
  public Tags: any[] = [];
  public taskId: string = "";
  public hasTask_View: boolean = false;
  public hasAction_Add: boolean = false;
  public pageData: any = {
    "previousPageIndex": -1,
    "pageIndex": 0,
    "pageSize": 50,
    "length": 0
  }
  public sort: any = {
    active: "encounterid",
    direction: "asc",
  }
  claimStatusList: string[] = ['Active', 'Pending', 'Denial', 'Completed'];
  constructor(private taskService: TaskService,
    private clientService: ClientService, private dialog: MatDialog,
    private userService: UserService,
    private actionService: ActionService,
    private auth: AuthService,
    route: ActivatedRoute,
    private router: Router) {
    this.taskId = route.snapshot.params["id"];
    this.loadDetails();
  }
  async loadDetails() {
    this.setPrivileges();
    if (this.taskId) {
      await this.onShowDetails({ _id: this.taskId })
    }
    await this.getLookupData();
    await this.getTagsLookup();

    this.getQueues();
    this.getTags();
    this.loadTasks();

    this.tasks = [];

  }
  async onShowDetails(item: any) {
    await this.getLookupData();
    this.lookupData.myTags = this.lookupData.tags.find((w: any) => w._id == this.auth.currentUser?.username)?.data?.filter((w: any) => { return !w.isSystemTag }).map((w: any) => { return { item_text: w._id } });
    item = await this.getTask(item);
    this.task = item;
    this.showInfo = true;
  }
  onTaskSelection(items: any[]) {
    this.tasks = items;
  }

  onCancelEdit(item: any) {
    //this.drawer1.toggle();    
  }
  onCancelInforamtion(item: any) {
    if (this.taskId) {
      this.router.navigate(["/Task"])
    }
    this.task = null;
    this.showInfo = false;
  }
  async onRefreshInforamtion(item: any) {
    item = await this.getTask(item);
    this.task = item;
    this.loadDetails();
  }

  onExpandQueueSection(item: string[]) {
    this.queueDrawerWidth = item[0];
    this.queueDrawerClass = item[1];
    this.expanded = item[2] == 'true';
  }
  getQueues() {
    let otherfilters = this.filters.otherfilters.map((w: any) => {
      return {
        searchOn: w.searchOn.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        operator: w.operator.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        values: w.values
      }
    })
    var request = [{
      UniquePracticeIds: this.filters.practiceIds.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentOwners: this.filters.owners.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentActions: this.filters.actions.map((w: any) => { return w.description }).filter((w: any) => w).join(','),
      otherfilters: JSON.stringify(otherfilters),
      freezed: this.filters.freezed
    }];
    this.taskService.getQueues(request).then(res => {
      this.lookupData.queues = this.lookupData.queues.map((QueueLabel: any, indx: number) => {
        const s = res.table1.find((w: any) => { return w._id == QueueLabel.queueName })
        if (!QueueLabel.order)
          QueueLabel.order = 1000 + indx;
        QueueLabel.count = s?.count ?? 0
        return QueueLabel
      });
      if (this.queueFilter?.type == "queue") {
        let filter = this.lookupData.queues.find((w: any) => { return w._id == this.queueFilter?._id });
        //filter.time = new Date();
        //this.queueFilter = filter;
        this.totalCount = filter.count ?? 0;
      }
    });
  }
  setPrivileges() {
    this.hasTask_View = this.auth.hasPrivilege(PrivilegeValues.Task_View);
    this.hasAction_Add = this.auth.hasPrivilege(PrivilegeValues.Task_Action_Add)
      || this.auth.hasPrivilege(PrivilegeValues.Task_Notes_Add);
  }
  loadTasks() {
    let otherfilters = this.filters.otherfilters.map((w: any) => {
      return {
        searchOn: w.searchOn.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        operator: w.operator.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        values: w.values
      }
    })
    let request: any[] = [{
      CurrentQueueName: (this.queueFilter?.type == 'queue') ? this.queueFilter?._id ?? '' : '',
      CurrentTagName: (this.queueFilter?.type == 'tages' || this.queueFilter?.type == 'teamTages') ? this.queueFilter?._id ?? '' : '',
      UniquePracticeIds: this.filters.practiceIds.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentOwners: this.filters.owners.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentActions: this.filters.actions.map((w: any) => { return w.description }).filter((w: any) => w).join(','),
      sortby: this.sort.active,
      isAsc: this.sort.direction === 'asc',
      pagesize: this.pageData.pageSize,
      pagestartrecord: (this.pageData.pageSize * this.pageData.pageIndex),
      currentUsername: this.queueFilter?.username ?? '',
      otherfilters: JSON.stringify(otherfilters),
      freezed: this.filters.freezed
    }];


    this.taskService.getWorkqueues(request).then(res => {

      this._isLoading = false;
      this.taskList = res.map((w: any, indx: any) => {
        w.practice = this.clientLookup?.find(s => s.code == w.UniquePracticeID)?.description;
        return w;
      });

      //this.task = this.taskList.find(w => w.encounternumber == "3960903");

    });


  }
  onFilter(item: QueueLabel) {
    this.pageData = {
      "previousPageIndex": -1,
      "pageIndex": 0,
      "pageSize": 50,
      "length": 0
    }
    this.queueFilter = item;
    this.totalCount = this.queueFilter.count ?? 0;
    if (this.queueFilter?.type == "queue")
      this.Bedcrum = ["Home", "Task", "Queues", this.queueFilter?._id ?? ""]
    else if (this.queueFilter?.type == "tages") {
      this.Bedcrum = ["Home", "Task", "Labels", this.queueFilter?._id ?? ""]
    }
    else if (this.queueFilter?.type == "teamTages") {
      this.Bedcrum = ["Home", "Task", "Team Labels", this.queueFilter?.username, this.queueFilter?._id ?? ""]
    }

    this.loadTasks();
  }
  randomDate() {
    const start = new Date(2024, 4, 1);
    const end = new Date()
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  addDays(date: Date, days: number) {
    return date.setDate(date.getDate() + days);;
  }
  async onActionClicked() {
    //await this.getLookupData();
    if (this.tasks.length == 1) {
      const task = await this.getTask(this.tasks[0]);
      this.tasks = [task];
    }
    const dlgRefAction = this.dialog.open(TaskActionDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          tasks: this.tasks,
          users: this.users,
          actions: this.actions,
          tags: this.Tags.find(w => w._id == this.auth.currentUser?.username)?.data?.filter((w: any) => { return !w.isSystemTag }).map((w: any) => { return { item_text: w._id } })
        },
        panelClass: 'confirm-dialog-container',
        width: '60%',
        /*height: '500px',*/
      });
    var Action_result = await dlgRefAction.afterClosed().toPromise();
    if (Action_result) {
      this.tasks = [];
      if (this.taskListView)
        this.taskListView.selectAll = false;
      this.loadDetails();
    }
  }
  async getLookupData() {
    debugger;
    if (this.users.length == 0) {
      const request = [{
        id: -1,
        statusid: -1,
        pagesize: 500,
        pagestartrecord: 0
      }]
      this._isLoading = true;
      const lookups = await Promise.all([
        this.userService.getLookup(request),
        this.actionService.getlookup(request),
        this.clientService.getlookup([]),
        //this.taskService.getTagsLookup(request),
        this.taskService.getQueueLookup(request)
      ]);
      this.users = lookups[0].map((s: any) => {
        let w = {} as LookupEntityBase;
        w.code = s.username;
        w.description = s.name ?? '';
        w.active = s.isactive == 1;
        w.value = s.userid;
        return w;
      });
      this.actions = lookups[1].map((s: any) => {
        let w = {} as LookupEntityBase;
        w.code = s.actionid;
        w.description = s.actionname.trim();
        return w;
      });
      this.clientLookup = lookups[2]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } });
      this._isLoading = false;
      if (lookups[3].findIndex((w: any) => { return w.queueName == "Unassigned" }) < 0) {
        lookups[3].push({
          "queueName": "Unassigned",
          "_id": "Unassigned",
          "order": -1000,
          "color": "#770737",
          "type": "queue"
        })
      }



      this.lookupData = {
        users: this.users,
        actions: this.actions,
        clients: this.clientLookup,
        tags: [],
        queues: lookups[3]
      }
      if (lookups[3].length > 0) {
        this.queueFilter = lookups[3][0];
        this.Bedcrum = ["Home", "Task", "Queues", this.queueFilter?._id ?? ""]
      }

    }
  }
  doCancelFilter() {
    this.showFilter = false;
  }
  doFilter(items: any) {
    this.filters = items;
    this.pageData = {
      "previousPageIndex": -1,
      "pageIndex": 0,
      "pageSize": 50,
      "length": 0
    }
    this.loadDetails();
    this.showFilter = false;
  }
  getTags() {
    let otherfilters = this.filters.otherfilters.map((w: any) => {
      return {
        searchOn: w.searchOn.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        operator: w.operator.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        values: w.values
      }
    })
    var request = [{
      UniquePracticeIds: this.filters.practiceIds.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentOwners: this.filters.owners.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentActions: this.filters.actions.map((w: any) => { return w.description }).filter((w: any) => w).join(','),
      otherfilters: JSON.stringify(otherfilters),
      freezed: this.filters.freezed
    }];
    this.taskService.getTags(request).then(res => {
      this.Tags = this.lookupData.tags.map((tag: any) => {
        const userTag = JSON.parse(res.tags).find((w: any) => { return w._id == tag._id });
        let data = [{
          "username": tag._id,
          "_id": "My Task",
          "count": JSON.parse(res.myTask).find((myTask: any) => { return myTask._id == tag._id })?.MyTask ?? 0,
          "type": "tags",
          isSystemTag: true,
          order: 1
        },
        {
          "username": tag._id,
          "_id": "Assigned By Me",
          "count": JSON.parse(res.assignedByMe).find((assignedByMe: any) => { return assignedByMe._id == tag._id })?.count ?? 0,
          "type": "tags",
          isSystemTag: true,
          order: 2
        },
        ...tag.data
        ].filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val._id == value._id) == index
          }
        )
        tag.data = data.map((w: any) => {
          if (!w.isSystemTag) {
            const tagCount = userTag?.data?.find((tagCount: any) => { return w._id == tagCount._id });
            w.count = tagCount?.count ?? 0;
          }
          return w
        });
        return tag;
      });
      if (this.queueFilter?.type == "tages") {
        let filter = this.Tags.find((w: any) => { return w._id == this.queueFilter?.username })?.
          data?.find((w: any) => { return w._id == this.queueFilter?._id })
        //filter.time = new Date();
        //this.queueFilter = filter;
        this.totalCount = filter.count ?? 0;
      }
      JSON.parse(res.assignedByMe)
        .filter((w: any) => {
          return this.Tags.findIndex(s => { return s._id == w._id }) < 0
        }).map((assignedByMe: any) => {
          this.Tags.push(
            {
              _id: assignedByMe._id,
              total: 1,
              data: [{
                "username": assignedByMe._id,
                "_id": "Assigned By Me",
                "count": assignedByMe?.count ?? 0,
                "type": "tags",
                isSystemTag: true,
                order: 2
              }, {
                "username": assignedByMe._id,
                "_id": "My Task",
                "count": JSON.parse(res.myTask).find((w: any) => { return w._id == assignedByMe._id })?.MyTask ?? 0,
                "type": "tags",
                isSystemTag: true,
                order: 1
              }]
            }
          )

        })
      JSON.parse(res.myTask)
        .filter((w: any) => {
          return this.Tags.findIndex(s => { return s._id == w._id }) < 0
        }).map((myTask: any) => {
          this.Tags.push(
            {
              _id: myTask._id,
              total: 1,
              data: [{
                "username": myTask._id,
                "_id": "My Task",
                "count": myTask?.MyTask ?? 0,
                "type": "tags",
                isSystemTag: true,
                order: 1
              }, {
                "username": myTask._id,
                "_id": "Assigned By Me",
                "count": 0,
                "type": "tags",
                isSystemTag: true,
                order: 2
              }]
            }
          )

        })


      this.lookupData.tags = this.Tags;



    })
  }
  async getTask(task: any) {
    this._isLoading = true;
    let res = await this.taskService.getTaskDetails(task._id);
    res[0].practice = this.clientLookup?.find(s => s.code == res[0].UniquePracticeID)?.description;
    this._isLoading = false;
    if (res && res.length > 0) {
      return res[0];
    }
    else return task;
  }
  async onPageChange(pageData: any) {
    this.pageData = pageData;
    this.loadTasks();
  }
  async onShortingChange(sort: any) {
    this.sort = sort;
    this.loadTasks();
  }
  async getTagsLookup() {
    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    let res = await this.taskService.getTagsLookup(request);
    if (this.lookupData.tags && this.lookupData.tags.length > 0) {
      let newTages = this.lookupData.tags.map((users: any) => {
        const userTag = res.find((w: any) => { return w._id == users._id });
        users.data = [
          ...users.data,
          ...userTag?.data ?? []
        ].filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val._id == value._id) == index
          }
        )
        return users;
      })
    }
    else {
      this.lookupData.tags = res;
    }
  }
  async exportData() {
    this._isLoading = true;
    let otherfilters = this.filters.otherfilters.map((w: any) => {
      return {
        searchOn: w.searchOn.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        operator: w.operator.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
        values: w.values
      }
    })
    let request: any[] = [{
      CurrentQueueName: (this.queueFilter?.type == 'queue') ? this.queueFilter?._id ?? '' : '',
      CurrentTagName: (this.queueFilter?.type == 'tages' || this.queueFilter?.type == 'teamTages') ? this.queueFilter?._id ?? '' : '',
      UniquePracticeIds: this.filters.practiceIds.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentOwners: this.filters.owners.map((w: any) => { return w.code }).filter((w: any) => w).join(','),
      CurrentActions: this.filters.actions.map((w: any) => { return w.description }).filter((w: any) => w).join(','),
      sortby: this.sort.active,
      isAsc: this.sort.direction === 'asc',
      pagesize: this.queueFilter?.count ?? 5000,
      pagestartrecord: 0,
      currentUsername: this.queueFilter?.username ?? '',
      otherfilters: JSON.stringify(otherfilters),
      freezed: this.filters.freezed,
      columns: JSON.stringify([{ "title": "Encounter #", "type": "string", "data": "encounternumber" }, { "title": "Practice", "type": "string", "data": "UniquePracticeID" }, { "title": "Queue Name", "type": "string", "data": "CurrentQueueName" }, { "title": "Patient Name", "type": "string", "data": "PatientName" }, { "title": "Patient #", "type": "string", "data": "patient_accountnumber" }, { "title": "Freezed", "type": "string", "data": "freezed" }, { "title": "Current Owner", "type": "string", "data": "currentAssigneeName" }, { "title": "Next Followup Date", "type": "datetime", "data": "followupdate" }])
    }];

    this.taskService.getWorkqueuesExport(request).then(res => {
      this.taskService.downloadWorkqueuesfile(res.filename, this.aftredownloadReport);

      this._isLoading = false;
    });



  }
  aftredownloadReport() {
  }
}
