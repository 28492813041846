<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <div class="row align-items-start" *ngIf="!showInfo">
                <mat-drawer-container class="example-container" autosize style="padding:0px !important">
                    <mat-drawer #drawer class="example-sidenav queue-label-sidenav" [class]="queueDrawerClass" mode="side" [opened]="true" [ngStyle]="{'width': queueDrawerWidth}">
                        <div style="padding: 10px 0px 0px 10px;">
                            <app-task-label [queueLabel]="lookupData.queues" [Tags]="lookupData.tags" [queueFilter]="queueFilter" [expanded]="expanded" (onFilter)="onFilter($event)" (onExpand)="onExpandQueueSection($event)"></app-task-label>
                        </div>
                    </mat-drawer>

                    <div class="example-sidenav-content" [ngStyle]="{'margin-left': queueDrawerWidth}">
                        <div class="task-title">

                            <div class="row" style="margin: auto;">
                                <div class="form-group col-lg-2">
                                    <h3>
                                        Tasks
                                    </h3>
                                    <h5>Home &gt; Tasks</h5>
                                </div>
                                <div class="form-group col-lg-10">
                                    <div class="form-group float-end" fxLayout="row" fxLayoutGap=".5em">  
                                        <div class="form-group" style="width: 300px;" *ngIf="false">
                                            <input matInput type="text" [(ngModel)]="value" class="form-control" style="padding-right: 8px !important;margin-top: 1px;">
                                            <!-- <mat-icon >filter_list</mat-icon> -->
                                        </div>
                                        
                                        <button mat-mini-fab color="primary" *ngIf="hasAction_Add" [disabled]="tasks?.length<1" class="float-end" (click)="onActionClicked()">
                                            <mat-icon>note_add</mat-icon>
                                        </button>
                                        <button mat-mini-fab color="primary" class="float-end" (click)="showFilter = !showFilter" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                                            <mat-icon>filter_list</mat-icon>
                                        </button>
                                        <button mat-mini-fab color="primary" style="float:right" class="print-report " (click)="exportData()">
                                            <mat-icon>cloud_download</mat-icon>
                                        </button>

                                    </div>
                                    
                                    <ng-template cdkConnectedOverlay
                                                 [cdkConnectedOverlayOrigin]="trigger"
                                                 [cdkConnectedOverlayOpen]="showFilter"
                                                 [cdkConnectedOverlayHasBackdrop]="true"
                                                 (detach)="showFilter = false"
                                                 (backdropClick)="showFilter = false">
                                        <div class="filter-pane">
                                            <app-task-filter [lookupData]="lookupData" (onFilter)="doFilter($event)" [filters]="filters"></app-task-filter>
                                        </div>
                                    </ng-template>

                                </div>
                            </div>



                        </div>
                        <mat-divider></mat-divider>
                        <app-task-list *ngIf="hasTask_View" [pageData]="pageData" [queueFilter]="queueFilter" [totalCount]="totalCount" [taskList]="taskList" (onTaskSelection)="onTaskSelection($event)" (onShowDetails)="onShowDetails($event)" (onPageChange)="onPageChange($event)" (onShortingChange)="onShortingChange($event)" #taskListView></app-task-list>
                    </div>
                    <mat-drawer #drawer1 class="example-sidenav" mode="side" [opened]="'false'" position="end" disableClose [ngStyle]="{'width': sideDrawerWidth}" style="">
                        <app-task-details *ngIf="false" [tasks]="tasks" (onCancelEdit)="onCancelEdit($event)" (onExpand)="onExpand($event)"></app-task-details>
                    </mat-drawer>

                </mat-drawer-container>
            </div>


            <section class=" " *ngIf="showInfo" style="height: 77vh;">
                <app-task-information [task]="task" [lookupData]="lookupData" (onCancelEdit)="onCancelInforamtion($event)" (onRefreshInforamtion)="onRefreshInforamtion($event)"></app-task-information>
            </section>
        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
