import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EncounterService } from '../../../../services/encounter.service';

@Component({
  selector: 'app-task-financial-detail',
  templateUrl: './task-financial-detail.component.html',
  styleUrls: ['./task-financial-detail.component.css']
})
export class TaskFinancialDetailComponent {
  constructor(private encounterService: EncounterService) {

  }

  public _task: any;
  public charges: any[]=[];
  public payments: any[] = [];
  @Input() set task(task: any) {
    /*this.sortedData.data = users;*/
    this._task = task;
    this.financialDetails();
  }
  get task() {
    return this._task;
  }
  displayedColumnsCharges: string[] = ['cpt', 'dos', 'servicingprovider', 'starttime', 'endtime', 'doe', 'transactiondate','charges'];
  displayedColumnsPmt: string[] = ['cpt', 'transactiondate', 'paid_by', 'paymentcode', 'adjustmentcode', 'ins_payments', 'pat_payments', 'ins_adjustments','pat_adjustments'];
  dataSourceCharges = new MatTableDataSource<any>([]);
  dataSourcePmt = new MatTableDataSource<any>([]);
  @ViewChild("paginatorCharges") set matPaginator(mp: MatPaginator) {
    this.dataSourceCharges.paginator = mp;
  }
  @ViewChild("paginatorPayments") set matPaginator1(mp: MatPaginator) {
    this.dataSourcePmt.paginator = mp;
  }
  financialDetails() {
    if (this._task) {
      let request: any = {
        "pagesize": 1000,
        "pagestartrecord": 0,
        "clientid": this._task?.UniquePracticeID,
        "encounterid": this._task?.encounterid
      };
      this.encounterService.financialDetails(request).then(res => {
        let data = res.table;
        let j = 0;
        for (let i = 0; i < 0; i++) {
          data.push(data[0]);
          if (j = 1) {
            data[data.length - 1].transactiontype = 'Payments';
          }
          else if (j = 2) {
            data[data.length - 1].transactiontype = 'Adjusment';
            j = -1;
          }
          j++;
        }
        this.dataSourceCharges.data = data;
        this.dataSourcePmt.data = data;
      });
    }
  }
  isCharges = (item: any) => {
    return item.transactiontype=="Charges";
  }

}
