import { Component } from '@angular/core';
import { PrivilegeValues } from '../../entities/Privilege';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';

import { Client } from './clientinfo';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent {
  public clientList: Client[] = [];
  public client!: Client;
  public filtervalue : string = "";
  public showEdit: boolean = false
  public isLoading: boolean = true;
  public hasClient_View: boolean = false;
  public hasClient_Edit: boolean = false;
  public hasClient_Add: boolean = false;

  constructor(
    private clientService: ClientService,
    private auth: AuthService
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getClients();
  }
  getClients() {
    const request = {
      ClientId: -1,
      isactive: -1,
      pagesize: 1000,
      pagestartrecord:0

    }
    this.clientService.getClient([request]).then(data => {
      this.clientList = data;
      console.log(this.clientList)
      this.isLoading = false;
     
    });
  }
  onEditClient(client: Client) {
    
    this.getClientDetails(client.clientid);
  }
  onCancelEdit(item: any) {
    this.client = {} as Client;
    this.showEdit = false;

    if (item)
      this.getClients();
  }
  onAddClient() {
    this.client = {} as Client;
    this.client.clientid = -1;
    this.client.isactive=1;
    this.showEdit = true;
  }
  async getClientDetails(id: number, showLoading = true) {
   console.log(id)
   if (showLoading)
   this.isLoading = true;
    this.clientService.getClientDetails(id).then(res => {
      this.showEdit = true;
        if (res) {
          this.isLoading = false;
          this.client = res[0] as Client;
          if (showLoading) {
            this.showEdit=true
          }
      }
    });
  }
  doUpdateEdit(client: Client) {
    this.getClients();
    this.getClientDetails(client.clientid, false);
  }
  doDelete(client: Client) {
    this.getClients();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }
  async onShowDetails(item: any) {    
    item = this.getClientDetails(item?.clientid);
    this.client = item;
    this.showEdit = true;
  }

  setPrivileges() {
    this.hasClient_View = this.auth.hasPrivilege(PrivilegeValues.ClientManage_View);
    this.hasClient_Edit = this.auth.hasPrivilege(PrivilegeValues.ClientManage_Edit);
    this.hasClient_Add = this.auth.hasPrivilege(PrivilegeValues.ClientManage_Add);
  }

}
