import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../user/user.service';
import { Group, GroupUser } from '../group';

@Component({
  selector: 'app-group-user',
  templateUrl: './group-user.component.html',
  styleUrls: ['./group-user.component.css']
})
export class GroupUserComponent {

  public _group!: Group;
  public _isLoading: boolean = false;
  @Input() set group(group: Group) {
    this._group = group;
    if (group?.groupid!=-1) {
      this.getMasterDetails(group.groupid)
    }
  }
  get group() {
    return this._group;
  }
  groupUsers!: GroupUser[];
  dataSource = new MatTableDataSource<GroupUser>([]);
  assignAll: boolean = true;
  displayedColumns: string[] = ['checkbox','name', 'description'];
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit();
  }

  constructor(private userService: UserService, private dialog: MatDialog) {


  }
  onCheckboxChange(event: any, element: GroupUser | null) {
    if (element == null) {
      this.groupUsers.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.groupUsers;
    }
    else {
      element.assigned = event.checked;
    }
    this.assignAll = this.groupUsers.filter(w => { return w.assigned }).length == this.groupUsers.length;
    console.log('Update successful:', this.dataSource);
  }

  
  @Output() onUpdateEdit = new EventEmitter();
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {
    this.onShowLoading.emit(true);
    let request = this.groupUsers.filter(w => { return w.assigned }).map(w => {
      return {
        userid: w.userId,
        groupid: this.group.groupid,
        typeid: 2,
        loginuserid: -1
      }
    });
    this.userService.setUserGroups(request).then(res => {
      this.onShowLoading.emit(false);
      if (type != 'next') {
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.message = res[0]?.errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        
        this.onUpdateEdit.emit();
        Confirmation(this.dialog, data);
      }
      else {
        this.onUpdateEdit.emit();
        this.doNextEdit.emit(2)
      }
    });     
  }
  async getMasterDetails(id: number) {
    let request = [{
      userid: -1,
      groupid: id
    }]
    const lookups = await Promise.all([
      this.userService.getUserGroups(request)      
    ]);
    this.groupUsers = lookups[0].map((w: any, idx: number) => {
      let s: GroupUser = {} as GroupUser;
      s.assigned = w.isassigned;
      s.email = w.email;
      s.userId = w.userid;
      s.id = idx;
      s.name = w.name;
      return s;
    });
    this.assignAll = this.groupUsers.filter(w => { return w.assigned==1 }).length == this.groupUsers.length;
    this.dataSource.data = this.groupUsers;

  }
  @Output() onShowLoading = new EventEmitter();
}
