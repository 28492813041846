<form class="example-form" fxLayout="column" fxLayoutGap=".5em">

    <div class="row">
        <form [formGroup]="formAdd" style="padding-right: 0px; padding-left: 0px;">

            <!--<div ngFor>-->
            <div class="form-group" *ngIf="displayFilters(3)">
                <small id="name" class="form-text text-muted">Practice</small>
                <app-multi-select (onSelectionChange)="onSelectionChange($event)" [dropdownList]="lookupData.clients" [selectedItems]="filters.practiceIds" [placeholder]="'Client'" #clients></app-multi-select>
            </div>
            <div class="form-group" *ngIf="displayFilters(7)">
                <small id="name" class="form-text text-muted">Service Provider</small>
                <app-multi-select [dropdownList]="lookupData.serviceProviderFiltered" [selectedItems]="filters.serviceProviderIds" [placeholder]="'Service Provider'" #serviceProvider></app-multi-select>
            </div>
            <div class="form-group" *ngIf="displayFilters(8)">
                <small id="name" class="form-text text-muted">Payor Groups</small>
                <app-multi-select [dropdownList]="lookupData.payorGroupsFiltered" [selectedItems]="filters.payorGroupsIds" [placeholder]="'Payor Groups'" #payorGroups></app-multi-select>
            </div>
            <div class="form-group" *ngIf="displayFilters(11)">
                <small id="name" class="form-text text-muted">Check Date</small>
                <div class="row">
                    <div class="col-lg-4">
                        <app-multi-select [dropdownList]="lookupData.relativeDateOperators" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="filters.checkDate?.operator" [placeholder]="'Check Date'" #checkDateOperator></app-multi-select>
                    </div>
                    <div class="col-lg-4">
                        <input matInput type="number" class="form-control" id="namestarttext" placeholder="0" formControlName="checkDateValue">
                    </div>
                    <div class="col-lg-4">
                        <app-multi-select [dropdownList]="lookupData.dateFilterTimeUnits" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="filters.checkDate?.timeUnit" [placeholder]="'Check Date'" #checkDateFilterTimeUnit></app-multi-select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="displayFilters(12)">
                <small id="name" class="form-text text-muted">Transaction Date</small>
                <div class="row">
                    <div class="col-lg-4">
                        <app-multi-select [dropdownList]="lookupData.relativeDateOperators" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="filters.transactionDates?.operator" [placeholder]="'Operator'" #transactionDateOperator></app-multi-select>
                    </div>
                    <div class="col-lg-4">
                        <input matInput type="number" class="form-control" id="namestarttext" placeholder="0" formControlName="transactionDateValue">
                    </div>
                    <div class="col-lg-4">
                        <app-multi-select [dropdownList]="lookupData.dateFilterTimeUnits" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="filters.transactionDates?.timeUnit" [placeholder]="'Unit'" #transactionDateTimeUnit></app-multi-select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="displayFilters(9)">
                <small id="name" class="form-text text-muted">Payor</small>
                <app-multi-select [dropdownList]="lookupData.payorsFiltered" [selectedItems]="filters.payorsIds" [placeholder]="'Payors'" #payors></app-multi-select>
            </div>

            <div class="form-group" *ngIf="displayFilters(4)" style="margin-top: 9px;">
                <small id="name" class="form-text text-muted">Selector</small>
                <app-multi-select [dropdownList]="lookupData.selector" [selectedItems]="filters.selectors" [singleSelection]="'true'" [placeholder]="'Selector'" #selectors></app-multi-select>
            </div>

            <div class="form-group" *ngIf="displayFilters(6)" style="margin-top: 9px;">
                <small id="name" class="form-text text-muted">Service location</small>
                <app-multi-select [dropdownList]="lookupData.serviceLocationFiltered" [selectedItems]="filters.serviceLocations" [placeholder]="'Service Location'" #serviceLocations></app-multi-select>
            </div>

            <div class="transactiondate" style="top: 9px;" *ngIf="displayFilters(10)">
                <div class="form-group">
                    <small id="name" class="form-text text-muted" style="width:100%">Transaction Date:</small>
                    <div class="input-group col-6 mx-auto">
                        <input name="transactiondate"
                               [type]="'text'"
                               class="form-control"
                               placeholder="MM/dd/yyyy"
                               (click)="transactiondatepicker.open()"
                               formControlName="transactionDate" [matDatepicker]="transactiondatepicker"
                               [for]="picker">
                    </div>
                    <mat-datepicker class="form-group-text" style="float:right;" #transactiondatepicker></mat-datepicker>
                </div>
            </div>

            <div class="transactiondate-range" style="position: relative; top: 13px;" *ngIf="displayFilters(5)">
                <mat-label id="transactiondate" class="form-text text-muted" style="position: relative; top: 0px;">Transaction Date</mat-label>
                <input type="text" class="form-control" readonly id="nametext" aria-describedby="transactionDate" placeholder="MM-dd-YYYY" [value]="getTransactionDateRangeValue()">
                <mat-date-range-input [rangePicker]="picker3">
                    <input matStartDate class="form-control" readonly id="namestarttext" aria-describedby="transactionStartDate" placeholder="MM-dd-YYYY" formControlName="transactionstartDate" style="display:none">
                    <input matEndDate class="form-control" readonly id="nameendtext" aria-describedby="transactionEndDate" placeholder="MM-dd-YYYY" formControlName="transactionendDate" style="display:none">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker3" style=" position: absolute; right: 0; top: 23px;"></mat-datepicker-toggle>
                <mat-date-range-picker class="form-group-text" #picker3></mat-date-range-picker>
            </div>

            <div class="calenderdate-range" style="position: relative; top: 5px; " *ngIf="displayFilters(2)">
                <mat-label id="calenderdate" class="form-text text-muted" style="position: relative; top: 0px;">Calendar Date</mat-label>
                <input type="text" class="form-control" readonly id="nametext" aria-describedby="calendarDate" placeholder="MM-dd-YYYY" [value]="getRangeValue()">
                <mat-date-range-input [rangePicker]="picker1">
                    <input matStartDate class="form-control" readonly id="namestarttext" aria-describedby="calenderStartDate" placeholder="MM-dd-YYYY" formControlName="calenderstartDate" style="display:none">
                    <input matEndDate class="form-control" readonly id="nameendtext" aria-describedby="calenderEndDate" placeholder="MM-dd-YYYY" formControlName="calenderendDate" style="display:none">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker1" style=" position: absolute; right: 0; top: 23px;"></mat-datepicker-toggle>
                <mat-date-range-picker class="form-group-text" #picker1></mat-date-range-picker>
            </div>

            <div class="checkdate-range" style="position: relative; top: -2px;" *ngIf="displayFilters(1)">
                <mat-label id="checkdate" class="form-text text-muted" style="position: relative; top: 0px;">Check Date</mat-label>
                <input type="text" class="form-control" readonly id="nametext" aria-describedby="checkDate" placeholder="MM-dd-YYYY" [value]="getCheckDateRangeValue()">
                <mat-date-range-input [rangePicker]="picker2">
                    <input matStartDate class="form-control" readonly id="namestarttext" aria-describedby="checkStartDate" placeholder="MM-dd-YYYY" formControlName="checkstartDate" style="display:none">
                    <input matEndDate class="form-control" readonly id="nameendtext" aria-describedby="checkEndDate" placeholder="MM-dd-YYYY" formControlName="checkendDate" style="display:none">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker2" style=" position: absolute; right: 0; top: 23px;"></mat-datepicker-toggle>
                <mat-date-range-picker class="form-group-text" #picker2></mat-date-range-picker>
            </div>
            <!--</div>-->

        </form>
    </div>
    <div class="button-group float-end">
        <button mat-flat-button color="primary" style="margin-right:10px;" class="btn" (click)="doFilter()">Filter</button>
        <button mat-flat-button extended class="btn" (click)="doCancelFilter()">Reset</button>
    </div>
</form>
