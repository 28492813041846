import { UserInfo } from "../../entities/userInfo";


export const login = 'login';
export function addLoginReducer(state: UserInfo = {} as UserInfo, action: any) {
  switch (action.type) {
    case login:
      state = action.payload;
      return { ...state  };
    default:
      return state;
  }
}
