<div fxFlex.gt-sm="100%" fxFlex="100" *ngIf="true">
    <mat-card class="main-card">
        <mat-card-header>
            <mat-card-title>Rules</mat-card-title>
            <mat-card-subtitle>Home<code>&gt;</code> Rules</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content style="min-height:60vh">
            <mat-grid-list cols="4" rowHeight="2:3">
                <mat-grid-tile colspan="1" style="border-right:1px solid">
                    <mat-list role="list">
                        <mat-list-item role="listitem" class="list-group-item list-group-item-action list-group-item-success" [draggable] *ngFor="let item of vegetables" [dragClass]="'active'"
                                       [dragData]="item" [dragScope]="item.type" [dragEnabled]="dragEnabled">
                            {{item.name}}
                        </mat-list-item>                        
                    </mat-list>

                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div class="tree">
                        <app-nested-flow [flowChart]="flowChart"></app-nested-flow>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>


        </mat-card-content>
    </mat-card>
</div>

<iframe src="https://kinsef.domo.com/auth/index" *ngIf="false"></iframe>
