import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { Role } from '../role';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css']
})
export class RoleEditComponent {
  public _role!: Role;
  public selectedTab!: number;
  public hasRole_View: boolean = false;
  public hasRoleMenu_View: boolean = false;
  public hasRoleUser_View: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }

  @Input() set role(_role: Role) {
    this._role = _role;

  }
  get role() {
    return this._role;
  }

  constructor(private auth: AuthService) { }

  @Output() onCancelEdit = new EventEmitter();
  //doCancelEdit(role: Role) {
  //  this.onCancelEdit.emit(role);
  //}
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(role: Role) {
    //this._group = group;
    this.onUpdateEdit.emit(role);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(role: Role) {
    //this._group = group;
    this.onShowLoading.emit(role);
  }

  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }

  setPrivileges() {
    this.hasRole_View = this.auth.hasPrivilege(PrivilegeValues.RoleManage_View);
    this.hasRoleMenu_View = this.auth.hasPrivilege(PrivilegeValues.RoleMenu_View);
    //this.hasRoleUser_View = this.auth.hasPrivilege(PrivilegeValues.UserNotification_View);    
  }
   

}
