
<section class="table-container">
    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" matSortActive="order" matSortDirection="asc">
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>

            </td>
        </ng-container>
        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>
                Color
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital"> 
                <span [ngStyle]="{'border-left': '25px '+element.Color+' solid;'}"></span>
            </td>
        </ng-container>
        <ng-container matColumnDef="order">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Order
            </th>
            <td mat-cell *matCellDef="let element" class="mat-column-number" [app-number]="(element.Order==-1)?0:element.Order" [app-number-cell]="true">
                {{element.Order }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Name

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
                {{element.QueueName?.toLocaleLowerCase() }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="description-cell">Description</th>
            <td mat-cell *matCellDef="let element" class="description-cell ellipses-on-line-3" [title]="element.roledescription">
                {{element.Description }}
            </td>
        </ng-container>


        <ng-container matColumnDef="isActive">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Active </th>
            <td mat-cell *matCellDef="let element" [app-entity-status]="element.Active" [app-entity-status-cell]="true">
            </td>
        </ng-container>
        <ng-container matColumnDef="count">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number">Count </th>
            <td mat-cell *matCellDef="let element" class="mat-column-number" [app-number]="(element.count==-1)?0:element.count" [app-number-cell]="true">
            </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">
                Created By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-created-by ellipses-on-line-3" [title]="element.createdby?.toLocaleLowerCase()">
                {{element.createdby?.toLocaleLowerCase() }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Createdon">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-on"> Created On </th>
            <td mat-cell *matCellDef="let element" class="cell-created-on text-right">
                {{element.createdon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="modifiedBy" class="cell-modified-by">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Modified By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-modified-by ellipses-on-line-3" [title]="element.modifiedby?.toLocaleLowerCase()">
                {{element.modifiedby?.toLocaleLowerCase() }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="modifiedOn">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-on"> Modified On </th>
            <td mat-cell *matCellDef="let element" class="cell-modified-on text-right"> {{element.modifiedon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>
        </tr>
    </table>
</section>
<mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngIf="hasWorkqueue_Edit" (click)="doEditUser(item)">Edit</button>
        <button mat-menu-item *ngIf="hasWorkqueue_Edit && item.Active == 1" (click)="doDeactivate(item,0)">Deactivate</button>
        <button mat-menu-item *ngIf="hasWorkqueue_Edit && item.Active == 0" (click)="doDeactivate(item,1)">Activate</button>
        <button mat-menu-item *ngIf="hasWorkqueue_Delete" (click)="doDeleteUser(item)"> Delete</button>
    </ng-template>
</mat-menu>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menuFilter" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #menuFilter="matMenu">
    <ng-template matMenuContent let-item="item">
        <div mat-dialog-title (click)="$event.stopPropagation();">
            <app-custom-filter [masterData]="item" (onFilterApply)="onFilterApply($event)"></app-custom-filter>
        </div>
    </ng-template>
</mat-menu>
