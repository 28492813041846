import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { Condition, Group } from '../../../entities/condition-entity';
import { TableList,ColumnSelection } from '../../../entities/table-list';
import { RuleAddComponent } from './rule-add/rule-add.component';

@Component({
  selector: 'app-rules-condition',
  templateUrl: './rules-condition.component.html',
  styleUrls: ['./rules-condition.component.css']
})

export class RulesConditionComponent {
 public _toTable!: TableList;
  @Input() set toTable(toTable:TableList){ 
    this._toTable=toTable;
  }
  get toTable()
  {
    return this._toTable;
  }
  @Input() _fromTable!: TableList;
  @Input() set fromTable(fromTable:TableList){     
    this._fromTable=fromTable; 
  }
  get fromTable()
  {
    return this._fromTable;
  }    
  public rootData: Condition[] = [];
  @ViewChild('contextMenu2') contextMenu!: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  
  public conditions: Condition[];
  @ViewChildren(RuleAddComponent) counters!: QueryList<RuleAddComponent>;
  constructor() {
    this.conditions = [];
    let group = { group: "Block-1" } as Group;
    let condition = {
      id: 1,
      condition: "AND",
      fromColumn: "19",
      toColumn: "14",
      oprator: "1",
      value: "1",
      isStart: true,
      parent: group
    } as Condition;
    //this.sortedData.data.push(group);
    this.conditions.push(condition);
    group = { group: "Block-And" } as Group;    
  }

  ngAfterViewInit() {
  }
  isGroup(index: any, item: any): boolean {
    return item.group;
  }

  groupHeaderClick(row: Group) {
    row.expanded = !row.expanded;
  }

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextAddCondition() {
    let condition = {
      id: (new Date()).getTime(),
      condition: "AND",
      fromColumn: "19",
      toColumn: "14",
      oprator: "1",
      value: "1",
      isStart: false
    } as Condition;
    this.conditions.push(condition);
  }

  onContextMenuAction2(item: any) {
    //alert(`Click on Action 2 for ${item.name}`);
  }
  getConditionValue(element: Condition) {
    //
    return element.condition;
  }
  setConditionValue(element: Condition) {
   
    return element.condition;
  }
  
  getConditions() {
    let conditions: Condition[] = [];

    this.counters.map(w => {
      let values = w.form.value as Condition;
      let condition = w.condition;
      condition.condition = values.condition
      condition.fromColumn = values.fromColumn
      condition.toColumn = values.toColumn
      condition.oprator = values.oprator
      conditions.push(condition);
    });
    console.log(conditions)

  }
  
  
}
