<div class="page-body-ineer">
    <ng-container *ngIf="isEmbedded">
        <ng-container *ngIf="displayFilters()">
            <button mat-mini-fab color="primary" style="float:right" class="filter-report" (click)="showFilter = !showFilter;" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                <mat-icon>filter_list</mat-icon>
            </button>
            <ng-template cdkConnectedOverlay
                         [cdkConnectedOverlayOrigin]="trigger"
                         [cdkConnectedOverlayOpen]="showFilter"
                         [cdkConnectedOverlayHasBackdrop]="true"
                         (detach)="showFilter = false"
                         (backdropClick)="showFilter = false">
                <div class="filter-pane" style="width: 450px; padding: 10px;">
                    <app-report-filter [lookupData]="lookupData" (onFilter)="doFilter($event)" [filters]="filters" [filterData]="filterData" [reportid]="reportId"></app-report-filter>
                </div>
            </ng-template>
        </ng-container>
        <button mat-mini-fab color="primary" style="float:right" class="print-report " (click)="printReport()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap" #powerbiControl>
        </powerbi-report>
    </ng-container>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
