<div class="body-wrapper">
    <div class="body-wrapper-login">
        <mat-card id="card" style="text-align: center;" class="login-main-card">
            <mat-card-title class="login-card-header">
                <span fxShow="false" fxShow.gt-xs>
                    <img src="assets/images/logo-white.png" id="topLogoImageLogin" class="login-logo" />
                </span>
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content class="login-card-body">
                <h3 class="mb-1">Welcome to ARCalytics! 👋</h3>
                <p class="mb-6">Please sign-in to your account and start the adventure</p>
                <form>
                    <div fxLayout="column" fxLayoutGap="1em">
                        <div class="form-group col-lg-12">
                            <small id="Email" class="form-text text-muted">Email</small>
                            <input style="margin-top:10px" type="text" class="form-control" placeholder="Enter your email" formControlName="username" />
                        </div>                          
                    </div>

                </form>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions class="login-card-action">
                <div class="my-8">
                    <div class="d-flex justify-content-between">
                        <button mat-raised-button class="login-card-action-button" color="primary">Send Reset Link</button>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button mat-raised-button class="login-card-action-button" [routerLink]="['/login']" color="primary">Back to login</button>
                    </div>
                </div>

            </mat-card-actions>
        </mat-card>
    </div>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>
