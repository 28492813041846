import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { User } from '../user';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent {
  public _user!: User;
  public selectedTab!: number;
  public hasUserAccount_View: boolean = false;
  public hasUserSecurity_View: boolean = false;
  public hasNotification_View: boolean = false;
  public hasUserRole_View: boolean = false;
  public hasUserGroup_View: boolean = false;

  ngOnInit() {
    this.setPrivileges();    
  }
  @Input() set user(user: User) {
    this._user = user;
  }
  get user() {
    return this._user;
  }

  @Output() onCancelEdit = new EventEmitter<User>();
  @Output() onUpdate = new EventEmitter<User>();
  @Output() onUpdateUser = new EventEmitter<any>();
  //doCancelEdit(user: User) {
  //  this.onCancelEdit.emit(user);
  //}
  doCancelEdit(item: any) {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit(item);
    }
    
  }

  constructor(private router: Router, private auth: AuthService) { }

  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
  }
  get profileEdit() {
    return this._profileEdit;
  }
  doUpdate(data: any) {
    this.onUpdate.emit(data);
  }
  doUpdateUser(data: any) {
    this.onUpdateUser.emit(data);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(user: User) {    
    this.onShowLoading.emit(user);
  }
  doNextEdit(item: any) {    
    console.log("item: " + item);
    this.selectedTab = item;
  }

  setPrivileges() {
    this.hasUserAccount_View = this.auth.hasPrivilege(PrivilegeValues.UserManage_View)
    this.hasUserSecurity_View = this.auth.hasPrivilege(PrivilegeValues.UserSecurity_View);
    this.hasNotification_View = this.auth.hasPrivilege(PrivilegeValues.UserNotification_View);
    this.hasUserRole_View = this.auth.hasPrivilege(PrivilegeValues.UserRoles_View);
    this.hasUserGroup_View = this.auth.hasPrivilege(PrivilegeValues.UserGroup_View);    
  }

}
