import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserRole } from '../user-role';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent {
  displayedColumns: string[] = ['checkbox','name', 'description' ];

  roles!: UserRole[];
  dataSource = new MatTableDataSource<UserRole>([]);
  public _user!: User;
  public assignAll: boolean = false;
  public _isLoading: boolean = false;
  public hasUserRole_Edit: boolean = false;

  ngOnInit(): void {
    this.setPrivileges();
  }
  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getUserRoles(user.userid)
    }
  }
  get user() {
    return this._user;
  }
  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
    if (profileEdit == true) {
      this.dataSource.data = this.roles.filter(w => { return w.assigned });
      this.displayedColumns = ['name', 'description'];
    }    
  }
  get profileEdit() {
    return this._profileEdit;
  }
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router, private auth: AuthService) {

  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
  }
  getUserRoles(userid: number) {
    const request = [{ userid: userid, roleid: -1 }]
    this.userService.getUserRoles(request).then((res: UserRole[]) => {
      this.roles = res.map((s: any) => {
        let newrole = s as UserRole
        newrole.assigned = s.isassigned == 1
        return newrole
      });
      
      this.assignAll = this.roles.filter(w => { return w.assigned }).length == this.roles.length;
      this.dataSource.data = this.roles;
    })
  }
  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {    
    let request = this.roles.filter(w => w.assigned).map(w => {
      return {
        userid: this.user.userid,
        roleid: w.roleid,
        typeid: 1,
        loginuserid: -1
      }
    });
    if (request.length == 0) {
      request  =[{
        userid: this.user.userid,
        roleid: -1,
        typeid: 1,
        loginuserid: -1
      }]
    }

    //
    //this._isLoading = true;
    this.onShowLoading.emit(true);
    this.userService.setUserRoles(request).then(res => {
      this.onShowLoading.emit(false);
      this._isLoading = false;
      if (type != 'next') {
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.message = res[0]?.errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        this.onUpdate.emit();
        Confirmation(this.dialog, data);
      }
      else {
        this.onUpdate.emit();
        this.doNextEdit.emit(4)
      }

    });
  }
  onCheckboxChange(event: any, element: any) {
    if (element == null) {
      this.roles.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.roles;
    }
    else {
      element.assigned = event.checked;
    }

    this.assignAll = this.roles.filter(w => { return w.assigned }).length == this.roles.length;
  }

  setPrivileges() {
    this.hasUserRole_Edit = this.auth.hasPrivilege(PrivilegeValues.UserRoles_Edit);
  }

}
