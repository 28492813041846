import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QueueLabel } from '../../task';

@Component({
  selector: 'app-task-label-list',
  templateUrl: './task-label-list.component.html',
  styleUrls: ['./task-label-list.component.css']
})
export class TaskLabelListComponent {
  public _queueLabels: QueueLabel[] = [];
  public _queueType: string = '';
  public _queueFilter!: QueueLabel;
  public maxItemCount: number = 5;
  @Input() set queueLabel(queueLabels: QueueLabel[]) {
    this._queueLabels = queueLabels;
  }
  get queueLabel() {
    return this._queueLabels;
  }
  @Input() set queueType(queueType: string) {    
    this._queueType = queueType;
  }
  get queueType() {    
    return this._queueType;
  }
  @Input() set queueFilter(queueFilter: QueueLabel) {
    
    this._queueFilter = queueFilter;
  }
  get queueFilter() {
    return this._queueFilter;
  }
  @Output() onFilter = new EventEmitter<any>();
  doFilter(item: any, type: string) {
    if (item.data) {
      item.expanded = !item.expanded
    }
    else {
      item.type = type;
      this.onFilter.emit(item)
    }
  }
}
