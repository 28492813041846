import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[app-entity-status]'
})
export class EntityStatusDirective {
  private _value: number = 0;
  private _isCell: boolean = false;   
  constructor(public elemRef: ElementRef, public renderer: Renderer2) { }

  ngOnInit() {
    //this.format(this.elemRef.nativeElement.value); // format any initial values
  }

  @Input('app-entity-status')
  set value(value: any) {
    this._value = value;     
    this.updateAttributes();
  }
  @Input('app-entity-status-cell')
  set isCell(isCell: boolean) {
    this._isCell = isCell;
    this.updateAttributes();
  }
   
  private updateAttributes() {
    this.renderer.setProperty(this.elemRef.nativeElement, 'innerHTML', `<span class="user-status ${(this._value == 0) ? 'user-status-inactive' : ''}">${(this._value == 1) ? 'Active' : 'Inactive'}</span>`);
    if (this._isCell) {
      this.renderer.addClass(this.elemRef.nativeElement, 'mat-cell');
    }
    else {
      this.renderer.removeClass(this.elemRef.nativeElement, 'mat-cell');
    }
  }

}
