import { Component } from '@angular/core';
import { FlowChart } from '../../entities/flow-chart';



@Component({
  selector: 'app-flow-chart',
  templateUrl: './flow-chart.component.html',
  styleUrls: ['./flow-chart.component.css']
})
export class FlowChartComponent {
  public flowCharts: FlowChart[] = [];
  public flowChart: FlowChart[] = [];
  display: boolean = false;
  currentDraggedItem: any;
  vegetables = [
    { name: 'Create Table', type: 'CreateTable', inputType: 'Create Table' },
    { name: 'Join', type: 'Join', inputType: 'Join', placeholder: '' },
    { name: 'Map Columns', type: 'MapColumn', inputType: 'Map-Columns' },
    { name: 'Add Filter', type: 'AddFilter', inputType: 'Add-Filter' },
    { name: 'Desision', type: 'Desision', inputType: 'Desision' },
    { name: 'Action', type: 'Action', inputType: 'Action' },

  ];
  dragEnabled = true;
  constructor() {
    this.flowCharts = [
      { id: 1, name: 'Start', type: 'Start', Child: [], perentId:-1 },
      { id: 2, name: 'Create Table', type: 'CreateTable', Child: [], perentId: 1 },
      { id: 3, name: 'Join', type: 'Join', Child: [] , perentId: 2},
      { id: 4, name: 'Map Column', type: 'MapColumn', Child: [], perentId: 3, },
      { id: 5, name: 'Add Filter', type: 'AddFilter', Child: [], perentId: 4, },
      { id: 6, name: 'Desision', type: 'Desision', Child: [], perentId: 5, },
      { id: 7, name: 'Action', type: 'Action', Child: [], perentId: 6, },
      { id: 8, name: 'Desision', type: 'Desision', Child: [], perentId: 6, },
      { id: 9, name: 'Action', type: 'Action', Child: [], perentId: 8, },
      { id: 10, name: 'false', type: 'blank', Child: [], perentId: 8, }
    ];
    this.flowChart = this.getNodeData(-1);
  }
  getNodeData(perentId: number) {
    let node = this.flowCharts.filter(w => w.perentId == perentId);
    if (node.length > 0) {
      for (let i = 0; i < node.length; i++) {
        node[i].Child = this.getNodeData(node[i].id);
      }
    }
    return node;
  }



}
