import { Component, OnInit } from '@angular/core';
import { AutomationSummary, AutomationLogs, AutomationDetails } from './bot-dashboard';
import { BotDashboardService } from './bot-dashboard.service';
import { GetFormattedDate } from '../../Utility/common-functions';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-bot-dashboard',
  templateUrl: './bot-dashboard.component.html',
  styleUrls: ['./bot-dashboard.component.css']
})
export class BotDashboardComponent implements OnInit {

  //public scheduleType: number = 3; // 1 for automation bots, 2 for reports, 3 for real time refresh
  public summaryList: AutomationSummary[] = [];
  public logsList: AutomationLogs[] = [];
  public detailsList: AutomationDetails[] = [];
  public setActionResponse: any[] = [];
  public showServers: boolean = false;
  //public showSummary: boolean = false;
  public showDetails: boolean = false;
  public isLoading: boolean = true;
  public requestObject: Object = {};
  public automationStepsList: any[] = [];
  public lookupListEvents!: any;
  public P_trackMonthsRequest!: any[];
  public summaryListTemp!: any[];
  public resetDataFlag!: any;
  constructor(
    private route: ActivatedRoute,
    private botDashboardService: BotDashboardService
  ) { }
  get scheduleType() {
    return this.route.snapshot.data.scheduleType??1;
  }
  ngOnInitBefore() {
    
  }

  async ngOnInit() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let startDate = GetFormattedDate(firstDay);
    let endDate = GetFormattedDate(lastDay);
    
    this.requestObject = {
      schedule_instance_id: '-1',
      eventid_google: "-1",
      scheduletypeid: this.scheduleType,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: startDate, //"01/01/1900",
      todate: endDate //"01/01/1900"
    }
    await this.getAutomationSummary({ "requestData": this.requestObject, "reset": 0 });
  }

  async getAutomationSummary(P_data: any) { // this is emitter call when we click on ok after saving data
     
    if (P_data.reset == 1) {
      this.isLoading = true;
      this.summaryList = [];
      this.P_trackMonthsRequest = [];
      this.automationStepsList = [];
    }

    const request = P_data.requestData;
    
    await this.updateSummaryList(request.fromdate, request);
 

    this.isLoading = false;

    // Start and end dates
    let startDate = new Date(request.fromdate);
    let endDate = new Date(request.todate);

    // Calculate the previous month range
    let prevStartDate = new Date(startDate);
    prevStartDate.setMonth(prevStartDate.getMonth() - 1);
    let prevEndDate = new Date(startDate);

    // Calculate the next month range
    let nextStartDate = new Date(endDate);
    let nextEndDate = new Date(nextStartDate);
    nextEndDate.setMonth(nextEndDate.getMonth() + 1);

    request.fromdate = prevStartDate.toISOString().split('T')[0];
    request.todate = prevEndDate.toISOString().split('T')[0];
    this.updateSummaryList(request.fromdate, request);

    request.fromdate = nextStartDate.toISOString().split('T')[0];
    request.todate = nextEndDate.toISOString().split('T')[0];
    this.updateSummaryList(request.fromdate, request);
  }

  async updateSummaryList(datetime: any, request: any) {
    let date1 = new Date(datetime);
    let P_FindDate = this.summaryList.find((s: any) => {
      let date2 = new Date(s.start);
      return (date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCFullYear() === date2.getUTCFullYear());
    });

    if (P_FindDate == null || P_FindDate == undefined) {
 
      if (this.P_trackMonthsRequest == null || this.P_trackMonthsRequest == undefined) {
        this.P_trackMonthsRequest = [];
      }

      if ((this.P_trackMonthsRequest == null || this.P_trackMonthsRequest == undefined) || this.P_trackMonthsRequest.indexOf(datetime) == -1) {
        this.P_trackMonthsRequest.push(datetime);

        let P_RequestScheduleLog = this.getScheduleLogJSON(request.fromdate, request.todate);
        let P_requestEvents = request;

        P_requestEvents.EventType = "Unknown"
        if (request.scheduletypeid == 1) {
          P_requestEvents.EventType = "Automation";
        } else if (request.scheduletypeid == 2) {
          P_requestEvents.EventType = "Report";
        } else if (request.scheduletypeid == 3) {
          P_requestEvents.EventType = "RealTimeRefresh";
        } else if (request.scheduletypeid == 4) {
          P_requestEvents.EventType = "Payroll";
        }

        this.lookupListEvents = await Promise.all([
          this.botDashboardService.getScheduleLog([P_RequestScheduleLog]),
          this.botDashboardService.getAutomationSummary([request]),
          this.botDashboardService.getAutomationEvents([P_requestEvents])
        ]);

        if (this.lookupListEvents[0].length > 0) {
          this.automationStepsList = [...this.automationStepsList, ...this.lookupListEvents[0]];
        }

        // old code
        this.summaryListTemp = [];
        if (this.lookupListEvents[2].length > 0) {
          this.summaryListTemp = this.lookupListEvents[2].filter((w: any) => {
            return this.lookupListEvents[1].findIndex((s: any) => {
              return s.eventid_google == w.recurringEventId
            }) > -1
          });

          this.summaryListTemp = this.summaryListTemp.map((w: any) => {

            let P_inputData = this.lookupListEvents[1].find((s: any) => {
              return s.eventid_google == w.recurringEventId;
            })

            var P_StartDateTime = w.start.dateTime;
            var P_EndDateTime = w.end.dateTime;
            var P_newEndTime = moment(P_StartDateTime).format("YYYY-MM-DD") + "T" + moment(P_EndDateTime).format("hh:mm:ss.ffffff");
            var P_subEventId = w.id;

            var P
            if (w.description != null && w.description != undefined && w.description.indexOf("&StartTime=") > -1) {
              P_StartDateTime = w.description.split("&StartTime=")[1].split('&EndTime=')[0];
              P_EndDateTime = w.description.split("&StartTime=")[1].split('&EndTime=')[1];
              P_newEndTime = moment(P_StartDateTime).format("YYYY-MM-DD") + "T" + moment(P_EndDateTime).format("hh:mm:ss.ffffff");
            }
            
            w["id"] = P_inputData["schedule_instance_id"];
            w["title"] = P_inputData["schedulename"];
            w["start"] = w.start.dateTime;
            w["end"] = w.end.dateTime;
            w["new_end"] = P_newEndTime;
            w["description"] = P_inputData["scheduledescription"];
            w["isactive"] = P_inputData["isactive"];
            w["display"] = "block";
            w["assignee_name"] = "";
            w["createdby_name"] = "";
            w["background_color"] = "#2e8bc0";
            w["border_color"] = "#2e8bc0";
            w["event_priority_id"] = 2;
            w["is_server"] = 0;
            w["event_id"] = w.recurringEventId;
            w["schedule_details"] = P_inputData["scheduledetails"];
            w["created_by_id"] = P_inputData[9];
            w["schedule_type_id"] = P_inputData.scheduletypeid;
            w["email_profile_id"] = P_inputData["createdby"];
            w["sub_event_id"] = P_subEventId;
            w["start_datetime_string"] = moment(P_StartDateTime).format("YYYY-MM-DDThh:mm:ss"); // moment(w.start.dateTime).format("YYYY-MM-DD'T'hh:mm:ss.ffffff")
            w["end_datetime_string"] = moment(P_EndDateTime).format("YYYY-MM-DDThh:mm:ss"); //moment(w.end.dateTime).format("YYYY-MM-DD'T'hh:mm:ss.ffffff");
            return w;
          })
        }

        if (this.summaryListTemp.length > 0) {
          this.summaryList = [...this.summaryList, ...this.summaryListTemp];
        }
      }
    }
  }

  getAutomationStepsList() {
    let request: any = { id: "-1" };
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let startDate = GetFormattedDate(firstDay);
    let endDate = GetFormattedDate(lastDay);

    console.log("this.scheduleType: ", this.scheduleType);
    request = {
      ScheduleID: -1,
      EventID_google: -1,
      Status: -1,
      ScheduleTypeID: this.scheduleType,
      pagesze: 1000,
      pagestartrecord: 0,
      fromdate: startDate + ' 01:01:01',
      todate: endDate + ' 01:01:01',
      SubEventId: '-1'
    };
    this.botDashboardService.getScheduleLog([request]).then(data => {
      this.automationStepsList = data;
      //this.isLoading = false;
    });
  }

  getAutomationLogs() {
    const request = {
      AutomationServerDetailID: -1,
      AutomationId: -1,
      AutomationInstanceID: -1,
      ProcessId: -1,
      logfromdate: "01/01/1900",
      logtodate: "01/01/1900",
      AutomationLogTypeID: -1,
    }
    this.botDashboardService.getAutomationLogs([request]).then(data => {
      this.logsList = data;
      //this.isLoading = false;
    });
  }

  getAutomationDetails(summaryList: AutomationSummary) {
    //const request = {
    //  AutomationServerDetailID: "", //summaryList.AutomationServerDetailID,
    //  AutomationId: "", //summaryList.AutomationId,
    //  AutomationInstanceID: "" //summaryList.processid
    //}
    //
    //this.isLoading = true;
    //
    //this.botDashboardService.getAutomationDetails([request]).then(data => {
    //  this.detailsList = data;
    //  this.isLoading = false;
    //  this.showDetails = true;
    //});
  }

  setAutomationActions() {
    const request = {
      AutomationInstanceID: -1,
      ProcessId: -1,
      UniqueServerID: -1,
      ForceQuit: -1,
      Adhocrun: -1,
      IsActive: -1
    }
    this.botDashboardService.setAutomationActions([request]).then(data => {
      this.setActionResponse = data;
      //this.isLoading = false;
    });
  }

  getScheduleLogJSON(P_FromDate: any, P_ToDate: any) {
    let request: any = { id: "-1" };
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let startDate = GetFormattedDate(firstDay);
    let endDate = GetFormattedDate(lastDay);

    if (P_FromDate != null && P_FromDate != undefined && P_ToDate != null && P_ToDate != undefined) {
      startDate = P_FromDate;
      endDate = P_ToDate;
    }

    request = {
      ScheduleID: -1,
      EventID_google: -1,
      Status: -1,
      ScheduleTypeID: this.scheduleType,
      pagesze: 1000,
      pagestartrecord: 0,
      fromdate: startDate + ' 01:01:01',
      todate: endDate + ' 01:01:01',
      SubEventId: '-1'
    };

    return request;
  }

}
