

<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content>
      <mat-card-title>
        Users
        <button *ngIf="!showEdit && hasUser_Add" mat-mini-fab color="primary" style="float:right" (click)="onAddUser()">
          <mat-icon>add</mat-icon>
        </button>
        <div class="form-group col-lg-2" style="float:right" *ngIf="!showEdit">
          <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value] ="filtervalue">
        </div>
      </mat-card-title>
      <mat-card-subtitle> Home &gt; Users</mat-card-subtitle>
      <mat-divider></mat-divider>
      <app-user-list *ngIf="!showEdit && hasUser_View" [userList]="userList" [filtervalue]="filtervalue" (onEditUser)="onEditUser($event)" (onUpdate)="onUpdate($event)" (onShowDetails)="onShowDetails($event)"></app-user-list>
      <section class="edit-container" *ngIf="showEdit && (hasUser_Add || hasUser_Edit)">
        <app-user-edit [user]="user" [isLoading]="isLoading" (onCancelEdit)="onCancelEdit($event)" (onUpdateUser)="onUpdateUser($event)" (onShowLoading)="onShowLoading($event)" (onUpdate)="onUpdate($event)"></app-user-edit>
      </section>
    </mat-card-content>
  </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>
