<div fxLayout="column" fxLayoutGap=".5em">
    <div class="row">
        <h3>Summary</h3>
    </div>
    <div class="row">
        <div class="col-md-6 claim-details-title">Charges:</div>
        <div class="col-md-6">
            <span [usd-money]="task?.Total_charges" [usd-money-cell]="false"></span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 claim-details-title">Payments:</div>
        <div class="col-md-6">
            <span [usd-money]="task?.total_payment" [usd-money-cell]="false"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 claim-details-title">Adjustments:</div>
        <div class="col-md-6">
            <span [usd-money]="task?.total_adjustment" [usd-money-cell]="false"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 claim-details-title">Insurance A/R:</div>
        <div class="col-md-6">
            <span [usd-money]="task?.Total_Insurance_balance" [usd-money-cell]="false"></span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 claim-details-title">Patient A/R:</div>
        <div class="col-md-6">
            <span [usd-money]="task?.Total_Patient_Balance" [usd-money-cell]="false"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 claim-details-title">Total A/R:</div>
        <div class="col-md-6"> <span [usd-money]="task?.Total_Balance" [usd-money-cell]="false"></span></div>
    </div>
    <div class="row">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="DOS">
                <app-task-aging-details [arData]="arData"></app-task-aging-details>
            </mat-tab>
            <mat-tab label="DOE">
                <app-task-aging-details [arData]="arData"></app-task-aging-details>
            </mat-tab>
            <mat-tab label="DOD">
                <app-task-aging-details [arData]="arData"></app-task-aging-details>
            </mat-tab>

        </mat-tab-group>

        
    </div>
</div>
