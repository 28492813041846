import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { Group } from '../group';
import * as Custom from '../../../Utility/CustomValidators.utilities';
import { MatPaginator } from '@angular/material/paginator';
import { GroupService } from '../group.service';
import { LookupEntitySelect } from '../../../entities/lookup-entity';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  public sortedData = new MatTableDataSource<Group>([]);
  displayedColumns: string[] = ['actions', 'name', 'description', 'isActive', 'userCount', 'createdBy', 'Createdon', 'modifiedBy', 'modifiedOn'];
  public pageSize = 10;
  public sort!: Sort;
  public _roleList: Group[] = [];
  public hasGroup_Edit: boolean = false;
  public hasGroup_Delete: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }

  @Output() onEditGroup = new EventEmitter<Group>();
  @Output() onDelete = new EventEmitter<Group>();
  @Input() set groupList(groups: Group[]) {
    console.log(groups)
    this.sortedData.data = groups;
    this._roleList = groups;
    console.log(this._roleList)
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  get groupList() {
    return this._roleList;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  constructor(private groupService: GroupService,
    private dialog: MatDialog, private auth: AuthService) {
    this.sortedData.data = this.groupList;
  }

  sortAndFilterData() {
    let filteredData = this.groupList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;
    });




    if (!this.sort) {
      this.sortedData.data = filteredData;
      return;      
    }
    else if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: Group, b: Group) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name':
          return Custom.compare(a.groupname, b.groupname, isAsc);
        case 'description':
          return Custom.compare(a.groupdescription, b.groupdescription, isAsc);
        case 'userCount':
          return Custom.compare(a.userCount, b.userCount, isAsc);
        case 'createdBy':
          return Custom.compare(a.createdBy, b.createdBy, isAsc);
        case 'createdon':
          return Custom.compare(a.createdOn, b.createdOn, isAsc);
        case 'modifiedBy':
          return Custom.compare(a.modifiedBy, b.modifiedBy, isAsc);
        case 'modifiedOn':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        case 'isActive':
          return Custom.compare(a.isactive, b.isactive, isAsc);
        default:
          return 0;
      }
    });
  }
  DateFormate(DateString: string, formateString: string) {
    return Custom.FormatDate(DateString, formateString);
  }
  doEditGroup(group: Group) {
    this.onEditGroup.emit(group);
  }
  async doDeleteGroup(group: Group) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: group.groupid,
    }]

    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.groupService.deleteGroup(request).then(res => {
        data.message = res[0]?.result;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }
  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();

  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    let items = this.getFiterData(item);
    this.menuFilter.menuData = { 'item': items };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.menuFilter.openMenu();
  }
  getFiterData(columnName: string) {
    let data = []
    if (columnName == "isemailverified" || columnName == 'isactive') {
      data = Custom.booleanLookups(this.filterData[columnName]);
    } else {

      data = this.groupList
        .map((item: any) => {
          return {
            "description": item[columnName],
            "code": item[columnName]?.toString(),
            active: true,
            selected: this.filterData[columnName] && (this.filterData[columnName] as any[]).findIndex(w => w.code == item[columnName]?.toString()) >= 0
          }
        }
        )
        .filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val.code == value.code) == index
          }
        );
    }
    return data as LookupEntitySelect[];
  }
  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    if (this.hasGroup_Edit) {
      this.onShowDetails.emit(item);
    }
  }
  setPrivileges() {
    this.hasGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.GroupManage_Edit);
    this.hasGroup_Delete = this.auth.hasPrivilege(PrivilegeValues.GroupManage_Delete);
    if (!this.hasGroup_Edit && !this.hasGroup_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }
}
