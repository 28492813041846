import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskService } from '../task.service';
import { QueueLabel } from '../task';
import { AuthService } from '../../../services/auth.service';

import * as custom from '../../../Utility/CustomValidators.utilities';



@Component({
  selector: 'app-task-label',
  templateUrl: './task-label.component.html',
  styleUrls: ['./task-label.component.css']
})
export class TaskLabelComponent {
  public _queueFilter!: QueueLabel;
  public uniquePracticeId: string = "1000060123_2124312";
  public _queueLabel: QueueLabel[] = [];
  public _Tags: any[] = [];
  public labelCode: string[] = [];
  @Output() onFilter = new EventEmitter<any>();
  @Output() onExpand = new EventEmitter<any>();

  @Input() set queueLabel(queueLabel: QueueLabel[]) {
    this._queueLabel = queueLabel;
  }
  get queueLabel() {
    
    return this._queueLabel;
  }

  public _expanded: boolean = false;
  @Input() set expanded(expanded: boolean) {
    this._expanded = expanded;
  }
  get expanded() {
    return this._expanded;
  }
  @Input() set Tags(Tags: any[]) {
    
    if (Tags && Tags.length) {
      this._Tags = Tags;
      this.customLabel = Tags.find((w: any) => w._id == this.auth.currentUser.username)?.data ?? [];
      this.teamTages = Tags.filter((w: any) => w._id.toLowerCase() != this.auth.currentUser.username.toLowerCase()) ?? [];
      this.customLabel = this.customLabel.sort((a: any, b: any) => {
        return custom.compare(a._id?.toLocaleLowerCase() as string, b._id.toLocaleLowerCase() as string, true);
      });
      this.teamTages = this.teamTages.map((w: any) => {
        w.data = w.data.sort((a: any, b: any) => {
          return custom.compare(a._id?.toLocaleLowerCase() as string, b._id?.toLocaleLowerCase() as string, true);
        })
        return w;
      });
    }
    else {
      this.customLabel = [];
      this.teamTages = [];
    }
  }
  get Tags() {
    return this._Tags;
  }
  @Input() set queueFilter(queueLabel: QueueLabel) {    
    this._queueFilter = queueLabel;
  }
  get queueFilter() {
    return this._queueFilter;
  }
  public customLabel: QueueLabel[] = [];
  public teamTages: QueueLabel[] = [];
  constructor(private auth: AuthService) {

  }




  doFilter(item: any) {
    this.onFilter.emit(item)
  }
  doExpand() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.labelCode = ['250px', 'left-sidebar-opened', 'true'];
      this.onExpand.emit(this.labelCode);
    } else {
      this.labelCode = ['50px', 'left-sidebar-closed', 'false'];
      this.onExpand.emit(this.labelCode);
    }
  }
  addLabel() {

  }

}

