<section class="table-container" tabindex="0">
    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" matSortActive="encounternumber" matSortDirection="asc" matSortDisableClear>

        <!-- Position Patient -->
        <ng-container matColumnDef="checkBox" sticky>
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox *ngIf="hasAction_Add" class="example-margin" [checked]="selectAll" (change)="onCheckboxChange($event,null,'all')"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="example-margin" [checked]="element.selected" (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
            </td>
        </ng-container>
        <!-- Ticket -->
        <ng-container matColumnDef="encounternumber" sticky>
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Encounter # </th>
            <td mat-cell *matCellDef="let element" class="text-right" [title]="getTitle(element)">
                {{element.encounternumber}}
            </td>
        </ng-container>
        <!-- Name Practice -->
        <ng-container matColumnDef="UniquePracticeID">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Practice </th>
            <td mat-cell *matCellDef="let element">
                {{element.practice}}
            </td>
        </ng-container>
        <!-- Position Patient -->
        <ng-container matColumnDef="PatientName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Patient </th>
            <td mat-cell *matCellDef="let element">
                {{element.PatientName}}
            </td>
        </ng-container>
        <!-- Name workqueuename -->
        <ng-container matColumnDef="CurrentQueueName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Queue Name </th>
            <td mat-cell *matCellDef="let element">
                {{element.CurrentQueueName}}
            </td>
        </ng-container>

        <!-- Name patientaccountnumber -->
        <ng-container matColumnDef="patient_accountnumber">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-right"> Patient #</th>
            <td mat-cell *matCellDef="let element">
                {{element.patient_accountnumber}}
            </td>
        </ng-container>
        <!-- issnoozed -->
        <ng-container matColumnDef="issnoozed">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Freezed </th>
            <td mat-cell *matCellDef="let element">
                {{element.freezed}}
            </td>
        </ng-container>
        <!-- currentOwner -->
        <ng-container matColumnDef="CurrentAssigneeName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Current Owner </th>
            <td mat-cell *matCellDef="let element">
                {{element.CurrentAssigneeName}}
            </td>
        </ng-container>
        <!-- Name followupdate -->
        <ng-container matColumnDef="followupdate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="next-follow-up-date"> Next Follow Up Date </th>
            <td mat-cell *matCellDef="let element" class="text-right next-follow-up-date">
                {{element.followupdate | datePipeSafe: 'MM/DD/YYYY':false}}
            </td>
        </ng-container>
        <!--

           <ng-container matColumnDef="TaskListStatus">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
               <td mat-cell *matCellDef="let element">
                   {{element.claimStatus}}
               </td>
           </ng-container>





           <ng-container matColumnDef="DOS">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOS </th>
               <td mat-cell *matCellDef="let element">
                   {{element.firstDOS | datePipeSafe: 'MM/DD/YYYY':true}}
               </td>
           </ng-container>


           <ng-container matColumnDef="DOSAge">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOS Age </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number">
                   {{element.firstDOS | displayAge}}
               </td>
           </ng-container>

           <ng-container matColumnDef="dosagingbucket">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOS Aging Bucket </th>
               <td mat-cell *matCellDef="let element">
                   {{element.firstDOS | displayAgingBucket}}
               </td>
           </ng-container>

           <ng-container matColumnDef="dischargedate">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOD Date </th>
               <td mat-cell *matCellDef="let element">
                   {{element.dischargeDate | datePipeSafe: 'MM/DD/YYYY':true}}
               </td>
           </ng-container>

           <ng-container matColumnDef="dischargedateage">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOD Account Age </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number">
                   {{element.dischargeDate | displayAge}}
               </td>
           </ng-container>

           <ng-container matColumnDef="dischargedateageagingbucket">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> DOD Aging Bucket </th>
               <td mat-cell *matCellDef="let element">
                   {{element.dischargeDate | displayAgingBucket}}
               </td>
           </ng-container>

           <ng-container matColumnDef="primaryinsurnace">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Primary Insurance </th>
               <td mat-cell *matCellDef="let element">
                   {{element.pM_System_PrimaryPayorname}}
               </td>
           </ng-container>

           <ng-container matColumnDef="primaryinsurancegroup">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Primary Insurance Group </th>
               <td mat-cell *matCellDef="let element">
                   {{element.pM_System_Primarypayorgroupname}}
               </td>
           </ng-container>

           <ng-container matColumnDef="currentinsurance">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Current Insurance </th>
               <td mat-cell *matCellDef="let element">
                   {{element.pM_System_CurrentPayorname}}
               </td>
           </ng-container>

           <ng-container matColumnDef="currentinsurancegroup">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Current Insurance Group </th>
               <td mat-cell *matCellDef="let element">
                   {{element.pM_System_Currentpayorgroupname}}
               </td>
           </ng-container>

           <ng-container matColumnDef="totalcharges">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number"> Total Charges </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_charges)?0:element.total_charges" [usd-money-cell]="true">
               </td>
           </ng-container>

           <ng-container matColumnDef="totalpayments">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number"> Total Payments </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_payment)?0:element.total_payment" [usd-money-cell]="true">
               </td>
           </ng-container>

           <ng-container matColumnDef="totaladjustments">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number"> Total Adjustments </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_adjustment)?0:element.total_adjustment" [usd-money-cell]="true">

               </td>
           </ng-container>



           <ng-container matColumnDef="Insurancebalance">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number"> Ins Bal </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_Insurance_balance)?0:element.total_Insurance_balance" [usd-money-cell]="true">
               </td>
           </ng-container>

           <ng-container matColumnDef="PatientBalance">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number">Pat Bal </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_Patient_Balance)?0:element.total_Patient_Balance" [usd-money-cell]="true">
               </td>
           </ng-container>

           <ng-container matColumnDef="totalbalance">
               <th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-number"> Total Bal </th>
               <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.total_Balance)?0:element.total_Balance" [usd-money-cell]="true">
               </td>
           </ng-container>

           <ng-container matColumnDef="Notes">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Notes </th>
               <td mat-cell *matCellDef="let element">
                   {{element.Notes}}
               </td>
           </ng-container>

           <ng-container matColumnDef="lastactiondate">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Last Followup Date </th>
               <td mat-cell *matCellDef="let element">
                   {{element.lastactiondate | datePipeSafe: 'MM/DD/YYYY':true}}
               </td>
           </ng-container>

           <ng-container matColumnDef="lastactiondateage">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Followup Age </th>
               <td mat-cell *matCellDef="let element">
                   {{element.lastactiondate | displayAge}}
               </td>
           </ng-container>


           <ng-container matColumnDef="activetaskage">
               <th mat-header-cell mat-sort-header *matHeaderCellDef> Next Action Age </th>
               <td mat-cell *matCellDef="let element">
                   {{element.followupdate | displayAge}}
               </td>
           </ng-container>

    -->




        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-records-cell" style="padding-left: 24px !important;" [attr.colspan]="displayedColumns.length">No record(s) found.</td>
        </tr>
    </table>

            </section>
<mat-paginator #paginator [length]="totalData" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true" (page)="doPageChange($event)">
</mat-paginator>
