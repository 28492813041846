import { Component, OnInit } from '@angular/core';
import { debug } from 'console';
import { AuthService } from '../../services/auth.service';
import { User, UserDetails } from './user';
import { UserService } from './user.service';
import { PrivilegeValues } from '../../entities/Privilege';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  public userList: User[] = [];
  public user!: UserDetails;
  public filtervalue: string = "";
  public filterlist!:[];
  public showEdit: boolean = false
  public isLoading: boolean = true;
  public hasUser_View: boolean = false;
  public hasUser_Edit: boolean = false;
  public hasUser_Add: boolean = false;

  constructor(
    private userService: UserService,
    private auth: AuthService
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getUsers();    
  }
  getUsers() {    
    const request = {
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }
    this.userService.getUsers([request]).then(data => {
      this.userList = data; 
      this.isLoading = false;
      //
    });
  }
  onEditUser(user: User) {
    
    this.getUserDetail(user.userid); 
  }
  onCancelEdit(item: any) {
    
    this.user = {} as UserDetails;
    this.showEdit = false;
    if (item)
      this.getUsers();
  }
  onUpdate(data: any) {
    
    this.getUsers();
  }
  onUpdateUser(user: User) {
    
    this.getUserDetail(user?.userid);
    this.getUsers();
  }
  onAddUser() {    
    this.user = {} as UserDetails;
    this.user.userid = -1;
    this.showEdit = true;
    this.user.isactive = 1;
    //this.userimagepath = 'assets/images/profile_blank.png';    
  } 
  async getUserDetail(userid: number, showLoading = true) {
    
    if (showLoading)
      this.isLoading = true;
      
    this.userService.getUserDetail({ id: userid }).then(res => {
      
      this.showEdit = true;
      if (res) {
        this.isLoading = false;
        this.user = res[0] as UserDetails;
        if (showLoading)
          this.showEdit = true;        
      }
    })
  }

  doUpdateEdit(user: User) {
    this.getUsers();
    this.getUserDetail(user?.userid);
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {
 
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }

  async onShowDetails(item: any) {    
    item = this.getUserDetail(item?.userid);
    this.user = item;
    this.showEdit = true;
  }

  setPrivileges() {
    this.hasUser_View = this.auth.hasPrivilege(PrivilegeValues.UserManage_View);
    this.hasUser_Edit = this.auth.hasPrivilege(PrivilegeValues.UserManage_Edit);
    this.hasUser_Add = this.auth.hasPrivilege(PrivilegeValues.UserManage_Add);
   
  }

}
