import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { models } from 'powerbi-client';
import { PowerBIDashboardComponent } from '../components/power-bidashboard-embed/power-bidashboard-embed.component';
import { UserService } from '../components/user/user.service';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild("powerbidashboard1") reportObj1!: PowerBIDashboardComponent;
  ngAfterViewInit() { }
  constructor(
    private userService: UserService) {
    this.setUserToken()
  }
  public accessToken: string = "";
  setUserToken() {
    this.userService.setUserToken().then(res => {
      this.accessToken = res.token;
    });
  }


}
