<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->
<div fxLayout="row wrap">
    <!-- column -->
    <div fxFlex.gt-lg="65" fxFlex.gt-md="65" fxFlex.gt-xs="100" fxFlex="100">
    </div>
    <!-- column -->
    <!-- column -->
    <div fxFlex.gt-lg="35" fxFlex.gt-md="35" fxFlex.gt-xs="100" fxFlex="100">
    </div>
    <!-- column -->
</div>

<!-- ============================================================== -->
<!-- Image Card row-->
<!-- ============================================================== -->
<div class="row">
    <div class="col-md-4">
        <app-power-bidashboard-embed [accessToken]="accessToken" [dashboardId]="'beaa24ba-dd97-4784-bbf0-3bd163b7f268'" [groupId]="'d147ea95-fa4d-489e-99f2-9a8b0cea6c46'"></app-power-bidashboard-embed>
    </div>
    <div class="col-md-4">
        <app-power-bidashboard-embed [accessToken]="accessToken" [dashboardId]="'3acd52c1-fb6e-4f7e-a93a-b6557a1d8954'" [groupId]="'d147ea95-fa4d-489e-99f2-9a8b0cea6c46'"></app-power-bidashboard-embed>
    </div>
    <div class="col-md-4">
        <app-power-bidashboard-embed [accessToken]="accessToken" [dashboardId]="'3acd52c1-fb6e-4f7e-a93a-b6557a1d8954'" [groupId]="'d147ea95-fa4d-489e-99f2-9a8b0cea6c46'"></app-power-bidashboard-embed>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="75" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <app-contacts></app-contacts>
    </div>
    <div fxFlex.gt-lg="75" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100">
        <app-activity-timeline></app-activity-timeline>
    </div>
</div>
