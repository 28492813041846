import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { Activity } from '../../../dashboard/dashboard-components/activity-timeline/activity-timeline-data';
import { AuthService } from '../../../services/auth.service';
import { UserInfo } from '../../../entities/userInfo';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.states';
import { Router } from '@angular/router';
import { ThemeService } from '../../../services/theme.service';
import { CommentService } from '../../../services/comment.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public activities: Activity[] = [];
   
  async signIn(): Promise<void> {
    //await this.authService.signIn();
  }
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private themeService: ThemeService,
    private commentService: CommentService
  ) {
    this.getNotifications();
  }
  getNotifications() {
    let request = [
      {
        "createdBy": 1,
        "notificationtypeid": 1,
        "practiceid": 1,
        "warehouseuniqueidentifier": 1,
        "pagesize": 5,
        "pagestartrecord": 0
      }
    ]
    this.commentService.getNotifications(request).then(res => { 
      this.activities = res;
    })
  }
  getUserTime(date: string) {
    var m1 = moment(date);
    return m1.fromNow();
  }


  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount &&
      this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  async loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  async loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      await this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          //
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      await this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          //
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  async logout() {
    //if (popup) {
    //  this.authService.logoutPopup({
    //    mainWindowRedirectUri: '/',
    //  });
    //} else {
    //  this.authService.logoutRedirect();
    //}
    this.store.dispatch({
      type: 'login',
      payload: {} as UserInfo
    });
    localStorage.setItem("token", '');
    this.auth.logoutRedirect().then(res => {
 
      this.router.navigateByUrl('login');
    });
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  async ngOnInit() {
    await this.authService.instance.initialize();
    await this.authService.instance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked
    await this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        //
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        //
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
  }

  toggleTheme(mode:number) {
    this.themeService.toggleTheme(mode);
  }

   
}
