<!-- ============================================================== -->
<!-- row -->
<!-- ============================================================== -->
<div fxLayout="row" fxLayoutWrap="wrap">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card class="main-card">
            <mat-card-header>
                <mat-card-title>Rules</mat-card-title>
                <mat-card-subtitle>Home<code>&gt;</code> Rules</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <mat-card class="rule-block-card">
                    <mat-card-header>
                        <button mat-button [matMenuTriggerFor]="saveMenu">Save</button>
                        <mat-menu #saveMenu="matMenu" yPosition="below">
                            <button mat-menu-item>Save</button>
                            <button mat-menu-item>Save As</button>
                        </mat-menu>
                        <button mat-button [matMenuTriggerFor]="controlMenu">Add Control</button>
                        <mat-menu #controlMenu="matMenu" yPosition="below">
                            <button mat-menu-item>Joins</button>
                            <button mat-menu-item>Create Table</button>
                            <button mat-menu-item>Add Filters</button>
                            <button mat-menu-item>Add Conditional blocks</button>
                        </mat-menu> 
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content class="rule-block">
                        <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="selected"
                                       (selectedIndexChange)="selected=$event">
                            <mat-tab label="Create Table">
                                <app-create-table [Table]="CreateTable"></app-create-table>
                            </mat-tab>
                            <mat-tab label="join">
                                <app-rules-joins [selectedColumn]="selectedColumn" [tempTable]="tableName"></app-rules-joins>
                            </mat-tab>
                            <mat-tab label="Mapping">
                                <app-rule-mapping [Table]="CreateTable" [selectedColumn]="selectedColumn" [tempTable]="tableName"></app-rule-mapping>
                            </mat-tab>
                            <mat-tab label="Filters">
                                <app-rule-filter [Table]="CreateTable"></app-rule-filter>
                            </mat-tab>
                            <mat-tab label="Actions">
                                <app-rule-action [Table]="CreateTable"></app-rule-action>
                            </mat-tab>
                        </mat-tab-group>


                    </mat-card-content>
                </mat-card>
            </mat-card-content>
        </mat-card>
    </div>
</div>
