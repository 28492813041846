import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import * as custom from '../../../Utility/CustomValidators.utilities'
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { PasswordgeneratorComponent } from '../../passwordgenerator/passwordgenerator.component';
import { Observable } from 'rxjs'; 
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'app-user-security',
  templateUrl: './user-security.component.html',
  styleUrls: ['./user-security.component.css']
})

  @Injectable({
    providedIn: 'root'
  })

export class UserSecurityComponent {
  public _user!: User;
  public resetpasswordonnextlogin: boolean = false;
  public hasUserSecurity_Edit: boolean = false;
  name = 'Angular';
  email = 'Pradeep@2023#';
  toBase64 = 'yrdrydry|tstrdrt';
  hash: string;
  public formAdd: FormGroup = new FormGroup({
    newPassword: new FormControl('', [Validators.required, custom.validatePassword]),
    oldpassword: new FormControl(''),
    confirmNewPassword: new FormControl('', [Validators.required]),
  }, { validators: custom.confirmPassword });;

  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getUserSecurity(user.userid)
    }
  }
  get user() {
    return this._user;
  }
  public _profileEdit: boolean = true;
  @Input() set profileEdit(profileEdit: boolean) {
    //debugger
    this._profileEdit = profileEdit;
    if (this.profileEdit) {      
      this.formAdd.controls.oldpassword = new FormControl('', [Validators.required, custom.validatePassword]);
    }
  }
  get profileEdit() {
    return this._profileEdit;
  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }

    
  }
  ngOnInit(): void {
    this.setPrivileges();
  }

  constructor(private dialog: MatDialog, private userService: UserService, private router: Router, private auth: AuthService) {    
    this.hash = this.hashKeyWebSafe(this.email);
  }

  
  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  @Output() doNextEdit = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  async doSave(type: string) { 
    debugger
    
    if (this.formAdd.valid) {

      //let pass = custom.sha256(this.formAdd.controls["newPassword"].value);
      //let oldpassword = custom.sha256(this.formAdd.controls["oldpassword"].value);
      
      let pass = this.hashKeyWebSafe(this.formAdd.controls["newPassword"].value);
      let oldpassword = (this._profileEdit)? this.hashKeyWebSafe(this.formAdd.controls["oldpassword"].value):'';

      let request = [{
        userid: this.user?.userid,
        oldpassword: (this._profileEdit) ? oldpassword  : '',
        newpassword: pass,
        ResetPasswordonNextLogin: this.resetpasswordonnextlogin ? 1 : 0,
        loginuserid: -1 
      }];
      this.onShowLoading.emit(true);
      this.userService.setUserSecurity(request).then(res => {
        this.onShowLoading.emit(false);
        if (type != 'next') {
          let data: ConfirmDialogData = {} as ConfirmDialogData;
          data.showNo = true;
          data.message = res[0]?.p_errormessage;
          data.title = 'Information!';
          data.showYes = false;
          data.noText = 'OK';
          this.onUpdate.emit();
          //if (res[0]?.p_result == 1)
          Confirmation(this.dialog, data);
        }
        else {
          this.onUpdate.emit();
          this.doNextEdit.emit(2)
        }
      })
    }
    else {
      this.formAdd.markAllAsTouched();
    }

  }
  checkPassword(type: number) {
    return custom.checkPassword(this.formAdd.controls.newPassword.value, type);
  }

  getUserSecurity(userid: number) {
    const request = [{ userid: userid }]
    this.userService.getUserSecurity(request).then(res => {
      this.resetpasswordonnextlogin = res[0]?.resetpasswordonnextlogin ?? false
    })
  }
  
  hashKeyWebSafe(str: string) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(str))
      .replace(/\//g, '_')
      .replace(/\+/g, '-');
  }
  async openDialog() {
    const dlgRef =  this.dialog.open(PasswordgeneratorComponent,
       {
         disableClose: true,
         autoFocus: false,
         data: {},
         panelClass: 'confirm-dialog-containerS',
         width: '600px',
         height: '285px',
      });
      dlgRef.afterClosed().subscribe(w => {
        console.log(w);
        this.formAdd.controls.newPassword.setValue(w);
        this.formAdd.controls.confirmNewPassword.setValue(w);
    })
  }
  setPrivileges() {
    this.hasUserSecurity_Edit = this.auth.hasPrivilege(PrivilegeValues.UserSecurity_Edit);
  }

}
