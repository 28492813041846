import { Pipe, PipeTransform } from '@angular/core';
import { getAge } from '../Utility/common-functions';

@Pipe({
  name: 'displayAge'
})
export class DisplayAgePipe implements PipeTransform {

  transform(value: any): any {
    
    return getAge(value);
  }

}
