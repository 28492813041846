<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content> <!--*ngIf="P_showCalendar"-->
      <full-calendar #calendarRef [options]="calendarOptions"></full-calendar>
    </mat-card-content>
  </mat-card>
</div>

<div id="contextMenu" *ngIf="contextMenuVisible" [ngStyle]="contextMenuPosition" class="context-menu">
  <ul>
    <li (click)="onAdhocRun($event)" class="cursor-pointer">Adhoc Re-run</li>
    <li (click)="onForceQuite($event)" class="cursor-pointer">Force Quit</li>
  </ul>
</div>

<app-data-loader [isLoading]="isLoading"></app-data-loader>
