<section class="table-container" tabindex="0">
    <form [formGroup]="form">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
            <mat-form-field fxFlex="0 0 12em">
                <mat-select placeholder="From Table" formControlName="fromTable" required [(ngModel)]="fromTable" (ngModelChange)="onInputChange($event,{},'fromTable')">
                    <mat-option *ngFor="let table of tables" [value]="table">{{table.name}}</mat-option>
                </mat-select>
                <mat-error>Please select the Table</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 0 12em">
                <mat-select placeholder="Join Type" formControlName="joinTable" required [(value)]="joinLookup">
                    <mat-option *ngFor="let join of joins" [value]="join">{{join.description}}</mat-option>
                </mat-select>
                <mat-error>Please select the Table</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 0 12em">
                <mat-select placeholder="To Table" formControlName="toTable" required [(ngModel)]="toTable" (ngModelChange)="onInputChange($event,{},'toTable')">
                    <mat-option *ngFor="let table of tables" [value]="table">{{table.name}}</mat-option>
                </mat-select>
                <mat-error>Please select the Table</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 0 10em">
                <input matInput placeholder="Name" formControlName="tableName" autocomplete="off" [value]="_tempTable">
            </mat-form-field>
            <button mat-mini-fab color="primary" style="float:right" (click)="addColumn()">
                <mat-icon>library_add</mat-icon>
            </button>
        </div>
    </form>
</section>
<section class="table-container" tabindex="0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="matMenuColumn" [matMenuTriggerData]="{item: element}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="tableName">
            <th mat-header-cell *matHeaderCellDef>Table</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="width:320px !important">
                    <mat-select required [(value)]="element.tableName">
                        <mat-option [value]="fromTable?.name">{{fromTable?.name}}</mat-option>
                        <mat-option [value]="toTable?.name">{{toTable?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Column </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="width:320px !important">
                    <mat-select required [ngModel]="getElementValue(element,'columnId')" (ngModelChange)="onInputChange($event,element,'columnId')">
                        <mat-option *ngFor="let column of fromTable?.columns" [value]="column.id">{{column.name}}</mat-option>
                        <mat-option *ngFor="let column of toTable?.columns" [value]="column.id">{{column.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef>Column As </th>
            <td mat-cell *matCellDef="let element">
                <input matInput placeholder="Name" autocomplete="off" [(value)]="element.displayName" (change)="onInputChange($event,element,'displayName')">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="block-Row"></tr>
    </table>
    <mat-menu #matMenuColumn="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onDeleteColumn(item)">Delete</button>
        </ng-template>
    </mat-menu>
</section>
<!-- *ngIf="toTable!=null && fromTable!=null" -->
<section class="table-container" tabindex="0">
    <app-rules-condition [toTable]="toTable" [fromTable]="fromTable"></app-rules-condition>
</section>
