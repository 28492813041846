import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { compare, FormatDate } from '../../../Utility/CustomValidators.utilities';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { WorkqueueService } from '../../workqueue.service';

@Component({
  selector: 'app-workqueue-list',
  templateUrl: './workqueue-list.component.html',
  styleUrls: ['./workqueue-list.component.css']
})
export class WorkqueueListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  public sortedData = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['actions', 'color', 'order', 'name', 'description', 'isActive', 'count'];
  //, 'createdBy', 'Createdon', 'modifiedBy', 'modifiedOn'
  public pageSize = 10;
  public sort: Sort = { 
    active: 'order',
    direction: 'asc'
  }
;
  public _WorkqueueList: any[] = [];
  public hasWorkqueue_Edit: boolean = false;
  public hasWorkqueue_Delete: boolean = false;

  ngOnInit() {
    this.setPrivileges();
  }

  @Output() onEditWorkqueue = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Input() set workqueueList(Workqueues: any[]) {    
    this._WorkqueueList = Workqueues;
    this.sortAndFilterData();
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  get workqueueList() {

    return this._WorkqueueList;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;

  }

  constructor(
    private dialog: MatDialog, private workqueueService: WorkqueueService, private auth: AuthService) {
    this.sortedData.data = this.workqueueList;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortAndFilterData() {
    let filteredData = this.workqueueList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;
    });




    if (!this.sort) {
      this.sortedData.data = filteredData;
      return;
    }
    else if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: any, b: any) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name':
          return compare(a.QueueName.toLowerCase(), b.QueueName.toLowerCase(), isAsc);
        case 'description':
          return compare(a.Description.toLowerCase(), b.Description.toLowerCase(), isAsc);
        case 'count':
          return compare(a.Count as number, b.Count as number, isAsc);
        case 'order': 
          return compare(a.Order as number, b.Order as number, isAsc);
        case 'createdBy':
          return compare(a.createdby.toLowerCase(), b.createdby.toLowerCase(), isAsc);
        case 'createdon':
          return compare(a.createdon, b.createdon, isAsc);
        case 'modifiedBy':
          return compare(a.modifiedby.toLowerCase(), b.modifiedby.toLowerCase(), isAsc);
        case 'modifiedOn':
          return compare(a.modifiedon, b.modifiedon, isAsc);
        case 'isActive':
          return compare(a.isactive as number, b.isactive as number, isAsc);
        default:
          return 0;
      }
    });
  }
  DateFormate(DateString: string, formateString: string) {
    return FormatDate(DateString, formateString);
  }
  doEditUser(Workqueue: any) {
    this.onEditWorkqueue.emit(Workqueue);
  }
  async doDeleteUser(Workqueue: any) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: Workqueue._id,
    }]
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.workqueueService.deleteWorkqueue(Workqueue._id).then((res:any) => {
        data.message = res?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  async doDeactivate(Workqueue: any,active:number) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: Workqueue._id,
    }]
    data.message = `Are you sure you want to make this queue ${((active==0)? "deactivate" : "activate")}?`;
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.workqueueService.deactivateWorkqueue(Workqueue._id, active).then((res: any) => {
        data.message = res?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }
  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();

  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';   
    this.menuFilter.openMenu();
  }
    
  doShowDetails(item: any) {
    if (this.hasWorkqueue_Edit) {
      this.onEditWorkqueue.emit(item);
    }
  }
  setPrivileges() {
    this.hasWorkqueue_Edit = this.auth.hasPrivilege(PrivilegeValues.WorkqueueManage_Edit);
    this.hasWorkqueue_Delete = this.auth.hasPrivilege(PrivilegeValues.WorkqueueManage_Delete);

    if (!this.hasWorkqueue_Edit && !this.hasWorkqueue_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }
}
