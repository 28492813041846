import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'task';
  constructor(private http: HttpClient) { }

  public async getWorkqueues(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getWorkqueuesExport(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/export`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;``
  }
  public async downloadWorkqueuesfile(filename: string, calbasckfun: any): Promise<any> {
    return this.http
      .get(`${this.baseUrl}/${this.baseApi}/${this.service}/file/export/${filename}`, {
        "headers": {
          contentType: "application/octet-stream",
        },
        responseType: 'blob'
      })
      .subscribe((res: any) => { 
        let url = window.URL.createObjectURL(res);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        calbasckfun();
      });


  }
  public async getQueues(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/queues`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async getTagsLookup(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/tags/lookup`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async getQueueLookup(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/queue/lookup`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async getTags(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/tags`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async getTaskLogs(id: string): Promise<any> {

    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/Logs/${id}`,
      httpOptionsJSON).toPromise();
    return result;

  }
  public async getTaskDetails(id: string): Promise<any> {

    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      //JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async setActions(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setaction`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  public async setNotes(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setnotes`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
}
