<section class="edit-container-popup" *ngIf="data?.node?.data?.type==1">
    <!-- <mat-card-title>
        Connector
    </mat-card-title>
    <mat-divider></mat-divider> -->

    <form [formGroup]="formAdd" class="popup-form">
        <div fxLayout="column" fxLayoutGap="1em">

            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('tablename')">
                <small id="name" class="form-text text-muted">Table Name<span style="color:red" *ngIf="isRequired('tablename')">*</span>: </small>
                <div>
                    <input type="text" matInput formControlName="tablename" style="width: 100% !important;" class="form-control" placeholder="Table Name" />
                    <mat-error *ngIf="!isValidInput('tablename') && formAdd.get('tablename')?.touched">
                        Table name is required
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('uniquepracticeid')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Practice<span style="color:red" *ngIf="isRequired('uniquepracticeid')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.practices" [selectedItems]="data?.node?.data?.filters?.uniquepracticeid" [placeholder]="'Practice'" #practice></app-multi-select>
                    <mat-error *ngIf="!isValidInput('uniquepracticeid') && formAdd?.touched">
                        Select practice
                    </mat-error>
                </div>
            </div>


            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('datetype')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Date Type<span style="color:red" *ngIf="isRequired('datetype')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.dateType" [selectedItems]="data?.node?.data?.filters?.datetype" [singleSelection]="'true'" [placeholder]="'Date Type'" #dateType></app-multi-select>
                    <mat-error *ngIf="!isValidInput('datetype') && formAdd?.touched">
                        Select date type
                    </mat-error>
                </div>
            </div>

            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('runOn')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Date Range<span style="color:red" *ngIf="isRequired('runOn')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.runOn" [selectedItems]="data?.node?.data?.filters?.runOn" [singleSelection]="'true'" [placeholder]="'Run for'" #runOn></app-multi-select>
                    <mat-error *ngIf="!isValidInput('runOn') && formAdd?.touched">
                        Select date range
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('reducepercentage')">
                <small id="name" class="form-text text-muted">Reduce Percentage<span style="color:red" *ngIf="isRequired('reducepercentage')">*</span>:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="reducepercentage" class="form-control" placeholder="0.00" (change)="castToFloat($event,'reducepercentage','float')">
                    <mat-error *ngIf="!isValidInput('reducepercentage') && formAdd?.touched">
                        Reduce percentage is required
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('excludecptCodes')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Exclude CPT<span style="color:red" *ngIf="isRequired('excludecptCodes')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.excludeCpts" [selectedItems]="data?.node?.data?.filters?.excludecptCodes" [addNewOption]="'true'" [placeholder]="'Cpt Codes'" #excludecpt></app-multi-select>
                    <mat-error *ngIf="!isValidInput('excludecptCodes') && formAdd?.touched">
                        Select CPT
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('providerTypeId')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Provider Type<span style="color:red" *ngIf="isRequired('providerTypeId')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.providerType" [selectedItems]="data?.node?.data?.filters?.providerTypeId" [singleSelection]="'true'" [placeholder]="'Provider Type'" #providerType></app-multi-select>
                    <mat-error *ngIf="!isValidInput('providerTypeId') && formAdd?.touched">
                        Select provider type
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('providerOrderSequence')">
                <small id="name" class="form-text text-muted">Provider Order Sequence:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="providerordersequence" class="form-control" placeholder="0" (change)="castToFloat($event,'providerordersequence','int')">
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('uniqueServicelocationid')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Service Location</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.serviceLocation" [selectedItems]="data?.node?.data?.filters?.serviceLocation" [placeholder]="'Service Location'" #serviceLocation></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('cptCodes')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">CPT</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.excludeCpts" [selectedItems]="data?.node?.data?.filters?.cptCodes" [addNewOption]="'true'" [placeholder]="'Cpt Codes'" #cptcodes></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('asaCodes')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Asa Codes</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.asacodes" [selectedItems]="data?.node?.data?.filters?.asaCodes" [addNewOption]="'true'" [placeholder]="'Asa Codes'" #asacodes></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('defaultunits')">
                <small id="name" class="form-text text-muted">Default Units:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="defaultunits" class="form-control" placeholder="0.00%" (change)="castToFloat($event,'defaultunits','int')">
                    <mat-error *ngIf="!isValidInput('defaultunits') && formAdd?.touched">
                        Enter defaultunits or Select CPT/Asa Codes
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('isanesthesia')">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="anesthesia">

                    <label class="form-check-label" for="flexSwitchCheckChecked">Anesthesia</label>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('excludeModifiers')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Exclude Modifiers</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.modifiers" [selectedItems]="data?.node?.data?.filters?.excludemodifiers" [addNewOption]="'true'" [placeholder]="'Modifiers'" #excludemodifiers></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('includeModifiers')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Include Modifiers</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.modifiers" [selectedItems]="data?.node?.data?.filters?.includemodifiers" [addNewOption]="'true'" [placeholder]="'Modifiers'" #includemodifiers></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('includeoccurances')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Include Occurances</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.occurances" [selectedItems]="data?.node?.data?.filters?.includeoccurances" [addNewOption]="'true'" [placeholder]="'Occurances'" #includeoccurances></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('excludeoccurances')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Exclude Occurances</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.occurances" [selectedItems]="data?.node?.data?.filters?.excludeoccurances" [addNewOption]="'true'" [placeholder]="'Occurances'" #excludeoccurances></app-multi-select>
                </div>
                <mat-error *ngIf="!isValidInput('occurances')">
                    Select Serevice Location/CPT Codes/Asa Codes/Include Occurances/Exclude Occurances
                </mat-error>
            </div>

            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('workingDayswithTime')">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Working Days With Time<span style="color:red" *ngIf="isRequired('workingDayswithTime')">*</span>:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.workingDays" [selectedItems]="data?.node?.data?.filters?.workingDayswithTime" [addNewOption]="'true'" [placeholder]="'Working Days'" #workingDays></app-multi-select>
                    <mat-error *ngIf="!isValidInput('workingDayswithTime')">
                        Select/Enter working days with time
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="canEdit('distributeTimeUnitsFactor')">
                <small id="name" class="form-text text-muted">Time Units Factor<span style="color:red" *ngIf="isRequired('distributeTimeUnitsFactor')">*</span>:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="distributeTimeUnitsFactor" class="form-control" placeholder="0.00" (change)="castToFloat($event,'distributeTimeUnitsFactor','float')">
                    <mat-error *ngIf="!isValidInput('distributeTimeUnitsFactor') && formAdd?.touched">
                        Time Units Factor is required
                    </mat-error>
                </div>
            </div>


        </div>
    </form>

    <mat-divider></mat-divider>

    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" (click)="onSaveConnector()">OK</button>
        <!-- <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button> -->
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
    </div>
</section>


<app-payroll-start *ngIf="data?.node?.data?.id==0" (onCancel)="doCancel($event)" [data]="data"></app-payroll-start>
<app-payroll-distribute-time-units-for-over-time *ngIf="data?.node?.data?.type==2" (onCancel)="doCancel($event)" [data]="data"></app-payroll-distribute-time-units-for-over-time>
<app-update-custom-time-units *ngIf="data?.node?.data?.type==3" (onCancel)="doCancel($event)" [data]="data"></app-update-custom-time-units>
