import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, public dialogRef: MatDialogRef<ConfirmDialogComponent>) {

  }
  public doYes() {
    this.dialogRef.close(true);
  }

  public doNo() {
    this.dialogRef.close(false);
  }
}


// Utility method to open dialog and return result (yes/no)
export async function Confirmation(dialog: MatDialog, data: ConfirmDialogData): Promise<boolean> {  
  const dlgRef = dialog.open(ConfirmDialogComponent,
    {
      disableClose: true,
      autoFocus: false,
      data: data,
      panelClass: 'confirm-dialog-container',
      width: '400px',
      height: '190px',
    });
  console.log(data)  
  return await dlgRef.afterClosed().toPromise();
}
