import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as custom from '../../../Utility/CustomValidators.utilities'
import { getConverted12HourTime } from '../../../Utility/common-functions';
import * as moment from 'moment';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { BotDashboardService } from '../bot-dashboard.service';

@Component({
  selector: 'app-adhocrun-action',
  templateUrl: './adhocrun-action.component.html',
  styleUrls: ['./adhocrun-action.component.css']
})
export class AdhocrunActionComponent {
  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdhocrunActionComponent>,
    private botDashboardService: BotDashboardService) {
    this.loadDetails();
  }
  currentUTCDate: Date = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds());
  minutesToAdd = 3; // create a future date
  currentDate = new Date();
  futureDate = new Date(this.currentDate.getTime() + this.minutesToAdd * 60000);
  minStartDate: Date = this.futureDate;
  currentTimeHours: number = this.futureDate.getHours();
  currentTimeMinutes: number = this.futureDate.getMinutes();
  twelveHourTime: string = getConverted12HourTime(this.currentTimeHours.toString(), this.currentTimeMinutes.toString(), true);
  waiting: boolean = false;
  isLoading: boolean = false;

  // data receiving from summary-list
  schedule_instance_id: number = 0;
  event_id: string = "";
  schedule_type_id: number = 0;
  server_ip: string = "";
  server_name: string = "";
  automation_id: number = 0;
  automation_name: string = "";
  automation_location: string = "";

  formAdd: FormGroup = new FormGroup({
    startDate: new FormControl(this.futureDate, [custom.validateDate]),
    startTime: new FormControl(this.twelveHourTime)
  });

  loadDetails() {
    this.schedule_instance_id = this.data.schedule_instance_id;
    this.event_id = this.data.event_id;
    this.schedule_type_id = this.data.schedule_type_id;
    this.server_ip = this.data.server_ip;
    this.server_name = this.data.server_name;
    this.automation_id = this.data.automation_id;
    this.automation_name = this.data.automation_name;
    this.automation_location = this.data.automation_location;
  }

  doCancelEdit(item: boolean) {
    this.doReset();
    this.dialogRef.close(item);
  }

  doReset() {
    this.formAdd.controls.startDate.setValue(this.futureDate);
    this.formAdd.controls.startTime.setValue(this.twelveHourTime);
  }

  canSave() {
    let result = true;
    let P_startDate = this.formAdd.controls.startDate.value;
    let P_startTime = this.formAdd.controls.startTime.value;

    if (P_startDate == null || P_startDate == undefined || P_startDate == "") { result = false; }
    if (P_startTime == null || P_startTime == undefined || P_startTime == "") { result = false; }

    return result;
  }
  onBlurDate(dp: any) {
    var date = moment(this.formAdd.controls.startDate.value);
    const status = date.isValid();
    if (!status) {
      this.formAdd.controls.startDate.setValue("");
    }
    //this.currentValue = +dp.input.currentValue.split('/')[0];
  }
  async onAction() {
    let startDate = new Date(this.formAdd.controls.startDate.value).toLocaleDateString();
    let startTime = getConverted12HourTime(this.formAdd.controls.startTime.value.split(":")[0],
      this.formAdd.controls.startTime.value.split(":")[1],
      false);

    let request = [{
      "ScheduleLogID": -1,
      "ScheduleID": this.schedule_instance_id,
      "EventID_google": this.event_id,
      "Status": 0,
      "ScheduleLogDetails": JSON.stringify([
        {
          "AdhocRun": true,
          "startDate": startDate,
          "startTime": startTime,
          "ServerIP": this.server_ip,
          "ServerName": this.server_name,
          "AutomationId": this.automation_id,
          "AutomationName": this.automation_name,
          "AutomationLocation": this.automation_location
        }
      ]),
      "ScheduleTypeID": this.schedule_type_id
    }]
    this.isLoading = true;
    this.botDashboardService.setScheduleLog(request).then((res: any) => {
      this.isLoading = false;

      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.message = res[0]?.message;
      data.title = 'Information!';
      data.showYes = false;
      data.showNo = true;
      data.noText = 'OK';
      Confirmation(this.dialog, data);

      this.doCancelEdit(true);
    })
  }
}
