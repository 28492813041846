<div class="row" style="margin: auto;margin-top: 15px;">
    <section class="table-container" tabindex="0">
        <table mat-table [dataSource]="dataSource" class="mat-table-remit-info" style="width: 95%;margin: auto;" multiTemplateDataRows>
            <!-- cpt Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon> {{element.expanded?'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="payor">
                <th mat-header-cell *matHeaderCellDef>Payor </th>
                <td mat-cell *matCellDef="let element"> {{element.payor}} </td>
            </ng-container>
            <ng-container matColumnDef="Billed">
                <th mat-header-cell *matHeaderCellDef> Billed </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.billed)?0:element.billed" [usd-money-cell]="true">
                </td>
            </ng-container>

            <ng-container matColumnDef="Paid">
                <th mat-header-cell *matHeaderCellDef> Paid </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.paid)?0:element.paid" [usd-money-cell]="true">
                </td>
            </ng-container>

            <ng-container matColumnDef="Allowed">
                <th mat-header-cell *matHeaderCellDef> Allowed </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.allowed)?0:element.allowed" [usd-money-cell]="true">
                </td>
            </ng-container>
            <ng-container matColumnDef="ClaimStatusCode">
                <th mat-header-cell *matHeaderCellDef> Claim Status Code </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.claim_status_code }}
                </td>
            </ng-container>
            <ng-container matColumnDef="CheckNo">
                <th mat-header-cell *matHeaderCellDef> Check # </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.check_number }}
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                        <div class="row remit-payor-detail-row">
                            <div fxLayout="column" fxLayoutGap=".5em">
                                <div class="row">
                                    <div class="form-group col-lg-2">
                                        <h4>
                                            Transaction Info
                                        </h4>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Check Id:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.check_id??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Payer Identifier:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.payer_identifier??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Payment Method Code:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.payement_menthod_code??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Sender Dfi Identifier:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.sender_dfi_identifier??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Sender Bank Account:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.sender_bank_account_no??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Receiver Identifier:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.receiver_identifier??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Receiver Or Provider Account:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.receiver_or_provider_account_no??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Receiver Or Provider Bank Id:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.receiver_or_provider_bank_id_no??''}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row remit-payor-detail-row">
                        </div>
                        <div class="row remit-payor-detail-row">
                            <div fxLayout="row" fxLayoutGap="1em">
                                <div class="col-lg-6">
                                    <h4>
                                        Payer
                                    </h4>
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Identifier:</div>
                                            <div class="col-md-6"> {{element?.PAYER_INFO?.identifier??''}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">name:</div>
                                            <div class="col-md-6"> {{element?.PAYER_INFO?.name??''}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Address:</div>
                                            <div class="col-md-6"> {{element?.PAYER_INFO?.payer_address??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <h4>
                                        Payee
                                    </h4>
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Identifier:</div>
                                            <div class="col-md-6"> {{element?.PAYEE_INFO?.identifier??''}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">name:</div>
                                            <div class="col-md-6"> {{element?.PAYEE_INFO?.name??''}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Address:</div>
                                            <div class="col-md-6"> {{element?.PAYEE_INFO?.payer_address??''}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row remit-payor-detail-row">
                        </div>
                        <div class="row remit-payor-detail-row">
                            <div class="form-group col-lg-2">
                                <h3>
                                    Claim Details
                                </h3>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-lg-12" fxLayout="column" fxLayoutGap=".5em">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Claim Reference Id:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.claim_reference_id??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Claim Filing Indicator Code:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.claim_filing_indicator_code??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Facility Type Code:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.facility_type_code??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Patient Name:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.patient_name??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Patient Policy Number:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.patient_policy_number??''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Provider Name:</div>
                                            <div class="col-md-6">{{element?.TRANSACTION_INFO?.provider_name??''}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 claim-details-title">Provider Npi:</div>
                                            <div class="col-md-6"> {{element?.TRANSACTION_INFO?.provider_npi??''}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row remit-payor-detail-row">
                            <app-remit-line-list [LineData]="element.LINE_LEVEL_INFO"></app-remit-line-list>
                        </div>
                    </div>

                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="example-element-row"
                [class.example-expanded-row]="element.expanded"
                (click)="onExpandRow(element)">
            </tr>

            <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" [ngClass]="{'display-row':  !row.expanded}"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">No record(s) found.</td>
            </tr>
        </table>
    </section>
    <mat-paginator #paginator [pageSize]="pageSize" [showFirstLastButtons]="false">
    </mat-paginator>
</div>
