<mat-list fxLayout="column" fxLayoutGap=".2em" *ngIf="queueLabel.length!=0">
    <ng-container *ngFor="let item of queueLabel;index as i">

        <mat-list-item role="listitem" class="mat-list-content-p-0" [ngClass]="{'active': item._id==queueFilter?._id && item.username==queueFilter?.username}" *ngIf="expanded || i<maxItemCount" [ngStyle]="{'border-left':queueType=='queue'? '5px '+item.color+' solid;':''}">
            <button mat-button style="width: 100%;" class="btn show-more-btn" (click)="doFilter(item,queueType)">

                <mat-icon class="float-end" *ngIf="item.data">{{ item.expanded?'expand_less':'expand_more'}}</mat-icon>
                <span class="float-start ellipses-on-line-1" [title]="item._id?item._id:'Other'"> {{(item._id)?item._id:'Other'}} </span>
                <span class="float-end" [app-number]="item.count" [app-number-cell]="false" *ngIf="!item.data"></span>
            </button>
        </mat-list-item>
        <mat-list-item role="listitem" class="mat-list-content-p-0" *ngIf="queueLabel?.length>maxItemCount && i==maxItemCount-1">
            <button mat-button (click)="expanded=!expanded" style="width:100%;" class="btn show-more-btn">
                {{(expanded)?'Less':'More'}}
                <mat-icon>{{ expanded ?'expand_less':'expand_more'}}</mat-icon>
            </button>
        </mat-list-item>
        <mat-list style="margin-left:5px;" *ngIf="item.expanded">
            <div *ngFor="let subItem of item.data">
                <mat-list-item role="listitem" class="mat-list-content-p-0" [ngClass]="{'active': subItem._id==queueFilter?._id && subItem.username==queueFilter?.username}">
                    <button mat-button style="width: 100%;" class="btn" (click)="doFilter(subItem,queueType)">
                        <span class="float-start ellipses-on-line-1" [title]="subItem._id?subItem._id:'Other'"> {{(subItem._id)?subItem._id:'Other'}} </span>
                        <span class="float-end" [app-number]="subItem.count" [app-number-cell]="false"></span>
                    </button>
                </mat-list-item>
            </div>
        </mat-list>
    </ng-container>
</mat-list>

<mat-list fxLayout="column" fxLayoutGap=".2em" *ngIf="queueLabel.length==0">

    <mat-list-item role="listitem" class="mat-list-content-p-0">
        No Record found
    </mat-list-item>
</mat-list>
