import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-payroll-input',
  templateUrl: './payroll-input.component.html',
  styleUrls: ['./payroll-input.component.css']
})
export class PayrollInputComponent {
  public _lookupData: any;
  @Input() set data(data: any) {
    this._lookupData = data;
    this.calculateValues();
  }
  get data() {
    return this._lookupData;
  }
  @ViewChild('practice') _practice!: MultiSelectComponent;
  @ViewChild('runOn') _runOn!: MultiSelectComponent;
  @ViewChild('dateType') _dateType!: MultiSelectComponent;
  @ViewChild('providerType') _providerTypeId!: MultiSelectComponent;
  @ViewChild('cptcodes') _cptCodes!: MultiSelectComponent;
  @ViewChild('asacodes') _asaCodes!: MultiSelectComponent;
  @ViewChild('excludecpt') _excludecpt!: MultiSelectComponent;
  @ViewChild('excludemodifiers') _excludemodifiers!: MultiSelectComponent;
  @ViewChild('includemodifiers') _includemodifiers!: MultiSelectComponent;
  @ViewChild('excludeoccurances') _excludeoccurances!: MultiSelectComponent;
  @ViewChild('includeoccurances') _includeoccurances!: MultiSelectComponent;
  @ViewChild('serviceLocation') _serviceLocation!: MultiSelectComponent;
  @ViewChild('workingDays') _workingDays!: MultiSelectComponent;
  formAdd: FormGroup = new FormGroup({
    tablename: new FormControl(''),
    // dayOfMonth: new FormControl(''),
    reducepercentage: new FormControl(''),
    providerordersequence: new FormControl(''),
    anesthesia: new FormControl(''),
    defaultunits: new FormControl(''),
  });
  @Output() onCancel = new EventEmitter();
  onSaveConnector() {

    if (this.isvalid()) {
      let item = {
        tablename: this.formAdd.controls.tablename.value,
        uniquepracticeid: this._practice?.selectedItems ?? [],
        datetype: this._dateType?.selectedItems ?? [],
        runOn: this._runOn?.selectedItems ?? [],
        reducepercentage: this.formAdd.controls.reducepercentage.value,
        cptCodes: this._cptCodes?.selectedItems ?? [],
        asaCodes: this._asaCodes?.selectedItems ?? [],
        providerOrderSequence: this.formAdd.controls.providerordersequence.value,
        providerTypeId: this._providerTypeId?.selectedItems ?? [],
        isanesthesia: this.formAdd.controls.anesthesia.value ? 1 : 0, /* 1 for anesthesia  and 0 for non anesthesia rows */
        defaultunits: this.formAdd.controls.defaultunits.value, /* should be used when we need to update hardcode value */
        excludemodifiers: this._excludemodifiers?.selectedItems ?? [], /*multiple input*/
        includemodifiers: this._includemodifiers?.selectedItems ?? [], /*multiple input*/
        excludeoccurances: this._excludeoccurances?.selectedItems ?? [], /*multiple input*/
        includeoccurances: this._includeoccurances?.selectedItems ?? [], /*multiple input*/
        excludecptCodes: this._excludecpt?.selectedItems ?? [], /*multiple input*/
        serviceLocation: this._serviceLocation?.selectedItems ?? [], /*multiple input*/
        workingDays: this._workingDays?.selectedItems ?? [], /*multiple input*/
      }
      this.onCancel.emit(item);
    }
  }
  doReset() {
    this.formAdd.controls.tablename.setValue('');
  }
  doCancelEdit() {
    this.doReset();
    this.onCancel.emit(false);
  }
  castToFloat(item: any, control: string, type: string) {
    let val = parseFloat(this.formAdd.controls[control].value);
    if (type == 'int') {
      val = parseInt(this.formAdd.controls[control].value);
    }

    if (isNaN(val)) {
      this.formAdd.controls[control].setValue('0');
    }
    else if (val > 100) {
      this.formAdd.controls[control].setValue('0');
    }
    else {
      this.formAdd.controls[control].setValue(val);
    }
  }
  isRequired(item: string) {
    return this.data.node.data.inputdata[item] == 1;
  }
  canEdit(item: string) {
    return this.data.node.data.inputdata[item] == 1 || this.data.node.data.inputdata[item] == 0;
  }
  isvalid() {
    this.formAdd.markAllAsTouched();
    let inputdata = this.data.node.data.inputdata;
    let result = true;
    Object.keys(inputdata).forEach(s => {
      if (!this.isValidInput(s)) {
        result = false;
      }
    });

    return result;
  }
  isValidInput(input: string) {
    switch (input) {
      case 'tablename':
      case 'reducepercentage': {
        return !this.isRequired(input) || (this.isRequired(input) && this.formAdd.controls[input].value != '');;
      }
      case 'defaultunits': {
        return this.formAdd.controls[input].value != '' ||
          (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0
          ;
      }
      case 'occurances': {
        return (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._serviceLocation?.selectedItems?.length ?? 0) > 0 ||
          (this._excludeoccurances?.selectedItems?.length ?? 0) > 0 ||
          (this._includeoccurances?.selectedItems?.length ?? 0) > 0
          ;
      }
      case 'workingDayswithTime':
        {
          return !this.isRequired(input) || ((this._workingDays?.selectedItems?.length??0) > 0)
        }
      case 'uniquepracticeid':
        {
          return !this.isRequired(input) || (this._practice?.selectedItems && this._practice?.selectedItems?.length > 0)
        }
      case 'datetype':
        {
          return !this.isRequired(input) || (this._dateType?.selectedItems && this._dateType?.selectedItems?.length > 0)
        }
      case 'runOn':
        {
          return !this.isRequired(input) || (this._runOn?.selectedItems && this._runOn?.selectedItems?.length > 0)
        }
    }



    return true;
  }
  calculateValues() {
    if (this.data && this.data?.node?.data?.filters) {
      let filters = this.data?.node?.data?.filters;
      let lookups = this.data?.lookupData;
      this.formAdd.controls.tablename.setValue(filters.tablename)
      this.formAdd.controls.reducepercentage.setValue(filters.reducepercentage)
      this.formAdd.controls.providerordersequence.setValue(filters.providerOrderSequence)
      this.formAdd.controls.anesthesia.setValue(filters.isanesthesia == 1)
      this.formAdd.controls.defaultunits.setValue(filters.defaultunits)
      if (filters.cptCodes?.length > 0) {
        filters.cptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
      if (filters.excludecptCodes?.length > 0) {
        filters.excludecptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
    }
  }
}
