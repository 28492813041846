import { Injectable } from '@angular/core';
import { UserInfo } from '../entities/userInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.states';

import { environment } from '../../environments/environment';
import * as custom from '../Utility/CustomValidators.utilities';
import { PrivilegeKey } from '../entities/Privilege';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Authenticate';
  public currentUser: UserInfo;
  public isLoggedIn: boolean = false;

  public privileges: PrivilegeKey = {};
  constructor(private http: HttpClient, private router: Router, private store: Store<AppState>) {
    this.currentUser = {} as UserInfo;

  }
  async checkLogin() {
    try {      
      const res = await this.validateLogin({});
      this.setUserDetails(res);
    }
    catch (ex) {
      this.isLoggedIn = false;
      this.redirectToLogin();
    }
  }
  setUserDetails(user: any) {
    if (user) {
      this.currentUser = user as UserInfo;
      if (this.currentUser.token != "") {
        this.currentUser.isLoggedIn = true;
        this.currentUser.privilege.map(priv => {
          if (priv) {
            this.privileges[priv] = true;
          }
        });
        this.isLoggedIn = true;
        this.store.dispatch({
          type: 'login',
          payload: this.currentUser
        });
        if (this.router.url == "/login") {
          location.href = this.currentUser.homeUrl;
          /*location.href = '/dashboard'*/

        }
      }
    } else {
      //
      this.redirectToLogin();
    }
  }
  async validateLogin(request: any) {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/validate`,
      JSON.stringify(request),
      custom.httpOptions()).toPromise().catch(e => {
        console.log(e);
        if (this.router.url != "/forgotpassword" && this.router.url != "/login") {
          this.router.navigateByUrl("login");
        }
      });
    return result;
  }
  async logoutRedirect( ) {

    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/logout`,
      custom.httpOptions()).toPromise();
    return result;
  }
  async getLogin(request: any) {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/Authenticate`,
      JSON.stringify(request),
      custom.httpOptions()).toPromise();
    return result;
  }
  public hasPrivilege(privilege: string): boolean {
    return (this.privileges[privilege] != null);
  }
  async redirectToLogin() {
    if (this.router.url != "/forgotpassword" && this.router.url != "/login") {
      this.router.navigateByUrl("login");
    }
  }
  // User permission and routing guard checks
  public async isRouteAllowed(route: ActivatedRouteSnapshot) {
    if (!this.currentUser.isLoggedIn) {
      return false;
    }

    if (route.data && (route.data.roles || route.data.privileges)) {
      return (route.data.privileges && this.userHasPrivilege(route.data.privileges));
    }
    return false; // If no role definitions, no-one can access
  }
  public userHasPrivilege(privileges: string[]): boolean {

    for (const priv of privileges) {
      if (this.currentUser.privilege.findIndex(f => f === priv) !== -1) {
        return true;
      }
    }
    return false;
  }

}


