import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { Role, RoleMenu } from '../role';
import { RoleService } from '../role.service';



type permissionKeys = 'view' | 'add' | 'edit' | 'delete' | 'click';
@Component({
  selector: 'app-role-menus',
  templateUrl: './role-menus.component.html',
  styleUrls: ['./role-menus.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RoleMenusComponent {
  public dataSource: RoleMenu[] = [];
  public roleMenu_data: RoleMenu[] = [];
  public expandedRoleMenu!: RoleMenu | null;
  displayedColumns: string[] = [ 'blank','Menu', 'name','all', 'weight', 'symbol', 'one', 'two'];
  displayedGroup: string[] = [ 'groupHeader','name', 'all', 'weight', 'symbol', 'one', 'two'];
  public _role!: Role;
  roleMenus!: RoleMenu[];
  groupExpand: boolean = false;
  public _isLoading: boolean = false;
  public hasRoleMenu_Edit: boolean = false;
  @Output() onUpdateEdit = new EventEmitter();
  @Input() set role(_role: Role) {
    this._role = _role;
    if (_role && _role.roleid != -1) {
      this.getMasterDetails(_role.roleid)
    }
  }
  get role() {
    return this._role;
  }

  constructor(private roleService: RoleService, private dialog: MatDialog, private auth: AuthService) { }

  ngOnInit(): void {
    this.setPrivileges();
  }
  getMasterDetails(id: number) {
    let request = [{
      menuid: -1,
      roleid: id,
      userid:-1
    }]
    this.roleService.getRoleMenus(request).then(data => {
      this.roleMenu_data = data;
      debugger;
      console.log(this.roleMenu_data)
      console.log(this)
      this.calulateMenus();
    });
  }
  calulateMenus() {
 
    const parentMenus = this.filterData(-1); 
    let localMenus: RoleMenu[] = [];
    parentMenus.forEach(element => {
      if (element.menuname != "Dashboard") {
 
      localMenus.push(element);
      const Menus = this.filterData(element.menuid);      
        Menus.forEach(menu => {
          localMenus.push(menu);
          const subMenu = this.filterData(menu.menuid);
          if (subMenu.length > 0) {            
            subMenu.forEach(smenu => {
              localMenus.push(smenu);
            });
          }
        });
      }
    }); 
    this.roleMenus = localMenus.map((w: any, idx: number) => {
       console.log(w)
      let s: RoleMenu = {} as RoleMenu;
      s.add = w.add == 1;
      s.edit = w.edit==1;
      s.click = w.click==1;
      s.delete = w.delete == 1;
      s.view = w.view == 1;

      s.add_ = w.add;
      s.edit_ = w.edit;
      s.delete_ = w.delete;
      s.view_ = w.view;

      s.sectionnametagname = w.sectionnametagname;
      s.menuname = w.menuname;
      s.menuid = w.menuid;
      s.menuparentid = w.menuparentid;
      s.roleid = w.roleid;
      s.aclPermissionId = w.aclpermissionsid;

    
      if (w.add == 1 && w.edit == 1 && w.view == 1 && w.delete == 1) {
        s.aclPermissionId = 1;
      }      
        if (w.sectionnametagname != "Grid" && w.sectionnametagname != null) {
          s.menuname = "";
        }
        else {
          s.sectionnametagname = "";
        }
      
      return s;
      
    });    
    this.dataSource = this.roleMenus;

    console.log("dataSource: ", this.dataSource);
  }

  filterData(ParentMenuId: number): RoleMenu[] {
    //
    return this.roleMenu_data.filter(item => item.menuparentid === ParentMenuId);
  }

  isGroup = (index: any, item: RoleMenu) => {
    return this.roleMenu_data.filter(w => w.menuparentid === item.menuid).length > 0 || item.menuparentid==-1;
  }
  hasChild = (item: RoleMenu) => {
    return this.roleMenu_data.filter(w => w.menuparentid === item.menuid).length > 0;
  }

  onCheckboxChange(event: any, element: RoleMenu, permissionType: permissionKeys) {   
    if (permissionType.toString() == 'all') {      
      element[`view`] = event.checked;
      element[`add`] = event.checked;
      element[`edit`] = event.checked;
      element[`delete`] = event.checked;
    }    
    element[`${permissionType}`] = event.checked;
    console.log('Update successful:', this.dataSource);
    if (permissionType.toString() != 'view' && event.checked) {
      element[`view`] = event.checked;
    }

    if (permissionType.toString() == 'view' && !event.checked) {
      if (element[`add`])
        element[`add`] = event.checked;
      if (element[`edit`])
        element[`edit`] = event.checked;
      if (element[`delete`])
        element[`delete`] = event.checked;      
    }

    if (element.edit_ == -1)
      element[`edit`] = event.checked;
    if (element.add_ == -1)
      element[`add`] = event.checked;
    if (element.delete_ == -1)
      element[`delete`] = event.checked;
    if (element.view_ == -1)
      element[`view`] = event.checked;
    
  }

  visible(row: any): boolean {
    let parent = this.dataSource.find(w => { return w.menuid == row.menuparentid })

    if (parent && parent?.menuparentid != -1) {
      let subParent = this.dataSource.find(w => { return w.menuid == parent?.menuparentid })
      if (subParent)
        return parent.expanded && subParent.expanded;
      else return true;
    }
    if (parent)
      return parent.expanded;
    else return true;
  }
  groupHeaderClick(row: any) {
    row.expanded = !row.expanded;
   
  }
  haveAllpermision(row: RoleMenu) {
    return (row.view || row.view == null) &&
      (row.add || row.add == null) &&
      (row.edit || row.edit == null) &&
      (row.delete || row.delete == null);
  }


  saveRoles() {
    console.log("Records saved")
  }
   
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit();
  }

  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {
    
    console.log(this.roleMenus)
    this.onShowLoading.emit(true);
   
    let request = this.roleMenus.filter(w => { return w.add || w.click || w.view || w.delete || w.edit}).map(w => {
      console.log(w)
      return {
        menuid: w.menuid,
        roleid: this._role.roleid,
        aclpermissionsid: w.aclPermissionId,
        add: w.add_ == -1 ? -1 : w.add ? 1 : 0,
        edit: w.edit_ == -1 ? -1 : w.edit ? 1 : 0,
        delete: w.delete_ == -1 ? -1 : w.delete ? 1 : 0,
        view: w.view_ == -1 ? -1 : w.view ? 1 : 0,
        click: w.click ? 1 : 0,
        loginuserid: -1
      }
    });
    console.log(request)

    //this.roleMenus.filter(w => { return w.add || w.click || w.delete || w.delete || w.edit || w.aclPermissionId }).map(w => { return w.menuparentid })

    let parentmenu = this.roleMenus.filter(w => { return w.add || w.click || w.view || w.delete || w.edit }).map(w => {
      return w.menuparentid
    })
    
    var filteredArray = parentmenu.filter(function (item, pos) {
      return parentmenu.indexOf(item) == pos && item != -1;
    });


    filteredArray.map(w => { request.push(
    {
      menuid: w,
      roleid: this._role.roleid,
      aclpermissionsid: this.roleMenus.find(s => s.menuid == w)?.aclPermissionId??-1,      
      add: 0,
      edit: 0,
      delete: 0,
      view: 1,
      click:0,
      loginuserid: -1
      })
    });
    

    this.roleService.setRoleMenus(request).then(res => {
      this.onShowLoading.emit(false);
      if (type != 'next') {
        // this.onUpdateEdit.emit(this._role);
        console.log(res)
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.message = res[0]?.errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onUpdateEdit.emit(this._role);
      }
      else {
        this.onUpdateEdit.emit(this._role);
        this.doNextEdit.emit(2);
      }
    });

  }
  @Output() onShowLoading = new EventEmitter();
  setPrivileges() {
    this.hasRoleMenu_Edit = this.auth.hasPrivilege(PrivilegeValues.RoleMenu_Edit);
  }
 // @Output() onUpdateEdit = new EventEmitter();
}
