import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../user/user.service';
import { Role } from '../role';
import { RoleService } from '../role.service';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.css']
})
export class RoleDetailsComponent {
  
  @Output() onCancelEdit = new EventEmitter();
  @Output() onUpdateEdit = new EventEmitter();
  //@Output() Loader = new EventEmitter();

  
  public formAdd: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.maxLength(250)]),
    isActive: new FormControl(true),
  });
  public _role!: Role;
  isEdit!: boolean
  checkboxActive!: boolean
  public _isLoading: boolean = false;
  public hasRole_Edit: boolean = false;

  ngOnInit(): void {
    this.setPrivileges();
    //console.log(this._role)
    //if (this._role.roleid != -1) {
    //  this.isEdit = true;
    //  this.checkboxActive = this._role.isactive

    //}
    //else {
    //  this.checkboxActive = true
    //}
    //console.log(this.checkboxActive)
  }
  doCancelEdit() {
    this.onCancelEdit.emit();
  }
 
  @Input() set role(_role: Role) {
    this._role = _role; 
    if (_role && _role.roleid != -1)
      this.getRoleDetails();    
   }
  get role() {
    return this._role;
  }
  constructor(private dialog: MatDialog, private fb: FormBuilder, private roleService: RoleService, private userService: UserService, private auth: AuthService) { }
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {
    //this.Loader.emit();
    if (this.formAdd.valid) {
     
      let roleNew: any[] = [{
        roleid: this.role?.roleid ?? -1,
        name: this.formAdd.controls.name.value,
        description: this.formAdd.controls.description.value,
        isActive: this.formAdd.controls.isActive.value ? 1 : 0,
        createdBy: 1
      }];      
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.title = 'Information!';
      data.showYes = true;
      data.noText = 'NO';
      data.yesText = 'YES';
    
      let update = true;
      if (roleNew[0].isActive == 0 && this._role.isactive != roleNew[0].isActive) {
        data.message = 'Are you sure you want to in active this role?';
        update = await Confirmation(this.dialog, data);
      }
      if (update) {
        //this._isLoading = true;
        this.onShowLoading.emit(true);
        let lookups: any[] = [];
        if (roleNew[0].isActive == 0) {
          lookups = await Promise.all([
            this.roleService.setRoles(roleNew),
            this.userService.setUserRoles([{
              userid: -2,
              roleid: this._role.roleid,
              typeid: 2,
              loginuserid: -1
            }]), this.roleService.setRoleMenus([{
              menuid: -2,
              roleid: this._role.roleid,
              aclpermissionsid:0,
              add : 0,
              edit: 0,
              delete: 0,
              view:0,
              click:0,
              loginuserid: -1
            }])
          ]);
        }
        else {
          lookups = await Promise.all([
            this.roleService.setRoles(roleNew)
          ]);
        }
        this._isLoading = false;
        this.onShowLoading.emit(false);

        if (lookups[0] && lookups[0].length > 0) {
 
          data.message = lookups[0][0]?.roleerrormessage;
          data.showYes = false;
          data.noText = 'OK';
          if (type != 'next' || !this.formAdd.controls.isActive.value) {
            if (lookups[0][0]?.result == 1) {              
              await Confirmation(this.dialog, data);
              this._role.rolename = roleNew[0].name
              this._role.isactive = roleNew[0].isActive
              this._role.roledescription = roleNew[0].description
              this._role.roleid = lookups[0][0]?.p_roleid
              this.onUpdateEdit.emit(this._role);
            }
            else {
              await Confirmation(this.dialog, data)
            }
          }
          else {
            if (type == 'next' && lookups[0][0]?.result == 0) {
              await Confirmation(this.dialog, data)
            }
            else {
              this.onUpdateEdit.emit(this._role);
              this.doNextEdit.emit(1);
            }
          }

        }
      

      }
    }
  
  
    
     else {
        this.formAdd.markAllAsTouched();
        return;
      }
    
   
  }
  getRoleDetails() {
    this.formAdd.controls.name.setValue(this.role.rolename);
    this.formAdd.controls.description.setValue(this.role.roledescription);
    this.formAdd.controls.isActive.setValue(this.role.isactive);
 
  }
  @Output() onShowLoading = new EventEmitter();
  setPrivileges() {
    this.hasRole_Edit = this.auth.hasPrivilege(PrivilegeValues.RoleManage_Edit);
  }   

}
