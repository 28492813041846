import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-task-aging-details',
  templateUrl: './task-aging-details.component.html',
  styleUrls: ['./task-aging-details.component.css']
})
export class TaskAgingDetailsComponent {
  displayedColumnsAr: string[] = ['name', 'Bu1', 'Bu2', 'Bu3', 'Bu4', 'Bu5'];
  dataSourceAr = new MatTableDataSource<any>([]);
  @Input() set arData(arData: any[]) { 
    this.dataSourceAr.data = arData;
  }
   
}
