import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-task-payor-list',
  templateUrl: './task-payor-list.component.html',
  styleUrls: ['./task-payor-list.component.css']
})
export class TaskPayorListComponent {
  public _task: any;
  displayedColumns: string[] = ['seq', 'payorname', 'payorgroupname', 'isCurrent'];
  dataSource = new MatTableDataSource<any>([]);
  @Input() set task(task: any) {
    this._task = task;
    this.calculateAr();
  }
  get task() {
    return this._task;
  }
  calculateAr() {
    if (this._task) {
      let data = [];
      if (this._task.PM_System_PrimaryPayorname != "" || this._task.PM_System_Primarypayorgroupname != "") {
        data.push({
          seq: 1,
          payorgroupname: this._task.PM_System_Primarypayorgroupname,
          payorname: this._task.PM_System_PrimaryPayorname,
          isCurrent: this._task.PM_System_Primarypayorgroupname == this._task.PM_System_Currentpayorgroupname && this._task.PM_System_PrimaryPayorname == this._task.PM_System_CurrentPayorname
        })
      }
      if (this._task.PM_System_SecondaryPayorname != "" || this._task.PM_System_Secondarypayorgroupname != "") {
        data.push({
          seq: 2,
          payorgroupname: this._task.PM_System_Secondarypayorgroupname,
          payorname: this._task.PM_System_SecondaryPayorname,
          isCurrent: this._task.PM_System_SecondaryPayorname == this._task.PM_System_Currentpayorgroupname && this._task.PM_System_SecondaryPayorname == this._task.PM_System_CurrentPayorname
        })
      }
      this.dataSource.data = data;
    }
  }
}
