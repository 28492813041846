import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MultiSelectComponent } from '../../../../shared/multi-select/multi-select.component';

@Component({
  selector: 'app-payroll-distribute-time-units-for-over-time',
  templateUrl: './payroll-distribute-time-units-for-over-time.component.html',
  styleUrls: ['./payroll-distribute-time-units-for-over-time.component.css']
})
export class PayrollDistributeTimeUnitsForOverTimeComponent {
  public _lookupData: any;
  @Input() set data(data: any) {
    this._lookupData = data;
    this.calculateValues();
  }
  get data() {
    return this._lookupData;
  }

  @ViewChild('providerType') _providerTypeId!: MultiSelectComponent;
  @ViewChild('cptcodes') _cptCodes!: MultiSelectComponent;
  formAdd: FormGroup = new FormGroup({
    distributeTimeUnitsFactor: new FormControl('', [Validators.required]),
    OverTimedistributeTimeUnitsFactor: new FormControl('', [Validators.required]),
    OverTimeStart: new FormControl('', [Validators.required]),
    OvertimeEnd: new FormControl('', [Validators.required])
  });
  @Output() onCancel = new EventEmitter();
  onSaveConnector() {    
    if (this.formAdd.valid) {
      let item: any = {
        tablename:"",
        uniquepracticeid: "",
        RegulardistributeTimeUnitsFactor: this.formAdd.controls.distributeTimeUnitsFactor.value, /* value which need to be use for distribution factor */
        OverTimedistributeTimeUnitsFactor: this.formAdd.controls.OverTimedistributeTimeUnitsFactor.value, /* value which need to be use for distribution factor */
        OverTimeStart: this.formAdd.controls.OverTimeStart.value,// --over time satrt time
        OvertimeEnd: this.formAdd.controls.OvertimeEnd.value, //--over time end time
        providerTypeId: this._providerTypeId?.selectedItems, /*servicing or directing*/ /* multiple input*/
        cptCodes: this._cptCodes?.selectedItems, /* multiple input cptcode|units,cptcode|units*/
        p_Isfirststep: 3 /* to identify if it firststep in flow  1 for first else 0 */
      };
      this.onCancel.emit(item);
    }
    else {
      this.formAdd.markAllAsTouched();
    }
  }
  doCancelEdit() {
    this.doReset();
    this.doCancel(false);
  }
  doReset() {
    this.formAdd.controls.distributeTimeUnitsFactor.setValue('');
  }
  doCancel(item: any) {
    this.onCancel.emit(item);
  }
  castToFloat(item: any, control: string, type: string) {
    let val = parseFloat(this.formAdd.controls[control].value);
    if (type == 'int') {
      val = parseInt(this.formAdd.controls[control].value);
    }

    if (isNaN(val)) {
      this.formAdd.controls[control].setValue('0');
    }
    else if (val > 100) {
      this.formAdd.controls[control].setValue('0');
    }
    else {
      this.formAdd.controls[control].setValue(val);
    }
  }
  calculateValues() {
    if (this.data && this.data?.node?.data?.filters) {
      let filters = this.data?.node?.data?.filters;
      let lookups = this.data?.lookupData;
      this.formAdd.controls.distributeTimeUnitsFactor.setValue(filters.RegulardistributeTimeUnitsFactor)
      this.formAdd.controls.OverTimedistributeTimeUnitsFactor.setValue(filters.OverTimedistributeTimeUnitsFactor)
      this.formAdd.controls.OverTimeStart.setValue(filters.OverTimeStart)
      this.formAdd.controls.OvertimeEnd.setValue(filters.OvertimeEnd);
      if (filters.cptCodes?.length > 0) {
        filters.cptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
      if (filters.excludecptCodes?.length > 0) {
        filters.excludecptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
    }
  }

}
