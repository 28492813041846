import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condition } from '../../../../entities/condition-entity';
import { TableList } from '../../../../entities/table-list';
import { TableService } from '../../../../services/table.service';

@Component({
  selector: 'app-column-selection',
  templateUrl: './column-selection.component.html',
  styleUrls: ['./column-selection.component.css']
})
export class ColumnSelectionComponent {

  tables: TableList[] = [];
  @Input() toTable!: TableList;
  @Input() fromTable!: TableList;
  public condition!: Condition;
  @Input('condition')
  set getCondition(condition: Condition) {
    this.condition = condition;
    //this.form.controls.id.setValue(this.condition.id);
    this.form.controls.condition.setValue(this.condition.condition);
    this.form.controls.fromColumn.setValue(this.condition.fromColumn);
    this.form.controls.oprator.setValue(this.condition.oprator);
    this.form.controls.toColumn.setValue(this.condition.toColumn);
  }
  public form: FormGroup = new FormGroup({
    condition: new FormControl('', [Validators.required]),
    fromColumn: new FormControl('', [Validators.required]),
    oprator: new FormControl('', [Validators.required]),
    toColumn: new FormControl('', [Validators.required])
  });   
  constructor(tableService: TableService) {     
    this.tables = tableService.getTableList();
     
  }
  modo(item: any) {
    console.log(item);
    this.toTable = item.value;
  }
}
