import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-notification-setting',
  templateUrl: './user-notification-setting.component.html',
  styleUrls: ['./user-notification-setting.component.css']
})

export class UserNotificationSettingComponent {
  public dataSource = new MatTableDataSource<any>([]);
  public notifications: any[] = [];
  displayedColumns: string[] = ['type', 'email', 'browser'];
  public assignAll: boolean = false;
  public _user!: User;
  public _isLoading: boolean = false;
  public hasUserNotification_Edit: boolean = false;

  ngOnInit(): void {
    this.setPrivileges();
  }
  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid  && user.userid != -1) {
      this.getNotificationSetting(user.userid)
    }
  }
  get user() {
    return this._user;
  }

  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
  }
  get profileEdit() {
    return this._profileEdit;
  }
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router, private auth: AuthService) {
    
  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
  }

  getNotificationSetting(userid: number) {
    const request = [{ userid: userid}]
    this.userService.getNotificationSetting(request).then(res => {
      this.notifications = res.map((s: any) => {
        s.isemail = s.isemail==1
        s.isbrowser = s.isbrowser == 1
        return s
      });

      this.dataSource.data = this.notifications;
    })
  }

  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) { 
    let request = this.notifications.map(w => {
      return {
        userid: this.user.userid,
        notificationtypeid: w.notificationtypeid,
        isbrowser: (w.isbrowser) ? 1 : 0,
        isemail: (w.isemail)?1:0,
        loginuserid: -1
      }
    });
    
    this.onShowLoading.emit(true);
    this.userService.setNotificationSetting(request).then(res => {
      this.onShowLoading.emit(false);
      if (type != 'next') {
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.message = res[0]?.errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        this.onUpdate.emit();
        Confirmation(this.dialog, data);
      }
      else {
        this.onUpdate.emit();
        this.doNextEdit.emit(3)
      }

    });
  }
  onCheckboxChange(event: any, element: any, type: number) {
     
    switch (type) {
      case 1:{
        element.isemail = event.checked;
        break;
      }
      case 2: {
        element.isbrowser = event.checked;
        break;
      }
    }
    
    //this.assignAll = this.notifications.filter(w => { return w.assigned }).length == this.notifications.length;
  }

  setPrivileges() {
    this.hasUserNotification_Edit = this.auth.hasPrivilege(PrivilegeValues.UserNotification_Edit);
  }
}
