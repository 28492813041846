import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EncounterService } from '../../../../services/encounter.service';
 
@Component({
  selector: 'app-task-remit',
  templateUrl: './task-remit.component.html',
  styleUrls: ['./task-remit.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TaskRemitComponent {
  public _task: any; 
  //dataSource = ELEMENT_DATA;
  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand']; 
  @Input() set task(task: any) { 
    this._task = task;
    this.financialRemit();
  }
  get task() {
    return this._task;
  }
  constructor(private encounterService: EncounterService) {

  }
  displayedColumns: string[] = ['action','payor', 'Billed', 'Paid', 'Allowed', 'ClaimStatusCode', 'CheckNo'];
  detailsColumns: string[] = ['details'];
  dataSource = new MatTableDataSource<any>([]);
  public pageSize: number = 10;
  @ViewChild("paginator") set matPaginator(mp: MatPaginator) {
    this.dataSource.paginator = mp;
  }
  financialRemit() {
    if (this._task) {
      let request: any = {
        "pagesize": 1000,
        "pagestartrecord": 0,
        "clientid": this._task?.UniquePracticeID,
        "encounterid": this._task?.encounterid
      };
      this.encounterService.financialRemit(request).then(res => {
        let data = JSON.parse(res.table[0]["data"]);
        console.log(data);
        if (data?.length>0) {           
          data[0].expanded = true;
        }
        this.dataSource.data = data; 
      });

    }
  }
  isExpanded = (index: any, item: any) => {
    return item.expanded;
  }
  isGroup = (index: any, item: any) => {
    return true;
  }
  onExpandRow(item: any) {
    if (!item.expanded) {
      let data = this.dataSource.data.map(w => { w.expanded = false; return w}); 
    }
    item.expanded = !item.expanded;
  }
}
