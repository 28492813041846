import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TableList } from '../entities/table-list';
import { Condition, Group, RuleAction } from '../entities/condition-entity';
import { environment } from '../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})

export class RuleService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Table';
  public conditions: any[] = [
    {
      "id": 1,
      "groupId": -1,
      "group": "Block-1",
      "isStart": true,
      "isGroup": true,
      "expanded": true
    },
    {
      "groupId": 1,
      "id": 4,
      "condition": "AND",
      "fromColumn": 3,
      "toColumn": "14",
      "oprator": "1",
      "value": "1",
      "isStart": true,
      "isGroup": false,
      "expanded": true
    },
    {
      "id": 5,
      "groupId": 1,
      "condition": "AND",
      "fromColumn": 6,
      "toColumn": "14",
      "oprator": "3",
      "value": "200",
      "isStart": false,
      "isGroup": false,
      "expanded": true
    },
    {
      "id": 3,
      "groupId": 1,
      "group": "Block-3",
      "isStart": false,
      "isGroup": true,
      "condition": "OR",
      "expanded": true
    },
    {
      "id": 7,
      "groupId": 3,
      "condition": "AND",
      "fromColumn": 3,
      "toColumn": "14",
      "oprator": "1",
      "value": "1",
      "isStart": true,
      "isGroup": false,
      "expanded": true
    },
    {
      "id": 1719324538549,
      "condition": "AND",
      "fromColumn": 6,
      "toColumn": "14",
      "oprator": "6",
      "value": "200",
      "isStart": false,
      "isGroup": false,
      "groupId": 3
    },
    {
      "id": 2,
      "groupId": -1,
      "group": "Block-2",
      "isStart": false,
      "isGroup": true,
      "condition": "AND",
      "expanded": true
    },
    {
      "id": 6,
      "groupId": 2,
      "condition": "AND",
      "fromColumn": 7,
      "toColumn": "14",
      "oprator": "3",
      "value": "1000",
      "isStart": true,
      "isGroup": false,
      "expanded": true
    }
  ];

  public actions: RuleAction[] = [
    {
      "id": 1,
      "tableName": "Visits",
      "column": "VisitStatus",
      "value": "Done"
    },
    {
      "id": 1,
      "tableName": "Visits",
      "column": "UserId",
      "value": "1"
    }
  ];

  public actionConditions: any[] = [
    {
      "id": 1,
      "groupId": -1,
      "group": "Block-1",
      "isStart": true,
      "isGroup": true,
      "expanded": true
    },
    {
      "groupId": 1,
      "id": 4,
      "condition": "",
      "fromColumn": 3,
      "toColumn": "14",
      "oprator": "1",
      "value": "1",
      "isStart": true,
      "isGroup": false,
      "expanded": true
    },
    {
      "id": 5,
      "groupId": 1,
      "condition": "AND",
      "fromColumn": 6,
      "toColumn": "14",
      "oprator": "3",
      "value": "200",
      "isStart": false,
      "isGroup": false,
      "expanded": true
    },   
    {
      "id": 1719324538549,
      "condition": "AND",
      "fromColumn": 6,
      "toColumn": "14",
      "oprator": "6",
      "value": "200",
      "isStart": false,
      "isGroup": false,
      "groupId": 3
    },
    {
      "id": 2,
      "groupId": -1,
      "group": "Block-2",
      "isStart": false,
      "isGroup": true,
      "condition": "OR",
      "expanded": true
    },
    {
      "id": 6,
      "groupId": 2,
      "condition": "",
      "fromColumn": 7,
      "toColumn": "14",
      "oprator": "3",
      "value": "1000",
      "isStart": true,
      "isGroup": false,
      "expanded": true
    }
  ];
  constructor(private http: HttpClient) { }
  public getRuleFilterList(): (Condition | Group)[] {    
    return this.conditions;
  }
  public getRuleAction(): RuleAction[] {
    return this.actions;
  }
  public getRuleActionConditions(): (Condition | Group)[] {
    return this.actionConditions;
  }
}
