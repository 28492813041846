import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as custom from '../Utility/CustomValidators.utilities';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'comment';
  constructor(private http: HttpClient) {

  }
   
  async getNotifications(request: any): Promise<any> {
    if (!request) {
      request = {}
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/notifications`,
      JSON.stringify(request),
      custom.httpOptions()).toPromise();
    return result;
  }
}
