<table mat-table [dataSource]="dataSourceAr" class="mat-elevation-z8" style="width: 95%;margin: auto;">
    <!-- Position Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Bu1">
        <th mat-header-cell *matHeaderCellDef> 0-30 </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Bu1!='' || element.Bu1==0" [usd-money]="element.Bu1" [usd-money-cell]="true"></span>
            <span *ngIf="element.Bu1=='' && element.Bu1!=0"> - </span>

        </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Bu2">
        <th mat-header-cell *matHeaderCellDef> 31-60 </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Bu2!='' || element.Bu2==0" [usd-money]="element.Bu2" [usd-money-cell]="true"></span>
            <span *ngIf="element.Bu2=='' && element.Bu2!=0"> - </span>
        </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Bu3">
        <th mat-header-cell *matHeaderCellDef>61-90 </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Bu3!='' || element.Bu3==0" [usd-money]="element.Bu3" [usd-money-cell]="true"></span>
            <span *ngIf="element.Bu3=='' && element.Bu3!=0"> - </span>
        </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="Bu4">
        <th mat-header-cell *matHeaderCellDef>91-120 </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Bu4!='' || element.Bu4 ==0" [usd-money]="element.Bu4" [usd-money-cell]="true"></span>
            <span *ngIf="element.Bu4=='' && element.Bu4 !=0"> - </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="Bu5">
        <th mat-header-cell *matHeaderCellDef>120+ </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Bu5!='' || element.Bu5==0 " [usd-money]="element.Bu5" [usd-money-cell]="true"></span>
            <span *ngIf="element.Bu5=='' && element.Bu5!=0 "> - </span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsAr"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsAr;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter ""</td>
    </tr>
</table>
