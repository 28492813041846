<div class="body-wrapper">
    <div class="body-wrapper-login">
        <mat-card id="card" style="text-align: center;" class="login-main-card" [ngClass]="{'login-main-error':error!=''}">
            <mat-card-title class="login-card-header">
                <span fxShow="false" fxShow.gt-xs>
                    <img [src]="(!themeService.isDarkMode())?'assets/images/logo-white.png':'assets/images/Logo-dark.png'" id="topLogoImageLogin" class="login-logo" />
                </span>
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content class="login-card-body">
                <h3 class="mb-1">Welcome to ARCalytics! 👋</h3>
                <p class="mb-6">Please sign-in to your account and start the adventure</p>
                <form [formGroup]="form" (ngSubmit)="submit()" (keyup.enter)="submit()">

                    <div fxLayout="column" fxLayoutGap=".5em">
                        <div class="form-group col-lg-12">
                            <small id="Email" class="form-text text-muted">Email Or Username</small>
                            <input style="margin-top:10px" type="text" class="form-control" placeholder="Enter your email" formControlName="username" />

                        </div>
                        <div class="form-group  col-lg-12 inner-addon right-addon">
                            <small id="Email" class="form-text text-muted">Password</small>
                            <div class="input-group col-6 mx-auto">

                                <input name="password"
                                       [type]="showPassword ? 'text' : 'password'"
                                       class="form-control pwd-control"
                                       id="password-field" placeholder="Enter your password" style="margin-top:10px;border-radius:10px" formControlName="password">
                                <div>
                                  <mat-icon matSuffix (click)="showPassword = !showPassword"
                                            [class.hide]="!showPassword">visibility</mat-icon>
                                  <mat-icon matPrefix (click)="showPassword = !showPassword"
                                            [class.hide]="showPassword">visibility_off</mat-icon>
                                </div>
                            </div>
                        </div>

                        <div class="my-8">
                            <div class="d-flex justify-content-between">
                                <div class="form-check mb-0 ms-2">
                                    <input class="form-check-input" type="checkbox" id="remember-me">
                                    <label class="form-check-label" for="remember-me">
                                        Remember Me
                                    </label>
                                </div>
                                <a [routerLink]="['/forgotpassword']">
                                    <p class="mb-0">Forgot Password?</p>
                                </a>
                            </div>
                        </div>

                        <div class="my-8" *ngIf="error">
                            <div class="d-flex justify-content-between">
                                <mat-error *ngIf="error" class="error">
                                    {{ error }}
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </form>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions class="login-card-action">
                <div class="my-8">
                    <div class="d-flex justify-content-between">
                        <button mat-raised-button class="login-card-action-button" (click)="submit()" color="primary">Login</button>
                    </div>
                </div>
                <div class="my-8">
                    <mat-divider flex style="width: 45%;float: left;"></mat-divider>
                    Or
                    <mat-divider flex style="width: 45%;float: right;"></mat-divider>
                </div>
                <div class="my-8">
                    <div class="d-flex justify-content-between">

                        <button type="button" id="submitButton" class="login-card-action-button" style="position:relative!important;margin-top: 10px;" mat-raised-button (click)="loginRedirect()" color="primary">
                            <span class="wrap">Login with SSO</span>
                            <span class="icon fa fa-windows" style="padding-left: 5%;"></span>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
            
        </mat-card>
    </div>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>
