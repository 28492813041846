import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _darkMode = false;

  constructor() {
    let thememode: string | null = localStorage.getItem("thememode");
    if (thememode != null) {
      this.toggleTheme(parseInt(thememode))
    }
    else {
      this.toggleTheme(0)
    }
  }



  toggleTheme(mode: number) {
    this._darkMode = mode == 1;
    if (this._darkMode) {
      document.body.classList.add('dark-theme');
      //var P_logoImage = document.getElementById("topLogoImage");
      //P_logoImage?.setAttribute("src", "assets/images/Logo-dark.png");

      //var P_logoImage = document.getElementById("topLogoImageLogin");
      //P_logoImage?.setAttribute("src", "assets/images/Logo-dark.png");
    } else {
      document.body.classList.remove('dark-theme');
      //var P_logoImage = document.getElementById("topLogoImage");
      //P_logoImage?.setAttribute("src", "assets/images/logo-white.png");

      //var P_logoImage = document.getElementById("topLogoImageLogin");
      //P_logoImage?.setAttribute("src", "assets/images/logo-white.png");
    }
    localStorage.setItem("thememode", mode.toString());
  }

  isDarkMode() {
    return this._darkMode;
  }
}
