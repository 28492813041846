import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Role, RoleMenu } from './role';
const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'roles';
  constructor(private http: HttpClient) { }

  //public getRoles(): Observable<Role[]> {
  //  return this.http.get<Role[]>("./assets/data/roles.json");
  //}
  public async getRole(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    
    return result;
    
  }
  public async getRoleDetails(id: number): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }

  async getRoleMenus(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/menus`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
      
    return result;
  }

  async setRoles(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async deleteRole(request: any[]): Promise<any> {        
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/deleterole`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setRoleMenus(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setmenus`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
}
