import { Component, ViewChild } from '@angular/core';
import { Table } from '../../entities/table-entity';
import { ColumnSelection } from '../../entities/table-list';
import { CreateTableComponent } from './create-table/create-table.component';
import { RuleMappingComponent } from './rule-mapping/rule-mapping.component';
import { RulesJoinsComponent } from './rules-joins/rules-joins.component';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent {
  public CreateTable: Table;
  public tableName = 'Testing';
  public selected = 4;
  public selectedColumn: ColumnSelection[]=[];
  @ViewChild(CreateTableComponent) ruleCreateTable!: CreateTableComponent;
  @ViewChild(RuleMappingComponent) ruleMappingTable!: RuleMappingComponent;
  @ViewChild(RulesJoinsComponent) ruleJoinsTable!: RulesJoinsComponent;
  constructor() {
    this.CreateTable = {
      name: 'Testing',
      columns: [{
        id: 1,
        name: "id",
        dataType: "INT",
        size: 0,
        scal: 0,
        isNotNull: false,
        default: "",
        sequence: 0,
      }, {
        id: 2,
        name: "Name",
        dataType: "VARCHAR",
        size: 0,
        scal: 0,
        isNotNull: false,
        default: "",
        sequence: 0
      }, {
        id: 3,
        name: "ClaimStatus",
        dataType: "VARCHAR",
        size: 0,
        scal: 0,
        isNotNull: false,
        default: "",
        sequence: 0
      }, {
        id: 4,
        name: "VisitId",
        dataType: "INT",
        size: 0,
        scal: 0,
        isNotNull: false,
        default: "",
        sequence: 0
      }, {
        id: 5,
        name: "TransactionId",
        dataType: "INT",
        size: 0,
        scal: 0,
        isNotNull: false,
        default: "",
        sequence: 0
      }, {
        id: 6,
        name: "Charges",
        dataType: "NUMERIC",
        size: 0,
        scal: 0,
        isNotNull: true,
        default: 0,
        sequence: 0
      }, {
        id: 7,
        name: "Payments",
        dataType: "NUMERIC",
        size: 10,
        scal: 2,
        isNotNull: true,
        default: 0,
        sequence: 0
      }, {
        id: 8,
        name: "Adjustments",
        dataType: "NUMERIC",
        size: 10,
        scal: 2,
        isNotNull: true,
        default: 0,
        sequence: 0
      }]
    } as Table;
  }
  tabClick(tab: any) {
    //
    //this.CreateTable = this.ruleCreateTable.Table;
    //this.ruleMappingTable.Table = this.CreateTable;

    if (tab.tab.textLabel == "Mapping" || tab.tab.textLabel == "Filters") {
      this.selectedColumn = this.ruleJoinsTable.selectedColumn;
      this.ruleMappingTable.tables = [this.ruleJoinsTable.form.controls.tableName.value];
    }
    console.log(this.selectedColumn);
  }
}
