
<div class="row" style="margin: auto;margin-top: 15px;">
    <div class="form-group col-lg-10">
        <h3>
            Charges
        </h3>
    </div>
    <section class="mat-table-charges-container" tabindex="0">
      <table mat-table [dataSource]="dataSourceCharges" class="mat-table-charges" style="width: 95%;margin: auto;">
        <!-- Position Column -->
        <ng-container matColumnDef="cpt">
          <th mat-header-cell *matHeaderCellDef> CPT </th>
          <td mat-cell *matCellDef="let element"> {{element.cpt}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dos">
          <th mat-header-cell *matHeaderCellDef> DOS </th>
          <td mat-cell *matCellDef="let element">
            {{element.dos | datePipeSafe: 'MM/DD/YYYY':true}}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="servicingprovider">
          <th mat-header-cell *matHeaderCellDef> servicing provider </th>
          <td mat-cell *matCellDef="let element">
            {{element.servicingprovider}}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="starttime">
          <th mat-header-cell *matHeaderCellDef>Start Time </th>
          <td mat-cell *matCellDef="let element">
            {{element.startime | datePipeSafe: 'MM/DD/YYYY':true}}
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="endtime">
          <th mat-header-cell *matHeaderCellDef>End Time </th>
          <td mat-cell *matCellDef="let element">
            {{element.endtime | datePipeSafe: 'MM/DD/YYYY':true}}
          </td>
        </ng-container>
        <ng-container matColumnDef="doe">
          <th mat-header-cell *matHeaderCellDef> DOE </th>
          <td mat-cell *matCellDef="let element">
            {{element.doe | datePipeSafe: 'MM/DD/YYYY':true}}
          </td>
        </ng-container>

        <ng-container matColumnDef="transactiondate">
          <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
          <td mat-cell *matCellDef="let element">
            {{element.transactiondate | datePipeSafe: 'MM/DD/YYYY':true}}
          </td>
        </ng-container>

        <ng-container matColumnDef="charges">
          <th mat-header-cell *matHeaderCellDef> Charges </th>
          <td mat-cell *matCellDef="let element">
            {{element.charges}}
          </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumnsCharges;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCharges; when: isCharges"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumnsCharges.length">No record(s) found.</td>
        </tr>
      </table>
    </section>
    <!-- <mat-paginator #paginatorCharges [pageSize]="10" [showFirstLastButtons]="true">
    </mat-paginator> -->
</div>

<div class="row" style="margin: auto;margin-top: 15px;">
    <div class="form-group col-lg-10">
        <h3>
            Payments / Adjustments
        </h3>
    </div>

    <section class="mat-table-charges-container" tabindex="0">
        <table mat-table [dataSource]="dataSourcePmt" class="mat-table-charges" style="width: 95%;margin: auto;">
            <!-- cpt Column -->
            <ng-container matColumnDef="cpt">
                <th mat-header-cell *matHeaderCellDef>CPT </th>
                <td mat-cell *matCellDef="let element"> {{element.cpt}} </td>
            </ng-container>
            <!-- transactiondate Column -->
            <ng-container matColumnDef="transactiondate">
                <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
                <td mat-cell *matCellDef="let element"> {{element.transactiondate | datePipeSafe: 'MM/DD/YYYY':true}} </td>
            </ng-container>


            <!-- paid_by Column -->
            <ng-container matColumnDef="paid_by">
                <th mat-header-cell *matHeaderCellDef> Paid By </th>
                <td mat-cell *matCellDef="let element">
                    {{element.paid_by}}
                </td>
            </ng-container>
            <!-- paid_by Column -->
            <ng-container matColumnDef="paymentcode">
                <th mat-header-cell *matHeaderCellDef> Payment Code </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.paymentcode }}
                </td>
            </ng-container>
            <ng-container matColumnDef="adjustmentcode">
                <th mat-header-cell *matHeaderCellDef> Adjustment Code</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.adjustmentcode }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ins_payments">
                <th mat-header-cell *matHeaderCellDef> ins payment </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.ins_payments)?0:element.ins_payments" [usd-money-cell]="true">
                    {{ element.ins_payments }}
                </td>
            </ng-container>
            <ng-container matColumnDef="pat_payments">
                <th mat-header-cell *matHeaderCellDef> Pat Payment </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.pat_payments)?0:element.pat_payments" [usd-money-cell]="true">
                    {{ element.pat_payments }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ins_adjustments">
                <th mat-header-cell *matHeaderCellDef> Ins Adjustment </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.ins_adjustments)?0:element.ins_adjustments" [usd-money-cell]="true">
                    {{ element.ins_adjustments }}
                </td>
            </ng-container>
            <ng-container matColumnDef="pat_adjustments">
                <th mat-header-cell *matHeaderCellDef> Pat Adjustment </th>
                <td mat-cell *matCellDef="let element" class="mat-column-number"  [usd-money]="(!element.pat_adjustments)?0:element.pat_adjustments" [usd-money-cell]="true">
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumnsPmt;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPmt; when: !isCharges"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumnsPmt.length">No record(s) found.</td>
            </tr>
        </table>
    </section>
   <!--  <mat-paginator #paginatorPayments [pageSize]="10" [showFirstLastButtons]="true">
    </mat-paginator> -->
</div>

