<mat-card>
  <mat-card-content>
    <div class="d-flex flex-wrap">
      <div>
        <mat-card-title>Sales Overview</mat-card-title>
        <mat-card-subtitle>Ample Admin Vs Material Admin</mat-card-subtitle>
      </div>
      <div class="ml-auto">
        <ul class="list-inline">
          <li>
            <h6 class="text-success m-0">
              <i class="mdi mdi-checkbox-blank-circle font-10 m-r-10"></i>Ample
            </h6>
          </li>
          <li>
            <h6 class="text-info m-0">
              <i class="mdi mdi-checkbox-blank-circle font-10 m-r-10"></i>Pixel
            </h6>
          </li>
        </ul>
      </div>
    </div>

    <div>
        <div fxLayout="row">

             
            <div fxLayout="column" class="hidden-overflow" fxFlex>

                <h4 class="color-primary" *ngIf="chartTitle">{{chartTitle}}</h4>

                <div class="chart-scroll">
                    <div class="chart-container">

                        <div fxlayout="row" fxLayoutAlign="center" *ngIf="dataLoading">
                            <mat-spinner color="accent"></mat-spinner>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        

    </div>
  </mat-card-content>
</mat-card>
