import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { Condition, Group } from '../../../entities/condition-entity';
import { ColumnSelection, TableList } from '../../../entities/table-list';
import { TableService } from '../../../services/table.service';
import { RuleService } from '../../../services/rule.service';
import { Table } from '../../../entities/table-entity';

@Component({
  selector: 'app-rule-filter',
  templateUrl: './rule-filter.component.html',
  styleUrls: ['./rule-filter.component.css']
})
export class RuleFilterComponent {
  public sortedData = new MatTableDataSource<(Condition | Group)>([]);
  displayedColumns: string[] = ['block', 'condition', 'fromColumn', 'oprator', 'columnValue'];
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  @Input() toTable!: TableList;
  @Input() fromTable!: TableList;
  public _createTable!: Table
  @Input() set Table(createTable: Table) {

    this._createTable = createTable as Table;
    
  }
   get table() {

     return this._createTable;

  }
  public conditions: (Condition | Group)[];
  constructor(tableService:TableService,ruleService:RuleService) {
    var tables=tableService.getTableList();
    this.conditions=ruleService.getRuleFilterList();
    this.fromTable=tables[0];
    this.toTable=tables[1];
    this.setConditions();
   }
   isGroup(index: any, item: any): boolean {
    return item.isGroup;
  }
  
  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextAddCondition(item: any) {
    let condition = {
      id: (new Date()).getTime(),
      condition: "AND",
      fromColumn: "19",
      toColumn: "14",
      oprator: "1",
      value: "1",
      isStart: false,
      isGroup:false,
      groupId:item.id
    } as Condition;
    this.conditions.push(condition);
    this.setConditions();
  }

  onContextMenuAction2(item: any) {
    //alert(`Click on Action 2 for ${item.name}`);
  }
  getConditionValue(element: Condition) {
    //
    return element.condition;
  }
  setConditionValue(element: Condition) {
    
    return element.condition;
  }
  setConditions() {
    let conditions:any[]=[];
    let groups= this.conditions.filter(w=> {
      if(w.groupId==-1) return true;
    });    
    groups.map((w,idx)=>{
      w.isStart=(idx==0);
      conditions.push(w);
      this.conditions.filter(s=>{return s.groupId==w.id}).map((s,indx)=>{
        s.isStart=(indx==0);
        conditions.push(s);
        this.conditions.filter(s1=>{return s1.groupId==s.id}).map((s1,indx1)=>{
            s1.isStart=(indx1==0);
            conditions.push(s1);

        })
       });
    });
    this.sortedData.data=conditions;
  }
 groupHeaderClick(row:any) {
    row.expanded = !row.expanded;
    //this.sortedData.filter = performance.now().toString();  // bug here need to fix
  }
  visible(row:any): boolean {
    let parent=this.sortedData.data.find(w=> {return w.id==row.groupId})
      
    if(parent && parent?.groupId!=-1)
    {
      let subParent=this.sortedData.data.find(w=> {return w.id==parent?.groupId})
       if(subParent)
         return parent.expanded && subParent.expanded;
       else return true;
    }
    if(parent)
      return parent.expanded;
    else return true;
  }

}
