import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 
  })
};

@Injectable({
  providedIn: 'root'
})
export class WorkqueueService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'workqueue';
  constructor(private http: HttpClient) { }
  public async getWorkqueues(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async setWorkqueues(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async deleteWorkqueue(_id: string): Promise<any> {
    let result = await this.http.delete(`${this.baseUrl}/${this.baseApi}/${this.service}/${_id}`,
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getWorkqueuesExport(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/export`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result; ``
  }
  public async deactivateWorkqueue(_id: string, active: number): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/deactivate/${active}/${_id}`,
      JSON.stringify([]),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async getWorkqueue(id: string): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}` ,
      httpOptionsJSON).toPromise();
    return result;
  }
}
