import { Component, Input } from '@angular/core';
import { FlowChart } from '../../../entities/flow-chart';

@Component({
  selector: 'app-nested-flow',
  templateUrl: './nested-flow.component.html',
  styleUrls: ['./nested-flow.component.css']
})
export class NestedFlowComponent {
  public _flowChart: FlowChart[] = []
  @Input() set flowChart(flowChart: FlowChart[]) {
    this._flowChart = flowChart;
  }
  get flowChart() {
    return this._flowChart;
  }

  onAnyDrop(newItem: any, node:any) {
    //
    //let s: FlowChart = this.getGrandNode(this.flowChart[0]);

    if (node.type == 'blank') {
      node = {
        id: 4,
        name: newItem.dragData.name,
        type: newItem.dragData.type,
        Child: (newItem.dragData.type == 'Desision') ? [{
          id: 4,
          name: 'true',
          type: 'blank',
          Child: []
        }, {
          id: 4,
          name: 'false',
          type: 'blank',
          Child: []
        }] : []
      };
    }
    else {
      node.Child.push({
        id: 4,
        name: newItem.dragData.name,
        type: newItem.dragData.type,
        Child: (newItem.dragData.type == 'Desision') ? [{
          id: 4,
          name: 'true',
          type: 'blank',
          Child: []
        }, {
          id: 4,
          name: 'false',
          type: 'blank',
          Child: []
        }] : []
      })
    }
  }
  getGrandNode(nodeItem: FlowChart): FlowChart {
    if (nodeItem.Child?.length > 0) {
      return this.getGrandNode(nodeItem.Child[0]);
    }
    else return nodeItem;
  }
}
