<button mat-icon-button (click)="doExpand()" style="width: 35px;">
    <mat-icon>menu</mat-icon>
</button>
<div class="task-list-left-sidebar queues">
  <button mat-icon-button class="mat-queue-icon-button">
    <app-avatar [username]="'Queues'" [initialColor]="'#7367f0'" [userInitialclass]="'queue-icon'" class="queue-icon"></app-avatar>
    <h4 class="queue-label" *ngIf="expanded">Queues</h4>
  </button>
  <app-task-label-list *ngIf="expanded" [queueLabel]="queueLabel" [queueType]="'queue'" style="width: 100%;" [queueFilter]="queueFilter" (onFilter)="doFilter($event)"></app-task-label-list>
</div>
<ng-container *ngIf='customLabel.length>0'>
  <div class="task-list-left-sidebar labels">
    <button mat-icon-button class="mat-queue-icon-button">
 
      <app-avatar [username]="'Labels'" [initialColor]="'#7367f0'" [userInitialclass]="'queue-icon'" class="queue-icon"></app-avatar>
      <h4 class="queue-label" *ngIf="expanded">Labels</h4>
        <mat-icon style="float:right" *ngIf="false" (click)="addLabel()">add</mat-icon> 
    </button>
    <app-task-label-list *ngIf="expanded" [queueLabel]="customLabel" [queueType]="'tages'" style="width: 100%;" [queueFilter]="queueFilter" (onFilter)="doFilter($event)"></app-task-label-list>
  </div>
</ng-container>
<ng-container *ngIf='teamTages.length>0'>
  <div class="task-list-left-sidebar team-label">
    <button mat-icon-button class="mat-queue-icon-button">
      <app-avatar [username]="'Team Labels'" [initialColor]="'#7367f0'" [userInitialclass]="'queue-icon'" class="queue-icon" (onFilter)="doFilter($event)"></app-avatar>
      <h4 class="queue-label" *ngIf="expanded">Team Labels</h4>
    </button>
    <app-task-label-list *ngIf="expanded" [queueLabel]="teamTages" [queueType]="'teamTages'" style="width: 100%;" [queueFilter]="queueFilter" (onFilter)="doFilter($event)"></app-task-label-list>
  </div>
</ng-container>
