<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content>
      <mat-card-title>
        Action Master
        <button *ngIf="!showEdit" mat-mini-fab color="primary" style="float:right" (click)="onAddAction()">
          <mat-icon>add</mat-icon>
        </button> 
        <div class="form-group col-lg-2" style="float:right" *ngIf="!showEdit">
      <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
    </div>
      </mat-card-title>
      <mat-card-subtitle> Home &gt; Action Master</mat-card-subtitle>
      <mat-divider></mat-divider>
      <!--<app-action-master-list *ngIf="!showEdit && hasAction_View" [actionList]="actionList" [filtervalue]="filtervalue" (onEditClient)="onEditAction($event)" (onDelete)="doDelete($event)" (onShowDetails)="onShowDetails($event)></app-action-master-list>-->
      <app-action-master-list [actionList]="actionList" *ngIf="!showEdit && hasActionMaster_View" [filtervalue]="filtervalue" (onEditAction)="onEditAction($event)" (onDelete)="doDelete($event)" (onShowDetails)="onShowDetails($event)"></app-action-master-list>
      <!--<section class="edit-container" *ngIf="showEdit ">-->
      <section class="edit-container" *ngIf="showEdit && (hasActionMaster_Add || hasActionMaster_Edit)">
        <app-action-master-edit [action]="action" [isLoading]="isLoading" (onCancelEdit)="onCancelEdit($event)" (onShowLoading)="onShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-action-master-edit>
        <!--<app-action-master-edit [action]="action"></app-action-master-edit>-->
      </section>
    </mat-card-content>
  </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>
