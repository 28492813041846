<form [formGroup]="formAdd">
  <div fxLayout="column" fxLayoutGap="1em" style="padding-top:10px">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em" *ngIf="profileEdit">
      <div class="form-group" fxFlex="0 0 28em">
        <small id="name" class="form-text text-muted">Current Password<span style="color: red">&nbsp;*</span></small>
        <input type="password" class="form-control" placeholder="****" formControlName="oldpassword">
        <mat-error *ngIf="formAdd.controls.oldpassword.hasError('required') && formAdd.controls.oldpassword.touched">
          This is a required field
        </mat-error>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
      <div class="form-group" fxFlex="0 0 28em">
        <small id="name" class="form-text text-muted">New Password<span style="color: red">&nbsp;*</span></small>
        <div style="display:flex">
        <input type="password" class="form-control" aria-describedby="New Password" placeholder="******" formControlName="newPassword">
        <mat-icon style="margin-top:8px;margin-left:10px" (click)="openDialog()">key</mat-icon>
      </div>
        <mat-error *ngIf="formAdd.controls.newPassword.hasError('required') && formAdd.controls.newPassword.touched">
          This is a required field
        </mat-error>
        <mat-error *ngIf="formAdd.get('newPassword')?.hasError('password') && formAdd.get('newPassword')?.touched">
          Weak password
        </mat-error>

      </div>

      <div class="form-group" fxFlex="0 0 28em">
        <small id="name" class="form-text text-muted">Confirm New Password<span style="color: red">&nbsp;*</span></small>
        <input type="password" class="form-control"  aria-describedby="Confirm New Password" placeholder="*****" formControlName="confirmNewPassword">
        <mat-error *ngIf="formAdd.controls.confirmNewPassword.hasError('required') && formAdd.controls.confirmNewPassword.touched">
          This is a required field
        </mat-error>
        <mat-error *ngIf="formAdd.hasError('password') && formAdd.get('confirmNewPassword')?.touched">
          Password not matched
        </mat-error>

      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
      <h6 class="text-body">Password Requirements:</h6>
      <ul class="ps-4 mb-0">
        <li class="form-text text-muted" style="list-style-type:none">
          <mat-icon [ngClass]="{'passwor-check-fail':!checkPassword(1),'passwor-check-pass':checkPassword(1)}">{{(checkPassword(1))?'check_circle':'highlight_off'}}</mat-icon>
          Minimum 8 characters long - the more, the better
        </li>
        <li class="form-text text-muted" style="list-style-type:none">
          <mat-icon [ngClass]="{'passwor-check-fail':!checkPassword(2),'passwor-check-pass':checkPassword(2)}">{{(checkPassword(2))?'check_circle':'highlight_off'}}</mat-icon>
          At least one lowercase character
        </li>
        <li class="form-text text-muted" style="list-style-type:none">
          <mat-icon [ngClass]="{'passwor-check-fail':!checkPassword(3),'passwor-check-pass':checkPassword(3)}">{{(checkPassword(3))?'check_circle':'highlight_off'}}</mat-icon>
          At least one uppercase character
        </li>
        <li class="form-text text-muted" style="list-style-type:none">
          <mat-icon [ngClass]="{'passwor-check-fail':!checkPassword(4),'passwor-check-pass':checkPassword(4)}">{{(checkPassword(4))?'check_circle':'highlight_off'}}</mat-icon>
          At least one number
        </li>
        <li class="form-text text-muted" style="list-style-type:none">
          <mat-icon [ngClass]="{'passwor-check-fail':!checkPassword(5),'passwor-check-pass':checkPassword(5)}">{{(checkPassword(5))?'check_circle':'highlight_off'}}</mat-icon>
          At least one symbol
        </li>
        <!--<li class="form-text text-muted" style="list-style-type:none"><mat-icon>check_circle</mat-icon>At least one lowercase character</li>
  <li class="form-text text-muted" style="list-style-type:none"><mat-icon>check_circle</mat-icon>At least one uppercase character</li>
  <li class="form-text text-muted" style="list-style-type:none"><mat-icon>check_circle</mat-icon>At least one number, symbol</li>-->
      </ul>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em" *ngIf="!profileEdit">
      <div class="form-group col-lg-4">
        <small id="name" class="form-text text-muted"></small>
        <div class="form-check form-switch" style="margin-top: 31px;">
            <input class="form-check-input" [checked]="resetpasswordonnextlogin" [ngModelOptions]="{standalone: true}" [(ngModel)]="resetpasswordonnextlogin" type="checkbox" id="flexSwitchCheckChecked" checked>
          <label class="form-check-label" for="flexSwitchCheckChecked">Ask user to reset password on next login</label>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
      <button mat-flat-button style="margin-right:10px;" (click)="doSave('next')" color="primary" *ngIf="hasUserSecurity_Edit">Save & Next</button>
      <button mat-flat-button style="margin-right:10px;" (click)="doSave('save')" color="primary" *ngIf="hasUserSecurity_Edit">Save Changes</button>
      <button mat-flat-button (click)="doCancelEdit()">Cancel</button>
    </div>
  </div>
</form>

