import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { ReportService } from '../../../services/report.service';
import { ThemeService } from '../../../services/theme.service';
import { UserService } from '../../user/user.service';
import { IEmbedConfiguration, models, Embed, factories, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent, PowerBIPaginatedReportEmbedComponent } from 'powerbi-client-angular';

@Component({
  selector: 'app-rdl-report',
  templateUrl: './rdl-report.component.html',
  styleUrls: ['./rdl-report.component.css']
})
export class RdlReportComponent {
  public reportSettingTimeer: any;
  public isDarkMode: boolean = false;
  public isApiStatus: boolean = false;
  public groupId: string = '';
  public reportId: string = '';
  public fileStatus: string = '';
  public lookupData: any = {};
  public reportUniqueId: string = '';
  filterData: any[] = [];
  // Track Report embedding status
  isEmbedded = false;
  showFilter: boolean = false;
  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';
  // Power BI service
  powerbi!: service.Service;
  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Get the token expiration from the access token
  public tokenExpiration: any;

  _isLoading: boolean = true;
  filters: any = { practiceIds: [{ "code": "0210_100252180", "description": "Northside_nexgen_1"}] };
  reportSetting: any = {};
  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: any = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false
    },
    theme: {
      themeJson: {}
    },
    parameterValues: [
      {
        "name": "Practice_ID",
        "value": "0210_100252180"
      },
      {
        "name": "BGColor",
        "value": "#3B3A39"
      },
      //{
      //  "name": "Practice_ID",
      //    "value": "1000060123"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "10023557"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "154021"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "202304041220"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "206005"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "243036"
      //},
      //{
      //  "name": "Practice_ID",
      //    "value": "95-62559"
      //}
    ]
  };
  // Wrapper object to access report properties
  @ViewChild('powerbiControl') reportObj!: any;
  constructor(public httpService: ReportService,
    private userService: UserService,
    private reportService: ReportService,
    private themeService: ThemeService,
    private clientService: ClientService,
    route: ActivatedRoute,
    router: Router,
  ) {
    this.getLookupData();
    //https://localhost:5162/rdlreport/421a2965-0f07-4442-a9c7-db6057c63273/42d8e825-b036-4639-a036-b9b0800a8b3e
    router.events.subscribe((val: any) => {
      //this.groupId = route.snapshot.params["id"];
      if (val.snapshot && val.snapshot.params["groupid"]) {
        this.isEmbedded = false;
        this._isLoading = true;
        this.reportId = val.snapshot.params["reportid"];
        this.groupId = val.snapshot.params["groupid"];
        //this.filters = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
        this.embedReport();
      }
    })
    this.groupId = route.snapshot.params["groupid"];
    this.reportId = route.snapshot.params["reportid"];
    this.isDarkMode != this.themeService.isDarkMode();
    this.reportSettingTimeer = setInterval(() => {
      if (this.isDarkMode != this.themeService.isDarkMode()) {
        this.isDarkMode = this.themeService.isDarkMode();
        //this.UpdateReportTheme();
      }

    }, 100);
    this.setUserToken();
    //setInterval(() => this.checkTokenAndUpdate(), this.INTERVAL_TIME);

  }
  setUserToken() {

    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting)
      this.embedReport();
    });
  }
  UpdateReportTheme() {
    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting);
      const report = this.reportObj.powerbi.embeds[0];
      if (report) {
        report.resetTheme();
        report.setAccessToken(this.httpService.accessToken);
        report.applyTheme({ themeJson: this.reportSetting });
        //report.reload();

      }
    });
  }
  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(): Promise<void> {

    let reportResponse = await this.httpService.getEmbedURL(this.groupId, this.reportId);
    let parameterValues = this.filters.practiceIds.map((w: any) => { return { name: "Practice_ID", value: w.code } })
    parameterValues.push({
      "name": "BGColor",
      "value": "#3B3A39"
    });
    this.reportConfig = {
      ...this.reportConfig,
      id: reportResponse.id,
      embedUrl: reportResponse.embedUrl,
      accessToken: this.httpService.accessToken,
      theme: { themeJson: this.reportSetting },
      filters: [],
      parameterValues: parameterValues
    };
    // Update the reportConfig to embed the PowerBI report

    this.isEmbedded = true;
    this._isLoading = false;
    if (this.reportObj) {
      const report = this.reportObj.powerbi.embeds[0];
      if (report) {
        report.off("loaded");
        report.on('loaded', (event: any) => {
          this._isLoading = false;
        })
        // Triggers when a report is successfully embedded in UI
        report.off("rendered");
        report.on("rendered", function () {
          console.log("Report render successful");
        });
        // Clear any other error handler event
        report.off("error");

        // Below patch of code is for handling errors that occur during embedding
        report.on("error", function (event: any) {
          let errorMsg = event.detail;

          // Use errorMsg variable to log error in any destination of choice
          console.error(errorMsg);
          return;
        });
      }
      else {
        this._isLoading = false;
      }
    }
    else {
      this._isLoading = false;
    }


  }
  displayFilters() {
    if (this.filterData)
      return this.filterData?.findIndex((w: any) => { return w.reportId == this.reportId }) > -1;

    return true;
  }
  async printReport() {

    const report = this.reportObj.powerbi.embeds[0];
    if (report) {
      this._isLoading = true;
      let request = {
        format: 'PDF',
        settings: {
          includeHiddenPages: true
        },
        paginatedReportConfiguration: {
          formatSettings: {
            "AccessiblePDF": true,
            "PageHeight": "17in",
            "PageWidth": "13in"
          },
          "parameterValues": report.config.parameterValues
        },
      }
      this.reportService.printReport(request, this.reportId).then(res => {
        console.log(res[0]);
        let exportId = res.id;
        console.log(exportId);
        this.checkReportStatus(request, exportId);
      }).catch(error => { console.log(error) });
    }
  }

  checkReportStatus(request: any, exportId: string) {
    this.reportService.checkReportStatus(request, this.reportId, exportId).then(async resStaus => {
      this.fileStatus = await resStaus.status;
      if (this.fileStatus == "Running" || this.fileStatus == "NotStarted") {
        setTimeout((s: any) => {
          this.checkReportStatus(request, exportId);
        }, 5000)

      }
      else if (this.fileStatus == "Succeeded") {
        this.downloadReport(request, exportId, resStaus.reportName + resStaus.resourceFileExtension);
      }
      else if (this.fileStatus == "Failed") {
        this._isLoading = false;
      }

    });
  }
  downloadReport(request: any, exportId: string, filename: string) {
    this._isLoading = false;
    this.reportService.downloadReport(request, this.reportId, exportId, filename, this.aftredownloadReport);
  }
  aftredownloadReport() {

  }
  async getLookupData() {
    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    this._isLoading = true;
    const lookups = await Promise.all([
      this.clientService.getlookup([]),
      this.clientService.getreportfilter(request)
    ]);

    this.filterData = lookups[1];
    this.lookupData = {
      clients: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
      serviceLocation: [],
      serviceProvider: [],
      payorGroups: [],
      payors: [],
    }

  }
  async doFilter(items: any) {
    if (items) {
      this.filters = items;
    }

    //this.reportObj.powerbi.embeds[0].config.parameterValues = this.filters.practiceIds.map((w:any) => { return { name: "Practice_ID", value: w.code } })
    this.showFilter = false;
    //await this.reportObj._embed.reload()
    //this.reportConfig = {
    //  ...this.reportConfig,

    //  parameterValues: this.filters.practiceIds.map((w: any) => { return { name: "Practice_ID", value: w.code } })
    //};
    this.isEmbedded = false;
    // this.reportObj.powerbi.reset();
    this.embedReport();

    //let report = await this.reportObj.powerbi.embed(this.reportObj.containerRef.nativeElement, this.reportConfig);
    //await this.reportObj.powerbi.embeds[0].refresh();
    // let s = await this.reportObj.powerbi.embeds[0].getFilters();
    //console.log(s);
    //report.setFilters([]);
    //this.embedReport();
  }
}
