import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { group } from 'console';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserGroup } from '../user-group';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.css']
})
export class UserGroupComponent {
  displayedColumns: string[] = ['checkbox','name', 'description'];

  groups!: UserGroup[];
  dataSource = new MatTableDataSource<UserGroup>([]);
  public _user!: User;
  public assignAll: boolean = false;
  public _isLoading: boolean = false;
  public hasUserGroup_Edit: boolean = false;

  ngOnInit(): void {
    this.setPrivileges();
  }

  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getUserGroups(user.userid)
    }
  }
  get user() {
    return this._user;
  }
  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
    if (profileEdit == true) {
      this.dataSource.data = this.groups.filter(w => { return w.assigned });
      this.displayedColumns = ['name', 'description'];
    }    
  }
  get profileEdit() {
    return this._profileEdit;
  }
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router, private auth: AuthService) {
     
  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
  }
  getUserGroups(userid: number) {
    const request = [{ userid: userid, groupid:-1 }]
    this.userService.getUserGroups(request).then(w => {
      this.groups = w.map((s:any) => {           
        return {
          groupid: s.groupid,
          userid: s.userid,
          name: s.groupname,
          description: s.groupdescription,
          assigned: s.isassigned==1
        } as UserGroup
      })
      //debugger
      this.assignAll = this.groups.filter(w => { return w.assigned }).length == this.groups.length;
      this.dataSource.data = this.groups;
    })
  }
  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  async doSave() {
    let request = this.groups.filter(w => { return w.assigned }).map(w => {
      return {
        userid: this.user.userid,
        groupid: w.groupid,
        typeid: 1,
        loginuserid: -1
      }
    });
    //
    /*this._isLoading = true;*/
    this.onShowLoading.emit(true);
    this.userService.setUserGroups(request).then(res => {
    this.onShowLoading.emit(false);
    
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      this.onUpdate.emit();
      Confirmation(this.dialog, data);
    });
  }
  onCheckboxChange(event: any, element: UserGroup | null) {
    if (element == null) {
      this.groups.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.groups;
    }
    else {
      element.assigned = event.checked;
    }
    this.assignAll = this.groups.filter(w => { return w.assigned }).length == this.groups.length;
  }
  setPrivileges() {
    this.hasUserGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.UserGroup_Edit);
  }
}
