<section class="edit-container-popup">
    <mat-card-title *ngIf="!isFromInfo">
        Action
    </mat-card-title>
    <mat-divider *ngIf="!isFromInfo"></mat-divider>
    <form [formGroup]="formAdd" class="popup-form">
        <div fxLayout="column" fxLayoutGap="1em">
            <div class="row" *ngIf="false">
                <div [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <small id="name" class="form-text text-muted float-start">Tags:</small>
                    <div class="float-start">
                        <span class="selected-item-container ng-star-inserted" style=""><span _ngcontent-eha-c324="" class="selected-item"><span _ngcontent-eha-c324="">g_Over Balance&nbsp;</span><a _ngcontent-eha-c324="" style="padding-left: 2px; color: white;">x</a></span></span>
                    </div>
                    <div class="float-start">
                        <button mat-mini-fab color="primary" class="btn-mini-fab">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="action-info" [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <small id="name" class="form-text text-muted">Owner:</small>
                    <div>
                        <input type="text" matInput [formControl]="assigneeName" class="form-control" [matAutocomplete]="auto" placeholder="Owner">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let user of filteredOptions" [value]="user">{{user.description}}</mat-option>
                        </mat-autocomplete>
                    </div>

                </div>
                <div [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Tags:</small>
                        <div>
                            <ng-multiselect-dropdown [placeholder]="'Tags'"
                                                     [settings]="dropdownSettings"
                                                     [data]="dropdownList"
                                                     [(ngModel)]="selectedItems"
                                                     (onSelect)="onItemSelect($event)"
                                                     (onSelectAll)="onSelectAll($event)"
                                                     (onFilterChange)="onFilterChange($event)" [ngModelOptions]="{standalone: true}">
                            </ng-multiselect-dropdown>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="action-info" [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Last Action</small>
                        <select class="form-select" disabled [formControl]="lastAction">
                            <option *ngFor="let action of actions" [value]="action.description">{{action.description}}</option>
                        </select>
                    </div>
                </div>
                <div [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Last Followup Date:</small>
                        <div>
                            <input type="text" class="form-control" readonly [value]="task?.followupdate??''" placeholder="mm-dd-yyyy">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="hasAction_Add">
                <div class="action-info" [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Action:</small>
                        <select class="form-select" formControlName="action">
                            <option *ngFor="let action of actions" [value]="action.description">{{action.description}}</option>
                        </select>

                    </div>
                </div>
                <!-- <div [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted" style="width:100%">Next Followup Date:</small>
                        <div>
                            <input type="text" [matDatepicker]="picker" [for]="picker" [min]="todayDate" formControlName="nextActionDate" style="width:88%;float: left;" class="form-control" readonly placeholder="mm-dd-yyyy">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker class="form-group-text" style="float:right;" #picker></mat-datepicker>
                        </div>
                    </div>
                </div> -->
                <div [class.col-lg-12]="isFromInfo" [class.col-lg-6]="!isFromInfo">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted" style="width:100%">Next Followup Date:</small>
                        <div class="input-group col-6 mx-auto">

                            <input name="actiondate"
                                   [type]="'text'"
                                   class="form-control"
                                   placeholder="mm/dd/yyyy"
                                   (click)="actiondatepicker.open()"
                                   formControlName="nextActionDate" [matDatepicker]="actiondatepicker"
                                   [for]="picker" [min]="todayDate" (blur)="onBlur($event)">
                            <div>
                                <mat-icon matSuffix class="form-input-date-icon" [for]="actiondatepicker" (click)="actiondatepicker.open()">calendar_today</mat-icon>
                            </div>
                        </div>
                        <mat-datepicker class="form-group-text" style="float:right;" #actiondatepicker></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="hasTask_Notes_Add">
                <div class="col-md-12 comment-box">
                    <small id="name" class="form-text text-muted" style="width:100%">Note:</small>
                    <div class="form-group">
                        <textarea id="txtarea_notes" rows="3" style="width: 100%; height: 102px;resize: none;" placeholder="Please enter note..." formControlName="notes"></textarea>

                    </div>
                </div>
            </div>
            <div class="row" *ngIf="hasTask_Notes_Add">
                <mat-error *ngIf="isError">
                    PLease enter/select at least one field
                </mat-error>
            </div>
        </div>
    </form>
    <mat-divider></mat-divider>
    <div class="button-group">
      <!--<button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting || !canSave()" class="btn" (click)="onSaveAction()">Save</button>-->
      <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting" class="btn" (click)="onSaveAction()">Save</button>
      <button mat-flat-button extended class="btn" (click)="doReset()" style="margin-right:10px;" [disabled]="waiting">Reset</button>
      <button mat-flat-button extended class="btn" *ngIf="!isFromInfo" (click)="doCancelEdit(false)" [disabled]="waiting">Cancel</button>
    </div>
</section>
