<mat-card>
    <mat-tab-group>
        <!-- Tab 1 -->
        <mat-tab label="Activity">
            <mat-card-content style="height: 300px;overflow: auto;">
                <mat-progress-bar *ngIf="_isLoading" mode="indeterminate"></mat-progress-bar>
                <!-- <mat-spinner *ngIf="_isLoading"></mat-spinner> -->
                <div class="d-flex no-blcok" *ngFor='let activity of activityData'>
                    <div class="m-r-20 col-md-12">
                        <!-- <img class="img-circle" width="50" [src]=activity.image alt="Image"> </div> -->
                        <div style="float: left;width: 47px;">
                            <app-avatar [username]="activity.fromuser" [userInitialclass]="'large'"></app-avatar>
                        </div>
                        <div class="p-b-20 b-b m-b-30">
                            <h4 class="m-0">
                                {{activity.fromuser}}
                                <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
                            </h4>
                            <p class="text-muted">{{activity.notificationbody}}</p>

                            <!-- <div fxLayout="row wrap" *ngIf="activity.bottomImage !==''">
                                                   <div fxFlex.gt-sm="20" fxFlex="100">
                                                       <img class="img-responsive rounded" [src]=activity.bottomImage alt="Image">
                                                   </div>
                                               </div>
                    -->
                            <button *ngIf="activity.actionurlid !=''" mat-raised-button color="primary" (click)="redirectToActions(activity.actionurlid)">Check Now</button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-tab>
    </mat-tab-group>
</mat-card>
