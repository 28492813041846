<section class="table-container-inner" tabindex="0">
  <table sstyle="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="first">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row"> </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell width="40%" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.rolename?.toLocaleLowerCase()}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell width="55%" *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let row"> {{row.roledescription?.toLocaleLowerCase()}} </td>
    </ng-container>


    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    </ng-container>
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox class="example-margin" [checked]="assignAll" (change)="onCheckboxChange($event,null,'all')"></mat-checkbox>

      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" [checked]="element.assigned==1" (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{''}}"</td>
    </tr>
  </table>
</section>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
  <button mat-flat-button (click)="doSave('next')" color="primary" *ngIf="hasUserRole_Edit">Save & Next</button>
  <button mat-flat-button (click)="doSave('save')" color="primary" *ngIf="!profileEdit && hasUserRole_Edit">Save Changes</button>
  <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>

<app-data-loader [isLoading]="_isLoading"></app-data-loader>

