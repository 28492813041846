import { Component, OnInit } from '@angular/core';
import { PrivilegeValues } from '../../entities/Privilege';
import { AuthService } from '../../services/auth.service';
import { Group } from './group';
import { GroupService } from './group.service';

@Component({
  selector: 'app-group-managment',
  templateUrl: './group-managment.component.html',
  styleUrls: ['./group-managment.component.css']
})
export class GroupManagmentComponent implements OnInit {
  public groupList: Group[] = [];
  public group!: Group;
  public filtervalue: string = "";
  public showEdit: boolean = false;
  public isLoading: boolean = true;
  public hasGroup_View: boolean = false;
  public hasGroup_Edit: boolean = false;
  public hasGroup_Add: boolean = false;

  constructor(
    private groupService: GroupService, private auth: AuthService
  ) { }

  ngOnInit() {
    this.setPrivileges();
    this.getGroups();
  }
  getGroups() {
    const request = {
      groupid: -1,
      statusid: -1,
      groupname: '',
      pagesize: 1000,
      pagestartrecord: 0
    }
    this.groupService.getGroup([request]).then(data => {
      this.groupList = data;
      this.isLoading = false;
    });
  }
  onEditGroup(group: Group) {
    this.getGroupDetails(group.groupid);
  }
  onCancelEdit(item: any) {
    this.group = {} as Group;
    this.showEdit = false;
    if (item)
      this.getGroups();
  }
  onAddGroup() {
    this.group = {} as Group;
    this.group.groupid = -1;
    this.group.isactive = 1;
    this.showEdit = true;
  }
  getGroupDetails(id: number, showLoading = true) {
    if (showLoading)
      this.isLoading = true;
    this.groupService.getGroupDetails(id).then(res => {
      if (res) {
        this.isLoading = false;
        this.group = res[0] as Group;
        if (showLoading)
        this.showEdit = true;
      }
    });
  }
  doUpdateEdit(group: Group) {
    this.getGroups();
    this.getGroupDetails(group.groupid, false);
  }
  doDelete(data: any) {    
    this.getGroups();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }

  async onShowDetails(item: any) {
    item = this.getGroupDetails(item?.groupid);
    this.group = item;
    this.showEdit = true;
  }
  setPrivileges() {
    this.hasGroup_View = this.auth.hasPrivilege(PrivilegeValues.GroupManage_View);
    this.hasGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.GroupManage_Edit);
    this.hasGroup_Add = this.auth.hasPrivilege(PrivilegeValues.GroupManage_Add);

  }
}
