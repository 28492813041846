import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BotDashboardService } from '../bot-dashboard.service';
import { GanttEditorComponent, GanttEditorOptions } from "ng-gantt";
import { AutomationSummary } from '../bot-dashboard';

@Component({
  selector: 'app-servers-list',
  templateUrl: './servers-list.component.html',
  styleUrls: ['./servers-list.component.css']
})
export class ServersListComponent implements OnInit {

  constructor(
    private dialog: MatDialog, private botDashboardService: BotDashboardService) {
  }
  
  @ViewChild("editor") editor: GanttEditorComponent = new GanttEditorComponent();

  public editorOptions: GanttEditorOptions = new GanttEditorOptions();
  public data : any[] = [];
  public distictServers: any;
  public distictInstances: any;
  public distictSteps: any;
  public distinctMap: any;
  public distinctFilterInstances: any;
  public distinctFilterSteps: any;

  displayedColumns: string[] = ['ServerName', 'IpAddress', 'IsActive', 'createdby', 'createdon', 'modifiedby', 'modifiedon'];
  public _summaryList: AutomationSummary[] = [];
  @Input() set summaryList(summaryList: AutomationSummary[]) {
    this._summaryList = summaryList;
  }

  // emmitter to sent data to automation dashboard main component
  @Output() getAutomationDetails = new EventEmitter<any>();


  public vUseSingleCell = '0';
  public vShowRes = '0';
  public vShowCost = '0';
  public vShowComp = '0';
  public vShowDur = '0';
  public vShowStartDate = '0';
  public vShowEndDate = '0';
  public vShowPlanStartDate = '0';
  public vShowPlanEndDate = '0';
  public vShowEndWeekDate = '0';
  public vShowTaskInfoLink = '0';
  public vShowServerUserName = '0'
  public vDebug = 'false';
  public vEditable = 'false';
  public vUseSort = 'false';
  public vLang = 'en';
  public delay = 150;

  public vShowAutomationServerDetailID = '0';
  public vShowAutomationId = '0';
  public vShowAutomationInstanceID = '0';

  ngOnInit() {
    this.distictServers = this.getDistinct(this._summaryList, "servername").distinctElements;
    this.distictInstances = this.getDistinct(this._summaryList, "processid").distinctElements;

    for (let i = 0; i < this.distictServers.length; i++) {
      this.data.push({
        pID: this.distictServers[i]["uniqueserverid"],
        pName: this.distictServers[i]["servername"],
        pStart: "", //this.distictServers[i]["Starttime"],
        pEnd: "", //this.distictServers[i]["endtime"],
        pClass: "ggroupblack",
        pLink: "",
        pMile: 0,
        pRes: "Brian",
        pComp: 100,
        pGroup: 1,
        pParent: 0,
        pOpen: 1,
        pDepend: "",
        pCaption: "",
        pNotes: "Some Notes text",
        pCost: 1000,

        // custom columns
        pServerUsername: this.distictServers[i]["serverusername"],
        pAutomationType: this.distictServers[i]["automationtype"],
        pAutomationPriority: this.distictServers[i]["automationpriority"],
        pAutomationStatus: this.distictServers[i]["automationstatus"],
        pInstanceName: this.distictServers[i]["automationname"],
        pInstanceStartTime: this.distictServers[i]["starttime"],
        pInstanceEndTime: this.distictServers[i]["endtime"],
        pInstanceDuration: this.distictServers[i]["duration"],
        pAutomationStep: this.distictServers[i]["automationstep"],
        pAutomationStepStatus: this.distictServers[i]["automationstepstatus"],
        pAutomationStepStartTime: this.distictServers[i]["automationstepstarttime"],
        pAutomationStepEndTime: this.distictServers[i]["automationstependtime"],
        pAutomationStepDuration: this.distictServers[i]["automationstepduration"],
        pIPAddress: this.distictServers[i]["ipaddress"],
        pNextRunTime: this.distictServers[i]["nextruntime"],
        pCreatedBy: this.distictServers[i]["createdby"],
        pCreatedOn: this.distictServers[i]["createdon"],
        pModifiedBy: this.distictServers[i]["modifiedby"],
        pModifiedOn: this.distictServers[i]["modifiedon"],
        AutomationServerDetailID: this.distictServers[i]["automationserverdetailid"],
        AutomationId: this.distictServers[i]["processid"],
        processid: this.distictServers[i]["processid"]
      });

      this.distinctFilterInstances = this.distictInstances.filter((obj: any) => {
        return (obj.uniqueserverid == this.distictServers[i]["uniqueserverid"])
      });

      for (let i = 0; i < this.distinctFilterInstances.length; i++) {
        this.data.push({
          pID: this.distinctFilterInstances[i]["processid"],
          pName: this.distinctFilterInstances[i]["automationname"],
          pStart: this.distinctFilterInstances[i]["starttime"],
          pEnd: this.distinctFilterInstances[i]["endtime"],
          pClass: "ggroupblack",
          pLink: "",
          pMile: 0,
          pRes: "Brian",
          pComp: 100,
          pGroup: 1,
          pParent: this.distinctFilterInstances[i]["uniqueserverid"],
          pOpen: 1,
          pDepend: "",
          pCaption: "",
          pNotes: "Some Notes text",
          pCost: 1000,

          // custom columns
          pServerUsername: this.distinctFilterInstances[i]["serverusername"],
          pAutomationType: this.distinctFilterInstances[i]["automationtype"],
          pAutomationPriority: this.distinctFilterInstances[i]["automationpriority"],
          pAutomationStatus: this.distinctFilterInstances[i]["automationstatus"],
          pInstanceName: this.distinctFilterInstances[i]["automationname"],
          pInstanceStartTime: this.distinctFilterInstances[i]["starttime"],
          pInstanceEndTime: this.distinctFilterInstances[i]["endtime"],
          pInstanceDuration: this.distinctFilterInstances[i]["duration"],
          pAutomationStep: this.distinctFilterInstances[i]["automationstep"],
          pAutomationStepStatus: this.distinctFilterInstances[i]["automationstepstatus"],
          pAutomationStepStartTime: this.distinctFilterInstances[i]["automationstepstarttime"],
          pAutomationStepEndTime: this.distinctFilterInstances[i]["automationstependtime"],
          pAutomationStepDuration: this.distinctFilterInstances[i]["automationstepduration"],
          pIPAddress: this.distinctFilterInstances[i]["ipaddress"],
          pNextRunTime: this.distinctFilterInstances[i]["nextruntime"],
          pCreatedBy: this.distinctFilterInstances[i]["createdby"],
          pCreatedOn: this.distinctFilterInstances[i]["createdon"],
          pModifiedBy: this.distinctFilterInstances[i]["modifiedby"],
          pModifiedOn: this.distinctFilterInstances[i]["modifiedon"],
          AutomationServerDetailID: this.distinctFilterInstances[i]["automationserverdetailid"],
          AutomationId: this.distinctFilterInstances[i]["processid"],
          processid: this.distinctFilterInstances[i]["processid"]
        });

        this.distinctFilterSteps = this._summaryList.filter((obj: any) => {
          return (obj.processid == this.distinctFilterInstances[i]["processid"])
        });

        for (let i = 0; i < this.distinctFilterSteps.length; i++) {
          this.data.push({
            pID: this.distinctFilterSteps[i]["automationstepid"],
            pName: this.distinctFilterSteps[i]["automationstep"],
            pStart: this.distinctFilterSteps[i]["automationstepstarttime"],
            pEnd: this.distinctFilterSteps[i]["automationstependtime"],
            pClass: "ggroupblack",
            pLink: "",
            pMile: 0,
            pRes: "Brian",
            pComp: 100,
            pGroup: 1,
            pParent: this.distinctFilterSteps[i]["processid"],
            pOpen: 1,
            pDepend: "",
            pCaption: "",
            pNotes: "Some Notes text",
            pCost: 1000,

            // custom columns
            pServerUsername: this.distinctFilterSteps[i]["serverusername"],
            pAutomationType: this.distinctFilterSteps[i]["automationtype"],
            pAutomationPriority: this.distinctFilterSteps[i]["automationpriority"],
            pAutomationStatus: this.distinctFilterSteps[i]["automationstatus"],
            pInstanceName: this.distinctFilterSteps[i]["automationname"],
            pInstanceStartTime: this.distinctFilterSteps[i]["starttime"],
            pInstanceEndTime: this.distinctFilterSteps[i]["endtime"],
            pInstanceDuration: this.distinctFilterSteps[i]["duration"],
            pAutomationStep: this.distinctFilterSteps[i]["automationstep"],
            pAutomationStepStatus: this.distinctFilterSteps[i]["automationstepstatus"],
            pAutomationStepStartTime: this.distinctFilterSteps[i]["automationstepstarttime"],
            pAutomationStepEndTime: this.distinctFilterSteps[i]["automationstependtime"],
            pAutomationStepDuration: this.distinctFilterSteps[i]["automationstepduration"],
            pIPAddress: this.distinctFilterSteps[i]["ipaddress"],
            pNextRunTime: this.distinctFilterSteps[i]["nextruntime"],
            pCreatedBy: this.distinctFilterSteps[i]["createdby"],
            pCreatedOn: this.distinctFilterSteps[i]["createdon"],
            pModifiedBy: this.distinctFilterSteps[i]["modifiedby"],
            pModifiedOn: this.distinctFilterSteps[i]["modifiedon"],
            AutomationServerDetailID: this.distinctFilterSteps[i]["automationserverdetailid"],
            AutomationId: this.distinctFilterSteps[i]["processid"],
            processid: this.distinctFilterSteps[i]["processid"]
          })
        }

      }
    }

    //this.data = this.initialData();

    const vAdditionalHeaders = {
      //pName: {
      //  title: 'Task Name',
      //  field: 'pName'
      //},
      pServerUsername: {
        title: 'Server Username',
        field: 'pServerUsername'
      },
      pAutomationType: {
        title: 'Automation Type',
        field: 'pAutomationType'
      },
      pAutomationPriority: {
        title: 'Priority',
        field: 'pAutomationPriority'
      },
      pAutomationStatus: {
        title: 'Status',
        field: 'pAutomationStatus'
      },
      pInstanceName: {
        title: 'Instance Name',
        field: 'pInstanceName'
      },
      pInstanceStartTime: {
        title: 'Instance Start Time',
        field: 'pInstanceStartTime'
      },
      pInstanceEndTime: {
        title: 'Instance End Time',
        field: 'pInstanceEndTime'
      },
      pInstanceDuration: {
        title: 'Instance Duration',
        field: 'pInstanceDuration'
      },
      pAutomationStep: {
        title: 'Automation Step',
        field: 'pAutomationStep'
      },
      pAutomationStepStatus: {
        title: 'Step Status',
        field: 'pAutomationStepStatus'
      },
      pAutomationStepStartTime: {
        title: 'Step Start Time',
        field: 'pAutomationStepStartTime'
      },
      pAutomationStepEndTime: {
        title: 'Step End Time',
        field: 'pAutomationStepEndTime'
      },
      pAutomationStepDuration: {
        title: 'Step Duration',
        field: 'pAutomationStepDuration'
      },
      pIPAddress: {
        title: 'IP Address',
        field: 'pIPAddress'
      },
      pNextRunTime: {
        title: 'Next Run Time',
        field: 'pNextRunTime'
      },
      pCreatedBy: {
        title: 'Created By',
        field: 'pCreatedBy'
      },
      pCreatedOn: {
        title: 'Created On',
        field: 'pCreatedOn'
      },
      pModifiedBy: {
        title: 'Modified By',
        field: 'pModifiedBy'
      },
      pModifiedOn: {
        title: 'Modified On',
        field: 'pModifiedOn'
      },
      AutomationServerDetailID: {
        title: 'AutomationServerDetailID',
        field: 'pAutomationServerDetailID'
      },
      AutomationId: {
        title: 'AutomationId',
        field: 'pAutomationId'
      },
      processid: {
        title: 'processid',
        field: 'pAutomationInstanceID'
      },
    };

    this.editorOptions = {
      vAdditionalHeaders: vAdditionalHeaders,
      vFormat: "hour",
      vFormatArr: ['Hour', 'Day', 'Week'],

      //add further columns
      vCaptionType: 'Complete',  // Set to Show Caption : None,Caption,Resource,Duration,Complete,
      vQuarterColWidth: 36,
      vDateTaskDisplayFormat: 'day dd month yyyy', // Shown in tool tip box
      vDayMajorDateDisplayFormat: 'mon yyyy - Week ww', // Set format to display dates in the "Major" header of the "Day" view
      vWeekMinorDateDisplayFormat: 'dd mon', // Set format to display dates in the "Minor" header of the "Week" view
      vLang: this.vLang,
      vUseSingleCell: this.vUseSingleCell,
      vShowRes: parseInt(this.vShowRes, 10),
      vShowCost: parseInt(this.vShowCost, 10),
      vShowComp: parseInt(this.vShowComp, 10),
      vShowDur: parseInt(this.vShowDur, 10),
      vShowStartDate: parseInt(this.vShowStartDate, 10),
      vShowEndDate: parseInt(this.vShowEndDate, 10),
      vShowPlanStartDate: parseInt(this.vShowPlanStartDate, 10),
      vShowPlanEndDate: parseInt(this.vShowPlanEndDate, 10),
      vShowTaskInfoLink: parseInt(this.vShowTaskInfoLink, 10), // Show link in tool tip (0/1)
      //vShowAutomationServerDetailID: parseInt(this.vShowAutomationServerDetailID, 10),
      //vShowAutomationId: parseInt(this.vShowAutomationId, 10),
      //vShowAutomationInstanceID: parseInt(this.vShowAutomationInstanceID, 10),

      //vShowServerUserName: parseInt(this.vShowServerUserName, 10), // Show link in tool tip (0/1)
      // Show/Hide the date for the last day of the week in header for daily view (1/0)
      vShowEndWeekDate: parseInt(this.vShowEndWeekDate, 10),
      //vAdditionalHeaders: vAdditionalHeaders,
      vEvents: {
        taskname: console.log,
        res: console.log,
        dur: console.log,
        comp: console.log,
        start: console.log,
        end: console.log,
        planstart: console.log,
        planend: console.log,
        cost: console.log
      },
      vEventsChange: {
        taskname: this.editValue.bind(this, this.data),
        res: this.editValue.bind(this, this.data),
        dur: this.editValue.bind(this, this.data),
        comp: this.editValue.bind(this, this.data),
        start: this.editValue.bind(this, this.data),
        end: this.editValue.bind(this, this.data),
        planstart: this.editValue.bind(this, this.data),
        planend: this.editValue.bind(this, this.data),
        cost: this.editValue.bind(this, this.data)
      },
      vEventClickRow: (event: any) => this.getDetailsData(event),
      vTooltipDelay: this.delay,
      vDebug: this.vDebug === 'true',
    };
  }
  
  initialData() {
    //return [
    //  {
    //    pID: 1,
    //    pName: "Server 1",
    //    pStart: "2024-01-10 6:00:00",
    //    pEnd: "2024-01-10 11:30:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 0,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: "Some Notes text",
    //    'pCost': 1000
    //  },
    //  {
    //    pID: 11,
    //    pName: "Instance 1",
    //    pStart: "2024-01-10 6:00:00",
    //    pEnd: "2024-01-10 11:30:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 1,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 111,
    //    pName: "Stage 1",
    //    pStart: "2024-01-10 6:00:00",
    //    pEnd: "2024-01-10 9:00:00",
    //    pClass: 'ggroupblack',
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 11,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 112,
    //    pName: "Stage 2",
    //    pStart: "2024-01-10 9:30:00",
    //    pEnd: "2024-01-10 11:30:00",
    //    pClass: 'ggroupblack',
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 11,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },

    //  {
    //    pID: 2,
    //    pName: "Server 2",
    //    pStart: "2024-01-12 3:00:00",
    //    pEnd: "2024-01-12 15:00:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 0,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: "Some Notes text"
    //  },
    //  {
    //    pID: 21,
    //    pName: "Instance 1",
    //    pStart: "2024-01-12 3:00:00",
    //    pEnd: "2024-01-12 9:00:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 2,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 211,
    //    pName: "Stage 1",
    //    pStart: "2024-01-12 3:00:00",
    //    pEnd: "2024-01-12 5:00:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 21,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 212,
    //    pName: "Stage 2",
    //    pStart: "2024-01-12 7:00:00",
    //    pEnd: "2024-01-12 9:00:00",
    //    pClass: 'ggroupblack',
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 21,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 22,
    //    pName: "Instance 2",
    //    pStart: "2024-01-12 10:00:00",
    //    pEnd: "2024-01-12 15:00:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 2,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 221,
    //    pName: "Stage 1",
    //    pStart: "2024-01-12 10:00:00",
    //    pEnd: "2024-01-12 12:00:00",
    //    pClass: 'ggroupblack',
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 22,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 222,
    //    pName: "Stage 2",
    //    pStart: "2024-01-12 13:00:00",
    //    pEnd: "2024-01-12 15:00:00",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 1,
    //    pParent: 22,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  }
    //];

    //return [
    //  {
    //    pID: 1,
    //    pName: "Define Chart API",
    //    pStart: "",
    //    pEnd: "",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 0,
    //    pGroup: 1,
    //    pParent: 0,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: "Some Notes text"
    //  },
    //  {
    //    pID: 11,
    //    pName: "Chart Object",
    //    pStart: "2017-02-20",
    //    pEnd: "2017-02-20",
    //    pClass: "gmilestone",
    //    pLink: "",
    //    pMile: 1,
    //    pRes: "Shlomy",
    //    pComp: 100,
    //    pGroup: 0,
    //    pParent: 1,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 12,
    //    pName: "Task Objects",
    //    pStart: "",
    //    pEnd: "",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 40,
    //    pGroup: 1,
    //    pParent: 1,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 121,
    //    pName: "Constructor Proc #1234 of February 2017",
    //    pStart: "2017-02-21",
    //    pEnd: "2017-03-09",
    //    pClass: "gtaskblue",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian T.",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 12,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 122,
    //    pName: "Task Variables",
    //    pStart: "2017-03-06",
    //    pEnd: "2017-03-11",
    //    pClass: "gtaskred",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 12,
    //    pOpen: 1,
    //    pDepend: 121,
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 123,
    //    pName: "Task by Minute/Hour",
    //    pStart: "2017-03-09",
    //    pEnd: "2017-03-14 12: 00",
    //    pClass: "gtaskyellow",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Ilan",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 12,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 124,
    //    pName: "Task Functions",
    //    pStart: "2017-03-09",
    //    pEnd: "2017-03-29",
    //    pClass: "gtaskred",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Anyone",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 12,
    //    pOpen: 1,
    //    pDepend: "123SS",
    //    pCaption: "This is a caption",
    //    pNotes: null
    //  },
    //  {
    //    pID: 2,
    //    pName: "Create HTML Shell",
    //    pStart: "2017-03-24",
    //    pEnd: "2017-03-24",
    //    pClass: "gtaskyellow",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 20,
    //    pGroup: 0,
    //    pParent: 0,
    //    pOpen: 1,
    //    pDepend: 122,
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 3,
    //    pName: "Code Javascript",
    //    pStart: "",
    //    pEnd: "",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 0,
    //    pGroup: 1,
    //    pParent: 0,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 31,
    //    pName: "Define Variables",
    //    pStart: "2017-02-25",
    //    pEnd: "2017-03-17",
    //    pClass: "gtaskpurple",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 30,
    //    pGroup: 0,
    //    pParent: 3,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 32,
    //    pName: "Calculate Chart Size",
    //    pStart: "2017-03-15",
    //    pEnd: "2017-03-24",
    //    pClass: "gtaskgreen",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Shlomy",
    //    pComp: 40,
    //    pGroup: 0,
    //    pParent: 3,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 33,
    //    pName: "Draw Task Items",
    //    pStart: "",
    //    pEnd: "",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Someone",
    //    pComp: 40,
    //    pGroup: 2,
    //    pParent: 3,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 332,
    //    pName: "Task Label Table",
    //    pStart: "2017-03-06",
    //    pEnd: "2017-03-09",
    //    pClass: "gtaskblue",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 33,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 333,
    //    pName: "Task Scrolling Grid",
    //    pStart: "2017-03-11",
    //    pEnd: "2017-03-20",
    //    pClass: "gtaskblue",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 0,
    //    pGroup: 0,
    //    pParent: 33,
    //    pOpen: 1,
    //    pDepend: "332",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 34,
    //    pName: "Draw Task Bars",
    //    pStart: "",
    //    pEnd: "",
    //    pClass: "ggroupblack",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Anybody",
    //    pComp: 60,
    //    pGroup: 1,
    //    pParent: 3,
    //    pOpen: 0,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 341,
    //    pName: "Loop each Task",
    //    pStart: "2017-03-26",
    //    pEnd: "2017-04-11",
    //    pClass: "gtaskred",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 34,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 342,
    //    pName: "Calculate Start/Stop",
    //    pStart: "2017-04-12",
    //    pEnd: "2017-05-18",
    //    pClass: "gtaskpink",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 34,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 343,
    //    pName: "Draw Task Div",
    //    pStart: "2017-05-13",
    //    pEnd: "2017-05-17",
    //    pClass: "gtaskred",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 34,
    //    pOpen: 1,
    //    pDepend: "",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 344,
    //    pName: "Draw Completion Div",
    //    pStart: "2017-05-17",
    //    pEnd: "2017-06-04",
    //    pClass: "gtaskred",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 60,
    //    pGroup: 0,
    //    pParent: 34,
    //    pOpen: 1,
    //    pDepend: "342,343",
    //    pCaption: "",
    //    pNotes: ""
    //  },
    //  {
    //    pID: 35,
    //    pName: "Make Updates",
    //    pStart: "2017-07-17",
    //    pEnd: "2017-09-04",
    //    pClass: "gtaskpurple",
    //    pLink: "",
    //    pMile: 0,
    //    pRes: "Brian",
    //    pComp: 30,
    //    pGroup: 0,
    //    pParent: 3,
    //    pOpen: 1,
    //    pDepend: "333",
    //    pCaption: "",
    //    pNotes: ""
    //  }
    //];
  }

  getDetailsData(event: any) {
    // get row data
    const rowData = event.getAllData();  // Adjust according to the actual event structure
    this.getAutomationDetails.emit(rowData.pDataObject);
  }


  // add methods related to ng-grantt
  editValue(list: any, task: any, event: any, cell: any, column: any) {
    const found = list.find((item: { pID: any; }) => item.pID == task.getOriginalID());
    if (!found) {
      return;
    } else {
      found[column] = event ? event.target.value : '';
    }
  }

  change() {
  }

  setLanguage(lang: any) {
    //this.editorOptions.vLang = lang;
    this.editor.setOptions(this.editorOptions);
  }

  customLanguage() {
    // this.editorOptions.languages = {
    //   'pt-BR': {
    //     'auto': 'Automático testing'
    //   },
    //   'en': {
    //     'auto': 'Auto testing'
    //   }
    // };
    this.editor.setOptions(this.editorOptions);
  }

  changeObject() {
    //this.data.randomNumber = Math.random() * 100;
  }

  changeData() {
    this.data = Object.assign({}, this.data,
      { randomNumber: Math.random() * 100 });
  }

  /**
   * Example on how get the json changed from the jsgantt
   */
  getData() {
    // const changedGantt = this.editor.get();
  }

  clear() {
    //const g = this.editor.getEditor();
    //g.ClearTasks();
    //g.Draw();
  }

  getDistinct(arr: any = [], field: any) {
    this.distinctMap = {};
    arr.forEach((item: any) => {
      if (!this.distinctMap[item[field]]) {
        this.distinctMap[item[field]] = item;
      }
    });
    const distinctFields = Object.keys(this.distinctMap);
    const distinctElements = Object.values(this.distinctMap);
    return { distinctFields, distinctElements }
  }
}
