import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { Group, GroupClient } from '../group';
import { GroupService } from '../group.service';

type permissionKeys = 'view' | 'add' | 'edit' | 'delete' | 'click';
@Component({
  selector: 'app-group-client',
  templateUrl: './group-client.component.html',
  styleUrls: ['./group-client.component.css']
})
export class GroupClientComponent {
  public _group!: Group;
  public clientid!: number
  public hasGropuClient_Edit: boolean = false;

  ngOnInit(): void {
    this.setPrivileges();
  }
  @Input() set group(group: Group) {
    this._group = group;
    if (group?.groupid != -1) {
     this.getMasterDetails(group.groupid)
    }

  }
  get group() {
    return this._group;
  }

  groupClients!: GroupClient[];
  public groupClient_data: GroupClient[] = [];
  dataSource = new MatTableDataSource<GroupClient>([]);
  assignAll: boolean = true;
  displayedColumns: string[] = ['blank', 'name', 'all', 'weight', 'symbol', 'one', 'two'];
  
  @Output() onCancelEdit = new EventEmitter();
 
  doCancelEdit() {   
    this.onCancelEdit.emit();
  }
  constructor(private userService: GroupService, private dialog: MatDialog, private auth: AuthService) { }
  
  @Output() onDelete = new EventEmitter();
  async doSave() {    
    
    
    let request = this.groupClients.filter(w => { return w.add || w.click || w.delete || w.delete || w.edit }).map(w => {
      console.log(w)
      return {
        clientid: w.clientId ??'',
        groupid: this._group.groupid,
        aclpermissionsid: w.aclPermissionId??-1,
        add: w.add_ ==-1 ? 0 : w.add ? 1 : 0,
        edit: w.edit_ == -1 ? 0 : w.edit ? 1 : 0,
        delete: w.delete_ == -1 ? 0 : w.delete ? 1 : 0,
        view: w.view_ == -1 ? 0 : w.view ? 1 : 0,
        click: w.click ? 1 : 0,
        loginuserid: -1
      }
    });
    
    this.userService.setGroupClient(request).then(res => {      
      console.log(res)
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      this.onDelete.emit();
      Confirmation(this.dialog, data);
    });

  }

  
   async getMasterDetails(id: number) {    
     let request = [{
       clientid: -1,   
      groupid: id       
     }]
     
      const lookups = await Promise.all([
        this.userService.getGroupClient(request)
      ]);
     

     this.groupClients = lookups[0].map((w: any, idx: number) => {
        let s: GroupClient = {} as GroupClient;
        s.add = w.add == 1;
        s.edit = w.edit == 1;
        s.click = w.click == 1;
        s.delete = w.delete == 1;
        s.view = w.view == 1;
        s.add_ = w.add;
        s.edit_ = w.edit;
        s.delete_ = w.delete;
        s.view_ = w.view;
        s.id = w.idx;
        s.clientId = w.clientid;
        s.clientname = w.clientname;
        s.description = w.description;
        s.assigned = w.isassigned;
       s.aclPermissionId = w.aclpermissionsid;
        return s;
      });

     
    this.dataSource.data = this.groupClients;
  }




  
  haveAllpermision(row: GroupClient) {
    return (row.view || row.view == null) &&
      (row.add || row.add == null) &&
      (row.edit || row.edit == null) &&
      (row.delete || row.delete == null);
  }

  onCheckboxChange(event: any, element: GroupClient, permissionType: permissionKeys) {
    if (permissionType.toString() == 'all') {
      element[`view`] = event.checked;
      element[`add`] = event.checked;
      element[`edit`] = event.checked;
      element[`delete`] = event.checked;
    }
    element[`${permissionType}`] = event.checked;
    console.log('Update successful:', this.dataSource);

    if (permissionType.toString() != 'view' && event.checked) {
      element[`view`] = event.checked;
    }

    if (permissionType.toString() == 'view' && !event.checked) {
      if (element[`add`])
        element[`add`] = event.checked;
      if (element[`edit`])
        element[`edit`] = event.checked;
      if (element[`delete`])
        element[`delete`] = event.checked;
    }
    
      if (element.edit_ == -1)
        element[`edit`] = event.checked;
      if (element.add_ == -1)
        element[`add`] = event.checked;
      if (element.delete_ == -1)
        element[`delete`] = event.checked;
      if (element.view_ == -1)
        element[`view`] = event.checked;
    

    console.log("element.edit_: ", element.edit_);

  }

  setPrivileges() {
    this.hasGropuClient_Edit = this.auth.hasPrivilege(PrivilegeValues.GroupClient_Edit);
  }  
  
}
