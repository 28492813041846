<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table-remit-cas-code" style="width: 95%;margin: auto;margin-bottom: 10px;margin-top: 10px;" multiTemplateDataRows>

    <ng-container matColumnDef="group_code">
        <th mat-header-cell *matHeaderCellDef>Group Code </th>
        <td mat-cell *matCellDef="let lineInfo"> {{lineInfo.group_code}} </td>
    </ng-container>
    <ng-container matColumnDef="reason_code">
        <th mat-header-cell *matHeaderCellDef> Reason Code </th>
        <td mat-cell *matCellDef="let lineInfo">
            {{ lineInfo.reason_code }}
        </td>
    </ng-container>
    <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef> Payment </th>
        <td mat-cell *matCellDef="let lineInfo" class="mat-column-number" [usd-money]="(!lineInfo.payment)?0:lineInfo.payment" [usd-money-cell]="true">
        </td>
    </ng-container>

    <ng-container matColumnDef="adjustment_amount">
        <th mat-header-cell *matHeaderCellDef> Adjustment </th>
        <td mat-cell *matCellDef="let lineInfo" class="mat-column-number" [usd-money]="(!lineInfo.adjustment_amount)?0:lineInfo.paid" [usd-money-cell]="true">
        </td>
    </ng-container>

    <ng-container matColumnDef="copypayment_amount">
        <th mat-header-cell *matHeaderCellDef> Copay </th>
        <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.copypayment_amount)?0:element.copypayment_amount" [usd-money-cell]="true">
        </td>
    </ng-container>
    <ng-container matColumnDef="deductible_amount">
        <th mat-header-cell *matHeaderCellDef> deductible </th>
        <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.deductible_amount)?0:element.deductible_amount" [usd-money-cell]="true">
        </td>
    </ng-container>
    <ng-container matColumnDef="coinsurance_amount">
        <th mat-header-cell *matHeaderCellDef> Coins </th>
        <td mat-cell *matCellDef="let element" class="mat-column-number" [usd-money]="(!element.coinsurance_amount)?0:element.coinsurance_amount" [usd-money-cell]="true">
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="lineInfoColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: lineInfoColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="lineInfoColumns.length">No record(s) found.</td>
    </tr>
</table>
