<form class="example-form" fxLayout="column" fxLayoutGap=".5em">

    <div class="row">
        <div class="form-group">
            <small id="name" class="form-text text-muted">Practice</small>
            <!-- <mat-select class="form-select" [formControl]="client" multiple [(ngModel)]="filters.practiceIds">
                <mat-option *ngFor="let client of clientLookup" [value]="client.code">{{client.description}}</mat-option>
            </mat-select> -->
            <app-multi-select [dropdownList]="clientLookup" [selectedItems]="filters.practiceIds" [placeholder]="'Client'" #clients></app-multi-select>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <small id="name" class="form-text text-muted">Owner</small>
            <app-multi-select [dropdownList]="users" [selectedItems]="filters.owners" [placeholder]="'Owner'" #owner></app-multi-select>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <small id="name" class="form-text text-muted">Action</small>
            <!-- <mat-select class="form-select" [formControl]="action" multiple [(ngModel)]="filters.actions">
                <mat-option *ngFor="let action of actions" [value]="action.description">{{action.description}}</mat-option>
            </mat-select> -->
            <app-multi-select [dropdownList]="actions" [selectedItems]="filters.actions" [placeholder]="'Action'" #action></app-multi-select>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <small id="name" class="form-text text-muted">Freezed</small>
            <div  style="width:100%">
            <mat-button-toggle-group name="fontStyle" [formControl]="freezedControl" [value]="filters.freezed" (change)="onValChange($event)">
            <mat-button-toggle value="-1">All</mat-button-toggle>
            <mat-button-toggle value="1">Freezed</mat-button-toggle>
            <mat-button-toggle value="0">Unfreezed</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        </div>
    </div>
    <div class="row">

        <div class="col-lg-6">
            <div class="form-group">
                <small id="name" class="form-text text-muted">Search On</small>
                <app-multi-select [singleSelection]="'true'" [dropdownList]="searchOn" [selectedItems]="filters.otherfilters[0]?.searchOn??[]" [placeholder]="'searchOn'" #ctrlsearchOn></app-multi-select>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <small id="name" class="form-text text-muted">Operator</small>
                <app-multi-select [singleSelection]="'true'" [dropdownList]="searchOperator" [selectedItems]="filters.otherfilters[0]?.operator??[]" [placeholder]="'Operator'" #ctrlsearchOperator></app-multi-select>
            </div>
        </div>
        <div class="col-lg-12" style="margin-top: 10px;">
            <div class="form-group">
                <textarea type="text" rows="2" [maxLength]="250" class="form-control" id="nametext" [value]="filters.otherfilters[0]?.values" aria-describedby="Description" placeholder="Description" [formControl]="description"></textarea>
            </div>
        </div>
        <div class="col-lg-12" style="margin-top: 10px;">
            <mat-error *ngIf="!isVaildFilter">
                PLease enter/Select required value
            </mat-error>
        </div>
    </div>
    <div class="button-group float-end">
        <button mat-flat-button color="primary" style="margin-right:10px;" class="btn" (click)="doFilter()">Filter</button>
        <button mat-flat-button extended class="btn" (click)="doCancelFilter()">Reset</button>
    </div>
</form>
