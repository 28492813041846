import { Component, OnInit } from '@angular/core';
import { AutomationSummary, AutomationLogs, AutomationDetails } from './bot-dashboard';
import { BotDashboardService } from './bot-dashboard.service';
import { GetFormattedDate } from '../../Utility/common-functions';

@Component({
  selector: 'app-bot-dashboard',
  templateUrl: './bot-dashboard.component.html',
  styleUrls: ['./bot-dashboard.component.css']
})
export class BotDashboardComponent implements OnInit {

  public scheduleType: number = 3; // 1 for automation bots, 2 for reports, 3 for real time refresh
  public summaryList: AutomationSummary[] = [];
  public logsList: AutomationLogs[] = [];
  public detailsList: AutomationDetails[] = [];
  public setActionResponse: any[] = [];
  public showServers: boolean = false;
  public showSummary: boolean = false;
  public showDetails: boolean = false;
  public isLoading: boolean = true;
  public requestObject: Object = {};
  public automationStepsList!: any;
  constructor(
    private botDashboardService: BotDashboardService
  ) { }

  ngOnInitBefore() {
    
  }
  ngOnInit() {
    this.scheduleType = this.getScheduleType();
    this.automationStepsList = this.getAutomationStepsList();

    this.requestObject = {
      schedule_instance_id: '-1',
      eventid_google: "-1",
      scheduletypeid: this.scheduleType,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: "01/01/1900",
      todate: "01/01/1900"
    }
    this.getAutomationSummary(this.requestObject);
  }

  getAutomationSummary(P_data: any) { // this is emitter call when we click on ok after saving data
    this.isLoading = true;
    const request = P_data;
    this.botDashboardService.getAutomationSummary([request]).then(data => {
      this.summaryList = data;
      this.isLoading = false;
      this.showSummary = true;
    });
  }

  getAutomationStepsList() {
    this.isLoading = true;
    let request: any = { id: "-1" };
    //this.botDashboardService.getAutomationSteps([request]).then(data => {
    //  this.automationStepsList = data;
    //  this.isLoading = false;
    //});


    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let startDate = GetFormattedDate(firstDay);
    let endDate = GetFormattedDate(lastDay);

    request = {
      ScheduleID: -1,
      EventID_google: -1,
      Status: -1,
      ScheduleTypeID: 1,
      pagesze: 1000,
      pagestartrecord: 0,
      fromdate: startDate + ' 01:01:01',
      todate: endDate + ' 01:01:01'
    };
    this.botDashboardService.getScheduleLog([request]).then(data => {
      this.automationStepsList = data;
      this.isLoading = false;
    });
  }

  getAutomationLogs() {
    const request = {
      AutomationServerDetailID: -1,
      AutomationId: -1,
      AutomationInstanceID: -1,
      ProcessId: -1,
      logfromdate: "01/01/1900",
      logtodate: "01/01/1900",
      AutomationLogTypeID: -1,
    }
    this.botDashboardService.getAutomationLogs([request]).then(data => {
      this.logsList = data;
      this.isLoading = false;
    });
  }

  getAutomationDetails(summaryList: AutomationSummary) {
    const request = {
      AutomationServerDetailID: "", //summaryList.AutomationServerDetailID,
      AutomationId: "", //summaryList.AutomationId,
      AutomationInstanceID: "" //summaryList.processid
    }

    this.isLoading = true;

    this.botDashboardService.getAutomationDetails([request]).then(data => {
      this.detailsList = data;
      this.isLoading = false;
      this.showDetails = true;
    });
  }

  setAutomationActions() {
    const request = {
      AutomationInstanceID: -1,
      ProcessId: -1,
      UniqueServerID: -1,
      ForceQuit: -1,
      Adhocrun: -1,
      IsActive: -1
    }
    this.botDashboardService.setAutomationActions([request]).then(data => {
      this.setActionResponse = data;
      this.isLoading = false;
    });
  }

  getScheduleType() {
    let P_href = window.location.href.split("/");
    let P_route = P_href[P_href.length - 1].toLowerCase();
    let P_scheduleType = 1;

    if (P_route == "automationdashboard") {
      P_scheduleType = 1;
    } else if (P_route == "schedule") {
      P_scheduleType = 2;
    } else if (P_route == "realtimerefresh") {
      P_scheduleType = 3;
    }
    return P_scheduleType;
  }
  
}
