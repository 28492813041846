<div class="button-group">
  <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" style="width: 100px; margin-left: -100px; z-index: 20000; margin-top: 5px; margin-right: 10px;">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back
  </button>
</div>
<mat-tab-group [(selectedIndex)]="selectedTab">
    <mat-tab label="Role" *ngIf="hasRole_View">
        <app-role-details [role]="role" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)"></app-role-details>
    </mat-tab>
    <mat-tab *ngIf="hasRoleMenu_View" label="Menus" [disabled]='!role?.isactive || role?.roleid==-1'> <app-role-menus (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)"  (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)"  [role]="role"></app-role-menus> </mat-tab>
    <mat-tab label="Users" [disabled]='!role?.isactive || role?.roleid==-1'><app-role-users (onCancelEdit)="doCancelEdit($event)"  (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)" [role]="role"></app-role-users> </mat-tab>
    
</mat-tab-group>

