<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title>
                <app-user-edit [user]="user" (onCancelEdit)="doCancelEdit($event)" [profileEdit]="true"></app-user-edit>
            </mat-card-title>
        </mat-card-content>
    </mat-card>
</div>

