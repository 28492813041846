import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Table, TableColumn, TableColumnMapping } from '../../../entities/table-entity';
import { ColumnSelection } from '../../../entities/table-list';

@Component({
  selector: 'app-rule-mapping',
  templateUrl: './rule-mapping.component.html',
  styleUrls: ['./rule-mapping.component.css']
})
export class RuleMappingComponent {
  public _createTable!: Table
  public _tempTable!: string;
  public tables: string[] = [];
  public _selectedColumn: ColumnSelection[] = []
  @Input() set selectedColumn(selectedColumn: ColumnSelection[]) {
    this._selectedColumn = selectedColumn;
  }
  get selectedColumn() {
     
    return this._selectedColumn;
  }
  @Input() set Table(createTable: Table) {
   
    this._createTable = createTable;
    this._createTable as Table;
    let s = createTable.columns as TableColumnMapping[];
    s = s.map(w => {
      w.tableName = createTable.name;
      w.srcTableName = '';
      w.srcColumnName = '';
      return w;
    })
    this.dataSource.data=s;
  }
  public dataSource = new MatTableDataSource<TableColumnMapping>([]);
  displayedColumns: string[] = ['actions', 'tableName', 'name', 'sourceTable', 'sourceColumn'];
  get table() {
    return this._createTable;
  }
  @Input() set tempTable(tempTable: string) {
    this._tempTable = tempTable;
    this.tables.push(tempTable);
  }
  get tempTable() {
    return this._tempTable;
  }
}
