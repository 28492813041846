<h2 mat-dialog-title style="margin-left:15px;margin-top:15px">Password Generator</h2>
<mat-dialog-content style="width:450px">
  <form [formGroup]="formAdd">
    <input type="text" class="form-control" [value]="" id="nametext" aria-describedby="Name" placeholder="Abc@123" formControlName="passwordgenerator" style="width:380px; margin-left:15px;" />
    <button mat-flat-button style="margin-left:15px; margin-top:20px" class="btn" color="primary" (click)="GeneratePassword()">Generate Password</button>
    <button mat-flat-button style="margin-left:15px; margin-top:20px" class="btn" color="primary" [cdkCopyToClipboard]="clipboardText">Copy to Clipboard</button>
    <div class="form-check" style="margin-left:15px; margin-top:15px">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="passwordgeneratorcheckbox" (click)="checkBoxCheckUncheck()">
      <label class="form-check-label" for="flexCheckDefault">
        I have copied this password in a safe place
      </label>
    </div>
  </form>
</mat-dialog-content>
<br />
<mat-dialog-actions style="margin-top:-12px;margin-right:10px" align="end">
  <button mat-flat-button class="btn" color="primary" [disabled]='!checkboxcheck' (click)="usePassword()">Use Password</button>
  <button mat-flat-button class="btn" mat-dialog-close (click)="cancelPassword()">Cancel</button>
</mat-dialog-actions>


