import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../user/user';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent {

  public _user!: User;
  public resetpasswordonnextlogin: boolean = false;
   router: any;
  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getCurrentUser(user.userid = -1)
    }
  }
  get user() {
    return this._user;
  }
  @Output() onUpdate = new EventEmitter<User>();
  @Output() onCencelEdit = new EventEmitter();
  doCencelEdit(data:any) {
    this.onCencelEdit.emit();
  }
  ngOnInit(data:any): void {
    this.getCurrentUser(-1);


  }
  constructor(private dialog: MatDialog, private userService: UserService) {


  }
  getCurrentUser(userid: number) {
    const request = [{ loginuserid:-1, isactive:-1 }]
    this.userService.GetCurrentUser(request).then(res => {
      this.resetpasswordonnextlogin = res[0]?.resetpasswordonnextlogin ?? false
      if (this.resetpasswordonnextlogin) {
        this.router.navigateByUrl('https://localhost:5162/ResetPassword');
      }
    })
  }
}
