import { Pipe, PipeTransform } from '@angular/core';
import { getSafeDateReturnMoment } from 'src/app/Utility/common-functions';
import * as moment from 'moment';
@Pipe({
  name: 'datePipeSafe'
})
export class DatePipeSafePipe implements PipeTransform {   
  // Modified to handle both UTC and Local timezones (UTC for pure dates, Local for datetime)
  transform(value: any, format?: string, local?: boolean, safeMaxAge?: number): string | null {
    var sd: moment.Moment | null;
    if (!safeMaxAge) {
      sd = getSafeDateReturnMoment(value, local);
    }
    else {
      sd = getSafeDateReturnMoment(value, local, safeMaxAge);
    }
    if (sd) {
      return sd.format(format);
    }
    return null;
  }

}
