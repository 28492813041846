import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-remit-line-cas-code',
  templateUrl: './remit-line-cas-code.component.html',
  styleUrls: ['./remit-line-cas-code.component.css']
})
export class RemitLineCasCodeComponent {

  dataSource = new MatTableDataSource<any>([]);
  lineInfoColumns: string[] = ['group_code', 'reason_code', 'payment', 'adjustment_amount', 'copypayment_amount', 'deductible_amount', 'coinsurance_amount'];
  @Input() set LineData(linedata: any) {
    this.dataSource.data = linedata;
  }
  get LineData() {
    return this.dataSource.data;
  }
}
