<section class="table-container-inner" tabindex="0">
  <table style="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="blank">
      <th mat-header-cell width="10%" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <ng-container matColumnDef="blank0">
      <th mat-header-cell width="5%" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <ng-container matColumnDef="Menu">
      <th mat-header-cell width="30%" *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"> {{element.menuname}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell width="30%" *matHeaderCellDef> Section </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"> {{element.sectionnametagname}} </td>
    </ng-container>
    <ng-container matColumnDef="all">
      <th mat-header-cell width="5%" *matHeaderCellDef> All </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin" [checked]="haveAllpermision(element)"
                      (change)="onCheckboxChange($event,element,'all')" *ngIf="!hasChild(element)"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="weight">
      <th mat-header-cell width="5%" *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin" *ngIf="element.view_ != -1 && !hasChild(element)" [checked]="element.view"
                      (change)="onCheckboxChange($event,element,'view')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th mat-header-cell width="5%" *matHeaderCellDef> Add</th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin" *ngIf="element.add_ != -1 && !hasChild(element)"
                      [checked]="element.add" (change)="onCheckboxChange($event,element,'add')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="one">
      <th mat-header-cell width="5%" *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin" *ngIf="element.edit_ != -1 && !hasChild(element)" [checked]="element.edit"
                      (change)="onCheckboxChange($event,element,'edit')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="two">
      <th mat-header-cell width="5%" *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin"
                      *ngIf="element.delete_ != -1 && !hasChild(element)" [checked]="element.delete"
                      (change)="onCheckboxChange($event,element,'delete')"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="three">
      <th mat-header-cell width="5%" *matHeaderCellDef> Click </th>
      <td mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <mat-checkbox class="example-margin" *ngIf="element.click_ != -1 && !hasChild(element)"
                      [checked]="element.click"
                      (change)="onCheckboxChange($event,element,'click')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="stickyHeader">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </ng-container>
    <ng-container matColumnDef="groupHeader" style="padding-left:10px">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <td colspan="2" mat-cell *matCellDef="let groupBy">
        <button mat-button style="float:left; padding-left:20px; border: none;" *ngIf="hasChild(groupBy)" (click)="groupHeaderClick(groupBy)">
          <mat-icon *ngIf="groupBy.expanded">expand_less</mat-icon>
          <mat-icon *ngIf="!groupBy.expanded">expand_more</mat-icon>
        </button>
        <strong style="position: relative; top: 6px;" [ngClass]="{'marginLeft':!hasChild(groupBy)}">{{groupBy.menuname}}</strong>
      </td>
    </ng-container>


    <tr mat-row *matRowDef="let row; columns: displayedGroup; when: isGroup" [ngClass]="{'hidden-mat-row':!visible(row)}"> </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'hidden-mat-row':!visible(row)}"></tr>
  </table>
</section>
 

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">  
  <button mat-flat-button (click)="doSave('next')" color="primary" *ngIf="hasRoleMenu_Edit">Save & Next</button>
  <button mat-flat-button (click)="doSave('save')" color="primary" *ngIf="hasRoleMenu_Edit">Save Changes</button>
  <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
