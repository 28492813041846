<form [formGroup]="formAdd">
    <div fxLayout="column" fxLayoutGap="1em">

        <div class="row prifile-upload">
            <div class="col-lg-1">
                <img [src]="file" alt="user-avatar" class="d-block w-px-100 h-px-100 rounded" id="uploadedAvatar">
            </div>
            <div class="col-lg-9">
                <input #f_input type="file" id="upload" class="account-file-input" hidden="" accept="image/png, image/jpeg" (change)="onChange($event)">
                <button mat-flat-button style="margin-right: 10px; margin-bottom: 10px;" (click)="f_input.click()" color="primary">Upload</button>
                <a mat-flat-button  (click)="onReset()">Reset</a>
                <div>Allowed JPG, GIF or PNG. Max size of 800K</div>
            </div>
        </div>


        <div class="row">
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">
              First Name
              <mat-error *ngIf="formAdd.get('firstName')?.hasError('required') && formAdd.get('firstName')?.touched">
                *
              </mat-error>
            </small>
            <input type="text" class="form-control" id="nametext" aria-describedby="First Name" placeholder="First Name" formControlName="firstName">
            <mat-error *ngIf="formAdd.get('firstName')?.hasError('required') && formAdd.get('firstName')?.touched">
              First Name is required
            </mat-error>
          </div>
            <div class="form-group col-lg-4">
              <small id="name" class="form-text text-muted">
                Last Name
                <mat-error *ngIf="formAdd.get('lastName')?.hasError('required') && formAdd.get('lastName')?.touched">
                  *
                </mat-error>
              </small>
              <input type="text" class="form-control"   id="nametext" aria-describedby="Last Name" placeholder="Last Name" formControlName="lastName">
              <mat-error *ngIf="formAdd.get('lastName')?.hasError('required') && formAdd.get('lastName')?.touched">
                Last Name is required
              </mat-error>
            </div>
            <div class="form-group col-lg-4">
                <small id="name" class="form-text text-muted">Middle Name</small>
                <input type="text" class="form-control" id="nametext" aria-describedby="middleName" placeholder="Middle Name" formControlName="middleName">
            </div>

        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">
              E-mail
              <mat-error *ngIf="formAdd.get('email')?.hasError('required') && formAdd.get('email')?.touched">
                *
              </mat-error>
            </small>
            <input type="text" class="form-control" id="nametext" aria-describedby="E-mail" placeholder="email@example.com" formControlName="email">
            <mat-error *ngIf="formAdd.get('email')?.hasError('required') && formAdd.get('email')?.touched">
              Email is required
            </mat-error>
            <mat-error *ngIf="formAdd.get('email')?.hasError('email') && formAdd.get('email')?.touched">
              Please enter a valid EmailId
            </mat-error>
          </div>
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">
              User Name
              <mat-error *ngIf="formAdd.get('username')?.hasError('required') && formAdd.get('username')?.touched">
                *
              </mat-error>
            </small>
            <input type="text" class="form-control" id="nametext" aria-describedby="UserName" placeholder="User Name" formControlName="username">
            <mat-error *ngIf="formAdd.get('username')?.hasError('required') && formAdd.get('username')?.touched">
              UserName is required
            </mat-error>
            <!--<mat-error *ngIf="formAdd.get('email')?.hasError('email') && formAdd.get('email')?.touched">
              Please enter User Name
            </mat-error>-->
          </div>
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">Phone</small>
            <input type="text" class="form-control" id="nametext" aria-describedby="Phone" placeholder="XXX XXX XXXX" formControlName="phone">
            <mat-error *ngIf="formAdd.get('phone')?.touched && formAdd.get('phone')?.invalid">

              <div *ngIf="formAdd.controls.phone.errors.phone">Please enter a valid phone number with at least 10 digits.</div>

            </mat-error>
            <!--<mat-error *ngIf="formAdd.get('phone')?.touched && formAdd.get('phone')?.invalid">
              Please enter a valid EmailId
            </mat-error>-->
          </div>

         

          <!--<div class="form-group col-lg-4">
    <small id="name" class="form-text text-muted">
      First Name
      <mat-error *ngIf="formAdd.get('employeeId')?.hasError('required') && formAdd.get('employeeId')?.touched">
        *
      </mat-error>
    </small>
    <input type="text" class="form-control" id="nametext" aria-describedby="First Name" placeholder="First Name" formControlName="employeeId">
    <mat-error *ngIf="formAdd.get('employeeId')?.hasError('required') && formAdd.get('employeeId')?.touched">
      First Name is required
    </mat-error>
  </div>-->

        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">
              Employee #
              <mat-error *ngIf="formAdd.get('employeeId')?.hasError('required') && formAdd.get('employeeId')?.touched">
                *
              </mat-error>
            </small>
            <!--[readonly]="profileEdit"-->
            <input type="text" class="form-control" id="nametext" aria-describedby="Employee" placeholder="Employee #" formControlName="employeeId">
            <mat-error *ngIf="formAdd.get('employeeId')?.hasError('required') && formAdd.get('employeeId')?.touched">
              Please Enter EmployeeId
            </mat-error>
          </div>

          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">
              User Type
              <mat-error *ngIf="formAdd.get('userTypeId')?.hasError('required') && formAdd.get('userTypeId')?.touched">
                *
              </mat-error>
            </small>
            <select class="form-select" aria-label="User Type" [disabled]="profileEdit" formControlName="userTypeId">
              <option selected>Select User Type</option>
              <option value="1">In office</option>
              <option value="2">offshore</option>
            </select>
            <mat-error *ngIf="formAdd.get('userTypeId')?.hasError('required') && formAdd.get('userTypeId')?.touched">
              Please Select at least one
            </mat-error>
          </div>
          <div class="form-group col-lg-4" style="width:33.33%">
            <small id="name" class="form-text text-muted" style="width:100%">
              Joining Date
              <mat-error *ngIf="formAdd.get('useronBoardDate')?.hasError('required') && formAdd.get('useronBoardDate')?.touched">
                *
              </mat-error>
            </small>
            <div>
              <input type="text" [matDatepicker]="picker" style="width:90%;float: left;" class="form-control" readonly id="nametext" aria-describedby="useronboarddate" placeholder="MM-dd-YYYY" formControlName="useronBoardDate">
              <mat-datepicker-toggle matIconSuffix *ngIf="!profileEdit" [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker class="form-group-text" style="float:right;" #picker></mat-datepicker>
            </div>
            <mat-error *ngIf="formAdd.get('useronBoardDate')?.hasError('required') && formAdd.get('useronBoardDate')?.touched">
              Please Select date
            </mat-error>
          </div>

        </div>
        <div class="row">

            <div class="form-group col-lg-4">
                <small id="name" class="form-text text-muted">Address 1</small>
                <input type="text" class="form-control" id="nametext"   aria-describedby="Address" placeholder="Address" formControlName="address1">
            </div>
            <div class="form-group col-lg-4">
                <small id="name" class="form-text text-muted">Address 2</small>
                <input type="text" class="form-control" id="nametext"  aria-describedby="Address" placeholder="Address" formControlName="address2">
            </div>
            <div class="form-group col-lg-4">
                <small id="name" class="form-text text-muted">Address 3</small>
                <input type="text" class="form-control" id="nametext"   aria-describedby="Address" placeholder="Address" formControlName="address3">
            </div>


        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">Country</small>
            <input type="text" class="form-control" id="nametext"   aria-describedby="Country" placeholder="Country" formControlName="country">
            <mat-error *ngIf="formAdd.errors?.countryRequired">
              Country must be entered
            </mat-error>

          </div>
          <div class="form-group col-lg-4">
            <small id="name" class="form-text text-muted">State</small>
            <input type="text" class="form-control" id="nametext" aria-describedby="State" placeholder="State" formControlName="state">
            <mat-error *ngIf="formAdd.errors?.stateRequired">
              State must be entered
            </mat-error>
          </div>
            <div class="form-group col-lg-4">
              <small id="name" class="form-text text-muted">City</small>
              <input type="text" class="form-control" id="nametext"   aria-describedby="City" placeholder="City" formControlName="city">
              <mat-error *ngIf="formAdd.errors?.cityRequired">
               City must be entered
              </mat-error>
            </div>

        </div>
        <div class="row">
          <div class="form-group col-lg-4">
            <small id="zipCode" class="form-text text-muted">Zip Code</small>
            <input type="text" class="form-control" id="zipCode" formControlName="zipCode">
            <mat-error *ngIf="formAdd.get('zipCode')?.touched && formAdd.get('zipCode')?.invalid">
              <div *ngIf="formAdd.get('zipCode')?.errors?.invalidZipCode">Please enter a valid 5-digit zip code.</div>
            </mat-error>
          </div>
            <div class="form-group col-lg-4" *ngIf="!profileEdit">
                <small id="name" class="form-text text-muted"></small>
                <div class="form-check form-switch" style="margin-top: 31px;">
                    <input class="form-check-input" [checked]="userDetails?.isActive" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="isActive">
                    <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                </div>
            </div>
        </div>
        <div>
          <button mat-flat-button style="margin-right:10px;" (click)="doSave('next')" color="primary" *ngIf="userDetails.isactive == 1 && hasUserAccount_Edit">Save & Next</button>
          <button mat-flat-button style="margin-right:10px;" (click)="doSave('save')" color="primary" *ngIf="hasUserAccount_Edit">Save Changes</button>
          <button mat-flat-button class="btn" (click)="doCancelEdit()">Cancel</button>
        </div>
    </div>
</form>

<app-data-loader [isLoading]="_isLoading"></app-data-loader>
