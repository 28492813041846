<!-- <mat-card>
    <mat-card-header style="padding: 10px 0 0 0;width:100%"> -->
<div style="width:100%; float:left;padding: 10px 0 0 0">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em" style="width:90%; float:left">
        <mat-form-field fxFlex="0 0 15em">
            <input matInput placeholder="Name" autocomplete="off" [(value)]="Table.name">
        </mat-form-field>
    </div>
    <button mat-mini-fab color="primary" style="float:right" (click)="addColumn()">
        <mat-icon>library_add</mat-icon>
    </button>
</div>
<!-- </mat-card-header>
<mat-card-content style="padding:0;"> -->
<section class="table-container" tabindex="0">
    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8 box-shadow-none">
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
                <input matInput placeholder="Name" autocomplete="off" [(value)]="element.name" (change)="onInputChange($event,element,'name')">
            </td>
        </ng-container>
        <ng-container matColumnDef="dataType">
            <th mat-header-cell *matHeaderCellDef>Data Type </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="width:120px !important">
                    <mat-select required [(value)]="element.dataType">
                        <mat-option *ngFor="let type of dataType" [value]="type">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <div class="task-title">{{element.condition}}</div> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Length </th>
            <td mat-cell *matCellDef="let element">
                <input matInput placeholder="Value" autocomplete="off" [(value)]="element.size" (change)="onInputChange($event,element,'size')" />

            </td>
        </ng-container>

        <ng-container matColumnDef="scal">
            <th mat-header-cell *matHeaderCellDef> Scal </th>
            <td mat-cell *matCellDef="let element">
                <input matInput placeholder="Value" autocomplete="off" [(value)]="element.scal" (change)="onInputChange($event,element,'scal')">
            </td>
        </ng-container>

        <ng-container matColumnDef="isNotNull">
            <th mat-header-cell *matHeaderCellDef>Allow Null</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="example-margin" (change)="onCheckboxChange($event,element)" [checked]="element.isNotNull"></mat-checkbox>

            </td>
        </ng-container>
        <ng-container matColumnDef="default">
            <th mat-header-cell *matHeaderCellDef>Default</th>
            <td mat-cell *matCellDef="let element">
                <input matInput placeholder="Value" autocomplete="off" [(value)]="element.default" (change)="onInputChange($event,element,'default')">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="mat-condition-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="block-Row"></tr>

    </table>

    <div style="visibility: hidden; position: fixed"
         [style.left]="contextMenuPosition.x"
         [style.top]="contextMenuPosition.y"
         [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onContextAddCondition(item)">Add Condition</button>
        </ng-template>
    </mat-menu>
</section>
<!--  </mat-card-content>
</mat-card> -->
