<!--<section class="table-container" tabindex="0" style="height:45vh;overflow:auto ;box-shadow:0px">
  <table sstyle="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <th mat-header-cell width="40%" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell width="40%" *matHeaderCellDef> description </th>
      <td mat-cell *matCellDef="let row"> {{row.description}} </td>
    </ng-container>


    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    </ng-container>
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell width="20%" *matHeaderCellDef>Assign User </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" [checked]="element.assigned"></mat-checkbox>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{''}}"</td>
    </tr>
  </table>
</section>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
  <button mat-stroked-button style="margin-right:10px;" (click)="doSave()">Save Changes</button>
  <button mat-stroked-button (click)="doCancelEdit()">Cancel</button>
</div>-->



<!--<section class="table-container-inner" tabindex="0">
  <table sstyle="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="first">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row"> </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell width="40%" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.name?.toLocaleLowerCase()}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell width="55%" *matHeaderCellDef> description </th>
      <td mat-cell *matCellDef="let row"> {{row.description?.toLocaleLowerCase()}} </td>
    </ng-container>


    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    </ng-container>
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox class="example-margin" [checked]="assignAll" (change)="onCheckboxChange($event,null,'all')"></mat-checkbox>

      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" [checked]="element.assigned==1" (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{''}}"</td>
    </tr>
  </table>
</section>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
  <button mat-flat-button (click)="doSave()" color="primary">Save Changes</button>
  <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>-->
<!--<app-data-loader [isLoading]="_isLoading"></app-data-loader>-->



<section class="table-container-inner" tabindex="0">
  <table style="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="blank">
      <th mat-header-cell width="10%" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <!--<ng-container matColumnDef="Menu">
      <th mat-header-cell width="30%" *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{row.name?.toLocaleLowerCase()}} </td>
    </ng-container>-->
    <ng-container matColumnDef="name">
      <th mat-header-cell width="30%" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.clientname}} </td>
    </ng-container>
    <ng-container matColumnDef="all">
      <th mat-header-cell width="5%" *matHeaderCellDef> All </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" [checked]="haveAllpermision(element)"
                      (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="weight">
      <th mat-header-cell width="5%" *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.view_ != null && element.view_ != -1" [checked]="element.view"
                      (change)="onCheckboxChange($event,element,'view')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th mat-header-cell width="5%" *matHeaderCellDef> Add</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.add_ != null && element.add_ != -1"
                      [checked]="element.add" (change)="onCheckboxChange($event,element,'add')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="one">
      <th mat-header-cell width="5%" *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.edit_ != null && element.edit_ != -1 " [checked]="element.edit"
                      (change)="onCheckboxChange($event,element,'edit')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="two">
      <th mat-header-cell width="5%" *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin"
                      *ngIf="element.delete_ != null && element.delete_ != -1" [checked]="element.delete"
                      (change)="onCheckboxChange($event,element,'delete')"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="three">
      <th mat-header-cell width="5%" *matHeaderCellDef> Click </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.click != null"
                      [checked]="element.click"
                      (change)="onCheckboxChange($event,element,'click')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="stickyHeader">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </ng-container>
    <!--<ng-container matColumnDef="groupHeader" style="padding-left:10px">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <td colspan="2" mat-cell *matCellDef="let groupBy">
        <button mat-button style="float:left; padding-left:20px; margin-right: 10px; border: none;" (click)="groupHeaderClick(groupBy)">
          <mat-icon *ngIf="groupBy.expanded">expand_less</mat-icon>
          <mat-icon *ngIf="!groupBy.expanded">expand_more</mat-icon>
        </button>
        <strong>{{groupBy.menuname}}</strong>
      </td>
    </ng-container>-->


    <!--<tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" [ngClass]="{'hidden-mat-row':!visible(row)}"> </tr>-->
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>


<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
  <button mat-flat-button (click)="doSave()" color="primary" *ngIf="hasGropuClient_Edit">Save Changes</button>
  <button mat-flat-button extended class="btn" (click)="doCancelEdit()" >Cancel</button>
</div>
<!--<app-data-loader [isLoading]="_isLoading"></app-data-loader>-->

