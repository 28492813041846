import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CommentService } from '../../../services/comment.service';
import { Activity, activities } from './activity-timeline-data';

@Component({
  selector: 'app-activity-timeline',
  templateUrl: './activity-timeline.component.html'
})
export class ActivityTimelineComponent implements OnInit {

  activityData: Activity[];
  public _isLoading: boolean = true;
  constructor(private commentService: CommentService,
    private router: Router,) {

    this.activityData = [];
    this.getNotifications();
  }
  getNotifications() {
    let request = [
      {
        "createdBy": 1,
        "notificationtypeid": 1,
        "practiceid": 1,
        "warehouseuniqueidentifier": 1,
        "pagesize": 1000,
        "pagestartrecord": 0
      }
    ]
    this.commentService.getNotifications(request).then(res => {
      this._isLoading = false;
      this.activityData = res;
    })
  }
  getUserTime(date: string) {
    var m1 = moment(date);
    return m1.fromNow();
  }
  ngOnInit(): void {
  }
  redirectToActions(url: string) {
    this.router.navigate([url])
  }
}
