import { Component, Input, ViewChild } from '@angular/core';
import {  IDashboardEmbedConfiguration, models } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { ReportService } from '../../services/report.service';
import { UserService } from '../user/user.service';
@Component({
  selector: 'app-power-bidashboard-embed',
  templateUrl: './power-bidashboard-embed.component.html',
  styleUrls: ['./power-bidashboard-embed.component.css']
})
export class PowerBIDashboardComponent {

  constructor(public httpService: ReportService
    , private userService: UserService) {
    //this.setUserToken();
    //this.embedReport()
  }
  //setUserToken() {
  //  this.userService.setUserToken().then(res => {
  //    this.httpService.accessToken = res.token;
  //    this.embedReport();
  //  });
  //} 
  @Input() set accessToken(token: string) {
    if (token) {
      this.httpService.accessToken = token;
      this.embedReport();
    }
  }
  @Input() groupId!: string;
  @Input() dashboardId!: string;
  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';

  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: IDashboardEmbedConfiguration = {
    type: 'dashboard',
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    pageView: "fitToWidth",
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false
    }    
  };
  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(): Promise<void> {

    let reportResponse = await this.httpService.getdashboardEmbedURL(this.groupId, this.dashboardId);
    this.reportConfig = {
      ...this.reportConfig,
      id: reportResponse.id,
      embedUrl: reportResponse.embedUrl,
      accessToken: this.httpService.accessToken
    };
    // Update the reportConfig to embed the PowerBI report


    if (this.reportObj) {
      const report = this.reportObj.getReport();
      report.on('loaded', event => {

      })
    }
    else {
    }


  }


}
