
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGanttEditorModule } from 'ng-gantt'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { RulesComponent } from './components/rules/rules.component';
import { RulesJoinsComponent } from './components/rules/rules-joins/rules-joins.component';
import { RulesConditionComponent } from './components/rules/rules-condition/rules-condition.component';
import { RuleAddComponent } from './components/rules/rules-condition/rule-add/rule-add.component';
import { CreateTableComponent } from './components/rules/create-table/create-table.component';
import { ColumnSelectionComponent } from './components/rules/create-table/column-selection/column-selection.component';
import { RuleFilterComponent } from './components/rules/rule-filter/rule-filter.component';
import { RuleMappingComponent } from './components/rules/rule-mapping/rule-mapping.component';
import { FlowChartComponent } from './components/flow-chart/flow-chart.component';
import { CustomStepComponent } from './components/flow-chart/custom-step/custom-step.component';
import { NestedFlowComponent } from './components/flow-chart/nested-flow/nested-flow.component';
import { ReportComponent } from './components/report/report.component';
import {  PowerBIEmbedModule } from 'powerbi-client-angular';
import { RuleActionComponent } from './components/rules/rule-action/rule-action.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { GridComponent } from './components/grid/grid.component';
import { AvatarComponent } from './layouts/full/avatar/avatar.component';
import { FormElimentComponent } from './components/form-eliment/form-eliment.component';
import { UserComponent } from './components/user/user.component';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { UserAccountComponent } from './components/user/user-account/user-account.component';
import { UserSecurityComponent } from './components/user/user-security/user-security.component';
import { UserNotificationSettingComponent } from './components/user/user-notification-setting/user-notification-setting.component';
import { UserEditComponent } from './components/user/user-edit/user-edit.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserRoleComponent } from './components/user/user-role/user-role.component';
import { UserGroupComponent } from './components/user/user-group/user-group.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { RoleManagmentComponent } from './components/role-managment/role-managment.component';
import { GroupManagmentComponent } from './components/group-managment/group-managment.component';
import { RoleListComponent } from './components/role-managment/role-list/role-list.component';
import { RoleEditComponent } from './components/role-managment/role-edit/role-edit.component';
import { RoleDetailsComponent } from './components/role-managment/role-details/role-details.component';
import { RoleMenusComponent } from './components/role-managment/role-menus/role-menus.component';
import { RoleUsersComponent } from './components/role-managment/role-users/role-users.component';
import { GroupDetailsComponent } from './components/group-managment/group-details/group-details.component';
import { GroupEditComponent } from './components/group-managment/group-edit/group-edit.component';
import { GroupClientComponent } from './components/group-managment/group-client/group-client.component';
import { GroupUserComponent } from './components/group-managment/group-user/group-user.component';
import { GroupListComponent } from './components/group-managment/group-list/group-list.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TaskListComponent } from './components/tasks/task-list/task-list.component';
import { TaskLabelComponent } from './components/tasks/task-label/task-label.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { TaskDetailsComponent } from './components/tasks/task-details/task-details.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { LoginComponent } from './components/login/login.component';
import { StoreModule } from '@ngrx/store';
import { addLoginReducer } from './store/reducer/login.reducer';
import { DataLoaderComponent } from './components/data-loader/data-loader.component';
import { MatListModule } from '@angular/material/list';
import { CustomFilterComponent } from './components/custom-filter/custom-filter.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { MoneyDirective } from './directive/money.directive';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { NumberDirective } from './directive/number.directive';
import { EntityStatusDirective } from './directive/entity-status.directive';
import { DatePipeSafePipe } from './pipes/date-pipe-safe.pipe';
import { DisplayAgePipe } from './pipes/display-agg.pipe';
import { DisplayAgingBucketPipe } from './pipes/display-aging-bucket.pipe';
import {  NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EmailVerifiedDirective } from './directive/email-verified.directive';

import { BotDashboardComponent } from './components/bot-dashboard/bot-dashboard.component';
import { ServersListComponent } from './components/bot-dashboard/servers-list/servers-list.component';
import { DetailsListComponent } from './components/bot-dashboard/details-list/details-list.component';
import { SummaryListComponent } from './components/bot-dashboard/summary-list/summary-list.component';

// Import FullCalendar module
import { FullCalendarModule } from '@fullcalendar/angular';
import { TaskLabelListComponent } from './components/tasks/task-label/task-label-list/task-label-list.component';
import { TaskActionComponent } from './components/tasks/task-action/task-action.component'; 
import { TaskInformationComponent } from './components/tasks/task-information/task-information.component';
import { TaskDetailComponent } from './components/tasks/task-information/task-detail/task-detail.component';
import { TaskFinancialDetailComponent } from './components/tasks/task-information/task-financial-detail/task-financial-detail.component';
import { TaskRemitComponent } from './components/tasks/task-information/task-remit/task-remit.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TaskAgingDetailsComponent } from './components/tasks/task-information/task-detail/task-aging-details/task-aging-details.component';
import { TaskPayorListComponent } from './components/tasks/task-information/task-detail/task-payor-list/task-payor-list.component';
import { TaskSummaryComponent } from './components/tasks/task-information/task-detail/task-summary/task-summary.component';
import { RemitLineListComponent } from './components/tasks/task-information/task-remit/remit-line-list/remit-line-list.component';
import { RemitLineCasCodeComponent } from './components/tasks/task-information/task-remit/remit-line-cas-code/remit-line-cas-code.component';

import { ClientManagementComponent } from './components/client-management/client-management.component';
import { ClientListComponent } from './components/client-management/client-list/client-list.component';
import { ClientEditComponent } from './components/client-management/client-edit/client-edit.component';
import { ClientDetailsComponent } from './components/client-management/client-details/client-details.component';
import { ClientGroupsComponent } from './components/client-management/client-groups/client-groups.component';
import { PasswordgeneratorComponent } from './components/passwordgenerator/passwordgenerator.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { DrawFlowDemoComponent } from './components/draw-flow-demo/draw-flow-demo.component';
import { NgxTimepickerModule } from 'ngx-timepicker';

import { TaskActionAddComponent } from './components/tasks/task-information/task-action-add/task-action-add.component';
import { TaskActionDialogComponent } from './components/tasks/task-action-dialog/task-action-dialog.component';
import { ChildRouteComponent } from './components/child-route/child-route.component';
import { TaskFilterComponent } from './components/tasks/task-filter/task-filter.component';
import { MultiSelectComponent } from './shared/multi-select/multi-select.component';

import { WorkqueuesComponent } from './components/workqueues/workqueues.component';
import { WorkqueueListComponent } from './components/workqueues/workqueue-list/workqueue-list.component';
import { ReportFilterComponent } from './components/report/report-filter/report-filter.component'; 
import { SchedulingActionComponent } from './components/bot-dashboard/scheduling-action/scheduling-action.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { AdhocrunActionComponent } from './components/bot-dashboard/adhocrun-action/adhocrun-action.component';
import { PayrollDialogComponent } from './components/payroll/payroll-dialog/payroll-dialog.component';
import { PayrollConnectorComponent } from './components/payroll/payroll-connector/payroll-connector.component';
import { PayrollInputComponent } from './components/payroll/payroll-input/payroll-input.component';
import { DatePipe } from '@angular/common';
import { ContactsComponent } from './dashboard/dashboard-components/contacts/contacts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SalesOverviewComponent } from './dashboard/dashboard-components/sales-overview/sales-overview.component';
import { activities } from './dashboard/dashboard-components/activity-timeline/activity-timeline-data';
import { ActivityTimelineComponent } from './dashboard/dashboard-components/activity-timeline/activity-timeline.component';
import { PowerBIDashboardComponent } from './components/power-bidashboard-embed/power-bidashboard-embed.component';
import { RdlReportComponent } from './components/report/rdl-report/rdl-report.component';
import { PowerBIPaginatedReportEmbedComponent } from './components/report/power-bipaginated-report-embed/power-bipaginated-report-embed.component';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal
 

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent ,
    RulesComponent,
    RulesJoinsComponent,
    RulesConditionComponent,
    RuleAddComponent,
    CreateTableComponent,
    ColumnSelectionComponent,
    RuleFilterComponent,
    RuleMappingComponent,
    FlowChartComponent,
    CustomStepComponent,
    NestedFlowComponent,
    ReportComponent,
    RuleActionComponent
    , GridComponent
    , AvatarComponent,
    FormElimentComponent,
    UserComponent,
    UserProfileComponent,
    UserAccountComponent,
    UserSecurityComponent,
    UserNotificationSettingComponent, UserEditComponent,
    UserListComponent, UserRoleComponent, UserGroupComponent,
    ConfirmDialogComponent, RoleManagmentComponent, GroupManagmentComponent,
    RoleListComponent, RoleEditComponent, RoleDetailsComponent, RoleMenusComponent,
    RoleUsersComponent, GroupDetailsComponent, GroupEditComponent, GroupClientComponent,
    GroupUserComponent, GroupListComponent, TaskListComponent,
    TasksComponent, TaskLabelComponent, TaskDetailsComponent,
    LoginComponent,
    DataLoaderComponent,
    CustomFilterComponent,
    ResetpasswordComponent,
    ForgotPasswordComponent,
    MoneyDirective, NumberDirective, EntityStatusDirective, DatePipeSafePipe, DisplayAgePipe,
    DisplayAgingBucketPipe, EmailVerifiedDirective, TaskLabelListComponent, TaskActionComponent,
 
    TaskDetailComponent, TaskInformationComponent, TaskFinancialDetailComponent,
    TaskRemitComponent, TaskAgingDetailsComponent, TaskPayorListComponent,
    TaskSummaryComponent, RemitLineListComponent, RemitLineCasCodeComponent,
    ClientManagementComponent, ClientListComponent, ClientEditComponent, ClientDetailsComponent,
    ClientGroupsComponent, PasswordgeneratorComponent
    , TaskActionAddComponent, TaskActionDialogComponent, ChildRouteComponent, TaskFilterComponent
 
    , MultiSelectComponent, WorkqueuesComponent, WorkqueueListComponent
    , MultiSelectComponent, ReportFilterComponent
    , BotDashboardComponent
    , ServersListComponent, DetailsListComponent, SummaryListComponent, SchedulingActionComponent
    , DrawFlowDemoComponent
    , PayrollComponent, PayrollDialogComponent, PayrollConnectorComponent, PayrollInputComponent, AdhocrunActionComponent
    , ContactsComponent, DashboardComponent, SalesOverviewComponent, ActivityTimelineComponent, PowerBIDashboardComponent, RdlReportComponent, PowerBIPaginatedReportEmbedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    ReactiveFormsModule,
    PowerBIEmbedModule,
    NgDragDropModule.forRoot(),
    MatTreeModule,
    MatBadgeModule,
    CdkAccordionModule,
    MatTooltipModule,
    MsalModule,    
    StoreModule.forRoot({ loginInfo: addLoginReducer }),
    NgMultiSelectDropDownModule.forRoot(),
    MatListModule,
    NgGanttEditorModule,
    FullCalendarModule,
    OverlayModule,
    ClipboardModule,
    NgxTimepickerModule
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {
}
