<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Position title -->
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type </th>
        <td mat-cell *matCellDef="let element">
            {{element.notificationname }}
        </td>
    </ng-container>

    <!-- Name Priority -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element">
            <!--<input class="example-margin" type="checkbox" id="nametext" [checked]="element.isemail" (change)="onCheckboxChange($event,element,1)" />-->
            <mat-checkbox class="example-margin" [checked]="element.isemail" (change)="onCheckboxChange($event,element,1)"></mat-checkbox>
        </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="browser">
        <th mat-header-cell *matHeaderCellDef> Browser </th>
        <td mat-cell *matCellDef="let element">
          <!--<input type="checkbox" id="nametext" [checked]="element.isbrowser" (change)="onCheckboxChange($event,element,2)" />-->
          <mat-checkbox class="example-margin"  [checked]="element.isbrowser" (change)="onCheckboxChange($event,element,2)"></mat-checkbox>
        </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="app">
        <th mat-header-cell *matHeaderCellDef> App </th>
        <td mat-cell *matCellDef="let element">
            <input type="checkbox" id="nametext" [checked]="element.app" />
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top: 20px;">
  <button mat-flat-button style="margin-right:10px;" (click)="doSave('next')" color="primary" *ngIf="hasUserNotification_Edit">Save & Next</button>
  <button mat-flat-button style="margin-right:10px;" (click)="doSave('save')" color="primary" *ngIf="hasUserNotification_Edit">Save Changes</button>
  <button mat-flat-button (click)="doCancelEdit()">Cancel</button>
</div>

<app-data-loader [isLoading]="_isLoading"></app-data-loader>
