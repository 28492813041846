<!-- <div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title> -->
 <div class="button-group">
    <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" style="width: 100px; margin-left: -100px; z-index: 20000; margin-top: 5px; margin-right: 10px;">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
    </button>     
</div>
                <mat-tab-group [(selectedIndex)]="selectedTab"> 
                    <mat-tab label="Account" *ngIf="hasUserAccount_View">
                        <app-user-account [user]="user" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)" (onUpdateUser)="doUpdateUser($event)" (doNextEdit)="doNextEdit($event)" [profileEdit]="profileEdit"></app-user-account>
                    </mat-tab>
                    <mat-tab label="Security" *ngIf="hasUserSecurity_View" [disabled]='!user?.isactive || user?.userid==-1'>
                        <app-user-security [user]="user" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)" (onUpdate)="doUpdate($event)" (doNextEdit)="doNextEdit($event)" [profileEdit]="profileEdit"></app-user-security>
                    </mat-tab>
                    <mat-tab label="Notifications" *ngIf="hasNotification_View" [disabled]='!user?.isactive || user?.userid==-1'>
                        <app-user-notification-setting  [user]="user" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)" (onUpdate)="doUpdate($event)" (doNextEdit)="doNextEdit($event)" [profileEdit]="profileEdit"></app-user-notification-setting>
                    </mat-tab>
                    <mat-tab label="Roles" *ngIf="hasUserRole_View" [disabled]='!user?.isactive || user?.userid==-1'>
                        <app-user-role  [user]="user" (onCancelEdit)="doCancelEdit($event)" (onUpdate)="doUpdate($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)" [profileEdit]="profileEdit"></app-user-role>
                    </mat-tab>
                    <mat-tab label="Groups" *ngIf="hasUserGroup_View" [disabled]='!user?.isactive || user?.userid==-1'>
                        <app-user-group  [user]="user" (onCancelEdit)="doCancelEdit($event)" (onUpdate)="doUpdate($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)" [profileEdit]="profileEdit"></app-user-group>
                    </mat-tab>

                </mat-tab-group>
           <!--  </mat-card-title>
        </mat-card-content>
    </mat-card>
</div> -->
