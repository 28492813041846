 

export class ReportFilters {
  public static readonly CheckDate = 1;
  public static readonly CalenderDate = 2;  
  public static readonly Practice = 3;
  public static readonly Selector = 4;
  public static readonly TransactionDate = 5;
  public static readonly Servicelocation = 6;
  public static readonly ServiceProvider = 7;
  public static readonly PayorGroups = 8;
  public static readonly Payor = 9;
  public static readonly STransactionDate = 10;
  public static readonly RCheckDate = 11;
  public static readonly RTransactionDate = 12;
}
