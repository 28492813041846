// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
   msalConfig: {
    auth: {
       clientId: 'dff87e40-b5f3-4ff7-9d6c-82a15e613ee4',
      // authorityDomain: "https://login.microsoftonline.com/common/v2.0"
       authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
       redirectUri: 'https://localhost:5162/login'
       /*redirectUri: 'https://template.pnestec.com/login'*/
    }
  },
  apiConfig: {
    scopes: [
      'https://analysis.windows.net/powerbi/api/.default',
      'offline_access',
        'openid',
        'email',
        'profile'
    ],
    //scopes: [
    //  'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
    //  'https://analysis.windows.net/powerbi/api/Report.Read.All',
    //  'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
    //  'openid',
    //  'email',
    //  'profile'
    //],
    uri: 'http://localhost:4200/',
    
  },
  apibaseUrl: '',
  powerBiBaseUrl: 'https://api.powerbi.com/v1.0/myorg',
  //b2cPolicies: {
  //  names: {
  //    signUpSignIn: "",
  //    resetPassword: "",
  //    editProfile: ""
  //  },
  //  authorities: {
  //    signUpSignIn: {
  //      authority: ""
  //    },
  //    resetPassword: {
  //      authority: ""
  //    },
  //    editProfile: {
  //      authority: ""
  //    }
  //  },
  //  authorityDomain: "https://login.microsoftonline.com/common/v2.0"
  //}
};
