

<mat-card class="add-rules-card">
    <mat-card-content class="add-rules-card-content">
        <button mat-button color="primary" (click)="onContextAddCondition()">Add</button>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em" class="join-header">
            <div fxFlex="0 0 10em">
                Condition
            </div>
            <div fxFlex="0 0 10em">
                From Column
            </div>
            <div fxFlex="0 0 10em">
                Oprator
            </div>
            <div fxFlex="0 0 10em">
                To Column
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em" *ngFor="let condition of conditions">
            <app-rule-add [condition]="condition" [toTable]="toTable" [fromTable]="fromTable"></app-rule-add>
        </div>
    </mat-card-content>
</mat-card>



<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" #contextMenu2>
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="onContextAddCondition(item)">Add Condition</button>
    </ng-template>
</mat-menu>
<mat-menu #matMenuColumn="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="onDeleteColumn(item)">Delete</button>
    </ng-template>
</mat-menu>
