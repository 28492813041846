<div class="button-group">
  <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" style="width: 100px; margin-left: -100px; z-index: 20000; margin-top: 10px; margin-right: 10px;">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back
  </button>
</div>
<mat-tab-group class="mat-table-edit-box" [(selectedIndex)]="selectedTab">
  <mat-tab label="Client" *ngIf="hasClient_View">
    <app-client-details [client]="client" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)"></app-client-details>
  </mat-tab>
  <!--<mat-tab label="Group" [disabled]='!client.isactive || client.clientid==-1'><app-client-groups [client]="client" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)"></app-client-groups> </mat-tab>-->
  <mat-tab label="Group" [disabled]=true><app-client-groups [client]="client" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)"></app-client-groups> </mat-tab>

</mat-tab-group>
