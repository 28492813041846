import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TableList } from '../entities/table-list';
import { Condition, Group } from '../entities/condition-entity';
import { environment } from '../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})

export class ReportService {
  readonly powerBiUrl: string = 'https://api.powerbi.com/v1.0/myorg/reports';
  readonly baseUrl: string = environment.powerBiBaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Table';
  public accessToken = "";


  constructor(private http: HttpClient) { }

  async getEmbedURL(groupId: string, repotId: string): Promise<any> {
    let embedUrl = `${this.baseUrl}/groups/${groupId}/reports/${repotId}`;

    let reportdata: any[] = JSON.parse(localStorage.getItem("reportUrls") ?? "[]");
    if (reportdata.length == 0) {
      let res = await this.getReports("");
      localStorage.setItem("reportUrls", JSON.stringify(res));
    }
    let EmbedUrl = reportdata.find(w => { return w.id == repotId });

    if (EmbedUrl) {
      return EmbedUrl;
    }
    return await this.http.get<any>(embedUrl, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        contentType: "application/json; charset=utf-8",
      },
    }).toPromise();
  }
  async getdashboardEmbedURL(groupId: string, dashboardId: string=""): Promise<any> {
    let embedUrl = `${this.baseUrl}/groups/${groupId}/dashboards/${dashboardId}`;
    if (!dashboardId) {
      embedUrl = `${this.baseUrl}/groups/${groupId}/dashboards`;
    }
    let reportdata: any[] = JSON.parse(localStorage.getItem("reportUrls") ?? "[]");
    let EmbedUrl = reportdata.find(w => { return w.id == dashboardId });

    if (EmbedUrl) {
      return EmbedUrl;
    }
    return await this.http.get<any>(embedUrl, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        contentType: "application/json; charset=utf-8",
      },
    }).toPromise();
  }
  async getWorkspace(): Promise<any> {
    let embedUrl = `${this.baseUrl}/groups`;

    return await this.http.get<any>(embedUrl, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        contentType: "application/json; charset=utf-8",
      }
    }).toPromise();
  }
  async getReports(groupId: string): Promise<any> {
    let embedUrl = `${this.baseUrl}/groups/${groupId}/reports`;

    if (!groupId) {
      embedUrl = `${this.baseUrl}/reports`
    }

    return await this.http.get<any>(embedUrl, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        contentType: "application/json; charset=utf-8",
      }
    }).toPromise();
  }

  async printReport(request: any, reportId: string): Promise<any> {
    let embedUrl = `${this.powerBiUrl}/${reportId}/ExportTo`;

    return await this.http.post<any>(embedUrl, request, {
      //headers: {
      //  "Authorization": `Bearer ${this.accessToken}`,
      //  contentType: "application/json; odata.metadata=minimal",
      //}
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        "contentType": "application/json; odata.metadata=minimal",
      }
    }).toPromise();
  }



  async checkReportStatus(request: any, reportId: string, exportId: string): Promise<any> {
    let embedUrl = `${this.powerBiUrl}/${reportId}/exports/${exportId}`;

    //return await this.http.post<any>(embedUrl,request, {
    //  headers: {
    //    "Authorization": `Bearer ${this.accessToken}`,
    //    contentType: "application/json; odata.metadata=minimal",
    //  }
    //}).toPromise();

    return await this.http.get<any>(embedUrl, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`,
        contentType: "application/json; odata.metadata=minimal",
      }
    }).toPromise();

  }




  downloadReport(request: any, reportId: string, exportId: string, filename: string, calbasckfun: any): any {
    let embedUrl = `${this.powerBiUrl}/${reportId}/exports/${exportId}/file`;
    return this.http
      .get(embedUrl, {
        "headers": {
          "Authorization": `Bearer ${this.accessToken}`,
          contentType: "application/octet-stream",
        },
        responseType: 'blob'
      })
      .subscribe((res: any) => {
        let url = window.URL.createObjectURL(res);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        calbasckfun();
      });

  }

}
