import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor() { }
  public async getNodes(request: any[]): Promise<any> {
    let result: any[] = [{
      id: 1,
      name: 'Payroll_FilterData',
      inputs: 0,
      outputs: 1,
      infos: { name: 'Filter Data' },
      inputdata: {
        tablename: 1,
        uniquepracticeid: 1,
        datetype: 1,
        runOn: 1,
        fromdate: 1,
        todate: 1,
        flowId: 3,
      }
    },
    {
      id: 2,
      name: 'Payroll_ReduceBaseunits',
      inputs: 1,
      outputs: 1,
      infos: { name: 'Reduce Base Units' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        reducepercentage: 1,
        excludecptCodes: 0,
        providerOrderSequence: 0,  /* multiple input*/
        providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
        Isfirststep: 3 /* to identify if it firststep in flow  1 for first else 0 */
      }
    },
    {
      id: 3,
      name: 'Payroll_UpdateCustomBaseunits',
      inputs: 1,
      outputs: 1,
      infos: { name: 'Update Custom Base Units' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
        asaCodes: 0,/* multiple input cptcode|units,cptcode|units*/
        providerOrderSequence: 2,
        providerTypeId: 2,/*servicing or directing*/
        isanesthesia: 1, /* 1 for anesthesia  and 0 for non anesthesia rows */
        defaultunits: 0, /* should be used when we need to update hardcode value */
        excludeModifiers: 0, /*multiple input*/
        includeModifiers: 0, /*multiple input*/
      }
    },
    {
      id: 4,
      name: 'Payroll_NoBasePoints',
      inputs: 1,
      outputs: 1,
      infos: { name: 'No Base Points' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        uniqueServicelocationid: 1, /* multiple input*/
        providerOrderSequence: 2,
        providerTypeId: 2,/*servicing or directing*/
        cptCodes: 0, /* 1 for anesthesia  and 0 for non anesthesia rows */
        asaCodes: 0, /* should be used when we need to update hardcode value */
        excludeoccurances: 0, /*multiple input*/
        includeoccurances: 0, /*multiple input*/
      }
    },
    {
      id: 5,
      name: 'Payroll_DistributeProviderBaseUnitsEqually',
      inputs: 1,
      outputs: 1,
      infos: { name: 'Distribute Provider Base Units Equally' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        providerTypeId: 2,/*servicing or directing*/
        excludecptCodes: 1,
        Isfirststep: 3,
      }
    },
    {
      id: 6,
      name: 'Payroll_DistributeProviderBaseUnitsBasedonPerMinutesSpend',
      inputs: 1,
      outputs: 1,
      infos: { name: 'Distribute Provider Base Units/Minutes' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        excludecptCodes: 0, /*multiplt values*/
        uniqueServicelocationid: 0, /* multiple input*/
        Isfirststep: 3,
      }
    },
    {
      id: 7,
      name: 'Payroll_ProviderNoBaseUnitsForBlackOutPeriod',
      inputs: 1,
      outputs: 1,
      infos: { name: 'Provider No Base Units For Blackout Period' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        cptCodes: 0,  /*multiplt values*/
        excludecptCodes: 0, /*multiplt values*/
        uniqueServicelocationid: 0, /* multiple input*/
        workingDayswithTime: 1 /*PM_System_UniqueProviderId|Daysofweek|starttime|endtime, example 0|07:00|17:00  days of week like for sunday 0, for monday 1, for tuesday 2 and so on */,
        isanesthesia: 1 /* 1 for anesthesia  and 0 for non anesthesia rows */
      }
    },
    {
      id: 8,
      name: 'Payroll_setPayrollData',
      inputs: 1,
      outputs: 0,
      infos: { name: 'Save/update Payroll' },
      inputdata: {
        tablename: 2,
        uniquepracticeid: 2,
        flowId: 3
      }
    }

    ];
    return result;
  }
  public async getPayrols(request: any[]): Promise<any> {

  }
}
