
<section class="table-container-inner" tabindex="0">
  <table style="box-shadow:0px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="blank">
      <th mat-header-cell width="10%" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell width="30%" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.clientname}} </td>
    </ng-container>
    <ng-container matColumnDef="all">
      <th mat-header-cell width="5%" *matHeaderCellDef> All </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" [checked]="haveAllpermision(element)"
                      (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="weight">
      <th mat-header-cell width="5%" *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.view != null" [checked]="element.view"
                      (change)="onCheckboxChange($event,element,'view')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th mat-header-cell width="5%" *matHeaderCellDef> Add</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.add != null"
                      [checked]="element.add" (change)="onCheckboxChange($event,element,'add')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="one">
      <th mat-header-cell width="5%" *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.edit != null" [checked]="element.edit"
                      (change)="onCheckboxChange($event,element,'edit')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="two">
      <th mat-header-cell width="5%" *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin"
                      *ngIf="element.delete != null" [checked]="element.delete"
                      (change)="onCheckboxChange($event,element,'delete')"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="three">
      <th mat-header-cell width="5%" *matHeaderCellDef> Click </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="example-margin" *ngIf="element.click != null"
                      [checked]="element.click"
                      (change)="onCheckboxChange($event,element,'click')"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="stickyHeader">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>


<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
  <button mat-flat-button (click)="doSave()" color="primary">Save Changes</button>
  <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>
<!--<app-data-loader [isLoading]="_isLoading"></app-data-loader>-->
