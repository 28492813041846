import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { GroupService } from '../../group-managment/group.service';
import { Client, ClientGroups } from '../clientinfo';

type permissionKeys = 'view' | 'add' | 'edit' | 'delete' | 'click';
@Component({
  selector: 'app-client-groups',
  templateUrl: './client-groups.component.html',
  styleUrls: ['./client-groups.component.css']
})
export class ClientGroupsComponent {
  public _client!: Client;
  public groupid!: number
  @Input() set client(client: Client) {
    this._client = client;
    if (client?.clientid != -1) {
      this.getMasterDetails(client.clientid)
    }

  }
  get client() {
    return this._client;
  }

  clientGroups!: ClientGroups[];
  dataSource = new MatTableDataSource<ClientGroups>([]);
  assignAll: boolean = true;
  displayedColumns: string[] = ['blank', 'name', 'all', 'weight', 'symbol', 'one', 'two'];

  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit();
  }
  constructor(private groupService: GroupService, private dialog: MatDialog) { }
  //async doSave() {
  //  //setTimeout(s=>{
  //    let data: ConfirmDialogData = {} as ConfirmDialogData;
  //    data.showNo = true;
  //    data.message = 'Record saved successfully.';
  //    data.title = 'Information!';
  //    data.showYes = false;
  //    data.noText = 'OK';
  //    await Confirmation(this.dialog, data);
  //    this.onCancelEdit.emit();
  //  //}, 1000);
  //}

  async doSave() {
    //this.onShowLoading.emit(true);
    
    let request = this.clientGroups.filter(w => { return w.add || w.click || w.delete || w.delete || w.edit }).map(w => {
      console.log(w)
      return {
        clientid: this._client.clientid,
        groupid: w.groupid,
        aclpermissionsid: 0,
        add: w.add ? 1 : 0,
        edit: w.edit ? 1 : 0,
        delete: w.delete ? 1 : 0,
        view: w.view ? 1 : 0,
        click: w.click ? 1 : 0,
        loginuserid: -1
      }
    });
    console.log(request)
    this.groupService.setGroupClient(request).then(res => {
      //this.onShowLoading.emit(false);
      console.log(res)
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      Confirmation(this.dialog, data);
    });

  }


  async getMasterDetails(id: number) {
    let request = [{
      clientid: this._client.clientid,
      groupid: -1
      //clientId: id
    }]

    const lookups = await Promise.all([
      this.groupService.getGroupClient(request)
    ]);
    console.log(lookups)
    this.clientGroups = lookups[0].map((w: any, idx: number) => {
      
      let s: ClientGroups = {} as ClientGroups;
      s.add = w.add == 1;
      s.edit = w.edit == 1;
      s.click = w.click == 1;
      s.delete = w.delete == 1;
      s.view = w.view == 1;
      s.clientid = w.idx;
      s.clientname = w.clientname;
      s.assigned = w.isassigned;
      
      return s;

    });

    this.dataSource.data = this.clientGroups;
  }


  haveAllpermision(row: ClientGroups) {
    return (row.view || row.view == null) &&
      (row.add || row.add == null) &&
      (row.edit || row.edit == null) &&
      (row.delete || row.delete == null);
  }

  onCheckboxChange(event: any, element: ClientGroups, permissionType: permissionKeys) {
    if (permissionType.toString() == 'all') {
      element[`view`] = event.checked;
      element[`add`] = event.checked;
      element[`edit`] = event.checked;
      element[`delete`] = event.checked;
    }
    element[`${permissionType}`] = event.checked;
    console.log('Update successful:', this.dataSource);
  }

}
