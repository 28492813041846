import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ClientService } from '../../../services/client.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { GroupService } from '../../group-managment/group.service';
import { Client } from '../clientinfo';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent {
  public _client!: Client;
  public _isLoading: boolean = false;
  public isEdit: boolean = false;
  public hasClient_Edit: boolean = false;

  formAdd: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    practiceid: new FormControl('', [Validators.required]),
    isActive: new FormControl(true),
    pmsystemid: new FormControl('')
  });
  @Input() set client(client: Client) {
    if (client.clientid!=- 1) {
      this.isEdit = true;
      console.log(this.isEdit)
    }
    else {
      this.isEdit=false
    }
    this._client = client;
    console.log(this._client)
    if (client?.clientid)
     this.getClientDetails();
  }

  get client() {
    return this._client;
  }

  ngOnInit(): void {
    this.setPrivileges();
  }
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit(false);
  }
  constructor(private dialog: MatDialog, private clientservice: ClientService, private groupService: GroupService, private auth: AuthService) { }
  @Output() doNextEdit = new EventEmitter();
  async doSave(type: string) {
    
    if (this.formAdd.valid) {
      let clientNew: any = {
        ClientId: this.isEdit ? this.client?.clientid :this.formAdd.controls.practiceid.value,
        clientname: this.formAdd.controls.name.value,
        isactive: (this.client?.clientid ?? -1) == -1 ? 1 : this.formAdd.controls.isActive.value ? 1 : 0,
        //warehouseid: this.client?.warehouseid ?? -1,
        practiceid: this.formAdd.controls.practiceid.value,
        practicemanagementsystemid: this.formAdd.controls.pmsystemid.value,
        isnewclient: this.isEdit?0:1,
        //dagid: this.client?.dagid ?? -1,
        //hubspotid: this.client?.hubspotid ?? -1,
        netsuite: this.client?.netsuite ?? 1233,
      };
      
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.title = 'Information!';
      data.showYes = true;
      data.noText = 'NO';
      data.yesText = 'YES';
      let update = true;
      if (clientNew.isactive == 0 && this.client.isactive != clientNew.isactive) {
        data.message = 'Are you sure you want to in active this client?';
        update = await Confirmation(this.dialog, data);
      }

      if (update) {
        this.onShowLoading.emit(true);
        let lookups: any[] = [];
        if (clientNew.isactive == 0) {
          console.log(clientNew)
          lookups = await Promise.all([
            this.clientservice.setClient(clientNew),
            this.groupService.setGroupClient([{
              Clientid: this.client?.clientid ?? -1,
              groupid:-1,
              aclpermissionsid:0,
              add: 0,
              edit: 0,
              delete: 0,
              view: 0,
              click: 0,
              loginuserid:-1
            }])
          ]);
        }
        else {
          lookups = await Promise.all([
            this.clientservice.setClient(clientNew)
          ]);
          console.log(lookups)
        }
        this._isLoading = false;
        this.onShowLoading.emit(false);
       
        if (lookups[0]) {
          if (type != 'next') {
            data.message = lookups[0].result[0].errormessage;
            data.showYes = false;
            data.noText = 'OK';
            if (lookups[0].result[0].result == 1) {
              console.log(clientNew)
              await Confirmation(this.dialog, data);
              this._client.clientname = clientNew.clientname
              this._client.isactive = clientNew.isactive
              this._client.practiceid = clientNew.practiceid
              this._client.clientid = clientNew.ClientId
              this.onUpdateEdit.emit(this._client);
            }
            else {
              await Confirmation(this.dialog, data)
            }
          }
          else {
            this.onUpdateEdit.emit(this._client);
            this.doNextEdit.emit(1)
          }

        }
        //}, 1000);
      }
    }
    else {
      this.formAdd.markAllAsTouched();
      return;
    }

  }
  getClientDetails() {
    console.log(this._client)
    this.formAdd.controls.name.setValue(this.client.clientname);
    this.formAdd.controls.practiceid.setValue(this.client.practiceid);
    this.formAdd.controls.isActive.setValue(this.client.isactive);
  }
  @Output() onShowLoading = new EventEmitter();

  setPrivileges() {
    this.hasClient_Edit = this.auth.hasPrivilege(PrivilegeValues.ClientManage_Edit);
  }
}

